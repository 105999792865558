<div mat-dialog-actions align="end">
    <mat-icon id="close" (click)="onClose()">close</mat-icon>
</div>
<div id="mat-dialog-contents" mat-dialog-content>
    <form ngNoForm [formGroup]="createSignForm" [action]="actionUrl" method="post" #signForm target="layoutFrame">
        <input type="hidden" name="isIntPortal" formControlName="isIntPortal" />
        <input type="hidden" name="isPortalSignature" formControlName="isIntPortal" />
        <input type="hidden" name="mailbox" formControlName="mailbox" />
        <input type="hidden" name="themid" formControlName="themid" />
        <input type="hidden" name="accountDID" formControlName="accountDID" />
    </form>
    <div class="iframe-container">
        <iframe id="layoutFrame" class="iframe-responsive" [frameBorder]="0" name="layoutFrame"
            allow="clipboard-write; encrypted-media" allowfullscreen allowpaymentrequest="true" #signFrame>
        </iframe>
    </div>
</div>
