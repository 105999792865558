import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { DEFAULT_THUMB_PREVIEW } from '../constants/constants';

@Directive({
  selector: '[thumbPreview]'
})
export class ThumbPreviewDirective {

  private timer: any;
  private pic;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2) {

    this.pic = document.createElement('img');
    this.pic.id = "preview-image";
    this.pic.role = "img";
    this.pic.style.cssText = this.style.toString()
  }


  @Input() thumbSelected: any;

  @HostListener('mouseenter') mouseover() {
    this.timer =
      setTimeout(() => this.previewImage(), 400);
  }

  private style = {
    "display": "block;",
    "position": "fixed;",
    "background-color": "var(--background);",
    "padding": "5px;",
    "border": "1px dashed var(--gray-200);",
    "color": "var(--dark);",
    "text-decoration": "none;",
    "left": "40%;",
    "top": "20%;",
    "z-index": "1000;",
    "width": "442px;",
    "height": "464px;",

    "background": "#fffdfd url(/assets/images/simple-spinner.gif) no-repeat scroll center center;",

    toString(): string {
      return Object.entries(this)
        .filter(([k, v]) => typeof v != 'function')
        .reduce((str, [p, val]) => {
          return `${str}${p}: ${val} `;
        }, '');
    }
  }


  previewImage() {
    this.pic.src = this.thumbSelected != null && this.thumbSelected != "" ? this.thumbSelected : DEFAULT_THUMB_PREVIEW;
    this.renderer.appendChild(this.elementRef.nativeElement, this.pic)
  }

  @HostListener('mouseleave') mouseleave() {
    clearTimeout(this.timer);
    this.renderer.removeChild(this.elementRef.nativeElement, this.pic)
  }

}
