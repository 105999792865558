<div class="container">
    <div class="container-header">
        <h1>{{ "billing.balance.settings" | transloco}}</h1>
        <button mat-icon-button aria-label="close dialog" (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <hr />
    <div class="form">

        <div class="display">
            <label id="billing-settings-label" hidden>{{ 'billing.balance.settings' | transloco }}</label>
            <mat-radio-group aria-labelledby="billing-settings-label">
                <mat-radio-button class="value" aria-label="{{ 'billing.balance.online' | transloco }}">
                    {{ 'billing.balance.online' | transloco }}
                </mat-radio-button>
                <mat-radio-button class="value" aria-label="{{ 'billing.balance.email' | transloco }}">
                    {{ 'billing.balance.email' | transloco }}
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <hr />
    <div class="button-container">
        <button id="update-button" name="update-button" aria-labelledby="update-button-label" mat-button
            [mat-dialog-close]="" class="update-button btn btn-md btn-info">
            <label>
                {{ "billing.usage.update" | transloco}}
            </label>
            <mat-icon>play_circle_filled</mat-icon>
        </button>
    </div>

    <div class="footer">
    </div>
</div>