import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';

@Injectable()
export class ThemeService {
  private style: HTMLLinkElement;
  private cssFile: string;
  private themeCSSID = 'main';

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) { }

  setTheme(theme: string, renderer2: Renderer2) {
    this.cssFile = `${theme}.css`;
    this.removeExistingThemeStyle(renderer2, this.themeCSSID);
    this.style = renderer2.createElement('link') as HTMLLinkElement;
    renderer2.setProperty(this.style, 'rel', 'stylesheet');
    renderer2.setProperty(this.style, 'href', this.cssFile);
    renderer2.setProperty(this.style, 'id', this.themeCSSID);
    renderer2.appendChild(this.document.head, this.style);
  }

  setFavIco(name: string) {
    const icoFile = `../assets/images/${name}`;
    this.document.getElementById('favicon')?.setAttribute('href', icoFile);
  }

  removeExistingThemeStyle(renderer2: Renderer2, themeCSSID: string) {
    const themeIDHTMlElem = this.document.getElementById(themeCSSID);
    if (themeIDHTMlElem) {
      renderer2.removeChild(this.document.head, themeIDHTMlElem);
    }
  }
}
