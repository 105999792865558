<div class="content pb-30">
  <div class="main">
    <div class="upper-section">
      <div class="title-section">
        {{ "billing.summary.section.title" | transloco }}
      </div>
      <hr class="separator-line" />
    </div>

    <div class="toolbar">
      <label hidden id="single-message-go-back-label">{{
        "general.goBack" | transloco
      }}</label>
      <button
        mat-icon-button
        matTooltip="{{ 'general.goBack' | transloco }}"
        [routerLink]="['/billing']"
        aria-labelledby="single-message-go-back-label"
      >
        <mat-icon aria-labelledby="single-message-go-back-label"
          >arrow_back</mat-icon
        >
      </button>
      <button
        mat-icon-button
        attr.aria-label="{{ 'general.print' | transloco }}"
        matTooltip="{{ 'general.print' | transloco }}"
        (click)="print()"
      >
        <mat-icon>print</mat-icon>
      </button>
    </div>

    <div class="segment">
      <div class="description">
        {{ "billing.choose.date" | transloco }}
      </div>
      <div class="horizontal">
        <myportal-date-range-picker
          [(start)]="filter.start"
          [startLabel]="('billing.table.header.from' | transloco) + ':'"
          [(end)]="filter.end"
          [endLabel]="('billing.table.header.to' | transloco) + ':'"
        >
        </myportal-date-range-picker>
        <button
          class="btn btn-md btn-info"
          (click)="
            applyFilter({
              start: filter.start,
              end: filter.end
            })
          "
        >
          {{ "billing.button.go" | transloco }}
        </button>
      </div>

      <ng-container *ngIf="dataSource">
        <myportal-summary-table
          class="printable-area"
          [dataList]="dataList"
          [dataSource]="dataSource"
        >
        </myportal-summary-table>
      </ng-container>
    </div>
  </div>
</div>
