import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { Service } from 'src/app/auth/models/user.single.model';
import { AuthFacadeService } from 'src/app/auth/services/auth-facade.service';
import { Brands } from "../constants/constants";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnDestroy, AfterViewInit {

  protected readonly brands = Brands;

  @Input() toggleSide: any;
  isComposeEnabled = false;
  isSignatureEnabled = false;
  isStorageEnabled = false;
  subscriptions: Subscription[] = [];
  inboundService$: Observable<Service | undefined>;
  outboundService$: Observable<Service | undefined>;
  userBrand$: Observable<string | undefined>;
  terminated$: Subject<boolean> = new Subject<boolean>();
  userSuspendedFlag: number = 0;
  currentSection = ''
  isContactsEnabled = false;
  isFileShareEnabled = false;
  isLegacyEnabled = false;

  constructor(private cdRef: ChangeDetectorRef, private authFacadeService: AuthFacadeService) { }

  ngAfterViewInit(): void {

    const localCounter = window.setTimeout(() => {
      const currentId = localStorage.getItem("portalSessionID") || "";
      this.isLegacyEnabled = (currentId !== "");
      clearTimeout(localCounter);
    }, 1000);

    setTimeout(() => {
      this.inboundService$ = this.authFacadeService.currentService$.pipe(distinctUntilChanged(), takeUntil(this.terminated$));
      this.outboundService$ = this.authFacadeService.userSingle$
        .pipe(
          map(result => result?.userSingle?.user?.services),
          map(svcs => svcs?.filter(svc => svc.serviceType === "OUTBOUND_FAXING")),
          map(svcList => svcList !== undefined ? svcList[0] : undefined),
          distinctUntilChanged(),
          takeUntil(this.terminated$)
        );

      this.userBrand$ = this.authFacadeService.userSingle$
        .pipe(
          map(result => result?.userSingle.user.account.brand),
          takeUntil(this.terminated$)
        );

      this.authFacadeService.currentSection$.pipe(takeUntil(this.terminated$)).subscribe(value => {
        this.currentSection = value.replace('/', '').trim();
      });

      this.authFacadeService.authState$.pipe(map(s => s.suspendedFlag), takeUntil(this.terminated$)).subscribe(value => this.userSuspendedFlag = value);

      this.subscriptions.push(
        combineLatest([this.inboundService$, this.outboundService$, this.userBrand$])
          .subscribe(([inbound, outbound, brand]) => {
            if (inbound !== undefined) {
              this.isComposeEnabled = (inbound.features?.isSendEnabled ?? false)
                && outbound !== undefined
                && outbound?.emailAddresses.filter(em => em.trim() !== "")?.length > 0;
              this.isSignatureEnabled = inbound.features?.isSignatureEnabled ?? false;
              this.isStorageEnabled = inbound.features?.isStorageEnabled ?? false;
              this.isContactsEnabled = brand !== undefined && outbound !== undefined;
            } else {
              this.isComposeEnabled = false;
              this.isSignatureEnabled = false;
              this.isStorageEnabled = false;
              this.isContactsEnabled = false;
            }
            this.isFileShareEnabled = brand === this.brands.EFAX.toUpperCase();
          }
          )
      );

      this.subscriptions.push(
        this.authFacadeService.isLegacySet$.pipe(takeUntil(this.terminated$))
          .subscribe((value) => {
            this.isLegacyEnabled = value;
          })
      );
      const currentId = localStorage.getItem("portalSessionID") || "";
      this.isLegacyEnabled = (currentId !== "");
    }, 0);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.terminated$.next(true);
  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

}
