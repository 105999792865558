import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { SUSPEND_FLAG_ALLOW_LIMIT } from 'src/app/shared/constants/constants';
import { AuthFacadeService } from '../../auth/services/auth-facade.service';

@Injectable({
  providedIn: 'root'
})
export class ContactsGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private authFacade: AuthFacadeService, private router: Router) { }

  canLoad() {
    return this.authFacade.authState$.pipe(
      filter(state => state !== undefined),
      take(1),
      map(state => { return { hasToken: !!state.access_token, flag: state.suspendedFlag } }),
      map(result => {
        if (result.flag > SUSPEND_FLAG_ALLOW_LIMIT) {
          return this.router.createUrlTree(['/error'], { queryParams: { message: "Access not allowed - account suspended" } });
        } else {
          if (result.hasToken)
            return true;
          else
            return this.router.parseUrl('/login');
        }
      })
    );
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authFacade.token$.pipe(take(1), map((value: string) => {
      if (value !== undefined && !!value) {
        return true;
      } else {
        this.router.navigate(['/login']);
        return false;
      }
    })
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (location.pathname.includes("contacts/new") || location.pathname.includes("contacts/view")) {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
}
