import { Service, UserSingle } from '../../models/user.single.model';

export interface State {
    authorized: boolean;
    access_token: string;
    expires_in: number;
    set_in: number;
    user_name: string;
    friendly_name: string;
    requires_log_in: boolean;
    is_busy: boolean;
    userSingle: UserSingle | undefined;
    currentService: Service | undefined;
    expiring: boolean;
    is_error: boolean;
    last_error: any | undefined;
    legacyState: { typeNumber: number, locationUrl: string } | undefined;
    suspendedFlag: number;
    fusionToken: string;
}

export const initialState: State = {
    authorized: false,
    access_token: "",
    expires_in: 0,
    set_in: 0,
    user_name: "",
    friendly_name: "",
    requires_log_in: false,
    is_busy: false,
    userSingle: undefined,
    currentService: undefined,
    expiring: false,
    is_error: false,
    last_error: undefined,
    legacyState: undefined,
    suspendedFlag: 0,
    fusionToken: ""
}
