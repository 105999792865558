import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { LoginModel, ssoResponseError } from '../models/login.model';
import { LocationStrategy } from '@angular/common';
import * as fromStore from '../store/reducers';
import * as fromSelector from '../store/selectors/login.selector';
import { Observable, Subscription, of } from 'rxjs';
import { State } from '../store/state/login.state';
import { RFC_EMAIL_REGEX } from 'src/app/shared/constants/constants';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { catchError, filter, map, pairwise, startWith, take } from 'rxjs/operators';
import { ForgotPasswordComponent } from './forgot-password.component';
import { NavigationEnd, Router } from '@angular/router';
import { AuthFacadeService } from "../../auth/services/auth-facade.service";
import { ForgotLoginComponent } from './forgot-login.component';
import { forbiddenUserNameValueValidator } from 'src/app/shared/functions/customFieldValidators';
import { BrandService } from 'src/app/shared/services/brand.service';
import { LoginFacade } from '../facade/login.facade';
import { DomSanitizer, Meta, SafeResourceUrl } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { RecaptchaService } from "./recaptcha/recaptcha.service";
import { LoadingService } from 'src/app/shared/services/loading.service';
import { LangStateService } from "../../shared/lang/lang-state.service";

@Component({
  selector: 'login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('loginIframe') loginIframe: ElementRef;

  form!: UntypedFormGroup;
  step2 = false;
  showPassword = false
  readonly viewModel$: Observable<State>;
  suscriptions: Subscription[] = [];
  usingSSO = false;
  ssoErrors: ssoResponseError[] = [];
  cleanState = true;
  recaptchaDisable = false;
  recaptchaKeyV2 = environment.recaptcha.siteKeyV2;
  createAccountLink = 'https://www.efax.com/pricing';
  @ViewChild('password') passwordElement: ElementRef;
  previousPath: any;
  currentPath: any;
  brandConfig: any = undefined;
  loginLegacyURL: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
  loadingCounter: number = 0;
  isLegacySet: boolean = false;
  protected authErrorMessage: string = '';

  pageAccessedByReload = (
    (window.performance.navigation && window.performance.navigation.type === 1) ||
    window.performance
      .getEntriesByType('navigation')
      .map((nav: any) => nav.type)
      .includes('reload')
  );


  constructor(
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private readonly store: Store<fromStore.State>,
    private router: Router,
    private location: LocationStrategy,
    private authService: AuthFacadeService,
    private brandService: BrandService,
    private recaptchaService: RecaptchaService<LoginModel>,
    private cookieService: CookieService,
    private loginFacade: LoginFacade, private sanitizer: DomSanitizer,
    public loadingService: LoadingService,
    private translocoService: LangStateService,
    private metaService: Meta
  ) {

    this.suscriptions.push(
      this.brandService.config$
        .pipe(filter((config: any) => config))
        .subscribe((config: any) => {
          this.brandConfig = config;
          this.createAccountLink = `https://www.${config.id}.com/pricing`
          this.metaService.updateTag({ property: 'og:image', content: `/assets/images/${config.logo.name}` });
          this.metaService.updateTag({ property: 'og:url', content: `https://myportal.${config.id}.com` });
        })
    );

    this.form = this.formBuilder.group({
      username: [null, [Validators.required, forbiddenUserNameValueValidator(), Validators.minLength(0), Validators.maxLength(100)]],
      password: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]]
    });

    this.viewModel$ = this.store.select(fromSelector.loginPageViewModel);

    this.suscriptions.push(
      this.loginFacade.failedAttempts$.subscribe(attempts => {

        this.translocoService.translate(this.getLoginMessageId(attempts))
          .subscribe(translation => {
            this.authErrorMessage = translation;
          });
      })
    );

    this.suscriptions.push(
      this.viewModel$.subscribe(state => {

        if (state?.ssoSuccess?.redirect_url && this.usingSSO) {
          window.location.href = state.ssoSuccess.redirect_url;
        }

        if (state?.ssoErrors !== undefined && this.fieldUsername.value?.toString().trim().length > 0) {
          this.onCloseSSO();
          this.passingOnStepTwo();
        }

        this.loadingService.setLoading(state?.isBusy);
      })
    );
  }

  ngOnDestroy(): void {
    this.suscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit() {

    const isRemoteOn = this.cookieService.get("_vis_rmt_on") === "true";
    if (!isRemoteOn) {
      const url = `${environment.accountDetailsBaseUrl}removePortalContext?isIntPortal=true`;
      this.loginLegacyURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    this.router.events
      .pipe(
        filter((x) => x instanceof NavigationEnd), // filter out only the navigation end event
        startWith(null), // set an initial value
        map((x: any) => x && x.url), // get only the necessary value
        pairwise() // emit both the previous and current value
      )
      .subscribe((event) => {
        this.previousPath = event[0];
        this.currentPath = event[1];

        if (this.currentPath === '/login') {
          this.disableBackButton();
        }
      });

    if (this.pageAccessedByReload) {
      this.disableBackButton();
    }

    this.recaptchaService.afterClosed.subscribe((recaptchaModel: LoginModel) => {
      this.login(recaptchaModel)
    });

    this.recaptchaService.ifSkipped.subscribe((recaptchaModel: LoginModel) => {
      this.login(recaptchaModel)
    });
  }

  disableBackButton() {
    history.pushState(null, '', window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, '', window.location.href);
    });
  }

  onStepTwo() {
    this.loadingService.setLoading(true);
    const userName: string = this.fieldUsername.value.toString().trim();

    this.fetchLinkedAppSimple(userName).subscribe(redirectToMGMT => {

      if (redirectToMGMT) {
        window.location.href = `${environment.mgmtUrl}`;
        this.form.controls['username'].setValue("");
        return;
      }

      const matchEmailFormat = userName.match(RFC_EMAIL_REGEX);
      if (matchEmailFormat !== null) {
        this.onCheckSSO();
        return;
      }
      // FMA-15190: no longer validation of proper number format, going straight to step 2 to try atttempt login
      this.passingOnStepTwo();
    });

  }

  private passingOnStepTwo() {
    this.step2 = true;
    this.loadingService.setLoading(false);
    if (this.passwordElement?.nativeElement) {
      setTimeout(() => {
        this.passwordElement.nativeElement.focus();
      }, 100);
    }
  }

  onSubmit() {
    this.loginFacade.resetLoginStatus()

    const loginModel: LoginModel = {
      skipCaptcha: this.fieldUsername.value.trim(),
      username: this.fieldUsername.value.trim(),
      password: this.fieldPassword.value
    }

    this.loadingService.setLoading(true);
    this.recaptchaService.displayIfNeed(loginModel)
  }

  login(loginModel: LoginModel) {
    this.loginFacade.login(loginModel, (this.brandConfig?.id as string || "").toUpperCase());
    this.cleanState = false;
  }

  onCheck() {
    this.showPassword = !this.showPassword
  }

  showSSO() {
    this.usingSSO = true;
  }

  onCheckSSO() {
    this.showSSO();
    this.ssoErrors = [];
    this.loginFacade.validateSSO(this.fieldUsername.value);
  }

  onCloseSSO() {
    this.usingSSO = false;
  }

  onForgotLoginId() {
    this.dialog.open(ForgotLoginComponent, {
      minWidth: '600px',
      panelClass: 'forgot-login-modal',
      hasBackdrop: true
    });
  }

  onForgotPassword() {
    const forgotPasswordModal = this.dialog.open(ForgotPasswordComponent, {
      width: '600px',
      panelClass: 'forgot-login-modal',
      hasBackdrop: true,
      data: { faxNumber: this.fieldUsername.value },
    });
    forgotPasswordModal.afterClosed().pipe(take(1)).subscribe(value => {
      if (value) {
        this.goToStepOne();
      }
    });
  }

  goToStepOne() {
    this.step2 = false;
    this.cleanState = true;
    this.fieldPassword.reset();
  }

  get fieldUsername(): AbstractControl {
    return this.form.controls['username'];
  }

  get fieldPassword(): AbstractControl {
    return this.form.controls['password'];
  }

  get allowedToStepTwo(): boolean {
    const value = this.fieldUsername.value;
    return !!value && value.trim().length > 0 && this.fieldUsername.valid;
  }

  get allowedToSignIn(): boolean {
    const value = this.fieldPassword.value;
    return !!value && value.trim().length > 0;
  }

  fetchLinkedAppSimple(user: string): Observable<boolean> {
    return this.authService.linkedAppSimple(user)
      .pipe(
        map((response) => {
          console.log("LinkedAppSimple: " + response);
          return response === "1";
        }),
        catchError((error: any) => {
          console.error("LinkedAppSimple Error: " + error);
          return of(false);
        })
      );
  }

  onLoad(event: any) {
    if (event?.eventPhase === 2) {
      this.loadingCounter++;
      console.log(this.loadingCounter);
      if (this.loadingCounter === 2) {
        const localCounter = window.setTimeout(() => {
          this.authService.dispatchSetLegacyNone();
          this.loadingCounter = 0;
          clearTimeout(localCounter);
        }, 3000);
      }
    }
  }

  private getLoginMessageId(attemptsCount = 0): string {
    if (attemptsCount < 4)
      return "login.error.response"
    else if (attemptsCount == 4)
      return "login.error.4_attempts"
    else return "login.error.more_then_4_attempts"
  }
}
