<h1 class="title-dialog" mat-dialog-title>{{ 'contacts.exclude' | transloco }}</h1>
<div mat-dialog-content>
  <label id="example-radio-group-label">{{ 'contacts.exclude.which' | transloco }}</label>
  <ul>
    <li *ngFor="let item of data.contacts">
      <label>{{item.name}}</label>
      <label *ngIf="!item.name">{{item.fax}}</label>
    </li>
  </ul>
</div>


<div mat-dialog-actions class="buttons">
  <button
    class="btn btn-md btn-cancel"
    aria-label="Cancel"
    (click)="onNoClick()">{{ 'contacts.cancel' | transloco }}
  </button>

  <button
    class="btn btn-md btn-info"
    aria-label="Confirm"
    [mat-dialog-close]="data.contacts"
    cdkFocusInitial>{{ 'contacts.confirm' | transloco }}
  </button>
</div>
