import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromFeature from '../store/reducers';
import { expiringFinished, expiringStarted, fireLegacyUrlCall, loadAuth, refreshAuth, refreshTiming, removeAuth, setAuth, setCurrentService, setLegacyUrlNone, settingUserName, updateUserSingle } from '../store/actions/auth.actions';
import { Service, UserSingle } from '../models/user.single.model';
import { filter, map, take } from 'rxjs/operators';
import { State } from '../store/models/auth.state';
import { FEEDBACK_DURATION, REFRESH_SESSION_TIMEOUT_SECONDS, TOKEN_EXPIRATION_SECONDS } from 'src/app/shared/constants/constants';
import { AuthApi } from '../api/auth.api';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthFacadeService {
  readonly token$: Observable<string>;
  readonly fusionToken$: Observable<string>;
  readonly userSingle$: Observable<UserSingle | undefined>;
  readonly isBusy$: Observable<boolean>;
  readonly currentService$: Observable<Service | undefined>;
  readonly authState$: Observable<State>;
  readonly currentSection$: Subject<string> = new Subject<string>();
  readonly legacyState$: Observable<{ typeNumber: number, locationUrl: string } | undefined>;
  readonly isLegacySet$: Subject<boolean> = new Subject<boolean>();
  timerTriggered: boolean = false;
  private refreshTokenTriggered: boolean = false;
  private localSubscriptions: Subscription[] = [];
  forceUpdateUserFlag: boolean = false;
  forceUpdateServiceNumber: string;
  // readonly performUserSingleUpdate$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private store: Store<fromFeature.AppState>,
    private authApi: AuthApi) {

    this.token$ = store.pipe(
      select(fromFeature.getAccessToken)
    );
    this.fusionToken$ = store.pipe(select(fromFeature.getFusionToken));
    this.userSingle$ = store.pipe(
      select(fromFeature.getUserSingle)
    );
    this.isBusy$ = store.pipe(select(fromFeature.getIsBusy));
    this.currentService$ = store.pipe(select(fromFeature.getCurrentService));
    this.authState$ = store.pipe(select(fromFeature.getAuthStateAll));
    this.legacyState$ = store.pipe(select(fromFeature.getLegacyState));
  }

  updateUserSingle(currentNumber: string) {
    this.store.dispatch(updateUserSingle({ currentDID: currentNumber }))
  }

  loadAuth() {
    this.checkAndSetTimerForRefresh();
  }

  refreshToken() {
    this.store.dispatch(refreshAuth());
    const url = `${environment.accountDetailsBaseUrl}refresh_portal_session?isIntPortal=true`;
    const localTimer = window.setTimeout(() => {
      this.store.dispatch(fireLegacyUrlCall({ typeNumber: 2, locationUrl: url }));
      clearTimeout(localTimer);
    }, 2000);
  }

  setAuth(token: string, username: string, suspendedFlag: number, fusionToken: string) {
    const currentSetIn = new Date().valueOf();
    const payload = {
      token: token,
      set_in: currentSetIn,
      expires: currentSetIn + (TOKEN_EXPIRATION_SECONDS * 1000),
      is_logged: true,
      username: username,
      suspendedFlag: suspendedFlag,
      fusionToken: fusionToken
    };
    this.store.dispatch(setAuth({ auth: payload }));
    let waitingALittle: number = 0;
    waitingALittle = window.setTimeout(() => {
      this.checkAndSetTimerForRefresh();
      clearInterval(waitingALittle);
    }, 1000);
  }

  signOff() {
    this.store.dispatch(removeAuth());
  }

  setCurrentService(service: Service) {
    this.store.dispatch(setCurrentService({ service: service }));
  }

  isSignedIn(): Observable<boolean> {
    return this.token$.pipe(
      take(1),
      map(value => {
        return !!value;
      })
    )
  }

  startExpiringProcess() {
    this.store.dispatch(expiringStarted());
  }

  endExpiringProcess() {
    this.store.dispatch(expiringFinished());
  }

  removeAuth() {
    this.refreshTokenTriggered = false;
    this.localSubscriptions.forEach(s => s.unsubscribe());
    this.store.dispatch(removeAuth());
  }

  setUserName(username: string) {
    this.store.dispatch(settingUserName({ username: username }));
  }

  get123MailRedirectUrl(serviceKey: string, brand: string) {
    return this.authApi.getSessionId(serviceKey, brand);
  }

  linkedAppSimple(info: string) {
    return this.authApi.getLinkedAppSimple(info);
  }

  storeWebMyAccountToken(jsonResp: any) {
    localStorage.setItem("portalSessionID", jsonResp.portalSessionID);
    localStorage.setItem("portalSessionIdHash", jsonResp.sessionIdHash);
    this.isLegacySet$.next(true);
  }

  async webMyAccountLogout(portalSessionID: string) {
    this.isLegacySet$.next(false);
  }

  private checkAndSetTimerForRefresh() {
    if (!this.refreshTokenTriggered) {
      this.authState$.pipe(take(1)).subscribe(value => {
        if (value !== undefined && !!value.access_token) {
          const upperLimit = value.set_in + (REFRESH_SESSION_TIMEOUT_SECONDS * 1000);
          const justNow = new Date().valueOf();
          // only when is not expired yet
          if (justNow < upperLimit) {
            this.localSubscriptions.push(
              timer((upperLimit - justNow), (REFRESH_SESSION_TIMEOUT_SECONDS * 1000)).subscribe(() => {
                this.refreshTokenTriggered = true;
                this.refreshToken();
              })
            )
            const currentValue = localStorage.getItem("portalSessionID") || "";
            this.isLegacySet$.next((currentValue !== ""));
          } else {
            this.refreshOnDemmand();
          }
        }
      })
    } else {
      this.store.dispatch(loadAuth());
    }
  }

  private refreshOnDemmand() {
    this.refreshToken();
    const localCounter = window.setTimeout(() => {
      this.store.dispatch(loadAuth());
      clearTimeout(localCounter);
    }, FEEDBACK_DURATION);
  }

  dispatchSetLegacyNone() {
    this.store.dispatch(setLegacyUrlNone());
  }

  sleep(ms: number): Promise<unknown> {
    return new Promise(resolve => window.setTimeout(resolve, ms));
  }
}
