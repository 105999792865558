import { ContactSortBy, ContactSortInput } from "./contact.model";
import { SortDirection } from "./shared.model";

export interface ContactsTableData {
    contactId: string;
    name: string;
    company: string;
    fax: string;
    email: string;
    countryCode: string;
    firstName: string;
    lastName: string;
  }

  export const CHECK_NEW_MESSAGES_INTERVAL = 300000;
  export const TABLE_DATA: ContactsTableData[] = [];
  export const CONTACT_DEFAULT_COLUMNS = ['select', 'name', 'company', 'fax', 'email'];
  export const CONTACT_SELECT_COLUMNS = ['select', 'name', 'company', 'fax','email'];
  export const CONTACT_SENT_COLUMNS = ['select', 'name', 'company', 'fax', 'email'];
  export const SORTABLE_COLUMNS = ['name', 'company', 'fax', 'email'];

  export const SNACK_BAR_DURATION_MS = 2000;
  export const LIMIT_TOTAL_COUNT_DEFAULT = 100;
  export const OFFSET_DEFAULT : Number = +0;
  export const DEFAULT_CONTACTS_SORT_INPUT: ContactSortInput = { sortBy: ContactSortBy.FIRST_NAME, sortDirection: SortDirection.ASC}
