import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { isCorrectFolderName } from "../../../shared/functions/stringValidators";

export interface RenameFolderData {
  folderName: string
  folderId: string
}

@Component({
  selector: 'rename-folder-item',
  templateUrl: 'rename-folder-item.component.html',
  styleUrls: ['./rename-folder-item.component.scss']
})
export class RenameFolderItem implements AfterViewInit {

  @ViewChild("renameInput") renameInput: ElementRef;
  @ViewChild("renameApproveButton") renameApproveButton: ElementRef;

  @Output() onDestroy: EventEmitter<void> = new EventEmitter<void>();
  @Output() onApprove: EventEmitter<RenameFolderData> = new EventEmitter<RenameFolderData>();

  @Input() originalFolderName = "";
  @Input() folderId!: string;

  private canClick: boolean = false;
  private canApprove: boolean = true;
  private originalBackgroundColor: any;

  constructor(
    private component: ElementRef<HTMLElement>
  ) { }

  @HostListener('click')
  clickInside() {
    this.canClick = true;
  }

  @HostListener('document:click')
  @HostListener('document:keydown.escape', ['$event'])
  clickout() {
    if (!this.canClick) {
      this.onDestroy.emit()
      this.component.nativeElement.remove()
    }
    this.canClick = false
  }

  @HostListener('document:keydown.enter', ['$event'])
  approve() {
    if (this.canApprove) {
      this.onApprove.emit({
        folderId: this.folderId,
        folderName: this.renameInput.nativeElement.value
      })
    }
  }

  ngAfterViewInit() {
    this.canClick = true;
    this.renameInput.nativeElement.focus();
    this.originalBackgroundColor = this.renameInput.nativeElement.style.backgroundColor;
  }

  onChange(value: string) {
    if (!isCorrectFolderName(value) && value.length > 0) {
      this.canApprove = false
      if (this.renameApproveButton.nativeElement !== null) {
        this.renameApproveButton.nativeElement.classList.add("disabled")
        this.renameApproveButton.nativeElement.classList.remove("enabled")

        this.renameInput.nativeElement.classList.add('blink_me');
      }
    } else {
      this.canApprove = true
      if (this.renameApproveButton.nativeElement !== null) {
        this.renameApproveButton.nativeElement.classList.add("enabled")
        this.renameApproveButton.nativeElement.classList.remove("disabled")
      }
    }
    const _this = this
    setTimeout(function () {
      if (_this.renameInput.nativeElement !== null) {
        _this.renameInput.nativeElement.classList.remove('blink_me');
      }
    }, 300)
  }

}
