import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorRoutingModule } from './error-routing.module';
import { ErrorComponent } from './components/error.component';
import { TranslocoRootModule } from 'src/transloco-root.module';
import { SharedModule } from '../shared/shared.module';
import { PipesModule } from "../shared/pipes/pipes.module";

@NgModule({
    declarations: [
        ErrorComponent
    ],
    providers: [],
    imports: [
        CommonModule,
        ErrorRoutingModule,
        TranslocoRootModule,
        SharedModule,
        PipesModule
    ]
})
export class ErrorModule { }
