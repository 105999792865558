<div class="modal">
  <div class="toolbar space-between">
    <h3 class="title">
      {{ "send.fax.options.title" | transloco }}
    </h3>
    <button mat-icon-button aria-label="close dialog" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="hr"></div>

  <div class="explanation">
    <p>
      <label
        >
        {{ "send.fax.options.any.changes" | transloco }}
      </label>
    </p>
  </div>

  <div class="hr"></div>

  <div class="fields">
    <div class="fieldset">
      <label for="csid">
        {{ "send.fax.options.fax.scid" | transloco }}:
      </label>
      <input name="csid" matInput formControlName="csid" />
    </div>

    <div class="checkbox-container">
      <div>
        <input name="checkbox" type="checkbox" />
      </div>
      <div>
        <label>
          {{ "send.fax.options.deliver.fax.receipts" | transloco }}:
        </label>
      </div>
    </div>

    <div class="fieldset">
      <label for="email">
        {{ "send.fax.options.default.fax.receipt.email" | transloco }}:
      </label>
      <select name="email">
        <option value="tif">{{ "jamie.sloniker@consensus.com" }}</option>
      </select>
    </div>
  </div>

  <div class="hr"></div>

  <div class="update-button-container">
    <button
      id="update-button"
      name="update-button"
      aria-labelledby="update-button-label"
      class="btn btn-md btn-info update-button"
    >
      <label>
        {{ "billing.usage.update" | transloco }}
      </label>
      <mat-icon>play_circle_filled</mat-icon>
    </button>
  </div>
</div>
