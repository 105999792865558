import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { LangStateService } from '../../shared/lang/lang-state.service';

@Pipe({
  name: 't',
  pure: false
})
export class I18nPipe implements PipeTransform {
  result?: string;

  constructor(private _ref: ChangeDetectorRef, 
    private translateService: LangStateService) {}

  transform(value: string): unknown {    
    // const token = value.replace(/\s/g, "")
    const token = value.trim()
    this.translateService.translate(token)
    .subscribe(translation => {      
      this._ref.markForCheck();
      this.result = translation || value
    });        
    return this.result
  }
}