import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ViewMessagesFeatureState } from '../index';
import * as fromViewMessagesReducer from '../reducers/view-messages.reducer';

export const selectViewMessagesState = createFeatureSelector<ViewMessagesFeatureState>("viewmessages");
export const selectFolderList = createSelector(selectViewMessagesState, state => state.viewMessagesFeatureState.folderList?.folder);
export const selectIsLoadingFolderList = createSelector(selectViewMessagesState, state => state.viewMessagesFeatureState.loadingFolderList);
export const selectFaxList = createSelector(selectViewMessagesState, state => state.viewMessagesFeatureState.faxList?.faxes);
export const selectLoadingFaxes = createSelector(selectViewMessagesState, state => state.viewMessagesFeatureState.loadingFaxList);
export const selectIsCreatingFolder = createSelector(selectViewMessagesState, state => state.viewMessagesFeatureState.isCreatingFolder);
export const selectRenameFolderSuccess = createSelector(selectViewMessagesState, state => state.viewMessagesFeatureState.renameFolderSuccess);
export const selectCreateFolderSuccess = createSelector(selectViewMessagesState, state => state.viewMessagesFeatureState.createFolderSuccess);
export const selectCreateFolderError = createSelector(selectViewMessagesState, state => state.viewMessagesFeatureState.createFolderError);
export const selectRenameFolderError = createSelector(selectViewMessagesState, state => state.viewMessagesFeatureState.renameFolderError);

export const selectIsDeletingFolder = createSelector(selectViewMessagesState, state => state.viewMessagesFeatureState.isDeletingFolder);
export const selectDeleteFolderSuccess = createSelector(selectViewMessagesState, state => state.viewMessagesFeatureState.deleteFolderSuccess);
export const selectDeleteFolderError = createSelector(selectViewMessagesState, state => state.viewMessagesFeatureState.deleteFolderError);
export const selectSelectedFolder = createSelector(selectViewMessagesState, state => state.viewMessagesFeatureState.selectedFolder);

export const selectSelectedFaxes = createSelector(selectViewMessagesState, state => state.viewMessagesFeatureState.selectedFaxes);
export const selectTagList = createSelector(selectViewMessagesState, state => state.viewMessagesFeatureState.tagList);
export const currentPagination = createSelector(selectViewMessagesState, state => state.viewMessagesFeatureState.currentPagination);

export const selectMessages = createSelector(selectViewMessagesState, state => state.viewMessagesFeatureState);

export const selectUpdateFolderCache = createSelector(selectViewMessagesState, state => state.viewMessagesFeatureState.updateFolderSingleCache);
export const selectErrorMEssage = createSelector(selectViewMessagesState, state => state.viewMessagesFeatureState.errorMessage);

export const viewMessagesPageViewModel = createSelector(
    selectMessages,
    fromViewMessagesReducer.getViewMessagesState
);
