import { createAction, props } from '@ngrx/store';
import {
    Folder,
    FolderCreate,
    FolderCreateInput,
    FolderDeleteInput,
    FolderList, FolderRename, FolderRenameInput,
    MoveFaxesInput
} from '../../models/folder.model';
import { FaxList, FaxFilterInput, FaxSortInput, Fax, FaxUpdateParams } from '../../models/fax.model';
import { ApiError } from '../../models/shared.model';
import { MatPaginator } from '@angular/material/paginator';

enum Actions {
    LOAD_FOLDER_LIST_BEGIN = "[ViewMessages] Load folder list begin",
    LOAD_FOLDER_LIST_SUCCESS = "[ViewMessages] Load folder list success",
    LOAD_FOLDER_LIST_FAILED = "[ViewMessages] Load folder list failed",

    LOAD_FAX_LIST_BEGIN = "[ViewMessages] Load fax list begin",
    LOAD_FAX_LIST_SUCCESS = "[ViewMessages] Load fax list success",
    LOAD_FAX_LIST_FAILED = "[ViewMessages] Load fax list failed",

    CREATE_FOLDER_BEGIN = "[ViewMessages] Create folder begin",
    RENAME_FOLDER_BEGIN = "[ViewMessages] Rename folder begin",
    CREATE_FOLDER_SUCCESS = "[ViewMessages] Create folder success",
    RENAME_FOLDER_SUCCESS = "[ViewMessages] Rename folder success",
    CREATE_FOLDER_FAILED = "[ViewMessages] Create folder failed",
    RENAME_FOLDER_FAILED = "[ViewMessages] Rename folder failed",

    DELETE_FOLDER_BEGIN = "[ViewMessages] Delete folder begin",
    DELETE_FOLDER_SUCCESS = "[ViewMessages] Delete folder success",
    DELETE_FOLDER_FAILED = "[ViewMessages] Delete folder failed",

    MOVE_FAXES_BEGIN = "[ViewMessages] Move faxes begin",
    MOVE_FAXES_SUCCESS = "[ViewMessages] Move faxes success",
    MOVE_FAXES_FAILED = "[ViewMessages] Move faxes failed",

    PRINT_FAXES = "[ViewMessages] Printing selected messages",

    MARK_READ_UNREAD_BEGIN = "[ViewMessages] Mark Fax as read/unread started",
    MARK_READ_UNREAD_END = "[ViewMessages] Mark Fax as read/unread finnished",

    DELETE_FAX_SINGLE_BEGIN = "[ViewMessages] Delete single fax starts",
    DELETE_FAX_SINGLE_END = "[ViewMessages] Delete single fax ends",
    DELETE_FAX_LIST_BEGIN = "[ViewMessages] Delete list of fax starts",
    DELETE_FAX_LIST_END = "[ViewMessages] Delete list of fax ends",

    CREATE_TAG_BEGIN = "[ViewMessages] Create custom tag starts",
    CREATE_TAG_END = "[ViewMessages] Create custom tag ends",

    DELETE_TAG_BEGIN = "[ViewMessages] Delete custom tag starts",
    DELETE_TAG_END = "[ViewMessages] Delete custom tag ends",

    DOWNLOAD_START = "[ViewMessages] Download starting",
    DOWNLOAD_END = "[ViewMessages] Download ending",

    BULK_DOWNLOAD = "[ViewMessages] Bulk Download getting zip file",
    BULK_DOWNLOAD_SUCCESS = "[ViewMessages] Download success",
    BULK_DOWNLOAD_FAILURE = "[ViewMessages] Download failed",

    DISPLAY_SINGLE_FAX = "[ViewMessages] Shows single fax",
    RELOAD_SINGLE_FAX_START = "[ViewMessages] start reload of a single fax",
    RELOAD_SINGLE_FAX_END = "[ViewMessages] end reload of a single fax",

    RELOAD_FOLDER_START = "[ViewMessages] reloading folder api call starts",
    RELOAD_FOLDER_END = "[ViewMessages] reloading folder api ends",

    REFRESH_CACHE_FOLDER = "[ViewMessages] refresh folder single cache for current query",
    REFRESH_CACHE_FOLDER_END = "[ViewMessages] refresh folder single cache ends",

    CURRENT_PAGINATION = "[ViewMessages] set current pagination",

}

export const loadFolderListBegin =
    createAction(Actions.LOAD_FOLDER_LIST_BEGIN, props<{ serviceKey: string, outboundServiceKey: string }>());
export const loadFolderListSuccess =
    createAction(Actions.LOAD_FOLDER_LIST_SUCCESS, props<{ folderList: FolderList }>());
export const loadFolderListFailed =
    createAction(Actions.LOAD_FOLDER_LIST_FAILED, props<{ errorMessage: ApiError }>());

export const loadFaxListBegin =
    createAction(Actions.LOAD_FAX_LIST_BEGIN, props<{ payload: { inboundServiceKey: string, outboundServiceKey: string, filter: FaxFilterInput, offset: number, limit: number, sort: FaxSortInput } }>());
export const loadFaxListSuccess =
    createAction(Actions.LOAD_FAX_LIST_SUCCESS, props<{ faxList: FaxList }>());
export const loadFaxListFailed =
    createAction(Actions.LOAD_FAX_LIST_FAILED, props<{ errorMessage: ApiError }>());


export const createNewFolderBegin =
    createAction(Actions.CREATE_FOLDER_BEGIN, props<{ payload: { serviceKey: string, input: FolderCreateInput } }>());

export const renameFolderBegin =
    createAction(Actions.RENAME_FOLDER_BEGIN, props<{ payload: { serviceKey: string, input: FolderRenameInput } }>());
export const renameFolderSuccess =
    createAction(Actions.RENAME_FOLDER_SUCCESS, props<{ payload: { serviceKey: string, folder: FolderRename } }>());
export const createNewFolderSuccess =
    createAction(Actions.CREATE_FOLDER_SUCCESS, props<{ payload: { serviceKey: string, folder: FolderCreate } }>());
export const renameFolderFailed =
    createAction(Actions.RENAME_FOLDER_FAILED, props<{ errorMessage: ApiError }>());

export const createNewFolderFailed =
    createAction(Actions.CREATE_FOLDER_FAILED, props<{ errorMessage: ApiError }>());


export const bulkDownload =
    createAction(Actions.BULK_DOWNLOAD, props<{ serviceKey: string, faxIds: string[] }>());
export const bulkDownloadSuccess =
    createAction(Actions.BULK_DOWNLOAD_SUCCESS, props<{ downloadUrl: string }>());
export const bulkDownloadSuccessFailure =
    createAction(Actions.BULK_DOWNLOAD_FAILURE, props<{ errorMessage: ApiError }>());

export const moveFaxesBegin =
    createAction(Actions.MOVE_FAXES_BEGIN, props<{ payload: { serviceKey: string, folderId: string, faxIds: string[], filter: FaxFilterInput | undefined } }>());
export const moveFaxesSuccess =
    createAction(Actions.MOVE_FAXES_SUCCESS, props<{ payload: { input: MoveFaxesInput } }>());
export const moveFaxesFailed =
    createAction(Actions.MOVE_FAXES_FAILED, props<{ errorMessage: ApiError }>());

export const deleteFolderBegin =
    createAction(Actions.DELETE_FOLDER_BEGIN, props<{ payload: { serviceKey: string, input: FolderDeleteInput } }>());
export const deleteFolderSuccess =
    createAction(Actions.DELETE_FOLDER_SUCCESS, props<{ payload: { serviceKey: string, folder: FolderCreate } }>());
export const deleteFolderFailed =
    createAction(Actions.DELETE_FOLDER_FAILED, props<{ errorMessage: ApiError }>());


export const printingSelectedFaxes = createAction(Actions.PRINT_FAXES, props<{ selectedFaxes: Fax[] }>());

export const markFaxReadUnreadBegun = createAction(Actions.MARK_READ_UNREAD_BEGIN, props<{ serviceKey: string, faxId: string[], input: FaxUpdateParams }>());
export const markFaxReadUnreadEnded = createAction(Actions.MARK_READ_UNREAD_END, props<{ serviceKey: string, faxes: string[] }>());

export const deleteFaxSingleBegin = createAction(Actions.DELETE_FAX_SINGLE_BEGIN, props<{ serviceKey: string, faxId: string, filter: FaxFilterInput, folderId: string, flush: boolean }>());
export const deleteFaxSingleEnd = createAction(Actions.DELETE_FAX_SINGLE_END, props<{ serviceKey: string, filter: FaxFilterInput | undefined, errorCode: string | undefined, errorMessage: string | undefined }>());
export const deleteFaxListBegin = createAction(Actions.DELETE_FAX_LIST_BEGIN, props<{ serviceKey: string, faxIds: string[], filter: FaxFilterInput, folderId: string, flush: boolean }>());
export const deleteFaxListEnd = createAction(Actions.DELETE_FAX_LIST_END, props<{ serviceKey: string, faxIds: string[] | undefined, filter: FaxFilterInput | undefined, errorCode: string | undefined, errorMessage: string | undefined }>());

export const createTagBegin = createAction(Actions.CREATE_TAG_BEGIN, props<{ serviceKey: string, faxId: string, tag: string, fromSingle: boolean }>());
export const createTagEnd = createAction(Actions.CREATE_TAG_END, props<{ tags: string[] | undefined, errorCode: string | undefined, errorMessage: string | undefined, fromSingle: boolean, serviceKey: string, faxId: string }>());
export const deleteTagBegin = createAction(Actions.DELETE_TAG_BEGIN, props<{ serviceKey: string, faxId: string, tag: string, fromSingle: boolean }>());
export const deleteTagEnd = createAction(Actions.DELETE_TAG_END, props<{ tags: string[] | undefined, errorCode: string | undefined, errorMessage: string | undefined, fromSingle: boolean, serviceKey: string, faxId: string }>());

export const downloadFaxStart = createAction(Actions.DOWNLOAD_START, props<{ fileName: string, urlToDownload: string }>());
export const downloadFaxEnd = createAction(Actions.DOWNLOAD_END, props<{ fileName: string, fileContent: Blob }>());

export const displaySingleFax = createAction(Actions.DISPLAY_SINGLE_FAX, props<{ currentFax: Fax }>());
export const reloadFaxSingleStart = createAction(Actions.RELOAD_SINGLE_FAX_START, props<{ serviceKey: string, faxId: string }>());
export const reloadFaxSingleEnd = createAction(Actions.RELOAD_SINGLE_FAX_END, props<{ fax: Fax | undefined, errorCode: string | undefined, errorMessage: string | undefined }>());

export const reloadFolderStarts = createAction(Actions.RELOAD_FOLDER_START, props<{ name: string, serviceKey: string, offset: number, limit: number, sort: FaxSortInput }>());
export const reloadFolderEnds = createAction(Actions.RELOAD_FOLDER_END, props<{ folder: Folder }>());

export const refreshCacheFolder = createAction(Actions.REFRESH_CACHE_FOLDER);
export const refreshCacheFolderEnds = createAction(Actions.REFRESH_CACHE_FOLDER_END, props<{ folder: Folder | undefined }>());

export const setCurrentPagination = createAction(Actions.CURRENT_PAGINATION, props<{ pageIndex: number, pageNumber: number, currentOffset: number }>());
