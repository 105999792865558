import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthFacadeService } from '../../auth/services/auth-facade.service';
import { TranslocoService } from '@ngneat/transloco';
import { filter, map, take } from 'rxjs/operators';
import { SUSPEND_FLAG_ALLOW_LIMIT } from 'src/app/shared/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class ViewMessagesGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private router: Router,
    private authFacade: AuthFacadeService,
    private snackBar: MatSnackBar,
    private translocoService: TranslocoService
  ) {
    this.translocoService.setTranslationKey('no.permission.view.messages', "You must enable Display Messages in order to view faxes");
  }

  canLoad() {
    return this.authFacade.authState$.pipe(
      filter(state => state !== undefined),
      take(1),
      map(state => { return { hasToken: !!state.access_token, flag: state.suspendedFlag } }),
      map(result => {
        if (result.flag > SUSPEND_FLAG_ALLOW_LIMIT) {
          return this.router.createUrlTree(['/error'], { queryParams: { message: "Access not allowed - account suspended" } });
        } else {
          if (result.hasToken)
            return true;
          else
            return this.router.parseUrl('/login');
        }
      })
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authFacade.currentService$
      .pipe(take(1),
        map(svc => {
          if (svc !== undefined && svc.features.isStorageEnabled) {
            return true;
          } else {
            return this.noPermission();
          }
        })
      );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (location.pathname.includes("view-messages/single")) {
      return false;
    }
    return true;
  }

  private noPermission() {
    return false;
  }
}
