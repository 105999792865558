import {Directive, ElementRef} from "@angular/core";
import {BrandService} from "../services/brand.service";
import {PermissionDirective} from "./permission.directive";

@Directive({
  selector: '[hidePermission]'
})
export class HidePermissionDirective extends PermissionDirective  {

  constructor(protected elementRef: ElementRef,
              protected brandService: BrandService) {
    super(elementRef, brandService)
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit()
    if (this.brandsArray.indexOf(this.brandName.toUpperCase()) > -1) {
      this.elementRef.nativeElement.remove();
    }
  }

}
