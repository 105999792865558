import * as fromCompose from './reducers/compose.reducer';
import * as fromRoot from '../../auth/store/reducers';
import { ActionReducerMap } from '@ngrx/store';
import { startingFaxCreation } from './actions/compose.actions';

export interface ComposeFeatureState {
    composeForm: fromCompose.ComposeState;
}

export interface State extends fromRoot.AppState {
    composeFormState: ComposeFeatureState;
}

export const reducers:
    ActionReducerMap<ComposeFeatureState, typeof startingFaxCreation> = {
    composeForm: fromCompose.reducer
}
