import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  Folder,
  FolderCreate,
  FolderCreateInput,
  FolderDeleteInput, FolderRename,
  FolderRenameInput
} from '../models/folder.model';
import {
  bulkDownload,
  createNewFolderBegin,
  createTagBegin,
  deleteFaxListBegin,
  deleteFaxSingleBegin,
  deleteFolderBegin,
  deleteTagBegin,
  displaySingleFax,
  downloadFaxStart,
  loadFaxListBegin,
  loadFolderListBegin,
  markFaxReadUnreadBegun,
  moveFaxesBegin,
  printingSelectedFaxes,
  refreshCacheFolder,
  reloadFaxSingleStart,
  reloadFolderStarts,
  renameFolderBegin,
  setCurrentPagination
} from '../store/actions/view-messages.actions';
import * as fromFeature from '../store/reducers/view-messages.reducer';
import * as fromSelector from '../store/selectors/view-messages.selector';
import {
  selectFaxList,
  selectFolderList,
  selectLoadingFaxes,
  selectIsCreatingFolder,
  selectCreateFolderSuccess,
  selectSelectedFolder,
  selectCreateFolderError,
  selectIsDeletingFolder,
  selectDeleteFolderSuccess,
  selectDeleteFolderError,
  selectIsLoadingFolderList,
  selectSelectedFaxes,
  selectTagList,
  selectRenameFolderSuccess, selectRenameFolderError,
  currentPagination,
} from '../store/selectors/view-messages.selector';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { Fax, FaxAttachment, FaxFilterInput, FaxSortInput, FaxUpdateParams, ForwardFaxToEmailResult } from '../models/fax.model';
import { ApiError } from '../models/shared.model';
import { ComposeFacadeService } from 'src/app/compose/services/compose-facade.service';
import { ViewMessagesApi } from '../api/view-messages.api';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class ViewMessagesFacadeService {


  folderList$: Observable<Folder[] | undefined>;
  faxList$: Observable<Fax[] | undefined>;
  isLoadingFolders$: Observable<boolean>;
  isLoadingFaxes$: Observable<boolean | undefined>;

  isCreatingFolder$: Observable<boolean | undefined>;
  createFolderSuccess$: Observable<FolderCreate | undefined>;
  renameFolderSuccess$: Observable<FolderRename | undefined>;
  createFolderError$: Observable<ApiError | undefined>;
  renameFolderError$: Observable<ApiError | undefined>;

  isDeletingFolder$: Observable<boolean | undefined>;
  deleteFolderSuccess$: Observable<FolderCreate | undefined>;
  deleteFolderError$: Observable<ApiError | undefined>;

  selectedFolder$: Observable<Folder>;

  selectedFaxes$: Observable<Fax[] | undefined>;
  tagList$: Observable<string[] | undefined>;

  requiresUpdateFolderCache$: Observable<boolean>;
  errorOccurred$: Observable<ApiError | undefined>;

  readonly viewMessagesViewModel$: Observable<fromFeature.ViewMessagesState>;
  currentPagination$: Observable<any>;

  constructor(
    private readonly store: Store<fromFeature.ViewMessagesState>,
    private readonly composeFacadeService: ComposeFacadeService,
    private readonly viewMessagesApi: ViewMessagesApi) {
    this.folderList$ = store.pipe(select(selectFolderList));
    this.faxList$ = store.pipe(select(selectFaxList));
    this.isLoadingFolders$ = store.pipe(select(selectIsLoadingFolderList));
    this.isLoadingFaxes$ = store.pipe(select(selectLoadingFaxes));
    this.isCreatingFolder$ = store.pipe(select(selectIsCreatingFolder));
    this.createFolderSuccess$ = store.pipe(select(selectCreateFolderSuccess));
    this.renameFolderSuccess$ = store.pipe(select(selectRenameFolderSuccess));
    this.createFolderError$ = store.pipe(select(selectCreateFolderError));
    this.renameFolderError$ = store.pipe(select(selectRenameFolderError));
    this.isDeletingFolder$ = store.pipe(select(selectIsDeletingFolder));
    this.deleteFolderSuccess$ = store.pipe(select(selectDeleteFolderSuccess));
    this.deleteFolderError$ = store.pipe(select(selectDeleteFolderError));
    this.selectedFolder$ = store.pipe(select(selectSelectedFolder));
    this.selectedFaxes$ = store.pipe(select(selectSelectedFaxes));
    this.tagList$ = store.pipe(select(selectTagList));
    this.currentPagination$ = store.pipe(select(currentPagination));
    this.requiresUpdateFolderCache$ = store.pipe(select(fromSelector.selectUpdateFolderCache));
    this.errorOccurred$ = store.pipe(select(fromSelector.selectErrorMEssage));
    this.viewMessagesViewModel$ = this.store.select(fromSelector.viewMessagesPageViewModel);
  }

  dispatchLoadFolderList(serviceKey: string, outboundServiceKey: string) {
    this.store.dispatch(loadFolderListBegin({ serviceKey: serviceKey, outboundServiceKey: outboundServiceKey }));
  }

  dispatchLoadFaxList(payload: {
    inboundServiceKey: string, outboundServiceKey: string, filter: FaxFilterInput,
    offset: number, limit: number, sort: FaxSortInput
  }) {
    this.store.dispatch(loadFaxListBegin({ payload }));
  }

  dispatchCreateNewFolder(payload: { serviceKey: string, input: FolderCreateInput }) {
    this.store.dispatch(createNewFolderBegin({ payload }));
  }

  dispatchRenameFolder(payload: { serviceKey: string, input: FolderRenameInput }) {
    this.store.dispatch(renameFolderBegin({ payload }));
  }

  dispatchDeleteFolder(payload: { serviceKey: string, input: FolderDeleteInput }) {
    this.store.dispatch(deleteFolderBegin({ payload }));
  }

  dispatchMoveFaxes(payload: { serviceKey: string, folderId: string, faxIds: string[], filter: FaxFilterInput }) {
    console.log('MOVE FAXES')
    this.store.dispatch(moveFaxesBegin({ payload }));
  }

  printMessages(faxes: Fax[]) {
    this.store.dispatch(printingSelectedFaxes({ selectedFaxes: faxes }));
  }

  replyMessage(fax: Fax, includeAttachments: boolean = false) {
    this.composeFacadeService.replyCompose(fax, includeAttachments);
  }

  markAsReadOrUnread(serviceKey: string, faxId: string[], isRead: boolean) {
    const options: FaxUpdateParams = { isRead: isRead, apiClientIpAddress: '0.0.0.0' };
    this.store.dispatch(markFaxReadUnreadBegun({ serviceKey: serviceKey, faxId: faxId, input: options }));
  }

  forwardMessage(fax: Fax) {
    this.composeFacadeService.forwardCompose(fax);
  }

  forwardMultipleMessage(faxes: Fax[]) {
    this.composeFacadeService.forwardMultipleCompose(faxes);
  }

  deleteFaxSingle(serviceKey: string, faxId: string, filter: FaxFilterInput, folderId: string, flush: boolean) {
    this.store.dispatch(deleteFaxSingleBegin({ serviceKey: serviceKey, faxId: faxId, filter: filter, folderId: folderId, flush: flush }));
  }

  deleteFaxList(serviceKey: string, faxIds: string[], filter: FaxFilterInput, folderId: string, flush: boolean) {
    this.store.dispatch(deleteFaxListBegin({ serviceKey: serviceKey, faxIds: faxIds, filter: filter, folderId: folderId, flush: flush }));
  }

  downloadbulk(serviceKey: string, faxIds: string[]) {
    this.store.dispatch(bulkDownload({ serviceKey: serviceKey, faxIds: faxIds }));
  }

  dispatchDownloadSingleFax(fileName: string, url: string) {
    this.store.dispatch(downloadFaxStart({ fileName: fileName, urlToDownload: url }));
  }

  dispatchShowSingleFax(faxToShow: Fax) {
    this.store.dispatch(displaySingleFax({ currentFax: faxToShow }));
  }

  dispatchTagAdd(serviceKey: string, faxId: string, tag: string, isFromSingleMessage: boolean = false) {
    this.store.dispatch(createTagBegin({ serviceKey: serviceKey, faxId: faxId, tag: tag, fromSingle: isFromSingleMessage }));
  }

  dispatchTagDelete(serviceKey: string, faxId: string, tag: string, isFromSingleMessage: boolean = false) {
    this.store.dispatch(deleteTagBegin({ serviceKey: serviceKey, faxId: faxId, tag: tag, fromSingle: isFromSingleMessage }));
  }

  dispatchReloadFaxSingle(serviceKey: string, faxId: string) {
    this.store.dispatch(reloadFaxSingleStart({ serviceKey: serviceKey, faxId: faxId }));
  }

  forwardFaxToEmail(serviceKey: string, subject: string, message: string, faxIds: string[], emails: string[]): Observable<ForwardFaxToEmailResult> {
    return this.viewMessagesApi.forwardFaxToEmail(serviceKey, subject, message, faxIds, emails);
  }

  dispatchReloadFolder(serviceKey: string, name: string,
    offset: number, limit: number, sort: FaxSortInput) {
    this.store.dispatch(reloadFolderStarts({ name: name, serviceKey: serviceKey, offset: offset, limit: limit, sort: sort }));
  }

  dispatchUpdateFolderCache() {
    this.store.dispatch(refreshCacheFolder());
  }

  getSelectedFaxes(faxes: FaxAttachment[]) {
    return combineLatest(
      faxes.map(oneFax => this.viewMessagesApi.fetchFaxSingleComplete(oneFax.serviceKey, oneFax.faxId, oneFax.pages)
        .pipe(map((r: any) => r?.faxSingle?.fax as Fax))
      )
    )
  }

  getSelectedFaxesImages(serviceKey: string, faxIds: string[]) {
    return forkJoin(
      faxIds.map(faxId => this.viewMessagesApi.fetchFaxSingleImages(serviceKey, faxId)
        .pipe(map(r => r?.faxSingle?.fax?.images?.map((v: any) => v.imageUrl)), tap(value => console.log(value))))
    );
  }

  setPagination(pageIndex: number, pageNumber: number, currentOffset: number) {
    this.store.dispatch(setCurrentPagination({ pageIndex, pageNumber, currentOffset }));
  }
}
