import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactsFacadeService } from './services/contacts-facade.service';
import { ContactsComponent } from './components/contacts.component';
import { DialogContactDelete } from './components/delete-contact-dialog.component';
import { ContactsEffects } from './store/effects/contacts.effects';
import { ContactsRoutingModule } from './contacts-routing.module';
import { TranslocoRootModule } from 'src/transloco-root.module';
import { ContactsApi } from './api/contacts.api';
import { SharedModule } from '../shared/shared.module';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import * as fromStore from './store/index';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { PipesModule } from '../shared/pipes/pipes.module';
import { AddEditContactComponent } from './components/add-edit-contact/add-edit-contact.component';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {FormatPhoneNumberPipe} from "../shared/pipes/format-phone-number.pipe";



@NgModule({
  declarations: [
    ContactsComponent,
    AddEditContactComponent,
    DialogContactDelete
  ],
  providers: [ContactsApi, ContactsFacadeService, FormatPhoneNumberPipe,
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'accent' },
    }
  ],

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ContactsRoutingModule,
    TranslocoRootModule,
    SharedModule,
    StoreModule.forFeature('contacts', fromStore.reducers),
    EffectsModule.forFeature([ContactsEffects]),
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatIconModule,
    MatListModule,
    MatChipsModule,
    MatRadioModule,
    PipesModule,
    MatInputModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ContactsModule { }
