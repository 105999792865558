import { SystemFolder } from "src/app/view-messages/models/folder.model";

export const LOCAL_STORAGE_KEY_CURRENT_LANGUAGE = "currentLanguage";
export const PHONE_REGEX = "^(\\+\\d{1,3}( )?)?((\\(\\d{1,3}\\))|\\d{1,3})[- .]?\\d{3,4}[- .]?\\d{4}$";
export const ALLOWED_FILE_TYPES: string[] = ['.pdf', '.bmp', '.wpg', '.vsd', '.vsdx', '.tga', '.icb', '.vda', '.vst', '.tif', '.tiff', '.sxw', '.sxc', '.sxi', '.sxd', '.rtf', '.qpw', '.wb1', '.wb2', '.wb3', '.png', '.txt', '.doc', '.log', '.err', '.c', '.cpp', '.h', '.src', '.pcx', '.odt', '.ods', '.odp', '.odg', '.doc', '.docx', '.pub', '.ppt', '.pptx', '.xls', '.xlsx', '.wps', '.dot', '.dotx', '.html', '.htm', '.eps', '.jfx', '.efx', '.hot', '.wpd', '.wp5', '.wp6', '.shw', '.gif87a', '.gif89a', '.gif', '.csv', '.iff', '.psd', '.ps', '.jpeg', '.jpg'];
export const FILE_TYPE_ZIP = "application/zip";
export const FILE_MAX_SIZE_ALLOWED = 1024 * 1024 * 20;
export const FILE_MAX_LENGTH_ALLOWED = 10;
export const RECIPIENTS_MAX_NUMBER_ALLOWED = 50;
export const IDLE_SESSION_TIMEOUT_SECONDS = 30 * 60;
export const IDLE_WAITING_CONFIRMATION_TIME = 60;
export const REFRESH_SESSION_TIMEOUT_SECONDS = 12 * 60;
export const TOKEN_EXPIRATION_SECONDS = 20 * 60;
export const REQUEST_TIMEOUT_MS = 5 * 60 * 1000;
export const RFC_EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const EMAIL_PATTERN = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
export const FAX_NUMBER_PATTERN = /^\+?[0-9]{10,}$/;
export const CREATE_TAG_PATTERN = /^[a-z\d\-_\s]+$/i;
export const SSO_CLIENT_ID = "messenger";
export const UNEXPECTED_ERROR_MESSAGE = "Unexpected error while processing the request.";
export const DEFAULT_LIMIT_CONTACTS_QUERY = 25;
export const DEFAULT_OFFSET_CONTACTS_QUERY = +0;
export const DEFAULT_THUMB_PREVIEW = "assets/images/no_preview.png";
export const NOT_EMPTY = /^(\s+\S+\s*)*(?!\s).*$/;
export const DOES_NOT_START_WITH_WHITE_SPACE = /^[^-\s][a-zA-Z0-9_\s-]+$/
export const NO_START_SPACE_SPECIAL_CHARS = /^[^-\s][a-zA-Z0-9_\s-\!\@\#\$\^\&\*\(\)\<\>\{\}\[\]\;\:\+\.]+$/
export const FEEDBACK_DURATION = 5000;
export const USER_FEEDBACK_NOTIFICATION_DURATION_MS = 3000;
export const SEARCH_BAR_DEBOUNCE_TIME = 1000;
export const SUSPEND_FLAG_ALLOW_LIMIT = 2;
export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 20;

export enum StateStoreKeys {
  COMPOSE = "COMPOSE-STORE",
  VIEW_MESSAGES = "VIEW-MESSAGES",
  AUTH_STORE = "AUTH-STORE",
  CONTACTS_STORE = "CONTACTS-STORE"
}

export enum Brands {
  UNDEFINED = "undefined",
  EFAX = "efax"
}

export const ENABLED_BRANDS: string[] = [
  'efax',
  'myfax',
  'fax',
  'rapidfax',
  'send2fax',
  'smartfax',
  'trustfax',
];
export const DEFAULT_BRAND_NAME: string = 'efax';

export const TIMEZONES = [
  {
    "@value": "Pacific/Kiritimati",
    "#text": "(UTC+14:00) Line Is. Time"
  },
  {
    "@value": "Pacific/Chatham",
    "#text": "(UTC+13:45) Chatham Standard Time"
  },
  {
    "@value": "Pacific/Auckland",
    "#text": "(UTC+13:00) Auckland: Wellington"
  },
  {
    "@value": "Pacific/Tongatapu",
    "#text": "(UTC+13:00) Tonga Time"
  },
  {
    "@value": "Pacific/Fiji",
    "#text": "(UTC+12:00) Fiji: Kamchatka: Marshall Is."
  },
  {
    "@value": "Pacific/Norfolk",
    "#text": "(UTC+11:30) Norfolk Time"
  },
  {
    "@value": "Australia/Sydney",
    "#text": "(UTC+11:00) Canberra: Melbourne: Sydney"
  },
  {
    "@value": "Australia/Hobart",
    "#text": "(UTC+11:00) Hobart"
  },
  {
    "@value": "Australia/Lord_Howe",
    "#text": "(UTC+11:00) Load Howe Standard Time"
  },
  {
    "@value": "Asia/Magadan",
    "#text": "(UTC+11:00) Magadan: Solomon Is.: New Caledonia"
  },
  {
    "@value": "Australia/Adelaide",
    "#text": "(UTC+10:30) Adelaide"
  },
  {
    "@value": "Australia/Brisbane",
    "#text": "(UTC+10:00) Brisbane"
  },
  {
    "@value": "Pacific/Guam",
    "#text": "(UTC+10:00) Guam: Port Moresby"
  },
  {
    "@value": "Asia/Vladivostok",
    "#text": "(UTC+10:00) Vladivostok"
  },
  {
    "@value": "Australia/Darwin",
    "#text": "(UTC+09:30) Darwin"
  },
  {
    "@value": "Asia/Tokyo",
    "#text": "(UTC+09:00) Osaka: Sapporo: Tokyo"
  },
  {
    "@value": "Australia/Perth",
    "#text": "(UTC+09:00) Perth"
  },
  {
    "@value": "Asia/Seoul",
    "#text": "(UTC+09:00) Seoul"
  },
  {
    "@value": "Asia/Yakutsk",
    "#text": "(UTC+09:00) Yakutsk Summer Time"
  },
  {
    "@value": "Asia/Hong_Kong",
    "#text": "(UTC+08:00) Beijing: Chongqing: Hong Kong: Urumqi"
  },
  {
    "@value": "Asia/Shanghai",
    "#text": "(UTC+08:00) China Standard Time"
  },
  {
    "@value": "Asia/Irkutsk",
    "#text": "(UTC+08:00) Irkutsk: Ulaan Bataar"
  },
  {
    "@value": "Asia/Singapore",
    "#text": "(UTC+08:00) Kuala Lumpur: Singapore"
  },
  {
    "@value": "Asia/Taipei",
    "#text": "(UTC+08:00) Taipei"
  },
  {
    "@value": "Asia/Bangkok",
    "#text": "(UTC+07:00) Bangkok: Hanoi: Jakarta"
  },
  {
    "@value": "Asia/Krasnoyarsk",
    "#text": "(UTC+07:00) Krasnoyarsk"
  },
  {
    "@value": "Asia/Rangoon",
    "#text": "(UTC+06:30) Rangoon"
  },
  {
    "@value": "Asia/Almaty",
    "#text": "(UTC+06:00) Alma-Ata: Novosibirsk"
  },
  {
    "@value": "Asia/Dhaka",
    "#text": "(UTC+06:00) Astana,Dhaka"
  },
  {
    "@value": "Antarctica/Mawson",
    "#text": "(UTC+06:00) Mawson Time"
  },
  {
    "@value": "Asia/Katmandu",
    "#text": "(UTC+05:45) Katmandu"
  },
  {
    "@value": "Asia/Calcutta",
    "#text": "(UTC+05:30) Calcutta: Chennai: Mumbai: New Delhi"
  },
  {
    "@value": "Asia/Karachi",
    "#text": "(UTC+05:00) Islamabad: Karachi: Tashkent"
  },
  {
    "@value": "Asia/Ashkhabad",
    "#text": "(UTC+05:00) Turkmenistan Time"
  },
  {
    "@value": "Asia/Yekaterinburg",
    "#text": "(UTC+05:00) Yekaterinburg"
  },
  {
    "@value": "Asia/Kabul",
    "#text": "(UTC+04:30) Kabul"
  },
  {
    "@value": "Asia/Dubai",
    "#text": "(UTC+04:00) Abu Dhabi: Muscat"
  },
  {
    "@value": "Asia/Baku",
    "#text": "(UTC+04:00) Baku: Tbilisi: Yerevan"
  },
  {
    "@value": "Asia/Tehran",
    "#text": "(UTC+03:30) Tehran"
  },
  {
    "@value": "Asia/Qatar",
    "#text": "(UTC+03:00) Arabia Standard Time"
  },
  {
    "@value": "Asia/Baghdad",
    "#text": "(UTC+03:00) Baghdad"
  },
  {
    "@value": "Asia/Kuwait",
    "#text": "(UTC+03:00) Kuwait: Riyadh"
  },
  {
    "@value": "Europe/Moscow",
    "#text": "(UTC+03:00) Moscow: St. Petersburg: Volgograd"
  },
  {
    "@value": "Africa/Nairobi",
    "#text": "(UTC+03:00) Nairobi"
  },
  {
    "@value": "Europe/Athens",
    "#text": "(UTC+02:00) Athens: Istanbul: Minsk"
  },
  {
    "@value": "Europe/Bucharest",
    "#text": "(UTC+02:00) Bucharest"
  },
  {
    "@value": "Africa/Cairo",
    "#text": "(UTC+02:00) Cairo"
  },
  {
    "@value": "Africa/Harare",
    "#text": "(UTC+02:00) Harare: Pretoria"
  },
  {
    "@value": "Europe/Riga",
    "#text": "(UTC+02:00) Helsinki: Riga: Tallinn"
  },
  {
    "@value": "Europe/Amsterdam",
    "#text": "(UTC+01:00) Amsterdam: Berlin: Bern: Rome: Stockholm: Vienna"
  },
  {
    "@value": "Europe/Budapest",
    "#text": "(UTC+01:00) Belgrade: Bratislava: Budapest: Ljubljana: Prague"
  },
  {
    "@value": "Europe/Brussels",
    "#text": "(UTC+01:00) Brussels: Copenhagen: Madrid: Paris"
  },
  {
    "@value": "Europe/Warsaw",
    "#text": "(UTC+01:00) Sarajevo: Skopje: Sofija: Vilnius: Warsaw: Zagreb"
  },
  {
    "@value": "Europe/Malta",
    "#text": "(UTC+01:00) West Central Africa"
  },
  {
    "@value": "Africa/Tunis",
    "#text": "(UTC+01:00) Western African Time (No Daylight Saving)"
  },
  {
    "@value": "Africa/Casablanca",
    "#text": "(UTC+00:00) Casablanca: Monrovia"
  },
  {
    "@value": "Europe/Dublin",
    "#text": "(UTC+00:00) Dublin: Edinburgh: Lisbon: London"
  },
  {
    "@value": "Atlantic/Azores",
    "#text": "(UTC-01:00) Azores"
  },
  {
    "@value": "Atlantic/Cape_Verde",
    "#text": "(UTC-01:00) Cape Verde Time"
  },
  {
    "@value": "America/Sao_Paulo",
    "#text": "(UTC-02:00) Brasilia"
  },
  {
    "@value": "America/Montevideo",
    "#text": "(UTC-02:00) Greenland"
  },
  {
    "@value": "Atlantic/South_Georgia",
    "#text": "(UTC-02:00) Mid-Atlantic"
  },
  {
    "@value": "America/Buenos_Aires",
    "#text": "(UTC-03:00) Buenos Aires: Georgetown"
  },
  {
    "@value": "America/Santiago",
    "#text": "(UTC-03:00) Santiago"
  },
  {
    "@value": "America/St_Johns",
    "#text": "(UTC-03:30) Newfoundland"
  },
  {
    "@value": "America/Anguilla",
    "#text": "(UTC-04:00) Atlantic Time (Canada)"
  },
  {
    "@value": "America/Caracas",
    "#text": "(UTC-04:00) Caracas: La Paz"
  },
  {
    "@value": "America/Bogota",
    "#text": "(UTC-05:00) Bogota: Lima: Quito"
  },
  {
    "@value": "America/New_York",
    "#text": "(UTC-05:00) Eastern Time (Daylight Savings)"
  },
  {
    "@value": "America/Cayman",
    "#text": "(UTC-05:00) Eastern Time (Non Daylight Savings)"
  },
  {
    "@value": "America/Indianapolis",
    "#text": "(UTC-05:00) Indiana (East)"
  },
  {
    "@value": "America/Costa_Rica",
    "#text": "(UTC-06:00) Central America"
  },
  {
    "@value": "America/Chicago",
    "#text": "(UTC-06:00) Central Time (Daylight Savings)"
  },
  {
    "@value": "America/Belize",
    "#text": "(UTC-06:00) Central Time (Non Daylight Savings)"
  },
  {
    "@value": "America/Mexico_City",
    "#text": "(UTC-06:00) Mexico City"
  },
  {
    "@value": "America/Denver",
    "#text": "(UTC-07:00) Mountain Time (Daylight Savings)"
  },
  {
    "@value": "America/Phoenix",
    "#text": "(UTC-07:00) Mountain Time (Non Daylight Savings)"
  },
  {
    "@value": "America/Los_Angeles",
    "#text": "(UTC-08:00) Pacific Time (Daylight Savings)"
  },
  {
    "@value": "America/Anchorage",
    "#text": "(UTC-09:00) Alaska"
  },
  {
    "@value": "Pacific/Honolulu",
    "#text": "(UTC-10:00) Hawaii"
  },
  {
    "@value": "Pacific/Apia",
    "#text": "(UTC-11:00) Midway Island: Samoa"
  }
];

export const VIEW_MESSAGES_SYSTEM_FOLDER_ORDER = Object.values(SystemFolder) as string[];
