import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraphQLModule } from './modules/graphql.module';
import { TopbarComponent } from './topbar/topbar.component';

import { SearchbarComponent } from './searchbar/searchbar.component';

import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { TranslocoRootModule } from '../../transloco-root.module';
import { RouterModule } from '@angular/router';
import { ThemeService } from './services/theme.service';

import { LayoutComponent } from './layout/layout.component';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { ThumbPreviewDirective } from './directives/thumb-preview.directive';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { IdleComponent } from './idle/idle.component';
import { PipesModule } from './pipes/pipes.module';
import { SnackSuccess } from './feedback/snack.success'
import { SnackError } from './feedback/snack.error';
import { HolderComponent } from './layout/holder.component';
import { FileshareComponent } from './layout/fileshare.component';
import { ConsentPopupComponent } from './footer/consent-popup.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HidePermissionDirective } from "./permissions/hide-permission.directive";
import { DisablePermissionDirective } from "./permissions/disable-permission.directive";
import { ShowPermissionDirective } from "./permissions/show-permission.directive";
import { MatDividerModule } from "@angular/material/divider";
import { ApolloIntportal } from "./apollo-intportal";
import { LangStateService } from './lang/lang-state.service';
import { LoadingService } from './services/loading.service';
import { environment } from "../../environments/environment";
import { GoogleTagOnclickDirective } from "../googletagmanager/directives/google-tag-onclick.directive";
import { GoogleTagManagerModule } from "../googletagmanager/google-tag-manager.module";

@NgModule({
  declarations: [
    TopbarComponent,
    SidebarComponent,
    FooterComponent,
    LayoutComponent,
    DragAndDropDirective,
    ThumbPreviewDirective,
    HidePermissionDirective,
    ShowPermissionDirective,
    DisablePermissionDirective,
    IdleComponent,
    SearchbarComponent,
    SnackSuccess,
    SnackError,
    HolderComponent,
    FileshareComponent,
    ConsentPopupComponent,
    GoogleTagOnclickDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GraphQLModule,
    RouterModule,
    TranslocoRootModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatTabsModule,
    PipesModule,
    MatDialogModule,
    MatMenuModule,
    MatSnackBarModule,
    MatDividerModule,
    GoogleTagManagerModule.forRoot({
      id: environment.gtm_id
    })
  ],
  exports: [
    TopbarComponent,
    SidebarComponent,
    FooterComponent,
    LayoutComponent,
    DragAndDropDirective,
    ThumbPreviewDirective,
    GoogleTagOnclickDirective,
    HidePermissionDirective,
    ShowPermissionDirective,
    DisablePermissionDirective,
    MatButtonModule,
    MatInputModule,
    IdleComponent,
    MatIconModule,
    MatTooltipModule,
    SearchbarComponent,
    SnackSuccess,
    SnackError,
    MatMenuModule,
    MatDialogModule,
    MatSnackBarModule,
    MatDividerModule,
    PipesModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    ApolloIntportal,
    ThemeService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    LangStateService,
    LoadingService
  ]
})
export class SharedModule { }
