<div *ngIf="cardDetails" class="add-payment-container">
    <button class="summary-btn">
      <span class="material-icons back" (click)="close()">arrow_back</span>
      {{ 'billing.payment.method' | transloco }} </button>
    <hr class="separator-line">
    <div class="payment-form">
      <label>{{ 'billing.payment.type.label' | transloco | uppercase }}</label>
        <!-- Payment Type Selector -->
        <div class="payment-type">
          <label class="payment-option" [ngClass]="{'selected': selectedPaymentType === 'creditCard'}">
              <input type="radio" name="paymentType" value="creditCard" (selectionchange)="updateSelection(selectedPaymentType)"
                    [(ngModel)]="selectedPaymentType">
              <span class="material-icons">add_card</span>
              {{ 'billing.credit.card' | transloco }}
          </label>
          <label class="payment-option" [ngClass]="{'selected': selectedPaymentType === 'debitCard'}">
              <input type="radio" name="paymentType" value="debitCard" (selectionchange)="updateSelection(selectedPaymentType)"
                    [(ngModel)]="selectedPaymentType">
              <span class="material-icons">add_card</span>
              {{ 'billing.debit.card' | transloco }}
          </label>
          <label class="payment-option" [ngClass]="{'selected': selectedPaymentType === 'directDebit'}">
              <input type="radio" name="paymentType" value="directDebit" (selectionchange)="updateSelection(selectedPaymentType)"
                    [(ngModel)]="selectedPaymentType">
              <span class="material-icons">credit_score</span>
              {{ 'billing.direct.debit' | transloco }}
          </label>
        </div>
        <hr class="separator-line">
        <!-- Name on Card -->
        <div class="form-group">
          <label for="nameOnCard">{{ 'billing.name.on.card.label' | transloco }}</label>
          <input type="text" id="nameOnCard" [(ngModel)]="cardDetails.name" [class.error-input]="errorFormBlank">
        </div>
        <!-- Card Number -->
        <div class="form-group">
          <label for="cardNumber">{{ 'billing.card.number.label' | transloco }}</label>
          <input type="text" id="cardNumber" [(ngModel)]="cardDetails.number" pattern="\\d*" minlength="16" maxlength="19" [class.error-input]="errorFormBlank">
        </div>
        <!-- Exp Date and CVC -->
        <div class="form-row">
          <div class="form-group half-width">
            <label for="expDate">{{ 'billing.exp.date.label' | transloco }}</label>
            <input type="text" matInput placeholder="MM/YYYY" [(ngModel)]="cardDetails.expDate">
            <!-- Disabled, expDate (string | date) -->
            <mat-datepicker-toggle matSuffix disabled></mat-datepicker-toggle>
            <mat-datepicker startView="multi-year">
            </mat-datepicker>
          </div>
          <div class="form-group half-width">
            <label for="cvc">{{ 'billing.cvc.label' | transloco }}</label>
            <input type="text" id="cvc" [(ngModel)]="cardDetails.cvc" pattern="\\d{3,4}" [class.error-input]="errorFormBlank">
          </div>
        </div>
        <!-- Billing Address -->
        <div class="form-group">
          <label for="billingAddress">{{ 'billing.billing.address.label' | transloco }}</label>
          <input type="text" id="billingAddress" [(ngModel)]="cardDetails.billingAddress" [class.error-input]="errorFormBlank">
        </div>
        <!-- City and Zip Code -->
        <div class="form-row">
          <div class="form-group half-width">
            <label for="city">{{ 'billing.city.label' | transloco }}</label>
            <input type="text" id="city" [(ngModel)]="cardDetails.city" [class.error-input]="errorFormBlank">
          </div>
          <div class="form-group half-width">
            <label for="zipCode">{{ 'billing.zip.code.label' | transloco }}</label>
            <input type="text" id="zipCode" [(ngModel)]="cardDetails.zipCode" [class.error-input]="errorFormBlank">
          </div>
        </div>
        <!-- State and Country -->
        <div class="form-row">
          <div class="form-group half-width">
            <label for="state">{{ 'billing.state.label' | transloco }}</label>
            <select id="state" [(ngModel)]="cardDetails.state" required>
              <option>California</option>
            </select>
          </div>
          <!-- Country not listed in prototype
          <div class="form-group half-width">
            <label for="country">Country</label>
            <select id="country" required>
              <option>USA</option>
            </select>
          </div>
          -->
        </div>
        <hr class="separator-line" style="margin-top: 0px !important;">
        <p *ngIf="errorFormBlank" class="error-msg">*{{ 'billing.all.fields.required' | transloco }}</p>
      <button class="submit-btn" (click)="savePaymentMethod()" >{{ 'billing.submit.button' | transloco }}</button>
    </div>
  </div>

