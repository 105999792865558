<div class="table-container">
  <table
    mat-table
    matSort
    [dataSource]="dataSource"
    class="mat-elevation-z1"
    (matSortChange)="sortData($event)"
  >
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'billing.summary.table.header.date' | transloco}}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.date | date: 'MM/dd/yyyy' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'billing.summary.table.header.description' | transloco }}</th>
      <td mat-cell *matCellDef="let element">{{ element.description }}</td>
    </ng-container>

    <ng-container matColumnDef="period">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'billing.summary.table.header.period' | transloco }}</th>
      <td mat-cell *matCellDef="let element">{{ element.period }}</td>
    </ng-container>

    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'billing.summary.table.header.number' | transloco }}</th>
      <td mat-cell *matCellDef="let element">{{ element.number }}</td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'billing.summary.table.header.amount' | transloco }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.amount | currency: 'USD' }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<mat-paginator
  #paginator
  class="paginator no-printable"
  [pageSizeOptions]="[10, 20, 30]"
  showFirstLastButtons
></mat-paginator>

















