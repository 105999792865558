import { Fax, FaxFilterInput } from './fax.model'
import { ApiError, SortBy, SortDirection } from './shared.model'

interface FaxSortInput {
    sortBy: SortBy
    sortDirection: SortDirection
}

export interface Folder {
    id: string
    name: string
    faxCountRead: number
    faxCountUnread: number
    faxTotalCount: number
    faxes: Fax[] | undefined
}

export interface FolderMutation {
    id: string
    name: string
}

export interface FolderSingle {
    object_type: string
    folder: Folder
}

export interface FolderList {
    object_type: string
    folder: Folder[]
}

export interface FolderCreateInput {
    name: string
}

export interface FolderDeleteInput {
    id: string
}

export interface MoveFaxesInput {
    id: string,
    faxes: string[]
}

export interface FolderCreate {
    folder: {
        id: string
        name: string
    }
}

export interface FolderRename extends FolderMutation { }
export interface FolderRenameInput extends FolderMutation { }

export enum SystemFolder {
    INBOX = 'INBOX',
    SENT = 'SENT',
    SIGNED_DOCS = 'SIGNED_DOCS',
    DOCUMENTS = 'DOCUMENTS',
    TRASH = 'TRASH',
}

export enum RestrictedFolder {
    SENT = 'SENT',
    SIGNED_DOCS = 'SIGNED_DOCS',
    DOCUMENTS = 'DOCUMENTS',
    TRASH = 'TRASH',
}
export type FolderListResult = FolderList | ApiError
