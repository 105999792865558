import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoginFacade } from '../facade/login.facade';

@Component({
  selector: 'myportal-corporate',
  templateUrl: './corporate.component.html',
  styleUrls: ['./corporate.component.scss']
})
export class CorporateComponent implements OnInit, OnDestroy {

  code: string | null = null;
  correlation_id: string | null = null;

  private suscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private facade: LoginFacade) {
  }
  ngOnDestroy(): void {
    this.suscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit(): void {
    this.suscriptions.push(
      this.route.queryParamMap.subscribe((value: ParamMap) => {
        console.log(JSON.stringify(value));
        this.code = value.get("code");
        this.correlation_id = value.get("correlation_id");
        if (!!this.code && !!this.correlation_id) {
          console.log(`code: ${this.code}`);
          console.log(`correlation id: ${this.correlation_id}`);
          this.facade.loginBySSO(this.code, this.correlation_id);
          console.log("loggin in by SSO...");
        } else {
          this.router.navigate(['/error']);
        }
      }));
  }
}
