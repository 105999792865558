import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MutationResult } from 'apollo-angular';
import { Observable } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginModel, PasswordForgotResult, PasswordVerifyResult, ssoResponseSuccess } from '../models/login.model';
import { LOGIN_FORGOT_ID, LOGIN_FORGOT_PASSWORD_MUTATION, LOGIN_MUTATION, LOGIN_PASSWORD_VERIFY_MUTATION, LOGIN_SSO_MUTATION, RECAPTCHA_MUTATION } from './login.mutation';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { BrandService } from 'src/app/shared/services/brand.service';
import {ApolloIntportal} from "../../shared/apollo-intportal";
// COMMENT: not sure if it is the best place to be or if I should create it in a separate file
export enum LoginAPIResponseTypes {
  API_ERROR = "ApiError",
  AUTHENTICATE = "Authenticate"
}

@Injectable()
export class LoginApi {

  constructor(private apollo: ApolloIntportal, private recaptchaV3Service: ReCaptchaV3Service,
              private http: HttpClient, private brandService: BrandService) { }

  login(loginModel: LoginModel, brand: string): Observable<any> {
    return this.apollo.mutate({
      mutation: LOGIN_MUTATION,
      variables: { username: loginModel.username, password: loginModel.password, brand: brand },
    });
  }

  loginSSO(code: String, correlation_id: String) {
    return this.apollo.mutate({
      mutation: LOGIN_SSO_MUTATION,
      variables: { code: code, correlation_id: correlation_id }
    });
  }

  ssoLookup(email: String): Observable<ssoResponseSuccess> {
    return this.brandService.config$.pipe(
      filter(value => value !== undefined),
      first(),
      switchMap((config: any) => {
        const ssoClientId = config?.ssoClientId[environment.name.toLocaleLowerCase()];
        const body = { client_id: ssoClientId, email: email };
        const headers = new HttpHeaders(
          { 'Accept': 'application/json' }
        );
        headers.append('rejectUnauthorized', 'false');
        // a parsing error will be catched by the caller
        return this.http.post<ssoResponseSuccess>(environment.lookUpSSOUrl, body, { headers: headers });
      })
    );

  }

  forgotLoginIdRequest(email: string, brand: string) {
    return this.apollo.mutate({
      mutation: LOGIN_FORGOT_ID,
      variables: { email: email, brand: brand }
    });
  }

  forgotPasswordRequest(faxNumber: string, brand: string): Observable<MutationResult<PasswordForgotResult>> {
    return this.apollo.mutate<PasswordForgotResult>({
      mutation: LOGIN_FORGOT_PASSWORD_MUTATION,
      variables: { faxNumber: faxNumber, brand: brand }
    });
  }

  passwordVerifyRequest(faxNumber: string, securityCode: string,
    password: string, confirmPassword: string, brand: string): Observable<MutationResult<PasswordVerifyResult>> {
    return this.apollo.mutate<PasswordVerifyResult>({
      mutation: LOGIN_PASSWORD_VERIFY_MUTATION,
      variables: {
        faxNumber: faxNumber, securityCode: securityCode,
        password: password, confirmPassword: confirmPassword, brand: brand
      }
    });
  }

  webMyAccountLogin(url: string): Observable<any> {
    return this.http.get(url, { responseType: 'json' });
  }
}
