
    <div class="header-container">

            <div *ngIf="brandConfig !== undefined">
              <img src="../../../assets/images/{{brandConfig.logo.name}}" role="img" alt="{{brandConfig.logo.alt}}">
           </div>

            <button mat-icon-button aria-label="close dialog" (click)="goBack()">
                <mat-icon>close</mat-icon>
              </button>
    </div>

    <div class="container">
    <div class="title">
        <span> <b>{{ 'login.sso.title' | transloco }} </b>
            <small>{{ 'login.forgot.email.subtitle' | transloco }}</small>
        </span>
    </div>

    <form [formGroup]="forgotLoginForm" (ngSubmit)="sendRequest()" novalidate>
        <div class="input-field-container">

          <mat-form-field appearance="outline" class="field">
            <mat-label> {{ 'login.sso.email' | transloco }} </mat-label>
            <input matInput autofocus id="email-user-forgot" type="email" maxlength="100" formControlName="email" required />
          </mat-form-field>
            <mat-error *ngIf="forgotLoginForm.controls.email.touched && forgotLoginForm.controls.email.errors?.email"> {{ 'forgot.login.error.message.valid_email'
                | transloco }}
            </mat-error>
        </div>

        <br />

        <div class="button-row">
            <mat-label id="button-forgot-login-send-label" hidden>{{ 'Send Button' | t }}</mat-label>
            <button
                id="send-button"
                name="send-button"
                aria-labelledby="button-forgot-login-send-label"
                type="submit"
                class="send-button btn btn-md btn-info"
                [disabled]="!forgotLoginForm.valid">
                {{ 'login.forgot.login.send' | transloco }}
            </button>

            <mat-label id="button-forgot-login-go-back-label" hidden>{{ 'Go Back Button' | t }}</mat-label>
            <button
                id="go-back-button"
                name="go-back-button"
                aria-labelledby="button-forgot-login-go-back-label"
                class="btn btn-md go-back-button"
                type="button"
                [disabled]="this.loadingService.isLoading"
                (click)="goBack()">
                {{ 'login.sso.back' | transloco }}
            </button>
        </div>

    </form>

    <div class="forgot-login-errors-container">
        <small class="error" *ngIf="sent && isError && gotResultBack">
            <b *ngIf="apiError?.message?.toUpperCase() === 'NOT_FOUND'"> {{ 'forgot.login.error.message.not_found' |
                transloco }} </b>
            <b *ngIf="apiError?.message && apiError?.message !== 'NOT_FOUND' && apiError?.message?.toLowerCase() !== 'email_invalid'"> {{apiError?.message}} </b>

            <b *ngIf="apiError?.message?.toLowerCase() === 'email_invalid'"> {{ 'forgot.login.error.message.valid_email'
                | transloco }} </b>
        </small>

        <small *ngIf="sent && isSuccess && gotResultBack" class="success"> {{ 'forgot.login.success.message.title' |
            transloco }}
        </small>
    </div>
