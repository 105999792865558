import {NgModule, ModuleWithProviders} from '@angular/core';
import {GoogleTagManagerConfig} from './google-tag-manager-config';
import {GoogleTagManagerConfigService} from "./google-tag-manager-config.service";

@NgModule()
export class GoogleTagManagerModule {
  public static forRoot(config?: GoogleTagManagerConfig): ModuleWithProviders<GoogleTagManagerModule> {
    return {
      ngModule: GoogleTagManagerModule,
      providers: [{provide: GoogleTagManagerConfigService, useValue: config}],
    };
  }
}
