<aside class="sidebar">
    <nav class="sidenav">
        <div id="btn-compose" class="btn-holder" *ngIf="userSuspendedFlag === 0">
            <button [routerLink]="['/compose']" aria-label="Compose Section" class="btn btn-shadow w-100 compose-btn"
                [disabled]="isComposeEnabled === false"
                matTooltip="{{ ( !isComposeEnabled ? 'no.permission': toggleSide ? 'sidebar.button.send.text' :'' ) | transloco }}"
                [class.compose]="currentSection === 'compose'">
                <span class="icon icon-send"></span>
                <span class="text">{{ 'sidebar.button.send.text' | transloco }}</span>
            </button>
        </div>
        <ul class="sidenav-list">
            <li>
                <button id="view-messages" [routerLink]="['/view-messages']" aria-label="View Message Section"
                    class="sidenav-link" [disabled]="!isStorageEnabled"
                    matTooltip="{{ ( !isStorageEnabled ? 'no.permission.view.messages' :  toggleSide ? 'sidebar.menu.view.messages.text' :'' ) | transloco }}"
                    [class.selected]="currentSection === 'view-messages'">
                    <span class="icon icon-inbox"></span>
                    <span class="text">{{ 'sidebar.menu.view.messages.text' | transloco }}</span>
                </button>
            </li>
            <li *ngIf="(userSuspendedFlag === 0)">
                <button id="reports" [routerLink]="['/reports']" class="sidenav-link" aria-label="Reports Section"
                    matTooltip="{{ ( toggleSide ? 'sidebar.menu.reports.text' :'' ) | transloco }}"
                    [class.selected]="currentSection === 'reports'">
                    <span class="icon icon-info"></span>
                    <span class="text">{{ 'sidebar.menu.reports.text' | transloco }}</span>
                </button>
            </li>
            <li *ngIf="isFileShareEnabled && (userSuspendedFlag === 0)" showPermission [brands]="brands.EFAX">
                <button id="fileshare" [routerLink]="['/fileshare']" class="sidenav-link" aria-label="FileShare Section"
                    matTooltip="{{ ( toggleSide ? 'sidebar.menu.fileshare.text' :'' ) | transloco }}"
                    [class.selected]="currentSection === 'fileshare'">
                    <span class="icon icon-fileshare"></span>
                    <span class="text">{{ 'sidebar.menu.fileshare.text' | transloco }}</span>
                </button>
            </li>
            <li *ngIf="isContactsEnabled">
                <button id="contacts" [routerLink]="['/contacts']" aria-label="Contacts Section" class="sidenav-link"
                    matTooltip="{{ ( toggleSide ? 'sidebar.menu.contacts.text' :'' ) | transloco }}"
                    [class.selected]="currentSection === 'contacts'">
                    <span class="icon icon-contacts"></span>
                    <span class="text">{{ 'sidebar.menu.contacts.text' | transloco }}</span>
                </button>
            </li>
            <!-- BILLINGS DISABLED FOR INTPORTAL-1.6 RELEASE
            <li *ngIf="isStorageEnabled">
                <button id="billing" [routerLink]="['/billing']" class="sidenav-link" aria-label="Billing Section"
                    matTooltip="{{ ( toggleSide ? 'sidebar.menu.billing.text' :'' ) | transloco }}"
                    [class.selected]="currentSection === 'billing'">
                    <span class="material-icons">credit_card</span>
                    <span class="text">{{ 'sidebar.menu.billing.text' | transloco }}</span>
                </button>
            </li> -->
            <li>
                <button id="account-details" class="sidenav-link" aria-label="Admin Section"
                    [disabled]="!isLegacyEnabled" [routerLink]="['/account-details']"
                    matTooltip="{{ ( toggleSide ? 'sidebar.menu.account-details.text' :'' ) | transloco }}"
                    [class.selected]="currentSection === 'account-details'">
                    <span class="icon icon-settings"></span>
                    <span class="text">{{ 'sidebar.menu.account-details.text' | transloco }}</span>
                </button>
            </li>
        </ul>
    </nav>
</aside>