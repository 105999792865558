export const environment = {
  graphqlServerUri: "https://faxclientapi.usw2.dev.ccs.biz/graphql",
  faxClientAPIHost: "https://faxclientapi.usw2.dev.ccs.biz",
  production: false,
  name: "DEV",
  brand: "myportal",
  buildVersion: '$BUILD_VERSION',
  enableMultibrand: false,
  lookUpSSOUrl: "https://auth.test.securedocex.com/corporate/lookup",
  baseUri: "http://localhost:3000",
  iframeUrl: "https://faxclientapi.usw2.qa.ccs.biz/status",
  accountDetailsUrl: "https://devmyaccount.efax.com/myaccount/autoLoginPortal?isIntPortal=true&",
  accountDetailsBaseUrl: "https://devmyaccount.efax.com/myaccount/",
  largeFileShareUrl: "https://files.dev.efax.com",
  signDomain: "test.messages.efax.com",
  recaptcha: {
    siteKeyV2: '6Ldj8JkUAAAAAIF0Rjg6ZigWbAPsHKmeqovdDn8K',
    siteKeyV3: '6Lda8JkUAAAAAJPNeMb2t4-uNh8IEhcOoI1-8bcq'
  },
  skipRecaptcha: false,
  skipCaptchaAutomationList: ["18058554480","12082756111","12016483782",
  "15304663103","13156932941"],
  coreApiUrl: "https://admin.dev.securedocex.com",
  mgmtUrl: "https://beaweb1.dmz.dev.j2noc.com:27011/mgmt/login",
  eFaxUrl: "https://www.efax.com/",
  gtm_id: "GTM-WJVT2P" // todo need to check if it is correct for DEV
};
