import {
  ForgotLoginIdFailure, ForgotLoginIdStart, ForgotLoginIdSuccess,
  LoginAction,
  LoginCompleteAction, LoginErrorAction, LoginSSOFailure, LoginSSOStart, resetModalStatus,
  LoginSSOSuccess, LogOutAction, LogOutCompleteAction
} from "../actions/login.actions";
import { LoginResponseModel } from "../../models/login.response.model";
import { initialState, State } from "../state/login.state";
import { createReducer, on } from "@ngrx/store";

export const reducer = createReducer(
  initialState,
  on(
    LoginAction,
    (state, { payload }) => {
      return {
        ...state, login: payload, response: new LoginResponseModel(),
        isBusy: true, isLoggedIn: false, isError: false
      }
    }
  ),
  on(
    LoginCompleteAction,
    (state, { payload }) => {
      return {
        ...state,
        login: { username: payload.userName, password: "" },
        response: payload,
        isBusy: false,
        isLoggedIn: payload.loginSucceded,
        isLoggedOut: false,
        isError: false,
        failedAttempts: 0
      }
    }
  ),
  on(
    LoginErrorAction,
    (state, { payload }) => {
      return {
        ...state,
        login: { username: payload.userName, password: "" },
        response: payload,
        isBusy: false,
        isLoggedIn: false,
        isLoggedOut: true,
        isError: true,
        failedAttempts: state.failedAttempts + 1
      }
    }
  ),
  on(
    LogOutAction,
    (state) => {
      return {
        ...state,
        login: { username: "", password: "" },
        response: new LoginResponseModel(),
        isBusy: true,
        isLoggedIn: false,
        isError: false
      }
    }
  ),
  on(
    LogOutCompleteAction,
    (state) => {
      return {
        ...state,
        login: { username: "", password: "" },
        response: new LoginResponseModel(),
        isBusy: false,
        isLoggedIn: false,
        isError: false,
        isLoggedOut: true
      }
    }
  ),
  on(
    LoginSSOStart,
    (state, { user_email }) => {
      return {
        ...state,
        login: { username: user_email, password: "" },
        isBusy: true,
        isError: false,
        isLoggedIn: false,
        apiError: undefined,
        ssoSuccess: { redirect_url: "", connection_id: "" },
        ssoErrors: undefined
      }
    }
  ),
  on(
    LoginSSOSuccess,
    (state, { response }) => {
      return {
        ...state,
        ssoSuccess: { ...response },
        isBusy: false,
        isError: false,
        isLoggedIn: false,
        apiError: undefined
      }
    }
  ),
  on(
    LoginSSOFailure,
    (state, { errors }) => {
      return {
        ...state,
        ssoErrors: errors,
        isBusy: false,
        isError: true,
        isLoggedIn: false,
        apiError: undefined,
        ssoSuccess: { redirect_url: "", connection_id: "" },
      }
    }
  ),
  on(
    ForgotLoginIdStart,
    (state, { payload }) => {
      return {
        ...state,
        forgotLoginId: true,
        forgotLoginIdPayload: { ...payload },
        forgotLoginIdFailed: false,
        forgotLoginIdSent: false,
        isError: false,
        isBusy: true
      }
    }
  ),
  on(
    ForgotLoginIdSuccess,
    (state, { status }) => {
      return {
        ...state,
        forgotLoginId: true,
        forgotLoginIdSent: status,
        forgotLoginIdFailed: false,
        isBusy: false,
        isError: false
      }
    }
  ),
  on(
    ForgotLoginIdFailure,
    (state, { error }) => {
      return {
        ...state,
        forgotLoginId: true,
        forgotLoginIdFailed: true,
        apiError: error,
        forgotLoginIdSent: false,
        isBusy: false,
        isError: true
      }
    }
  ), on(
    resetModalStatus,
    (state) => ({
      ...state,
      forgotLoginId: false,
      isBusy: false,
      isError: false,
      apiError: undefined,
      ssoErrors: undefined,
      forgotLoginIdSent: false,
      forgotLoginIdFailed: false
    })
  )
);

export const getLoginState = (state: State) => state;
