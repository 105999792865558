<div class="content">
    <main class="main" *ngIf="!addEditFlag">
        <div class="horizontal-grid">
            <div class="second-block">


                <div class="table-actions">
                    <div class="box-contact-title">
                        <h5 id="contacts-table-title">{{ 'sidebar.menu.contacts.text' | transloco }}</h5>
                    </div>

                    <div class="box-icons">


                        <mat-icon *ngIf="!this.loadingService.isLoading" id="add-contact-icon" aria-label="Add"
                            (click)="addEditContact()">add_circle_outline</mat-icon>
                        <button class="center-delete" aria-label="Delete" mat-icon-button
                            [disabled]="!selection.hasValue() || selection.selected.length === 0"
                            (click)="startDeleteContact()">
                            <mat-icon *ngIf="!this.loadingService.isLoading" aria-label="Delete icon">delete</mat-icon>
                        </button>

                    </div>
                </div>

                <!--div class="paginator-block">
                    <mat-form-field  class="page-input">
                        <input matInput type="number"  min="1"  placeholder="Page" [(ngModel)]="pageNumber" (keyup.enter)="goToPage()">
                    </mat-form-field>
                    <mat-error class="c-label error-pos" *ngIf="this.invalidPage">
                        {{ 'compose.form.errors.pattern.label' | transloco }}
                    </mat-error>
                    <mat-paginator [disabled]="dataSource.data.length === 0"
                        [length]="contactsObject !== undefined && contactsObject !== null? contactsObject.totalRecords : 0"
                        (page)="pageChanged($event)" [showFirstLastButtons]="true" [pageSize]="25" [hidePageSize]="true"
                        aria-label="{{ 'general.selectPage' | transloco }}">
                    </mat-paginator>
                </div-->

                <div class="paginator-block">

                  <div class="pagination-field">

                      <label for="pageNumber" class="page-number-label">{{('Page' | transloco) + ' '}}</label>
                      <mat-form-field id="pageNumber" class="page-input {{isPageNumberOutOfPage ? 'mdc-text-field--invalid' : ''}}">
                          <input matInput class="text-center" type="number" min="1" [max]="pageCount"
                              placeholder="Page"
                                 (focusout)="goToPage($event)"
                                 [value]="pageIndex"
                                 (keyup.enter)="goToPage($event)"
                          >
                      </mat-form-field>

                      <label for="pageNumber" class="page-number-label">{{' ' + ('of' | transloco) + ' ' +
                      pageCount + ' | '}}</label>
                  </div>


                  <mat-paginator class="paginator" [disabled]="dataSource.data.length === 0"
                     [length]="recordsLength"
                      (page)="pageChanged($event)" [showFirstLastButtons]="true" [pageSize]="rowPerPage" [hidePageSize]="true"
                      attr.aria-label="{{ 'general.selectPage' | transloco }}">
                  </mat-paginator>
              </div>

            </div>
        </div>

        <div class="horizontal-grid">

            <table id="view-contacts-table" mat-table matSort matSortDisableClear
                (matSortChange)="announceSortChange($event)" [dataSource]="dataSource" multiTemplateDataRows>

                <!-- Checkbox Column For -->
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox class="align-check check-size" (change)="masterToggle($event)"
                            [checked]="selection.hasValue() && isAllSelected()" *ngIf="dataSource.data.length > 0"
                            [aria-label]="checkboxLabel()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox class="check-size" (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
                            [aria-label]="checkboxLabel(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pl-10"> {{ 'contacts.name' | transloco
                        }}
                    </th>
                    <td mat-cell *matCellDef="let element" (mouseover)="element.hovered=true"
                        (click)="tdClicked(element.contactId)"> {{ element.name }}</td>
                </ng-container>

                <ng-container matColumnDef="company">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pl-10"> {{ 'contacts.company' |
                        transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element" (mouseover)="element.hovered=true"
                        (click)="tdClicked(element.contactId)"> {{element.company}} </td>
                </ng-container>

                <ng-container matColumnDef="fax">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pl-10"> {{ 'contacts.faxnumber' |
                        transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element" (mouseover)="element.hovered=true"
                        (click)="tdClicked(element.contactId)"> {{element.countryCode}} {{element.fax | FormatPhoneNumber}} </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pl-10">
                        {{ 'contacts.email' | transloco }} </th>
                    <td mat-cell *matCellDef="let element" (mouseover)="element.hovered=true"
                        (click)="tdClicked(element.contactId)"> {{element.email}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="contactsHeader"></tr>
                <tr mat-row *matRowDef="let row; columns: contactsHeader;" [class.row-hovered]="row.hovered"
                    (mouseout)="row.hovered=false" [ngClass]="!row.isRead ? 'unread-message' : 'read-message'">
                </tr>

                <div *ngIf="!this.loadingService.isLoading">
                    <!-- Row shown when there is no matching data.-->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell no-contacts-inbox" colspan="9999">
                            {{ 'contacts.nodata' | transloco }}
                        </td>
                    </tr>
                </div>

            </table>
        </div>
    </main>

    <snack-success *ngIf="contactOperationStatus === 'CREATED'"
        message="{{ 'contacts.create.success' | transloco }}"></snack-success>
    <snack-error *ngIf="contactOperationStatus === 'CREATE_ERROR'"
        message="{{ 'contacts.create.error' | transloco }}"></snack-error>
    <snack-error *ngIf="contactOperationStatus === 'DUPLICATE_CONTACT'"
        message="{{ 'contacts.create.duplicated' | transloco }}"></snack-error>
    <snack-success *ngIf="contactOperationStatus === 'DELETED'"
        message="{{ 'contacts.delete.success' | transloco }}"></snack-success>
    <snack-error *ngIf="contactOperationStatus === 'DELETE_ERROR'"
        message="{{ 'contacts.delete.error' | transloco }}"></snack-error>

</div>
