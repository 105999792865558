import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'myportal-send-fax-options',
  templateUrl: './send-fax-options.component.html',
  styleUrls: ['./send-fax-options.component.scss']
})
export class SendFaxOptionsComponent {

  constructor(private dialogRef: DialogRef) {

  }

  close() {
    this.dialogRef.close();
  }

}
