import { createReducer, on } from '@ngrx/store';
import * as contactActions from '../actions/contacts.actions';
import { createRehydrateReducer } from '../../../shared/functions/rehydrateReducer';
import { Contact, ContactList, ContactSingle } from '../../models/contact.model';
import { ApiError, ContactOperationStatus } from '../../models/shared.model';
import { StateStoreKeys } from 'src/app/shared/constants/constants';

export interface ContactsState {
    errorMessage: ApiError | undefined;
    serviceKey: string | undefined,
    loading: boolean;
    contactList: ContactList | undefined;
    contactSingle: ContactSingle | undefined;
    offset: Number,
    limit: number,
    contactOperationStatus: ContactOperationStatus | undefined,
    addEditData: { selectedContact: Contact | undefined, contactId: string | undefined } | undefined
}

export const initialComposeState: ContactsState = {
    loading: false,
    contactList: undefined,
    contactSingle: undefined,

    errorMessage: undefined,
    serviceKey: undefined,
    offset: +0,
    limit: 0,

    contactOperationStatus: undefined,

    addEditData: undefined
}

export const reducer = createReducer(
    initialComposeState,
    on(
        contactActions.loadContactListBegin,
        (state, { payload: { limit, offset, sortBy, sortDirection } }) =>
        ({
            ...state, contactList: undefined, loading: true,
            limit: limit, offset: offset, sortBy: sortBy, sortDirection: sortDirection,
            contactOperationStatus: undefined, addEditData: undefined
        })
    ),
    on(
        contactActions.loadContactListSuccess,
        (state, { contactList }) =>
            ({ ...state, contactList: contactList, loading: false })
    ),
    on(
        contactActions.loadContactListFailed,
        (state, { errorMessage }) =>
            ({ ...state, errorMessage: errorMessage, loading: false })
    ),
    on(
        contactActions.loadContactSingleBegin,
        (state, { payload: { contactId } }) =>
        ({
            ...state, contactSingle: undefined, loading: true,
            contactId: contactId,
            contactOperationStatus: undefined,
        })
    ),
    on(
        contactActions.loadContactSingleSuccess,
        (state, { contactSingle }) =>
        ({
            ...state, contactSingle: contactSingle, loading: false,
            addEditData: { contactId: contactSingle?.contact.contactId, selectedContact: contactSingle?.contact }
        })
    ),
    on(
        contactActions.loadContactSingleFailed,
        (state, { errorMessage }) =>
            ({ ...state, errorMessage: errorMessage, loading: false })
    ),

    on(
        contactActions.createNewContactBegin,
        (state, { payload: { } }) =>
        ({
            ...state, loading: true,
            contactOperationStatus: undefined,
        })
    ),
    on(
        contactActions.createNewContactSuccess,
        (state) =>
        ({
            ...state, loading: false,
            contactOperationStatus: ContactOperationStatus.CREATED
        })
    ),
    on(
        contactActions.createNewContactFailed,
        (state, { errorMessage }) =>
        ({
            ...state, errorMessage: errorMessage, loading: false,
            contactOperationStatus: errorMessage?.message.includes("DUPLICATE_CONTACT") ?
                ContactOperationStatus.DUPLICATE_CONTACT : ContactOperationStatus.CREATE_ERROR
        })
    ),

    on(
        contactActions.updateContactBegin,
        (state, { payload: { } }) =>
        ({
            ...state, loading: true,
            contactOperationStatus: undefined,
        })
    ),
    on(
        contactActions.updateContactSuccess,
        (state, { contact }) =>
        ({
            ...state, loading: false,
            contactOperationStatus: ContactOperationStatus.UPDATED,
            contactSingle: { object_type: 'ContactSingle', contact: contact },
            addEditData: { contactId: contact.contactId, selectedContact: contact }
        })
    ),
    on(
        contactActions.updateContactFailed,
        (state, { errorMessage }) =>
        ({
            ...state, errorMessage: errorMessage, loading: false,
            contactOperationStatus: errorMessage?.message.includes("DUPLICATE_CONTACT") ?
                ContactOperationStatus.DUPLICATE_CONTACT : ContactOperationStatus.UPDATE_ERROR
        })
    ),

    on(
        contactActions.deleteContactBegin,
        (state, { payload: { input } }) =>
        ({
            ...state, input: input, loading: true,
            contactOperationStatus: undefined,
        })
    ),
    on(
        contactActions.deleteContactSuccess,
        (state, { }) => {
            return ({
                ...state,
                loading: false,
                contactOperationStatus: ContactOperationStatus.DELETED
            })
        }
    ),
    on(
        contactActions.deleteContactFailed,
        (state, { errorMessage }) =>
        ({
            ...state,
            errorMessage: errorMessage,
            loading: false,
            contactOperationStatus: ContactOperationStatus.DELETE_ERROR
        })
    ),
    on(
        contactActions.addEditSingleContact,
        (state, { content }) =>
            ({ ...state, addEditData: { contactId: content?.contactId, selectedContact: content?.contact } })
    )
);
