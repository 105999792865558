<div class="container-form">
  <div class="box-contact-title">
    <div class="box-icons">
      <mat-icon
        class="d-inline-block c-pointer"
        aria-hidden="false"
        aria-label="Back"
        (click)="cancelAdd()"
        >chevron_left</mat-icon
      >
      <div class="d-inline-block center-vertical">
        <h5>
          {{ (addingFlag ? "contacts.new" : "contacts.view") | transloco }}
        </h5>
      </div>
    </div>

    <div mat-dialog-actions class="d-inline-block">
      <button [disabled]="editingFlag || addingFlag || !hasComposePermission || !hasFaxnumber" aria-label="Compose Fax"
        (click)="composeFax()" class="btn btn-md btn-primary btn-custom"
        matTooltip="{{(!hasComposePermission ? 'no.permission' : 'compose.lead.text') | transloco}}" cdkFocusInitial>
        {{ "compose.lead.text" | transloco }}
      </button>
    </div>
    <div mat-dialog-actions class="buttons-right d-inline-block">
      <button class="btn btn-md btn-info btn-custom" aria-label="Edit Contact" *ngIf="!editingFlag && !addingFlag"
        (click)="toggleEdit()">
        {{ "contacts.edit" | transloco }}
      </button>

      <button aria-label="Cancel" *ngIf="editingFlag || addingFlag" (click)="cancelAdd()"
        class="ml-5 btn btn-md btn-primary btn-custom">
        {{ "contacts.cancel" | transloco }}
      </button>

      <button class="ml-5 btn btn-md btn-info btn-custom" aria-label="Save Contact" *ngIf="editingFlag || addingFlag"
        (click)="onSubmit(formGroup.value)" [disabled]="!formGroup.valid">
        {{ "contacts.save" | transloco }}
      </button>

      <button class="ml-5 mr-35 btn btn-md btn-primary btn-custom" aria-label="Delete Contact"
        *ngIf="!addingFlag && !editingFlag" (click)="startDeleteContact()">
        {{ "contacts.exclude" | transloco }}
      </button>
    </div>
  </div>

  <div class="horizontal-grid">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" class="form">
      <div class="row">
        <mat-form-field appearance="fill" class="field">
          <mat-label>{{ "contacts.form.firstname" | transloco }} </mat-label>
          <input matInput formControlName="firstName" />

          <mat-error class="c-label" *ngIf="
              this.formGroup.get('firstName')?.invalid &&
              (this.formGroup.get('firstName')?.touched ||
                this.formGroup.get('firstName')?.dirty) &&
              formGroup.get('firstName')?.errors?.required
            ">
            {{ "compose.form.errors.required.label" | transloco }}
          </mat-error>
          <mat-error class="c-label" *ngIf="
              (this.formGroup.get('firstName')?.invalid &&
                (this.formGroup.get('firstName')?.touched ||
                  this.formGroup.get('firstName')?.dirty) &&
                formGroup.get('firstName')?.errors?.pattern) ||
              formGroup.get('firstName')?.errors?.maxlength ||
              formGroup.get('firstName')?.errors?.minlength
            ">
            {{ "compose.form.errors.pattern.label" | transloco }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="field">
          <mat-label>{{ "contacts.form.lastname" | transloco }}</mat-label>
          <input matInput formControlName="lastName" />
          <ng-container *ngIf="
              this.formGroup.get('lastName')?.invalid &&
              (this.formGroup.get('lastName')?.touched ||
                this.formGroup.get('lastName')?.dirty)
            " class="c-validation">
            <div class="c-label" *ngIf="
                formGroup.get('lastName')?.errors?.pattern ||
                formGroup.get('lastName')?.errors?.maxlength ||
                formGroup.get('lastName')?.errors?.minlength
              ">
              {{ "compose.form.errors.pattern.label" | transloco }}
            </div>
          </ng-container>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field appearance="fill" class="field">
          <mat-label>{{ "contacts.form.countrycode" | transloco }}</mat-label>
          <select matNativeControl formControlName="countryCode">
            <option value="" selected>{{'contacts.country.code.placeholder' | transloco}}</option>
            <option *ngFor="let country of countries" [value]="country.code">
              {{ country.name }} ({{ country.dial_code }}) - {{ country.code }}
            </option>
          </select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="field">
          <mat-label *ngIf="isFaxRequired()">{{
            "contacts.faxnumber" | transloco
            }}</mat-label>
          <mat-label *ngIf="!isFaxRequired()">{{
            "contacts.faxnumber" | transloco
            }}</mat-label>
          <input matInput
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            type="number" (keypress)="checkFaxNumbervalue($event)" maxlength="11" formControlName="fax" />
          <mat-error *ngIf="
              formGroup.get('fax')?.hasError('required') &&
              formGroup.get('fax')?.touched
            ">
            {{ "compose.form.errors.required.label" | transloco }}
          </mat-error>
          <mat-error *ngIf="
              formGroup.get('fax')?.hasError('pattern') &&
              formGroup.get('fax')?.touched
            ">
            {{ "compose.form.errors.pattern.label" | transloco }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field appearance="fill" class="field">
          <mat-label>{{ "contacts.company" | transloco }}</mat-label>
          <input matInput formControlName="company" />
          <ng-container *ngIf="
              this.formGroup.get('company')?.invalid &&
              (this.formGroup.get('company')?.touched ||
                this.formGroup.get('company')?.dirty)
            " class="c-validation">
            <div class="c-label" *ngIf="
                formGroup.get('company')?.errors?.pattern ||
                formGroup.get('company')?.errors?.maxlength ||
                formGroup.get('company')?.errors?.minlength
              ">
              {{ "compose.form.errors.pattern.label" | transloco }}
            </div>
          </ng-container>
        </mat-form-field>
        <mat-form-field appearance="fill" class="field">
          <mat-label *ngIf="!isEmailRequired()">{{
            "contacts.email" | transloco
            }}</mat-label>
          <mat-label *ngIf="isEmailRequired()">{{ "contacts.email" | transloco }}
          </mat-label>
          <input matInput formControlName="email" />

          <mat-error class="c-label" *ngIf="formGroup.get('email')?.errors?.required">
            {{ "compose.form.errors.required.label" | transloco }}
          </mat-error>

          <mat-error *ngIf="formGroup.get('email')?.errors?.pattern">
            {{ "contacts.email.invalid" | transloco }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>

  <snack-success
    *ngIf="contactOperationStatus === 'UPDATED'"
    message="{{ 'contacts.update.success' | transloco }}"
  ></snack-success>
  <snack-error
    *ngIf="contactOperationStatus === 'UPDATE_ERROR'"
    message="{{ 'contacts.update.error' | transloco }}"
  ></snack-error>
  <snack-error
    *ngIf="contactOperationStatus === 'DUPLICATE_CONTACT'"
    message="{{ 'contacts.create.duplicated' | transloco }}"
  ></snack-error>
</div>
