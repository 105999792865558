import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'single-item',
  templateUrl: 'single-item.component.html',
  styleUrls: ['./single-item.component.scss']
})
export class SingleItemComponent {

  @Input() isCollapse: boolean = true
  @Input() isEditable: boolean = true

  @Input() originalFolderName = "";
  @Input() messagesCount: number;

  @Output() onEdit: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  edit() {
    this.onEdit.emit()

  }
}
