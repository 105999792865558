import { Pipe, PipeTransform } from '@angular/core';

const MAX_VISIBLE_LENGTH = 40;

@Pipe({
  name: 'longFileName'
})
export class LongFileNamePipe implements PipeTransform {
     transform(value: string | undefined): string {         
        if (value === undefined) {
          return "";
        }       
        if(value.length <= MAX_VISIBLE_LENGTH) {
          return value;
        }             
        const firstPart = value.slice(0, MAX_VISIBLE_LENGTH);
        const split = value.split('.');
        const extension = split[split.length - 1];  
        return `${firstPart}...`;
     }
 }