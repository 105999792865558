import { Component, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription, Observable, Subject } from 'rxjs';
import { take, map, takeUntil, filter, switchMap } from 'rxjs/operators';
import { Service, UserSingle } from 'src/app/auth/models/user.single.model';
import { AuthFacadeService } from 'src/app/auth/services/auth-facade.service';
import { LoginFacade } from 'src/app/login/facade/login.facade';
import { BnNgIdleService } from 'bn-ng-idle';

import { environment } from 'src/environments/environment';
import { BrandService } from '../services/brand.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { FEEDBACK_DURATION } from '../constants/constants';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, OnDestroy {

  @Output() toggleButton = new EventEmitter<boolean>();
  isToggled = false;
  @Output() signOff = new EventEmitter();
  subscriptions: Subscription[] = [];
  searchText: string;
  serviceKeyText: string = '';

  @ViewChild('legacyFrame') legacyFrame: ElementRef;

  servicesList$: Observable<Service[] | undefined>;
  localCurrentService$: Observable<Service | undefined>;

  terminated$: Subject<boolean> = new Subject<boolean>();
  userBrand$: Observable<string | undefined>;
  portalSessionID: any;

  brandConfig: any = undefined;
  loadingCounter: number = 0;
  runningCounter: boolean = false;
  userSuspendFlag: number = 0;

  constructor(private authFacadeService: AuthFacadeService,
    private bnIdle: BnNgIdleService,
    private loginFacadeService: LoginFacade,
    private brandService: BrandService, private sanitizer: DomSanitizer, private cookieService: CookieService) {

    this.brandService.config$
      .pipe(filter((config: any) => config), takeUntil(this.terminated$))
      .subscribe((config: any) => {
        this.brandConfig = config;
      });

    this.authFacadeService.currentService$.subscribe(service => {
      if (service !== undefined) {
        this.serviceKeyText = service.phoneNumber;
      }
    });

    this.localCurrentService$ = this.authFacadeService.currentService$.pipe(takeUntil(this.terminated$));
    this.servicesList$ =
      this.authFacadeService.userSingle$.pipe(
        map((value: UserSingle | undefined) => {
          if (value !== undefined && value?.userSingle?.user?.services?.length > 0) {
            return value?.userSingle.user.services.filter(svc => svc.serviceType !== 'OUTBOUND_FAXING');
          } else {
            return [];
          }
        })
      );

    this.userBrand$ = this.authFacadeService.userSingle$
      .pipe(
        map(result => result?.userSingle.user.account.brand),
        takeUntil(this.terminated$)
      );

    this.authFacadeService.legacyState$.pipe(takeUntil(this.terminated$))
      .subscribe(value => {
        if (value !== undefined) {
          if (value.typeNumber === 1 && !this.runningCounter) {
            this.loadAutoLoginIframe(value.locationUrl);
            this.runningCounter = true;
          }
          if (value.typeNumber === 2) {
            this.loadAlternateIframe(value.locationUrl);
          }
        }
      });

    if (window.addEventListener) {
      window.addEventListener("message", this.receiveMessage.bind(this), false);
    } else {
      (<any>window).attachEvent("onmessage", this.receiveMessage.bind(this));
    }

    this.authFacadeService.authState$.pipe(map(s => s.suspendedFlag), takeUntil(this.terminated$)).subscribe(value => {
      this.userSuspendFlag = value;
    });
  }

  private loadAutoLoginIframe(url: string) {
    const iFrame = document.createElement("iframe");
    iFrame.width = "0";
    iFrame.height = "0";
    iFrame.src = url;
    iFrame.id = "autoLoginIframe";
    document.body.append(iFrame);
  }

  private loadAlternateIframe(url: string) {
    const iFrame = document.createElement("iframe");
    iFrame.width = "0";
    iFrame.height = "0";
    iFrame.src = url;
    iFrame.id = "alternateIframe";
    document.body.append(iFrame);
    const localCounter = window.setTimeout(() => {
      const iframeReference = document.getElementById("alternateIframe");
      if (iframeReference !== null) {
        iframeReference.remove();
      }
      clearTimeout(localCounter);

    }, FEEDBACK_DURATION);
  }

  onLoad(event: any) {
    if (event?.eventPhase === 2) {
      this.loadingCounter++;
      if (this.loadingCounter === 2) {
        const localCounter = window.setTimeout(() => {
          this.authFacadeService.dispatchSetLegacyNone();
          this.loadingCounter = 0;
          clearTimeout(localCounter);
        }, 3000);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.terminated$.next(true);
  }

  toggle() {
    this.isToggled = !this.isToggled;
    this.toggleButton.emit(this.isToggled);
  }

  logout() {
    this.authFacadeService.timerTriggered = false;
    this.bnIdle.stopTimer();
    const portalSessionID = localStorage.getItem("portalSessionID") || "";
    if (portalSessionID !== undefined) {
      try {
        this.authFacadeService.webMyAccountLogout(portalSessionID);
        sessionStorage.clear();
      } catch (err) {
        console.log(`Error legacy logout`, err);
      }
    }
    this.loginFacadeService.logOut();
  }

  redirectTo123Mail() {
    this.localCurrentService$.pipe(
      filter(sv => sv !== undefined),
      switchMap(sv => this.authFacadeService.get123MailRedirectUrl((sv?.serviceKey || ""), this.brandConfig.id)),
      take(1)
    ).subscribe(data => {
      if (data.eFaxMailUrl?.url?.length > 0) {
        window.open(data.eFaxMailUrl.url, '_blank');
      } else {
        console.log('Error getting 123 email url', data);
      }
    });
  }

  changeSelectedService(event: any) {
    this.servicesList$.pipe(take(1)).subscribe(serviceList => {
      if (serviceList !== undefined) {
        for (let svc of serviceList) {
          if (svc.serviceKey === event.target.value) {
            this.authFacadeService.setCurrentService(svc);
            break;
          }
        }
      }
    }
    );
  }

  returnToOldSystem() {
    const portalSessionID = localStorage.getItem("portalSessionID");
    if (portalSessionID !== undefined) {
      const requestStickySession = "JK_JSESSIONID=" + (portalSessionID || "");
      this.authFacadeService.timerTriggered = false;
      this.bnIdle.stopTimer();
      this.cookieService.set("_vis_rmt_on", "true");
      window.open(environment.accountDetailsBaseUrl +
        "redirect_legacy?isIntPortal=true&portalSessionID=" + portalSessionID + "&" +
        requestStickySession + (this.userSuspendFlag > 0 ? "&suspendFlags=2" : "") +
        '&accountDID=' + this.serviceKeyText, "_blank");
      this.loginFacadeService.logOut();
    } else {
      console.error("Portal SessionId is missing");
    }
  }

  ngOnInit(): void {
  }

  receiveMessage(event: any) {
    if (typeof (event.data) === 'string') {
      if (event.data.includes('sessionIdHash')) {
        this.authFacadeService.dispatchSetLegacyNone();
        const content = JSON.parse(event.data);
        // this.autoLoginPortalURL = this.sanitizer.bypassSecurityTrustResourceUrl("");
        const iframe = document.getElementById("autoLoginIframe");
        if (iframe !== null) {
          iframe.remove();
        }
        this.authFacadeService.storeWebMyAccountToken(content);
        this.authFacadeService.isLegacySet$.next(true);
      }
    }
  }
}
