<div class="container">
  <h6 mat-dialog-title>{{ sessionMessage }}</h6>
  <div class="button-row">

    <button class="btn btn-md btn-info button-right" color="primary" cdkFocusInitial (click)="onContinue()">
      {{ continueMessage }}
    </button>

    <button class="btn btn-md btn-error button-right" (click)="onCancel()">
      {{ cancelMessage }}
    </button>
  </div>

  <small class="closing">{{ closingMessage }} <b>{{counter}} </b> {{ secondsMessage }}</small>
</div>