import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DetailUsageLog, UsageLog } from './reports.service';

@Injectable()
export class ReportsApiService {

  constructor(private http: HttpClient) { }

  getUsage(currentDID: string) {
    // const url = environment.faxFusion + "/core/usage";
    const url = environment.faxFusion + "/core/usage";
    const params = new HttpParams()
      .set("faxNumber", currentDID)
      .set("maxAge", 30);
    return this.http.get<UsageLog>(url, { params: params });
  }

  /// Date format required: YYYYMMDD
  getSendLogs(fromDate: string, toDate: string, pageIndex: number, pageSize: number) {
    const url = environment.faxFusion + "/core/usage/log/send"
    const params = new HttpParams()
      .set("fromDate", fromDate)
      .set("toDate", toDate)
      .set("page", pageIndex)
      .set("size", pageSize);
    return this.http.get<DetailUsageLog>(url, { params: params });
  }

  /// Date format required: YYYYMMDD
  getReceivedLogs(fromDate: string, toDate: string, faxNumber: string, pageIndex: number, pageSize: number) {
    const url = environment.faxFusion + "/core/usage/log/receive"
    const params = new HttpParams()
      .set("faxNumber", faxNumber)
      .set("fromDate", fromDate)
      .set("toDate", toDate)
      .set("page", pageIndex)
      .set("size", pageSize)
      .set("sort", "MSG_TIME,DESC");
    return this.http.get<DetailUsageLog>(url, { params: params });
  }
}
