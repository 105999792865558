import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AuthFacadeService } from 'src/app/auth/services/auth-facade.service';
import { LangStateService } from 'src/app/shared/lang/lang-state.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'myportal-fileshare',
  templateUrl: './fileshare.component.html',
  styleUrls: ['./fileshare.component.scss']
})
export class FileshareComponent implements OnInit, OnDestroy {
  targetUrl: string = '';
  private terminated$: Subject<boolean> = new Subject<boolean>();
  private langChanging$: Observable<string>;
  private currentUserEmail$: Subject<string> = new Subject<string>();
  private currentSelectedLanguage$: Subject<string> = new Subject<string>();

  constructor(private authFacadeService: AuthFacadeService, private translateService: LangStateService) {
    this.langChanging$ = this.translateService.isChanging$();
    this.subscribeToGetUrl();
  }


  private subscribeToCurrentUser(): void {
    this.authFacadeService.userSingle$
      .pipe(map(result => result?.userSingle?.user), takeUntil(this.terminated$))
      .subscribe(value => {
        if (value !== undefined) {
          this.currentUserEmail$.next(btoa(value?.email));
        }
        else {
          console.log('Error loading');
        }
      }, (err) => {
        console.log('Error on Observable', err);
      }, () => {
      });
  }

  private subscribeToLanguage(): void {
    this.langChanging$.pipe(takeUntil(this.terminated$)).subscribe(val => {
      this.currentSelectedLanguage$.next(this.translateService.getSelectedLanguage()?.split('-')[0]);
    });
  }

  private subscribeToGetUrl(): void {
    const fileShareSessionId: string = "ddc9c544f679dde5c9e1383be56634c5";
    combineLatest([this.currentSelectedLanguage$, this.currentUserEmail$]).pipe(takeUntil(this.terminated$)).subscribe(([lang, encodedEmail]) => {
      if (!!lang && !!encodedEmail) {
        const fileShareUrl: string = `${environment.largeFileShareUrl}/?sessionid=${fileShareSessionId}&email=${encodedEmail}&lang=${lang}`;
        this.targetUrl = fileShareUrl;
      }
    })

  }


  ngOnInit(): void {
    this.subscribeToCurrentUser();
    this.subscribeToLanguage();
  }

  ngOnDestroy(): void {
    this.terminated$.next(true);
  }
}
