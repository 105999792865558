import { gql } from 'apollo-angular';

export const USER_SINGLE_QUERY = gql`
query($isRefresh: Boolean) {
  userSingle(isRefresh: $isRefresh) {
    ... on UserSingle {
      user {
        object_type
        id
        account {
          brand
          resellerId
          accountType
          isInAppPurchaseAccount
        }
        email
        firstName
        lastName
        company
        timeFormat
        timeZone
        dateFormat
        contactNumbers {
          isDefault
          phoneNumber
          location
        }
        services {
          isSecure
          serviceKey
          serviceType
          formattedPhoneNumber
          phoneNumber
          internationalFormattedPhoneNumber
          mailboxCanCreateFolders
          mailboxCanSignDocuments
          mailboxCanUploadDocuments
          offerCode
          freeSendLimited
          freeSendGiftDepleted
          freeSendGiftExpired
          freeTrialEndDate
          nextBillingDate
          emailAddresses
          emailAddressesPending {
            id
            email
            pendingKey
            valEmailSent
            status
          }
          planDetail {
            offerCode
            appName
            subscriptionFee
            subscriptionFeeFormatted
            billingFrequency
            includedPackageInbound
            includedPackageOutbound
            currency
          }
          features {
            faxCSID
            isInboundStorageEnabled
            isStorageEnabled
            isSendEnabled
            isEditProfileEnabled
            isEditEmailSettingsEnabled
            isDeleteFaxEnabled
            isEditPreferenceEnabled
            isSignatureEnabled
            isReceiveEnabled
            isSuppressCoverPage
          }
        }
        dataSovereignty {
          isDS
          sovereignRegion
          proxyUrl
        }
        userInfo {
          faxNumber
          isValidLoginId
          customerKey
          firstName
          lastName
          country
          oemId
          customerType
          migrateFlag
          loginFlag
          status
        }
      }
    }
    ... on ApiError {
      object_type
      code
      message
    }
  }
}
`;

export const AUTH_REFRESH_TOKEN_QUERY = gql`
mutation refreshToken {
  refreshToken {
    __typename

    ...on Authenticate {
      object_type
      token
      passwordExpireIn
      registerDeviceStatus
    }

    ... on ApiError {
	    object_type
	    code
      message
	  }
  }
}
`;

export const AUTH_WEB_AUTH_LOGIN_HASH = gql`
  query ($serviceKey: String!, $isIntPortal: Boolean, $current: Boolean) {
    getWebAutoLoginHash(serviceKey: $serviceKey, isIntPortal:$isIntPortal, current:$current) {
      __typename
      ...on WebAutoLoginHash{
        object_type
        hash
        code
        description
      }
      ...on ApiError{
        object_type
        code
        message
      }
    }
  }
`;

export const EMAIL_123_QUERY = gql`
  query ($serviceKey: String!, $brand: String!) {
    eFaxMailUrl (serviceKey: $serviceKey, brand: $brand){
      __typename
      ... on EFaxMailUrl{
        object_type
         url
      }
       ... on ApiError{
        code
        message
      }
    }
  }
`;
