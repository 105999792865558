import { Component } from '@angular/core';

@Component({
  selector: 'myportal-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  email: string = 'scott.scheweigert@consensus.com';
  workNumber: string = '13238609223';


  changeName() {
    console.log('changeName');

  }

  changeContactEmail() {
    console.log('changeContactEmail');

  }

  changePhysicalAddress() {
    console.log('changePhysicalAddress');

  }

  changePassword() {
    console.log('changePassword');

  }

  changeCompanyName() {
    console.log('changeCompanyName');

  }
  changeCompanySize() {
    console.log('changeCompanySize');

  }

  changeHomeNumber() {
    console.log('changeHomeNumber');

  }

  changeWorkNumber() {
    console.log('changeWorkNumber');

  }

  changeMobileNumber() {
    console.log('changeMobileNumber');

  }

}
