import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import {LoadingService} from "../../shared/services/loading.service";
import {AuthFacadeService} from "../../auth/services/auth-facade.service";

@Component({
  selector: 'myportal-sign-single-fax',
  templateUrl: './sign-single-fax.component.html',
  styleUrls: ['./sign-single-fax.component.scss']
})
export class SignSingleFaxComponent implements OnInit, OnDestroy, AfterViewInit {

  serviceKeyText: string = '';
  terminated$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('signForm') signFormGroup: ElementRef;
  @ViewChild('signFrame') signFrame: ElementRef;
  createSignForm: UntypedFormGroup = this.fb.group({
    isIntPortal: [true],
    isPortalSignature: [true],
    mailbox: [''],
    themid: [''],
    accountDID: ['']
  });
  actionUrl: string = environment.accountDetailsBaseUrl + 'portalSignature'
  counter: number;

  constructor(private fb: UntypedFormBuilder,
              private authFacadeService: AuthFacadeService,
              public loadingService: LoadingService,
              public dialogRef: MatDialogRef<SignSingleFaxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { faxId: string, did: string }) {
    if (window.addEventListener) {
      window.addEventListener("message", this.receiveMessage.bind(this), false);
    } else {
      (<any>window).attachEvent("onmessage", this.receiveMessage.bind(this));
    }
    this.authFacadeService.currentService$.subscribe(service => {
      if (service !== undefined) {
        this.serviceKeyText = service.phoneNumber;
      }
    });
  }

  ngAfterViewInit(): void {
    this.loadingService.setLoading(true)
    this.generateIframeUrl();
    const frameElement = this.signFrame.nativeElement;
    frameElement.addEventListener('load', this.loaderOff.bind(this));
  }

  generateIframeUrl(): void {
    const portalSessionID = localStorage.getItem('portalSessionID');
    this.createSignForm.patchValue({
      mailbox: `${this.data.did}@${environment.signDomain}`,
      themid: this.data.faxId,
      accountDID: this.serviceKeyText
    });

    if (this.signFormGroup !== undefined) {
      this.signFormGroup.nativeElement.submit();
    }
  }

  ngOnDestroy(): void {
    this.terminated$.next(true);
    clearInterval(this.counter);
  }

  ngOnInit(): void {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
        this.onClose();
      }
    });
  }

  receiveMessage(event: any) {
    //receving request to close popup post logout from legacy-myaccount
    if ((null != event && undefined != event) && event.data == 'portalLogoutSuccess') {
      this.dialogRef.close();
      this.loadingService.setLoading(false)
    }
  }

  onClose() {
    //executing logout to legacy-myaccount on close sign popup.
    // this.isBusy = true;
    // this.signFrame.nativeElement.contentWindow.postMessage('logoutPortal', '*');

    this.dialogRef.close();
    this.loadingService.setLoading(false)
  }

  loaderOff() {
    this.loadingService.setLoading(false)
  }
}
