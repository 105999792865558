import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComposeModel, CreateFax, ForwardFaxMultiple, ForwardFaxSingle, ForwardFaxSingleFaxResult } from '../models/compose.model';
import { FAX_PREPARE_SEND_MUTATION, FORWARD_FAX_MULTIPLE_FAX, FORWARD_FAX_SINGLE_FAX, GENERATE_SENDFAX_TOKEN } from './compose.query';
import * as JSZip from 'jszip';
import { Recipient } from '../models/recipient.model';
import { environment } from 'src/environments/environment';
import { AuthFacadeService } from 'src/app/auth/services/auth-facade.service';
import { map } from 'rxjs/operators';
import {ApolloIntportal} from "../../shared/apollo-intportal";

@Injectable()
export class ComposeApi {
  readyState: any;
  responseText: any;
  status: any;
  userId: string;
  faxCSID: any;
  constructor(private apollo: ApolloIntportal, private http: HttpClient, private authFacadeService: AuthFacadeService) {
    this.subscribeToAuthState();
    this.getfaxCSID();
  }

  faxPrepareSend(serviceKey: string, input: CreateFax) {
    return this.apollo.mutate({
      mutation: FAX_PREPARE_SEND_MUTATION,
      variables: { serviceKey: serviceKey, input: input },
    });
  }

  forwardFaxSingle(input: ForwardFaxSingle) {
    console.log(input);
    return this.apollo.mutate<ForwardFaxSingleFaxResult>({
      mutation: FORWARD_FAX_SINGLE_FAX,
      variables: input,
    });
  }

  forwardFaxMultiple(input: ForwardFaxMultiple) {
    return this.apollo.mutate({
      mutation: FORWARD_FAX_MULTIPLE_FAX,
      variables: input,
    });
  }

  uploadResourcesToS3(faxId: string, s3Url: string, file: Blob) {

    const newFile = new File([file], `${faxId}.zip`);

    // XML HTTP REQUEST
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.withCredentials = true;

      xhr.onreadystatechange = () => {
        console.log(`Stage: ${xhr.readyState}`);
        if (xhr.readyState === 4) {
          console.log(`File uploaded: ${faxId}`);
          resolve({
            status: true,
            statusText: `RESULT`,
          });
        }
      };

      xhr.onerror = () => {
        reject({
          status: false,
          statusText: xhr.responseText
        });
      }
      xhr.open("PUT", s3Url);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.send(newFile);
    });

  }

  // deprecated so far, up to further notice; kept just in case
  uploadFileToAWSS3(s3Url: string, file: Blob, name: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/octet-stream' });
    const newFile = new File([file], name);
    const req = new HttpRequest(
      'PUT',
      s3Url,
      newFile,
      {
        headers: headers,
      });
    return this.http.request(req);
  }

  zipFiles(serviceKey: string, model: ComposeModel, files: File[]) {
    const zip = new JSZip.default();
    const fileNames: object[] = [];
    files.forEach(f => {
      fileNames.push({ filename: f.name });
      zip.file(f.name, f);
    });
    const fileName = 'payload.json';
    const zipPayload = this.createPayloadFromModel(serviceKey, model, fileNames);
    console.log(zipPayload);
    zip.file(fileName, zipPayload);
    return zip.generateAsync({ type: "blob" });
  }

  private createPayloadFromModel(serviceKey: string, model: ComposeModel, fileNames: object[]): Blob {
    const recipients = model.recipients.map((value: Recipient) => {
      return {
        fax_number: value.fax,
        name: value.firstName.concat(" ", value.lastName),
        company: value.company
      }
    });
    const payload = {
      serviceKey: serviceKey,
      originator: {
        email_address: model.additionalSettings?.sendEmailAddress
      },
      recipients: recipients,
      documents: fileNames,
      cover_page: {
        template_name: "MyTEMPLATESAMPLE",
        subject: model.coverPage?.coverTitle,
        message: model.coverPage?.coverText,
        from: model.userId || this.faxCSID || this.userId
      },
      client_tracking: {
        reference_id: model.additionalSettings?.referenceId
      },
      fax_resolution: model.additionalSettings?.faxQuality
    };
    return new Blob([JSON.stringify(payload)], { type: "application/json" });
  }

  downloadFileFromUrl(url: string) {
    return this.http.get(url, { responseType: 'blob' });
  }


  sendFaxUsingMyAccount(formData: any) {
    const sessionIDValue = localStorage.getItem("portalSessionID");
    const requestStickySession = "?JK_JSESSIONID=" + sessionIDValue;
    return this.http.post(environment.accountDetailsBaseUrl + "account/sendFax" + requestStickySession,
      formData,
      {
        withCredentials: true
      });
  }
  generateSendToken(sessionHashId: string) {
    return this.apollo.query<any>({
      query: GENERATE_SENDFAX_TOKEN,
      variables: {
        hashToken: sessionHashId
      }
    });

  }

  private subscribeToAuthState() {
    this.authFacadeService.authState$.subscribe((value) => {
      this.userId = value.user_name;
    })
  }

  private getfaxCSID() {
    this.authFacadeService.userSingle$
      .pipe(map((result: any) => result?.userSingle?.user?.services), map((svcs: any) => svcs?.filter((svc: any) => svc.serviceType === "INBOUND_FAXING")))
      .subscribe(value => {
        if (value !== undefined) {
          this.faxCSID = value[0].features.faxCSID;
        }
      })
  }
}
