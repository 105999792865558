import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Contact, ContactCreate, ContactDeleteInput, ContactList, ContactListParams, ContactSingle, ContactSortBy } from '../models/contact.model';
import { addEditSingleContact, createNewContactBegin, deleteContactBegin, deleteContactListBegin, loadContactListBegin, loadContactSingleBegin, updateContactBegin } from '../store/actions/contacts.actions';
import * as fromFeature from '../store/reducers/contacts.reducer';
import { selectContactList, selectContactSingle, selectLoading, selectContactOperationStatus, selectContactData } from '../store/selectors/contacts.selector';
import { Observable } from 'rxjs';
import { ApiError, ContactOperationStatus, SortDirection } from '../models/shared.model';

@Injectable()
export class ContactsFacadeService {

  contactsList$: Observable<ContactList | undefined>;
  isLoading$: Observable<boolean | undefined>;

  contactOperationStatus$: Observable<ContactOperationStatus | undefined>;

  contactSingle$: Observable<ContactSingle | undefined>;

  contactData$: Observable<{ selectedContact: Contact | undefined, contactId: string | undefined } | undefined>;

  constructor(
    private readonly store: Store<fromFeature.ContactsState>) {
    this.contactsList$ = store.pipe(select(selectContactList));
    this.contactSingle$ = store.pipe(select(selectContactSingle));
    this.isLoading$ = store.pipe(select(selectLoading));
    this.contactOperationStatus$ = store.pipe(select(selectContactOperationStatus));
    this.contactData$ = store.pipe(select(selectContactData));
  }

  dispatchLoadContactsList(payload: ContactListParams) {
    this.store.dispatch(loadContactListBegin({ payload }));
  }

  dispatchCreateNewContact(payload: { input: Contact }) {
    this.store.dispatch(createNewContactBegin({ payload }));
  }

  dispatchUpdateContact(payload: { contactId: string, input: Contact }) {
    this.store.dispatch(updateContactBegin({ payload }));
  }

  dispatchDeleteContact(payload: { input: ContactDeleteInput, list: ContactListParams }) {
    this.store.dispatch(deleteContactBegin({ payload }));
  }

  dispatchGetSingleContact(payload: { contactId: string }) {
    this.store.dispatch(loadContactSingleBegin({ payload }));
  }

  dispatchDeleteContactList(contactList: string[], paramsList: ContactListParams) {
    this.store.dispatch(deleteContactListBegin({ payload: { contactsList: contactList, paramsList: paramsList } }));
  }

  dispatchAddEditContact(selectedContact: Contact | undefined, contactId: string | undefined) {
    this.store.dispatch(addEditSingleContact({ content: { contactId: contactId, contact: selectedContact } }));
  }
}
