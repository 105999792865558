import { Component, OnInit } from '@angular/core';
import { AuthFacadeService } from 'src/app/auth/services/auth-facade.service';

@Component({
  selector: 'main-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  collapsed = false;

  constructor(private authFacadeService: AuthFacadeService) { }

  ngOnInit(): void {
  }

  toggleSidebar(toggled: boolean) {
    this.collapsed = toggled;
  }

  clickSignOff() {
    this.authFacadeService.signOff();
  }

}
