import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafePipeModule } from 'safe-pipe';
import { ViewMessagesFacadeService } from './services/view-messages-facade.service';
import { SnackBarGenerateZip, ViewMessagesComponent } from './components/view-messages.component';

import { DialogFolderCreate } from './components/create-new-folder-dialog.component';
import { DialogFolderDelete } from './components/delete-folder-dialog.component';

import { ViewMessagesEffects } from './store/effects/view-messages.effects';
import { ViewMessagesRoutingModule } from './view-messages-routing.module';
import { ViewMessagesApi } from './api/view-messages.api';
import { SharedModule } from '../shared/shared.module';
import * as fromStore from './store/index';
import { PipesModule } from '../shared/pipes/pipes.module';
import { SingleMessageComponent } from './components/single-message.component';
import { TagHandleDialogComponent } from './components/tag-handle-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog.component';
import { SignSingleFaxComponent } from './components/sign-single-fax.component';
import { DeleteFaxDialogComponent } from './components/delete-fax-dialog.component';
import { ForwardFaxEmailDialogComponent } from './components/forward-fax-email-dialog.component';
import { PrintingAreaComponent } from "./components/printing-area/printing-area.component";
import { PrintingItemComponent } from "./components/printing-area/printing-item/printing-item.component";
import { RenameFolderItem } from "./components/rename-folder-item/rename-folder-item.component";
import { SingleItemComponent } from "./components/single-item/single-item.component";
import { ViewMessagesErrorsComponent } from "./components/errors/view-messages-errors.component";


@NgModule({
  declarations: [
    ViewMessagesComponent,
    DialogFolderCreate,
    DialogFolderDelete,
    RenameFolderItem,
    SingleItemComponent,
    ViewMessagesErrorsComponent,
    SingleMessageComponent,
    PrintingAreaComponent,
    PrintingItemComponent,
    TagHandleDialogComponent,
    SnackBarGenerateZip,
    ConfirmDialogComponent,
    SignSingleFaxComponent,
    DeleteFaxDialogComponent,
    ForwardFaxEmailDialogComponent
  ],
  providers: [ViewMessagesApi, ViewMessagesFacadeService
  ],

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ViewMessagesRoutingModule,
    SharedModule,
    StoreModule.forFeature('viewmessages', fromStore.reducers),
    EffectsModule.forFeature([ViewMessagesEffects]),
    PipesModule,
    SafePipeModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ViewMessagesModule { }
