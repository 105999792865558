<div class="header-modal-dialog">
  <h6 mat-dialog-title class="title-modal-dialog">
    {{ 'contacts.select.contacts.title' | transloco }}
  </h6>

  <label mat-dialog-title class="selected-amount">
    <span>({{ this.selection.selected.length }} {{!this.maxAvailableSelection ? ")" : ""}}</span>
    <div class="ofMax-available" *ngIf="this.maxAvailableSelection">
      <span>{{ 'of' | transloco }}</span>
      <span> {{ this.maxAvailableSelection }})</span>
    </div>
  </label>

  <div class="pagination-modal-dialog d-inline-block">
    <mat-paginator
      [disabled]="dataSource.data.length === 0"
      [length]="recordsLength"
      [showFirstLastButtons]="true"
      [pageSize]="rowPerPage"
      [hidePageSize]="true"
      aria-label="{{ 'general.selectPage' | transloco }}">
    </mat-paginator>
  </div>
</div>

<mat-divider></mat-divider>

<div class="body-modal-dialog">
  <div mat-dialog-content class="table-container horizontal-grid w100 d-inline-block">
    <table id="view-contacts-table" matSort mat-table [dataSource]="dataSource">

      <!-- Checkbox Column For -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
              class="check-size"
              (change)="masterToggle($event)"
              [checked]="isAllSelected()"
              *ngIf="dataSource.data.length > 0 && paginator"
              [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox class="check-size" (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [disabled]="!canSelectRow(row)"
                        [checked]="selection.isSelected(row)"
                        [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> {{ 'contacts.name' | transloco }}
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="company">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> {{ 'contacts.company' | transloco }}
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.company }}</td>
      </ng-container>

      <ng-container matColumnDef="fax">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> {{ 'contacts.faxnumber' | transloco }}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.countryCode}} {{element.fax | FormatPhoneNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> {{ 'contacts.email' | transloco }}
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.email }}</td>
      </ng-container>

      <!--      sticky: true-->
      <tr mat-header-row *matHeaderRowDef="contactsHeader"></tr>
      <tr mat-row *matRowDef="let row; columns: contactsHeader;" [class.row-hovered]="row.hovered"
        (mouseover)="row.hovered=true" (mouseout)="row.hovered=false"
        [ngClass]="!row.isRead ? 'unread-message' : 'read-message'">
      </tr>

    </table>
  </div>
</div>

<mat-divider></mat-divider>

<div class="footer-modal-dialog">
  <div mat-dialog-actions align="end">
    <button mat-button class="btn btn-md btn-info" aria-label="Insert Selected" [disabled]="disableSubmitButton"
      [mat-dialog-close]="{ selected: selection.selected, all: dataSource.data }">
      {{ 'contacts.add' | transloco }}
    </button>
    <button mat-button class="btn btn-md btn-cancel" aria-label="Dismiss" [mat-dialog-close]="{ cancel: true }">
      {{ 'login.sso.back' | transloco }}
    </button>
  </div>
</div>
