import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'myportal-home-number',
  templateUrl: './home-number.component.html',
  styleUrls: ['./home-number.component.scss']
})
export class HomeNumberComponent {
  constructor(private dialogRef: DialogRef) {

  }
  close() {
    this.dialogRef.close();
  }
}
