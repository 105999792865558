import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AuthFacadeService } from '../../../auth/services/auth-facade.service';
import { SUSPEND_FLAG_ALLOW_LIMIT } from '../../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class AccountDetailsGuard implements CanActivate, CanLoad {

  constructor(private authFacade: AuthFacadeService, private router: Router) { }

  canLoad() {
    return this.authFacade.authState$.pipe(
      filter(state => state !== undefined),
      take(1),
      map(state => { return { hasToken: !!state.access_token, flag: state.suspendedFlag } }),
      map(result => {
        if (result.flag > SUSPEND_FLAG_ALLOW_LIMIT) {
          return this.router.parseUrl('/error?message=Access-not-allowed-account-suspended-')
        } else {
          if (result.hasToken)
            return true;
          else
            return this.router.parseUrl('/login');
        }
      })
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authFacade.token$.pipe(take(1), map((value: string) => {
      return (value !== undefined && !!value);
    })
    );
  }
}
