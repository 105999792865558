import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, of, Subject, Subscription, timer } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Fax, FaxFilterInput, ForwardFaxToEmail } from '../models/fax.model';
import { Folder, RestrictedFolder, SystemFolder } from '../models/folder.model';
import { ViewMessagesFacadeService } from '../services/view-messages-facade.service';
import { TagHandleDialogComponent } from './tag-handle-dialog.component';
import JSZip from 'jszip';
import { AuthFacadeService } from 'src/app/auth/services/auth-facade.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { FAX_PREPARE_SIGNEDDOC_MUTATION } from '../api/view-messages.query';
import { DialogFolderDelete } from './delete-folder-dialog.component';
import { DEFAULT_THUMB_PREVIEW } from 'src/app/shared/constants/constants';
import { ForwardFaxEmailDialogComponent } from './forward-fax-email-dialog.component';
import { BrandService } from 'src/app/shared/services/brand.service';
import { PrintingAreaComponent } from "./printing-area/printing-area.component";
import { SignSingleFaxComponent } from './sign-single-fax.component';
import { ApolloIntportal } from "../../shared/apollo-intportal";
import { TIMEZONES } from 'src/app/shared/constants/constants';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { DeleteFaxDialogComponent } from './delete-fax-dialog.component';

const LOCKED_FOLDERS_FOR_SIGNATURE = ['SENT', 'SIGNED_DOCS', 'TRASH'];

@Component({
  selector: 'myportal-single-message',
  templateUrl: './single-message.component.html',
  styleUrls: ['./single-message.component.scss']
})
export class SingleMessageComponent implements OnInit, OnDestroy {
  currentFax: Fax;
  isToolbarEnabled = false;
  imageList: string[];
  private subscriptions: Subscription[] = [];
  zipFile: JSZip = new JSZip();
  currentFolder$: Observable<Folder>;
  serviceKey: string;
  emailAddresses: string[];
  folders: Folder[] | undefined;
  lastFolder: Folder;
  outboundService: any;
  didNumber: string;
  defaultPreview = DEFAULT_THUMB_PREVIEW;
  private terminated$: Subject<boolean> = new Subject<boolean>();
  isSecure: boolean;
  hideSignOption: boolean;
  completedTimestamp: string;
  rotation: number = 0;
  zoom: number = 1;
  fileName: string;
  maxZoomIn: number = 1.2;
  minZoomOut: number = 0.5;
  zoomInDisabled: boolean = false;
  zoomOutDisabled: boolean = false;
  totalPages: number = 0;
  currentPage: number = 0;
  userSuspendedFlag: number = 0;
  user: any;
  utcOffset: string;

  @ViewChild('printingArea') printingArea: PrintingAreaComponent;


  constructor(private viewMessagesFacadeService: ViewMessagesFacadeService,
    private authFacadeService: AuthFacadeService,
    private router: Router, public dialog: MatDialog, private apollo: ApolloIntportal,
    private _snackBarRef: MatSnackBar,
    private translocoService: TranslocoService,
    private brandService: BrandService, private loadingService: LoadingService) {
    this.subscribeToCurrentFax();
    this.subscribetoUserSingle();
    this.subscribeToFolderList();
    this.updateZoomButtonsState();
    this.currentFolder$ = this.viewMessagesFacadeService.selectedFolder$.pipe(takeUntil(this.terminated$));

    this.subscriptions.push(
      this.currentFolder$.pipe(
        filter((value) => value !== undefined && value.id !== undefined),
        take(1)).subscribe(fld => {
          this.lastFolder = { ...fld };
        })
    );

    this.authFacadeService.currentService$
      .pipe(takeUntil(this.terminated$))
      .subscribe((service) => {
        if (service !== undefined) {
          this.didNumber = service.phoneNumber;
          this.isSecure = service.isSecure;
        }
      }
      );

    this.authFacadeService.authState$.pipe(map(s => s.suspendedFlag), takeUntil(this.terminated$)).subscribe(value => this.userSuspendedFlag = value);
    this.authFacadeService.userSingle$.subscribe((userSingle) => {
      this.user = userSingle?.userSingle.user;
      this.setUTCOffset();
    });
  }

  addDigitalSignature() {
    this.prepareSignedDoc();
  }

  ngOnInit(): void {
    this.brandService.config$
      .pipe(filter((config: any) => config), take(1))
      .subscribe((config: any) => {
        this.hideSignOption = !config.signAllowed;
      });
  }

  async uploadResourcesToS3(faxId: string, s3Url: string, file: Blob) {

    const newFile = new File([file], `${faxId}.zip`);

    // XML HTTP REQUEST
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.withCredentials = true;

      xhr.onreadystatechange = () => {
        console.log(`Stage: ${xhr.readyState}`);
        if (xhr.readyState === 4) {
          console.log(`File uploaded: ${faxId}`);
          resolve({
            status: true,
            statusText: `RESULT`,
          });
        }
      };

      xhr.onerror = () => {
        reject({
          status: false,
          statusText: xhr.responseText
        });
      }
      xhr.open("PUT", s3Url);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.send(newFile);
    });

  }

  prepareSignedDoc() {
    this.apollo.mutate({ mutation: FAX_PREPARE_SIGNEDDOC_MUTATION, variables: { serviceKey: JSON.parse(localStorage['AUTH-STORE']).currentService.serviceKey, input: { apiClientIpAddress: "127.0.0.1" } } }).subscribe((data: any) => {
      console.log(data.data.faxPrepareSignedDoc.s3Url);

      const zipFile: JSZip = new JSZip();

      const imageurls = this.currentFax.thumbnails.map(v => v.imageUrl);

      const fileArray = [];

      const fileNames: any[] = [];

      imageurls.forEach((url: string) => {
        fileNames.push(url.split('?')[0].split('/')[url.split('?')[0].split('/').length - 1]);
        fileArray.push(url);
      });

      const jsn = JSON.stringify(JSON.stringify({
        serviceKey: this.currentFax.serviceKey,
        originator: {
          email_address: this.emailAddresses[0],
        },
        recipients: this.outboundService.phoneNumber,
        documents: fileNames,
        faxId: data.data.faxPrepareSignedDoc.fax.faxId,
        pages: fileNames.length,
        jobType: "signedDoc",
        fax_subject: 'testing'
      }));
      const blob = new Blob([jsn], { type: 'application/json' });
      const file = new File([blob], 'payload.json');

      const self = this;

      const fileURL = URL.createObjectURL(file);
      fileArray.push(fileURL);
      zipFile.file(data.data.faxPrepareSignedDoc.fax.faxId, JSON.stringify(fileArray));

      zipFile.generateAsync({ type: "blob" })
        .then(async function callback(blob: any) {

          const s3Result: any = await self.uploadResourcesToS3(data.data.faxPrepareSignedDoc.fax.faxId, data.data.faxPrepareSignedDoc.s3Url, blob);

          console.log(s3Result);

          if (s3Result.status === true) {
            self._snackBarRef.open(self.translocoService.translateObject('messages.signedFileUpload'), '', {
              duration: 5000
            });
          }
        });
    });
  }

  ngOnDestroy(): void {
    this.terminated$.next(true);
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private subscribetoUserSingle() {
    this.subscriptions.push(
      this.authFacadeService.userSingle$
        .pipe(map(result => result?.userSingle?.user?.services), map(svcs => svcs?.filter(svc => svc.serviceType === "OUTBOUND_FAXING")))
        .subscribe(value => {
          if (value !== undefined && value[0] !== undefined) {
            this.serviceKey = value[0].serviceKey;
            this.emailAddresses = value[0].emailAddresses.filter(email => email.trim() !== "");

            this.outboundService = value[0];
          }
        })
    );
  }

  private folderPosition(folderName: string): number {
    switch (folderName) {
      case SystemFolder.INBOX:
        return Math.max();
      case SystemFolder.TRASH:
        return Math.min();
      case SystemFolder.SENT:
        return -1;
      default:
        return folderName.charCodeAt(0);
    }
  }

  private subscribeToFolderList() {
    this.subscriptions.push(
      this.viewMessagesFacadeService.folderList$.pipe(take(1))
        .subscribe(folders => {

          if (folders) {
            this.folders = folders;
          } else if (folders !== undefined) {
            this.folders = JSON.parse(JSON.stringify(folders)).sort((a: Folder, b: Folder) => this.folderPosition(a.name) - this.folderPosition(b.name));
          }
        })
    );
  }

  moveFax() {

    const folders = this.folders?.filter(item => item.name !== 'SIGNED_DOCS'
      && item.name !== 'DOCUMENTS'
      && item.name !== 'SENT'
      && item.name !== 'TRASH'
      && item.name !== this.lastFolder.name);

    const dialog = this.dialog.open(DialogFolderDelete, {
      backdropClass: 'cdk-overlay-transparent-backdrop',
      hasBackdrop: true,
      disableClose: true,
      data: { folders: folders, type: 'move' },
    });

    dialog.afterClosed().subscribe(result => {

      if (result.id) {
        const faxIds = [this.currentFax.faxId];
        this._snackBarRef.open(this.translocoService.translateObject('messages.movefaxes'), '', {
          duration: 5000
        });
        this.submitMoveMaxes(result.id, faxIds);
      }
    });
  }

  private submitMoveMaxes(folderId: string, faxes: string[]) {

    const filter: FaxFilterInput = { folder: this.lastFolder.name };

    this.viewMessagesFacadeService.dispatchMoveFaxes({
      serviceKey: this.currentFax.serviceKey,
      folderId: folderId,
      faxIds: faxes,
      filter: filter
    });
  }

  getExtension(fileName: string) {
    let file = fileName.split('.');
    file.pop();
    return file.join('.');
  }

  private subscribeToCurrentFax() {
    this.subscriptions.push(
      this.viewMessagesFacadeService.selectedFaxes$
        .subscribe(value => {
          if (value !== undefined && value.length > 0) {
            this.currentFax = value[0];

            if (!this.currentFax.images) {
              this.viewMessagesFacadeService.getSelectedFaxes([{ serviceKey: this.currentFax.serviceKey, faxId: this.currentFax.faxId, pages: this.currentFax.pages }])
                .pipe(take(1)).subscribe((faxes: Fax[]) => {
                  this.currentFax = faxes[0];
                  this.imageList = faxes[0].thumbnails.map(v => v.imageUrl);
                  this.completedTimestamp = faxes[0].completedTimestamp;
                  this.fileName = this.extractFileName(faxes[0].imageUrl)
                  this.totalPages = this.imageList.length;
                  this.currentPage = -1;
                });
            }
            this.isToolbarEnabled = true;
          }
        })
    );
  }

  extractFileName(url: string) {
    const regex = /([^/]+\.tiff|[^/]+\.pdf)/i;
    const match = url.match(regex);

    return match ? match[0] : 'file.tiff';
  }

  forwardByMail() {
    const faxIds = [this.currentFax.faxId]
    const dialog = this.dialog.open(ForwardFaxEmailDialogComponent, {
      backdropClass: 'cdk-overlay-transparent-backdrop',
      hasBackdrop: true,
      disableClose: true,
      minWidth: '500px'
    });

    dialog.afterClosed().pipe(filter(r => r !== 'cancel' && r.mailsList?.length > 0),
      take(1),
      map((d: any) => { return { mailsList: d.mailsList, subject: d.subject, message: d.message } }),
      switchMap(result => this.viewMessagesFacadeService.forwardFaxToEmail(this.currentFax.serviceKey, result.subject, result.message, faxIds, result.mailsList)),
      map((r: any) => r.forwardFaxToEmail as ForwardFaxToEmail)
    )
      .subscribe(result => {
        if (result.emailSuccess?.length === 0) {
          this._snackBarRef.open(this.translocoService.translateObject('messages.noForwardToMail'), undefined, {
            duration: 5000,
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            panelClass: ['error-snack-bar']
          });
        } else {
          this._snackBarRef.open(`${this.translocoService.translateObject('messages.successForwardToMail')} ${result.emailSuccess?.join(', ')}`, undefined, {
            duration: 5000,
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            panelClass: ['success-snack-bar']
          });
        }

      }, () => {
        this._snackBarRef.open(this.translocoService.translateObject('messages.noForwardToMail'), undefined, {
          duration: 5000,
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
          panelClass: ['error-snack-bar']
        });
      });
  }

  forwardMessage() {
    this.viewMessagesFacadeService.forwardMessage(this.currentFax);
  }

  replyMessage() {
    this.viewMessagesFacadeService.replyMessage(this.currentFax);
  }

  printMessages() {
    this.printingArea.printing();
  }

  markReadUnread(isRead: boolean) {
    const faxId = this.currentFax.faxId;
    this.viewMessagesFacadeService.markAsReadOrUnread(this.currentFax.serviceKey, [faxId], isRead);
  }

  deleteFax() {
    const msg: string = Object.keys(RestrictedFolder).includes(this.lastFolder.name) ? 'view-messages.delete.fax.confirm.warning' : 'view-messages.delete.fax.confirm'
    const dialog = this.dialog.open(DeleteFaxDialogComponent, {
      backdropClass: 'cdk-overlay-transparent-backdrop',
      hasBackdrop: true,
      disableClose: true,
      data: {
        msg: msg,
        count: 1
      },
    });

    dialog.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.confirmDeleteFax();
      }
    });
  }

  confirmDeleteFax() {
    const currentFolderId = this.lastFolder.name
    const filter: FaxFilterInput = { folder: currentFolderId };
    const flush = Object.keys(RestrictedFolder).includes(this.lastFolder.name);
    const oneSource = of(this.viewMessagesFacadeService.deleteFaxSingle(
      this.currentFax.serviceKey,
      this.currentFax.faxId,
      filter, this.lastFolder.id, flush));
    this.loadingService.setLoading(true);
    oneSource.pipe(map(() => timer(2500)), take(1)).subscribe(() => {
      this.goBack();
    });
  }

  downloadBlob(url: string, name: string) {
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.target = "_blank";

    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(url);
      link.remove();
    }, 100);
  }
  download() {
    if (this.currentFax?.imageUrl) {
      const startsFrom = this.currentFax.imageUrl.lastIndexOf("/") + 1;
      const endsAt = this.currentFax.imageUrl.indexOf("?", startsFrom) - 1;
      const fileName = this.currentFax.imageUrl.substring(startsFrom, endsAt);

      this.downloadBlob(this.currentFax.imageUrl, fileName)
    } else {
      console.log(`Unable to download due to a lack of data: url: ${this.currentFax?.imageUrl}`);
    }
  }

  goBack() {
    this.router.navigate(["/view-messages"]);
  }

  showCreateTagDialog() {
    const dialog = this.dialog.open(TagHandleDialogComponent, {
      backdropClass: 'cdk-overlay-transparent-backdrop',
      hasBackdrop: true,
      disableClose: true,
      data: { isCreating: true, tags: this.currentFax.tags, selectedTag: "" },
    });

    dialog.afterClosed().pipe(take(1)).subscribe((result: string) => {
      if (result.length > 0) {
        this.viewMessagesFacadeService.dispatchTagAdd(this.currentFax.serviceKey, this.currentFax.faxId, result, true);
      }
    });
  }

  showDeleteTagDialog() {
    const dialog = this.dialog.open(TagHandleDialogComponent, {
      backdropClass: 'cdk-overlay-transparent-backdrop',
      hasBackdrop: true,
      disableClose: true,
      data: { isCreating: false, tags: this.currentFax.tags, selectedTag: "" },
    });

    dialog.afterClosed().pipe(take(1)).subscribe((result: string) => {
      if (result.length > 0) {
        this.viewMessagesFacadeService.dispatchTagDelete(this.currentFax.serviceKey, this.currentFax.faxId, result, true);
      }
    });
  }

  signFax() {
    if (this.currentFax !== undefined) {
      const dialog = this.dialog.open(SignSingleFaxComponent, {
        width: '80%',
        hasBackdrop: true,
        disableClose: true,
        data: { faxId: this.currentFax.faxId, did: this.didNumber }
      });
    }
  }

  isSignatureDisabled() {
    return this.imageList?.length === 0 || LOCKED_FOLDERS_FOR_SIGNATURE.includes(this.lastFolder.name.toUpperCase());
  }

  rotateLeft() {
    this.rotation -= 90;
    this.printingArea.updateTransform(this.rotation, this.zoom);
  }

  rotateRight() {
    this.rotation += 90;
    this.printingArea.updateTransform(this.rotation, this.zoom);
  }

  zoomIn() {
    if (this.zoom < this.maxZoomIn) {
      this.zoom += 0.1;
      this.printingArea.updateTransform(this.rotation, this.zoom);
    }
    this.updateZoomButtonsState();
  }

  zoomOut() {
    if (this.zoom > this.minZoomOut) {
      this.zoom -= 0.1;
      this.printingArea.updateTransform(this.rotation, this.zoom);
    }
    this.updateZoomButtonsState();
  }

  updateZoomButtonsState() {
    this.zoomInDisabled = this.zoom >= this.maxZoomIn;
    this.zoomOutDisabled = this.zoom <= this.minZoomOut;
  }

  setPage(event: any) {
    const page = event?.target?.value;
    if (page !== null && page !== undefined && page >= -1 && page < this.imageList.length) {
      this.currentPage = page;
      this.zoom = 1;
      this.updateZoomButtonsState();
      this.printingArea.updateTransform(this.rotation, this.zoom);
      this.printingArea.setValue(this.imageList, this.currentPage);
    }
  }

  setUTCOffset() {
    if (this.user && this.user.timeZone) {
      const timezone = this.user.timeZone.split('@').join('/');
      this.utcOffset = TIMEZONES.filter((zone: any) => zone['@value'] === timezone)[0]['#text'].split(' ')[0].slice(1, -1);
    }
  }

  loadDateLocale() {
    const formats: { [key: string]: string } = {
      'DDMMYYYY_Hour24': 'd/M/yyyy',
      'DDMMYYYY_Hour12': 'd/M/yyyy',
      'MMDDYYYY_Hour24': 'M/d/yyyy',
      'MMDDYYYY_Hour12': 'M/d/yyyy',
      'YYYYMMDD_Hour24': 'yyyy/M/d',
      'YYYYMMDD_Hour12': 'yyyy/M/d',
    };

    const key = `${this.user.dateFormat}_${this.user.timeFormat}`;

    return formats[key] || 'd/M/yyyy';
  }

}
