import { Component, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/dialog';


export interface DialogData {  
  contacts: any;
  selectedContact: any;
}

@Component({
  selector: 'view-messages-delete-contact-dialog',
  templateUrl: 'delete-contact-dialog.component.html',
  styleUrls: ['./delete-contact-dialog.component.scss']
})
export class DialogContactDelete {  
  constructor(
    public dialogRef: MatDialogRef<DialogContactDelete>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  onNoClick(): void {
    this.data.contacts = [];
    this.dialogRef.close();
  }

}