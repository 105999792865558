<header class="header">
  <div class="header-aside">
    <a id="btn-menu" attr.aria-label="{{ 'sidebar.burger.menu' | transloco }}" class="sidebar-opener" (click)="toggle()"
      role="button" href="javascript:void(0)">
      <span class="icon-menu"></span>
    </a>
    <div id="brand-logo" class="logo" *ngIf="brandConfig !== undefined">
      <img src="assets/images/{{brandConfig.logo.name}}" alt="Integrated Portal" role="img" />
    </div>
  </div>
  <div class="header-main">
    <select class="drop hide-on-mob" id="services" (change)="changeSelectedService($event)">
      <option class="drop-opener" *ngFor="let srv of servicesList$ | async" value="{{ srv.serviceKey }}" [selected]="
          (localCurrentService$ | async)?.serviceKey === srv.serviceKey
        ">
        {{ srv.phoneNumber | FormatPhoneNumber }}
      </option>
    </select>
    <div class="header-controls">
      <div class="hide-on-mob first-section"></div>
      <div class="second-section">
        <app-searchbar></app-searchbar>
      </div>
      <div class="third-section">
        <div class="header-settings">
          <div class="settings-dropdown">
            <button mat-icon-button id="btn-settings" aria-label="Settings" matTooltip="Settings"
              [matMenuTriggerFor]="settingsMenu" align="center">
              <mat-icon>settings</mat-icon>
            </button>
            <!-- <button id="btn-settings" aria-label="Settings" class="settings-drop-icon-wrapper">
              <span class="icon icon-settings"></span>
            </button> -->
            <mat-menu #settingsMenu="matMenu">
              <button mat-menu-item (click)="redirectTo123Mail()">
                <mat-icon>email</mat-icon>
                <span>
                  <ng-container *ngIf="brandConfig !== undefined">
                    {{ 'topbar.123mail.text' | transloco: {name: brandConfig.name} }}
                  </ng-container>
                </span>
              </button>
              <button *ngIf="brandConfig !== undefined && brandConfig.returnToLegacyAllowed" mat-menu-item
                (click)="returnToOldSystem()">
                <mat-icon>assignment_return</mat-icon>
                <span>{{'topbar.button.return.old.website.text' | transloco}}</span>
              </button>
              <button mat-menu-item (click)="logout()">
                <mat-icon>exit_to_app</mat-icon>
                <span>{{ 'topbar.button.logout.text' | transloco }}</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
