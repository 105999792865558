import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ReportsComponent } from './components/reports.component';
import { ReportsRoutingModule } from './reports-routing.module';
import { InboudActivityLogsComponent } from './components/inboud-activity-logs/inboud-activity-logs.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportsService } from './services/reports.service';
import { ReportsApiService } from './services/reports-api.service';
import { ReceivedLogsTableComponent } from './components/received-logs-table/received-logs-table.component';
import { SentLogsTableComponent } from './components/sent-logs-table/sent-logs-table.component';

@NgModule({
  declarations: [
    ReportsComponent,
    InboudActivityLogsComponent,
    ReceivedLogsTableComponent,
    SentLogsTableComponent,
  ],
  imports: [
    CommonModule,
    ReportsRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [ReportsService, ReportsApiService]
})
export class ReportsModule { }
