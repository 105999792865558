<div class="container">
  <div class="container-header">
    <h1>{{ "billing.usage.balance" | transloco}}</h1>
    <button mat-icon-button aria-label="close dialog" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <hr />

  <p>
    {{
      "billing.usage.your.reserve" | transloco
    }}
  </p>
  <p>
    {{
      "billing.usage.you.are.in.control" | transloco
    }}
  </p>
  <p>
    {{
      "billing.usage.we.advise" | transloco
    }}
  </p>

  <div class="warning">
    <p>
      {{
        "billing.usage.please.specify" | transloco
      }}
    </p>
  </div>

  <hr />

  <div class="form">

    <div class="display">
      <div class="label">
        <label for="">{{'billing.usage.current.usage.balance' | transloco}}:</label>
      </div>
      <div class="value">
        <em>
          {{balance | number: '2.0' | currency: 'USD'}}
         </em>
      </div>
    </div>

    <div class="display">
      <div class="label">
        <label for="">{{'billing.usage.last.reorder.amount' | transloco}}:</label>
      </div>
      <div class="value">
        <em>
          {{lastReorderAmount | number: '2.0' | currency: 'USD'}}
         </em>
      </div>
    </div>


    <div class="display">
      <div class="label">
        <label for="">{{ "billing.usage.select.new.reorder" | transloco}}</label>
      </div>
      <div class="value">
        <select id="amount" [(ngModel)]="newReorderAmount" [value]="newReorderAmount" required>
          <option *ngFor="let opt of amounts" [value]="opt" [selected]="opt === newReorderAmount">{{opt | currency: 'USD'}}</option>
        </select>
      </div>
    </div>

  </div>

  <hr />

  <div class="button-container">
    <button
      id="update-button"
      name="update-button"
      aria-labelledby="update-button-label"
      type="submit"
      class="update-button btn btn-md btn-info"
    >
      <label>
        {{ "billing.usage.update" | transloco}}
      </label>
      <mat-icon>play_circle_filled</mat-icon>
    </button>
  </div>

  <div class="footer">
    <p>
      *{{
        "billing.usage.your.current.usage" | transloco
      }}
    </p>
  </div>
</div>
