import * as fromContacts from './reducers/contacts.reducer';
import * as fromRoot from '../../auth/store/reducers';
import { ActionReducerMap } from '@ngrx/store';
import { loadContactListBegin } from './actions/contacts.actions';

export interface ContactsFeatureState {
    contactsFeatureState: fromContacts.ContactsState;    
}

export interface State extends fromRoot.AppState {
    contactsFeatureState: ContactsFeatureState;
}

export const reducers:
    ActionReducerMap<ContactsFeatureState, typeof loadContactListBegin> = {
    contactsFeatureState: fromContacts.reducer
}
