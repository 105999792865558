<div [class.sidebar-active]="collapsed">
    <div class="wrapper">
        <app-topbar (toggleButton)="toggleSidebar($event)" (signOff)="clickSignOff()"></app-topbar>
        <ng-container>
            <ng-container>
                <app-sidebar [toggleSide]="collapsed" ></app-sidebar>
            </ng-container>
            <div class="main-content">
                <router-outlet></router-outlet>
            </div>
        </ng-container>
    </div>
    <app-footer></app-footer>
</div>
