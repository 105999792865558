import { createReducer, on } from '@ngrx/store';
import * as composeActions from '../actions/compose.actions';
import { StateStoreKeys } from 'src/app/shared/constants/constants';
import { ComposeAction, FaxCreate, ComposeActionTypes, ForwardFaxSingleFaxResult, ApiError, ForwardFaxMultipleFaxesResult } from '../../models/compose.model';
import { createRehydrateReducer } from '../../../shared/functions/rehydrateReducer';

export interface ComposeState {
    loadedFax: boolean | undefined;
    loadingFax: boolean;
    faxCreate: FaxCreate | undefined;
    isError: boolean | undefined;
    errorMessage: string | undefined;
    formAction: ComposeAction | undefined;
    sendingResource: boolean,
    sentResource: boolean,
    forwardFaxSingle: ForwardFaxSingleFaxResult | undefined
    forwardFaxMultiple: ForwardFaxMultipleFaxesResult | undefined
}

export const initialComposeState: ComposeState = {
    loadedFax: false,
    loadingFax: false,
    faxCreate: undefined,
    isError: undefined,
    errorMessage: undefined,
    formAction: {
        type: ComposeActionTypes.NEW,
        form: undefined,
        faxId: undefined,
        serviceKey: undefined
    },
    sendingResource: false,
    sentResource: false,
    forwardFaxSingle: undefined,
    forwardFaxMultiple: undefined,
}

export const reducer = createReducer(
    initialComposeState,
    on(
        composeActions.updatingFaxCreation,
        (state) => ({
            ...state,
            formAction: {
                type: ComposeActionTypes.UPDATE,
                form: undefined,
                faxId: undefined,
                serviceKey: undefined
            }
        })
    ),
    on(
        composeActions.startingFaxCreation,
        (state, { model }) => ({
            ...state,
            formAction: {
                type: ComposeActionTypes.NEW,
                form: model,
                faxId: undefined,
                serviceKey: undefined
            },
            loadingFax: true,
            errorMessage: undefined,
            loadedFax: false,
            isError: false,
            forwardFaxSingle: undefined,
            forwardFaxMultiple: undefined
        })
    ),
    on(
        composeActions.replyComposeStart,
        (state, { model, faxId, serviceKey }) => {
            return {
                ...state,
                formAction: {
                    type: ComposeActionTypes.REPLY,
                    form: model,
                    faxId: faxId,
                    serviceKey: serviceKey
                },
                loadingFax: false,
                errorMessage: undefined,
                loadedFax: false,
                isError: false,
                forwardFaxSingle: undefined,
                forwardFaxMultiple: undefined
            }
        }
    ),
    on(
        composeActions.forwardFaxSingleStart,
        (state, { model, faxId, serviceKey }) => {
            return {
                ...state,
                formAction: {
                    type: ComposeActionTypes.FOWARD_FAX_SINGLE,
                    form: model,
                    faxId: faxId,
                    serviceKey: serviceKey
                },
                loadingFax: false,
                errorMessage: undefined,
                loadedFax: false,
                isError: false,
                forwardFaxSingle: undefined,
                forwardFaxMultiple: undefined
            }
        }
    ),
    on(
        composeActions.forwardFaxMultipleStart,
        (state, { model, faxIds, serviceKey }) => {
            return {
                ...state,
                formAction: {
                    type: ComposeActionTypes.FOWARD_FAX_MULTIPLE,
                    form: model,
                    faxId: faxIds,
                    serviceKey: serviceKey
                },
                loadingFax: false,
                errorMessage: undefined,
                loadedFax: false,
                isError: false,
                forwardFaxSingle: undefined,
                forwardFaxMultiple: undefined
            }
        }
    ),
    on(
        composeActions.startingSuccess,
        (state, { model }) => {
            return {
                ...state,
                formAction: {
                    type: ComposeActionTypes.NEW,
                    form: model,
                    faxId: undefined,
                    serviceKey: undefined
                },
                loadingFax: false,
                loadedFax: true,
                isError: false,
                errorMessage: undefined,
                forwardFaxSingle: undefined,
                forwardFaxMultiple: undefined
            }
        }
    ),
    on(
        composeActions.startingFail,
        (state, { errorMessage }) => {
            return {
                ...state,
                loadingFax: false,
                loadedFax: false,
                isError: true,
                errorMessage: errorMessage,
                formAction: undefined,
                forwardFaxSingle: undefined
            }
        }
    ),
    on(
        composeActions.createFaxStart,
        (state) => {
            return {
                ...state,
                loadingFax: true,
                faxCreate: undefined,
                loadedFax: false,
                isError: false,
                errorMessage: undefined,
                forwardFaxSingle: undefined
            }
        }
    ),
    on(
        composeActions.createFaxSuccedded,
        (state, { faxPrepareSend }) => {
            return {
                ...state,
                faxCreate: faxPrepareSend,
                loadingFax: false,
                loadedFax: true,
                isError: false,
                errorMessage: undefined,
                forwardFaxSingle: undefined
            }
        }
    ),
    on(
        composeActions.createFaxFail,
        (state) => {
            return {
                ...state,
                loadingFax: false,
                loadedFax: false,
                isError: true,
                errorMessage: undefined,
                formAction: undefined,
                forwardFaxSingle: undefined
            }
        }
    ),
    on(
        composeActions.sendResource,
        (state) => {
            return {
                ...state,
                sendingResource: true,
                sentResource: false,
                isError: false,
                errorMessage: undefined,
                formAction: undefined,
                forwardFaxSingle: undefined
            }
        }
    ),
    on(
        composeActions.sendResourceSuccess,
        (state) => {
            return {
                ...state,
                sendingResource: false,
                sentResource: true,
                isError: false,
                errorMessage: undefined,
                formAction: undefined,
                forwardFaxSingle: undefined
            }
        }
    ),
    on(
        composeActions.sendResourceFail,
        (state) => { return { ...state, sendingResource: false, sentResource: false, isError: true, errorMessage: undefined, formAction: undefined, forwardFaxSingle: undefined } }
    ),
    on(
        composeActions.forwardFaxSingleSend,
        (state) => {
            return {
                ...state,
                forwardFaxSingle: undefined,
                forwardFaxMultiple: undefined,
                isError: false,
                errorMessage: undefined
            }
        }
    ),
    on(
        composeActions.forwardFaxSingleReceive,
        (state, { model }) => {
            return {
                ...state,
                forwardFaxSingle: model,
                isError: model.object_type === 'ApiError',
                errorMessage: model.object_type === 'ApiError' ? (<ApiError>model)?.message : 'unknown error',
                formAction: {
                    type: ComposeActionTypes.NEW,
                    form: undefined,
                    faxId: undefined,
                    serviceKey: undefined
                }
            }
        }
    ),
    on(
        composeActions.forwardFaxMultipleSend,
        (state) => {
            return {
                ...state,
                forwardFaxSingle: undefined,
                forwardFaxMultiple: undefined,
                isError: false,
                errorMessage: undefined
            }
        }
    ),
    on(
        composeActions.forwardFaxMultipleReceive,
        (state, { model }) => {
            return {
                ...state,
                forwardFaxMultiple: model,
                isError: model.object_type === 'ApiError',
                errorMessage: model.object_type === 'ApiError' ? (<ApiError>model)?.message : 'unknown error',
                formAction: {
                    type: ComposeActionTypes.NEW,
                    form: undefined,
                    faxId: undefined,
                    serviceKey: undefined
                }
            }
        }
    ),
    on(
        composeActions.setDefaultState,
        () => initialComposeState
    )
);
