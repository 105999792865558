<div class="table-container">
  <table mat-table class="mat-elevation-z1"  matSort [dataSource]="dataSource" (matSortChange)="sortData($event)">
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'reports.table.header.date' | transloco }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.formattedMessageTime }}
      </td>
    </ng-container>

    <ng-container matColumnDef="to">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'reports.table.header.to' | transloco }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.destination}}
      </td>
    </ng-container>

    <ng-container matColumnDef="pages">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'reports.table.header.pages' | transloco }}</th>
      <td mat-cell *matCellDef="let element">{{ element.pages }}</td>
    </ng-container>

    <ng-container matColumnDef="charge">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'reports.table.header.charge' | transloco }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.formattedChargeAmount }}
      </td>
    </ng-container>

    <ng-container matColumnDef="subject">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'reports.table.header.subject' | transloco }}</th>
      <td mat-cell *matCellDef="let element">{{ element.subject }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'reports.table.header.status' | transloco }}</th>
      <td mat-cell *matCellDef="let element">{{ element.status }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<mat-paginator #paginator class="paginator no-printable" [pageSize]="pageSize" showFirstLastButtons="true"
  [length]="totalElements" (page)="pageChanged($event)"></mat-paginator>
