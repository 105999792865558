import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { filter, map, take } from 'rxjs/operators';
import { SelectContactsComponent } from 'src/app/contacts/components/select-contacts.component';
import { ContactsTableData } from 'src/app/contacts/models/table.model';
import { EMAIL_PATTERN, NOT_EMPTY } from 'src/app/shared/constants/constants';

@Component({
  selector: 'myportal-forward-fax-email-dialog',
  templateUrl: './forward-fax-email-dialog.component.html',
  styleUrls: ['./forward-fax-email-dialog.component.scss']
})
export class ForwardFaxEmailDialogComponent {

  forwardEmailForm: FormGroup;
  emailList: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<ForwardFaxEmailDialogComponent>,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private translocoService: TranslocoService) {
    this.forwardEmailForm = new FormGroup({
      email: new FormControl('', { validators: [Validators.pattern(NOT_EMPTY),
        Validators.email,
        Validators.pattern(EMAIL_PATTERN),] }),
      subject: new FormControl(''),
      message: new FormControl('')
    });
  }

  get emailField() {
    return this.forwardEmailForm.get('email');
  }
  get subjectField() {
    return this.forwardEmailForm.get('subject');
  }

  get messageField() {
    return this.forwardEmailForm.get('message');
  }

  addEmail() {
    const currentValue = this.forwardEmailForm.get('email')?.value as string;
    // const currentIndex = this.emailList.findIndex(v => v.localeCompare(currentValue, undefined, { sensitivity: 'base' }));
    if (!!currentValue && this.emailList.find(v => v === currentValue) === undefined) {
      this.emailList.push(currentValue);
      this.emailField?.setValue(undefined);
    }
  }
  removeEmail(i: number) {
    this.emailList.splice(i, 1);
  }
  fromContacts() {
    const selectContactDialog = this.dialog.open(SelectContactsComponent, {
      backdropClass: 'cdk-overlay-transparent-backdrop',
      hasBackdrop: true,
      disableClose: true,
      minWidth: '500px',
      maxHeight: '70%' ,
    });
    selectContactDialog.afterClosed().pipe(filter(r => (r.cancel === false || r.cancel === undefined)),
      take(1),
      map(s => s.selected as ContactsTableData[])
    ).subscribe(result => {
      let showExistMessage: boolean = false;
      let contactWithoutEmail: boolean = false;
      result.forEach(s => {
        if (s.email !== undefined && !!s.email) {
          if (this.emailList.find(v => v === s.email) === undefined) {
            this.emailList.push(s.email);
          } else {
            showExistMessage = true;
          }
        }else{
          contactWithoutEmail = true;
        }
        if (showExistMessage || contactWithoutEmail) {
          let message = showExistMessage ? this.translocoService.translate(["general.contact.exists"]) : "" ;
          message += " ";
          message += contactWithoutEmail ? this.translocoService.translate(["general.contact.without.email"]) : "";
          this._snackBar.open(
            message, "X", { duration: 5000 }
          )
        }

      });
    });
  }
}
