<div class="modal">
  <div class="toolbar space-between">
    <h3 class="title">
      {{ "receive.fax.options.title" | transloco }}
    </h3>
    <button mat-icon-button aria-label="close dialog" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="hr"></div>

  <div class="fax-number">
    <label>{{ "Fax Number" | transloco }}:</label> {{ "+1 415 276 3762" }}
    {{ "receive.fax.options.fax.number" | transloco }}
  </div>

  <div class="explanation">
    <p>
      <label
        >{{ "receive.fax.options.update.options" | transloco }}
      </label>
    </p>

  </div>

  <div class="hr"></div>

  <div class="fields">

    <div class="fieldset">
      <label for="singleSelect">{{"receive.fax.options.file.format" | transloco}}:</label>
      <select name="singleSelect" ng-model="data.singleSelect">
        <option value="tif">TIF</option>
        <option value="pdf">PDF</option>
        <option value="pdf_s">PDF ({{ "receive.fax.options.searchable" | transloco }})</option>
      </select>
    </div>

    <div class="hr"></div>

    <div class="fieldset">
      <label for="csid">{{'receive.fax.options.fax.csid' | transloco}}:</label>
      <input name="csid" matInput formControlName="csid" />
    </div>

  </div>

  <div class="checkbox-container">
    <div>
      <input name="checkbox" type="checkbox">
    </div>
    <div>
      <label>{{'receive.fax.options.display.first.page' | transloco}}</label>
    </div>
  </div>

  <div class="hr"></div>

  <div class="update-button-container">
    <button
      id="update-button"
      name="update-button"
      aria-labelledby="update-button-label"
      class="btn btn-md btn-info update-button">
      <label>
        {{ "billing.usage.update" | transloco }}
      </label>
      <mat-icon>play_circle_filled</mat-icon>
    </button>
  </div>
</div>

