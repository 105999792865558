import { Pipe, PipeTransform } from '@angular/core';

const ICONS: any = {
  "inbox": "inbox",
  "sent": "send",
  "trash": "delete"
};

@Pipe({
  name: 'iconMap'
})
export class IconMapPipe implements PipeTransform {
  transform(value: string): string {
    return ICONS[value.toLowerCase()] ?? "folder";
  }
}