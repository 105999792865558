import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import packageJson from 'package.json';

@Component({
  selector: 'statuspage-component',
  templateUrl: './statuspage.component.html',
})
export class StatusPageComponent implements OnInit {

  data : any;
  readonly terminated$: Subject<boolean> = new Subject<boolean>();

  constructor(private http: HttpClient) {
    this.data = {
      name: packageJson.name,
      version: packageJson.version,
      status: "UP"
    };
  }

  ngOnInit(): void {
    this.checkServerInfo();
  }

  checkServerInfo() {
    const url = `${environment.faxClientAPIHost}/status`
    this.http.get(url, { responseType: 'json' })
      .pipe(takeUntil(this.terminated$))
      .subscribe(response => {
        this.data.faxclient_api = response;
      });
  }
}
