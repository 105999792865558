import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LoadingService } from 'src/app/shared/services/loading.service';

@Component({
  selector: 'recaptcha-V3',
  templateUrl: './recaptchaV3.component.html',
  styleUrls: ['./recaptchaV3.component.scss']
})
export class RecaptchaV3Component {

  @ViewChild('divRecaptcha')
  divRecaptcha!: ElementRef<HTMLDivElement>;

  isError: boolean = false;

  constructor(private matDialogRef: MatDialogRef<RecaptchaV3Component>, private loadingService: LoadingService) { }

  public get grecaptcha() {
    const w = window as any;
    return w['grecaptcha'];
  }

  show() {
    const idElemento = this.divRecaptcha.nativeElement.getAttribute('id');

    this.grecaptcha.render(idElemento, {
      sitekey: '6Ldj8JkUAAAAAIF0Rjg6ZigWbAPsHKmeqovdDn8K',
      callback: () => {
        this.loadingService.setLoading(true);
        this.matDialogRef.close();
      },
    });
  }

}
