import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor() { }

  deepShallowCopy(source: any) {
    return JSON.parse(JSON.stringify(Object.assign({}, source)));
  }

  checkForErrors(apiResponse: any, callwhile: any = undefined) {
    let taskMessage: string = "";
    let successMessage: string = "";
    let errorMessage: string = "";
    try {
      if (apiResponse?.object_type && (apiResponse?.object_type as string).toUpperCase() === "API_ERROR") {
        switch (apiResponse?.message) {
          case "ACCESS_TOKEN_EXPIRED":
            taskMessage = "Trying to renew the authorization token";
            successMessage = "Token renewal task executed.";
            errorMessage = "Authorization token could not been renewed automatically";
            break;
          default:
            break;
        }
        if (callwhile !== undefined && !!taskMessage) {
          this.logMessage(taskMessage);
          callwhile();
          this.logMessage(successMessage);
        }
      }
    } catch (error) {
      this.logMessage(errorMessage);
      apiResponse.error = Object.assign({}, error);
    }
    return apiResponse;
  }

  logMessage(message: any) {
    console.log(new Date().toUTCString() + ": " + message);
  }
}
