import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { FAX_NUMBER_PATTERN, NO_START_SPACE_SPECIAL_CHARS, RFC_EMAIL_REGEX } from '../constants/constants';
import { isCorrectFolderName } from "./stringValidators";

export function forbiddenUserNameValueValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const value = control.value;
    const hasValidPattern = value.match(RFC_EMAIL_REGEX) || value.match(FAX_NUMBER_PATTERN) || value.match(NO_START_SPACE_SPECIAL_CHARS);
    if (hasValidPattern) {
      return null;
    }
    const isAlphaNumeric = /[\d]+/.test(value) && /[\w]+/.test(value);
    return isAlphaNumeric ? { invalidFormat: true } : null;
  };
}

export function forbiddenValueValidator(forbidden: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return forbidden.test(control.value) ? { forbidden: true } : null;
  };
}
