import { LoginModel, ssoResponseError, ssoResponseSuccess, ssoResponseValidatedModel } from '../../models/login.model';
import { createAction, props } from '@ngrx/store';
import { LoginResponseModel } from '../../models/login.response.model';
import { ApiError } from 'src/app/auth/models/api.error.model';

export enum LoginActionTypes {
    LoginStart = '[Login] Login started',
    LoginSuccess = '[Login] Login successful',
    LoginFailure = '[Login] Login failure',
    LogOut = '[Login] Logging out of the application',
    LogOutComplete = '[Login] Logged out of the application',
    LoginSSOStart = '[Login] Login through sso started',
    LoginSSOSuccess = '[Login] Login through sso succeeded - just lookup',
    LoginSSOFailure = '[Login] Login through sso failed',
    SSOResponseValidated = '[Login] Login trough SSO validated',
    SSOResponseFailed = '[Login] Login response through SSO was not valid',
    ForgotLoginIdStart = '[Login] Forgot login id started',
    ForgotLoginIdSuccess = '[Login] Forgot login id response succeeded',
    ForgotLoginIdFailure = '[Login] Forgot login id response failed',
    ForgotPasswordStart = '[Login] Forgot password started',
    ForgotPasswordSuccess = '[Login] Forgot password response succeeded',
    ForgotPasswordFailure = '[Login] Forgot password response failed',
    ResetStatus = '[Login] reseting modal statuses'
}

export const LoginAction = createAction(LoginActionTypes.LoginStart, props<{ payload: LoginModel, brand: string }>());
export const LoginCompleteAction = createAction(LoginActionTypes.LoginSuccess, props<{ payload: LoginResponseModel }>());
export const LoginErrorAction = createAction(LoginActionTypes.LoginFailure, props<{ payload: LoginResponseModel }>());
export const LogOutAction = createAction(LoginActionTypes.LogOut);
export const LogOutCompleteAction = createAction(LoginActionTypes.LogOutComplete);
export const LoginSSOStart = createAction(LoginActionTypes.LoginSSOStart, props<{ user_email: string }>());
export const LoginSSOSuccess = createAction(LoginActionTypes.LoginSSOSuccess, props<{ response: ssoResponseSuccess }>());
export const LoginSSOFailure = createAction(LoginActionTypes.LoginSSOFailure, props<{ errors: ssoResponseError[] }>());
export const SSOResponseValidated = createAction(LoginActionTypes.SSOResponseValidated, props<{ response: ssoResponseValidatedModel }>());
export const SSOResponseFailed = createAction(LoginActionTypes.SSOResponseFailed, props<{ errors: any }>());
export const ForgotLoginIdStart = createAction(LoginActionTypes.ForgotLoginIdStart, props<{ payload: { user_email: string, brand: string } }>());
export const ForgotLoginIdSuccess = createAction(LoginActionTypes.ForgotLoginIdSuccess, props<{ status: boolean }>());
export const ForgotLoginIdFailure = createAction(LoginActionTypes.ForgotLoginIdFailure, props<{ error: ApiError }>());
export const ForgotPasswordStart = createAction(LoginActionTypes.ForgotPasswordStart, props<{ payload: { fax_number: string, brand: string } }>());
export const ForgotPasswordSuccess = createAction(LoginActionTypes.ForgotPasswordSuccess, props<{ payload: { faxNumber: string, emailSent: boolean } }>());
export const ForgotPasswordFailure = createAction(LoginActionTypes.ForgotPasswordFailure, props<{ error: ApiError }>());
export const resetModalStatus = createAction(LoginActionTypes.ResetStatus);