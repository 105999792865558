import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'myportal-receive-email-address',
  templateUrl: './receive-email-address.component.html',
  styleUrls: ['./receive-email-address.component.scss']
})
export class ReceiveEmailAddressComponent {

  constructor(private dialogRef: DialogRef) {

  }
  close() {
    this.dialogRef.close();
  }

}
