import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/guards/auth.guard';
import { ComposeGuard } from 'src/app/compose/guards/compose.guard';
import { FileshareGuard } from 'src/app/shared/layout/guards/fileshare.guard';
import { ViewMessagesGuard } from './view-messages/guards/view-messages.guard';
import { HolderComponent } from './shared/layout/holder.component';
import { LayoutComponent } from './shared/layout/layout.component';
import { FileshareComponent } from './shared/layout/fileshare.component';
import { AccountDetailsGuard } from "./shared/layout/guards/account-details.guard";
import { ContactsGuard } from "./contacts/guards/contacts.guard";

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'compose',
    canLoad: [ComposeGuard],
    canActivate: [ComposeGuard],
    loadChildren: () => import('./compose/compose.module').then(m => m.ComposeModule)
  },
  {
    path: 'view-messages',
    canLoad: [ViewMessagesGuard],
    canActivate: [ViewMessagesGuard],
    canActivateChild: [ViewMessagesGuard],
    loadChildren: () => import('./view-messages/view-messages.module').then(m => m.ViewMessagesModule)
  },
  {
    path: 'contacts',
    canLoad: [ContactsGuard],
    canActivate: [ContactsGuard],
    canActivateChild: [ContactsGuard],
    loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule)
  },
  {
    path: 'health',
    loadChildren: () => import('./healthcheck/healthcheck.module').then(m => m.HealthcheckModule)
  },
  {
    path: 'status',
    loadChildren: () => import('./statuspage/statuspage.module').then(m => m.StatusPageModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenanceModule)
  },
  {
    path: 'account-details',
    component: LayoutComponent,
    children: [
      {
        path: '', component: HolderComponent
      }
    ],
    canLoad: [AccountDetailsGuard],
    canActivate: [AccountDetailsGuard]
  },
  {
    path: 'fileshare',
    component: LayoutComponent,
    children: [
      {
        path: '', component: FileshareComponent
      }
    ],
    canLoad: [FileshareGuard],
    canActivate: [FileshareGuard]
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/login'
  },
  {
    path: '**',
    redirectTo: '/login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
