import { DialogRef } from '@angular/cdk/dialog';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ApiError } from 'src/app/auth/models/api.error.model';
import { LoginFacade } from '../facade/login.facade';
import { BrandService } from 'src/app/shared/services/brand.service';
import { FEEDBACK_DURATION } from 'src/app/shared/constants/constants';
import {LoadingService} from "../../shared/services/loading.service";

@Component({
  selector: 'app-forgot-login',
  templateUrl: './forgot-login.component.html',
  styleUrls: ['./forgot-login.component.scss']
})
export class ForgotLoginComponent implements OnInit, OnDestroy {
  @Output() close = new EventEmitter();
  userEmail!: string;
  thisName = 'email-login-id';
  isError = false;
  sent = false;
  apiError: ApiError | undefined = undefined;
  isSuccess: boolean = false;
  gotResultBack: boolean = false;
  forgotLoginForm: UntypedFormGroup;
  private terminated$: Subject<boolean> = new Subject<boolean>();
  brandConfig: any = undefined;

  feedbackMessageTimeout: number;

  constructor(
    private facade: LoginFacade,
    private brandService: BrandService,
    public loadingService: LoadingService,
    private dialogRef: DialogRef) {

    this.forgotLoginForm = new UntypedFormGroup({
      email: new UntypedFormControl('',
        [
          Validators.required,
          Validators.email,
          Validators.minLength(0),
          Validators.maxLength(100)
        ])
    });

    facade.loginStateViewModel$.pipe(
      filter(state => state !== undefined && state.forgotLoginId === true),
      takeUntil(this.terminated$)
    ).subscribe(
      result => {
        if (result.forgotLoginIdFailed) {
          this.gotResultBack = true;
          this.isError = true;
          this.apiError = result.apiError;
          this.feedbackMessageTimeout = window.setTimeout(() => {
            this.clearErrors();
          }, FEEDBACK_DURATION);

        } else if (result.forgotLoginIdSent) {
          this.gotResultBack = true;
          this.isSuccess = true;
          this.isError = false;
          const localTimer = window.setTimeout(() => {
            clearTimeout(localTimer);
            this.goBack();
          }, 5000);
        }
      }, err => {
        console.log("No idea what happened there, ", err);
      }
    )
  }

  ngOnInit(): void {
    this.brandService.config$
      .pipe(take(1))
      .subscribe((config:any) => {
        this.brandConfig = config;
      });
  }

  ngOnDestroy(): void {
    this.terminated$.next(true);
  }

  goBack() {
    this.facade.resetLoginStatus();
    this.userEmail = "";
    this.isError = false;
    this.apiError = undefined;
    this.isSuccess = false;
    this.forgotLoginForm.reset();
    this.dialogRef.close();
  }

  sendRequest() {
    this.clearErrors();
    this.sent = true;
    this.facade.forgotLoginIdSendRequest(this.forgotLoginForm.value.email, this.brandConfig.id);
  }

  clearErrors() {
    clearTimeout(this.feedbackMessageTimeout);
    this.isError = false;
    this.apiError = undefined;
    this.gotResultBack = false;
  }
}

