<h1 class="title-dialog" mat-dialog-title align="center">{{ getTitle(data.type) | transloco | titlecase  }}</h1>
<div mat-dialog-content class="folder-content">
  <label id="folder-list-label">{{ 'general.selectFolder' | transloco }}</label>
  <mat-radio-group aria-labelledby="folder-list-label" class="example-radio-group" [(ngModel)]="data.selectedFolder">
    <mat-radio-button class="example-radio-button" *ngFor="let item of data.folders" [value]="item"
      [aria-label]="item.name">
      {{item.name}}
    </mat-radio-button>
  </mat-radio-group>
</div>
<div mat-dialog-actions align="end">
  <label hidden id="cancel-delete-folder-label">{{ 'contacts.cancel' | transloco }}</label>
  <label hidden id="confirm-delete-folder-label"></label>
  <button mat-button class="btn btn-md" (click)="onNoClick()" aria-labelledby="cancel-delete-folder-label">{{ 'contacts.cancel' | transloco
    }}</button>
  <button mat-button class="btn btn-info btn-md" [disabled]="!data.selectedFolder"  [mat-dialog-close]="data.selectedFolder" cdkFocusInitial
    aria-labelledby="confirm-delete-folder-label">{{ 'contacts.confirm' | transloco }}</button>
</div>