import { ApiError, SortBy, SortDirection } from './shared.model'

export enum ContactSortBy {
    FIRST_NAME = "first_name",
    LAST_NAME = "last_name",
    EMAIL = "email",
    FAX = "fax",
    COMPANY = "company"
}

export interface ContactSortInput {
    sortBy?: ContactSortBy
    sortDirection?: SortDirection
}


export interface Contact {
    contactId: string
    firstName: string
    lastName: string
    company: string
    fax: string
    email: string
    countryCode: string
}

export interface ContactMutation {
    id: string
    name: string
    company: string
    faxNumber: string
    email: string
    location: string
}

export interface ContactSingle {
    object_type: string
    contact: Contact
}

export interface ContactList {
    object_type: string
    nextPageStartIndex: number
    pageSize: string
    pageStartIndex: string
    offset: number
    sort: string
    sortDirection: string
    totalRecords: string
    contacts: [Contact]
}

export interface ContactCreateInput {
    name: string
    company: string
    faxNumber: string
    email: string
    location: string
}

export interface ContactDeleteInput {
    id: string
}

export interface ContactCreate {
    contact: {
        id: string
        name: string
        company: string
        faxNumber: string
        email: string
        location: string
    }
}

export type ContactsListResult = ContactList | ApiError

export type ContactSingleResult = ContactSingle | ApiError

export interface ContactListParams {
    limit: number;
    offset: Number;
    sortBy: ContactSortBy;
    sortDirection: SortDirection;
}
