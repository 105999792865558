import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './components/login.component';
import { LoginRoutingModule } from './login-routing.module';
import { LoginFacade } from './facade/login.facade';
import { PipesModule } from '../shared/pipes/pipes.module';
import * as fromLogins from './store/reducers';
import { LoginEffects } from './store/effects/login.effects';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LoginApi } from './api/login.api';
import { TranslocoRootModule } from '../../transloco-root.module';
import { SharedModule } from '../shared/shared.module';
import { CorporateComponent } from './components/corporate.component';
import { ForgotLoginComponent } from './components/forgot-login.component';
import { ForgotPasswordComponent } from './components/forgot-password.component';
import { ErrorLoginComponent } from './components/error-login.component';
import { RecaptchaLoadFailDialogComponent } from './components/recaptcha-load-fail-dialog.component';
import { RecaptchaV3Component } from "./components/recaptcha/recaptchaV3.component";
import { RecaptchaService } from "./components/recaptcha/recaptcha.service";
import { LoginGuard } from './guards/login.guard';

@NgModule({
  declarations: [
    LoginComponent,
    CorporateComponent,
    RecaptchaV3Component,
    ForgotLoginComponent,
    ForgotPasswordComponent,
    ErrorLoginComponent,
    RecaptchaLoadFailDialogComponent
  ],
  imports: [
    CommonModule,
    LoginRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoRootModule,
    StoreModule.forFeature('loggedState', fromLogins.reducers),
    EffectsModule.forFeature([LoginEffects]),
    SharedModule,
    PipesModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [LoginFacade, LoginApi, RecaptchaService, LoginGuard]
})
export class LoginModule { }
