<h1 class="title-dialog" mat-dialog-title align="center">{{ 'general.createFolder' | transloco }}</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill" [style.width.%]="100">
    <mat-label id="newFolderLabel">{{ 'general.newFolder' | transloco }}</mat-label>
    <input matInput [(ngModel)]="data.name" maxlength="30" placeholder="eg. Favorites" aria-labelledby="newFolderLabel"
      autocomplete="folder-name" id="newFolderName" (input)="onChange()">
  </mat-form-field>
  <div *ngIf="data.errorMessage" class="error-message">
    {{ data.errorMessage }}
  </div>
</div>
<div mat-dialog-actions align="end">
  <label hidden id="create-folder-cancel-label"></label>
  <label hidden id="create-folder-confirm-label"></label>
  <button mat-button class="btn btn-md" (click)="onNoClick()" aria-labelledby="create-folder-cancel-label">{{ 'contacts.cancel' | transloco
    }}</button>
  <button mat-button class="btn btn-info btn-md" [mat-dialog-close]="data.name" cdkFocusInitial aria-labelledby="create-folder-confirm-label">{{
    'contacts.confirm' | transloco }}</button>
</div>
