<mat-form-field appearance="fill" class="w-100 search-mat-input">
  <mat-icon matPrefix mat-icon-button>search</mat-icon>
  <input
    matInput type="text"
    attr.aria-label="{{ 'search.bar.view-messages.placeholder' | transloco }}"
    aria-labelledby="search-label"
    [formControl]="search"
    placeholder="{{ 'search.bar.view-messages.placeholder' | transloco }}" />
    <!--mat-icon *ngIf="!isSearchEnabled()" matSuffix mat-icon-button>expand_more</mat-icon-->

  <button *ngIf="isSearchEnabled()" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
