import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'myportal-company-size',
  templateUrl: './company-size.component.html',
  styleUrls: ['./company-size.component.scss']
})
export class CompanySizeComponent {
  constructor(private dialogRef: DialogRef) {

  }
  close() {
    this.dialogRef.close();
  }
}
