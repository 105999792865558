<div class="modal">
  <div class="toolbar no-printable">
    <button mat-icon-button aria-label="close dialog" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="table-container" *ngIf="columns && this.dataSource.data">
    <table>
      <thead>
        <th *ngFor="let column of columns">{{column}}</th>
      </thead>

      <tbody>
        <tr *ngFor="let row of dataSource.data; index as i">
          <td *ngFor="let column of columns;">
            {{getValue(column, row)}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
