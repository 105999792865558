<div class="modal">
  <div class="toolbar space-between">
    <h3 class="title">
      {{ "edit.email.address.receiving.title" | transloco }}
    </h3>
    <button mat-icon-button aria-label="close dialog" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="hr"></div>

  <div class="fax-number">
    <label>{{ "edit.email.address.fax.number" | transloco }}:</label> {{ "+1 415 276 3762" }}
  </div>

  <div class="explanation">
    <p>
      <label
        >{{
          'edit.email.address.to.add.additional.email.address'
            | transloco
        }}
      </label>
    </p>

    <p>
      <label>
        {{
          "edit.email.address.note" | transloco
        }}
      </label>
    </p>

    <p>
      <label>{{
        "edit.email.address.email.limit"
          | transloco
      }}</label>
    </p>
  </div>

  <div class="hr"></div>

  <div class="toolbar">
    <h3 class="title">{{ "edit.email.address.receive.addresses" | transloco }}:</h3>
  </div>

  <div class="fields">
    <ol type="1">
      <li>
        <input matInput formControlName="email" />
      </li>
      <li>
        <input matInput formControlName="email" />
      </li>
      <li>
        <input matInput formControlName="email" />
      </li>
      <li>
        <input matInput formControlName="email" />
      </li>
      <li>
        <input matInput formControlName="email" />
      </li>
    </ol>
  </div>

  <div class="update-button-container">
    <button
      id="update-button"
      name="update-button"
      aria-labelledby="update-button-label"
      class="btn btn-md btn-info update-button">
      <label>
        {{ "billing.usage.update" | transloco }}
      </label>
      <mat-icon>play_circle_filled</mat-icon>
    </button>
  </div>
</div>

