import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { map, take, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApiError } from 'src/app/auth/models/api.error.model';
import { LoginFacade } from '../facade/login.facade';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PasswordForgot, PasswordVerify } from '../models/login.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH, USER_FEEDBACK_NOTIFICATION_DURATION_MS } from 'src/app/shared/constants/constants';
import { BrandService } from 'src/app/shared/services/brand.service';
import {LoadingService} from "../../shared/services/loading.service";
import {forbiddenUserNameValueValidator} from "../../shared/functions/customFieldValidators";

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.valid && (control.dirty || control.touched) && form?.hasError('notMatched'));
  }
}

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})

export class ForgotPasswordComponent implements OnInit, OnDestroy {
  apiError: ApiError | undefined = undefined;
  showSuccessResponse = false;
  isSuccess = false;
  forgotPasswordForm: UntypedFormGroup;
  verifyPasswordForm: UntypedFormGroup;
  sent = false;
  apiSuccess: boolean | undefined = undefined;
  private terminated$: Subject<boolean> = new Subject<boolean>();
  step1: boolean = true;
  brandConfig: any = undefined;
  hidePassword: boolean = true;
  hideConfirmPassword: boolean = true;
  public passwordPattern = /^(?=.*[a-z]).{8,20}$/
  public matcher = new MyErrorStateMatcher();

  recoveryCodeSentSuccess$ = new BehaviorSubject<any>(undefined);

  constructor(
    private facade: LoginFacade,
    private brandService: BrandService,
    public dialogRef: MatDialogRef<ForgotPasswordComponent>,
    public fb: FormBuilder,
    public loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA) public data: { faxNumber: string }) {

    this.forgotPasswordForm = this.fb.group({
      faxNumber: [data.faxNumber, [Validators.required, forbiddenUserNameValueValidator(), Validators.minLength(0), Validators.maxLength(100)]],
    });

    this.verifyPasswordForm = this.fb.group({
      faxNumber: new UntypedFormControl(data.faxNumber),
      securityCode: new UntypedFormControl('',
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(3),
          Validators.maxLength(10),
        ]),
      password: ['',
        [
          Validators.required,
          Validators.pattern(this.passwordPattern),
          Validators.minLength(MIN_PASSWORD_LENGTH),
          Validators.maxLength(MAX_PASSWORD_LENGTH)
        ]],
      confirmPassword: ['',
        [
          Validators.required,
          Validators.pattern(this.passwordPattern),
          Validators.minLength(MIN_PASSWORD_LENGTH),
          Validators.maxLength(MAX_PASSWORD_LENGTH)
        ]],
      brand: new UntypedFormControl('')
    }, { validator: this.checkingPasswords });

  }

  public checkingPasswords(formGroup: FormGroup) {
    if (
      formGroup.controls.password.value &&
      formGroup.controls.confirmPassword.value &&
      formGroup.controls.password.value &&
      formGroup.controls.password.value.length >= MIN_PASSWORD_LENGTH &&
      formGroup.controls.password.value.length <= MAX_PASSWORD_LENGTH &&
      formGroup.controls.confirmPassword.value.length >= MIN_PASSWORD_LENGTH &&
      formGroup.controls.confirmPassword.value.length <= MAX_PASSWORD_LENGTH
    ) {
      return formGroup.controls.password.value === formGroup.controls.confirmPassword.value ? false : { "notMatched": true }
    }
    return false;
  }

  checkValidations(control: any, type: any) {
    switch (type) {
      case 'lowercase':
        return /[a-z]/.test(control.value);
      case 'length':
        return control.value.length >= 8 && control.value.length <= 20;
      default:
        return false
    }
  }


  ngOnInit(): void {
    this.step1 = true;
    this.brandService.config$
      .pipe(takeUntil(this.terminated$))
      .subscribe((config: any) => {
        this.brandConfig = config;
      });
  }

  ngOnDestroy(): void {
    this.terminated$.next(true);
  }

  createCompareValidator(controlOne: AbstractControl, controlTwo: AbstractControl) {
    return () => {
      if (controlOne.value !== controlTwo.value)
        return { match_error: 'Value does not match' };
      return null;
    };
  }

  cancelVerify() {
    this.recoveryCodeSentSuccess$.next(undefined);
    this.step1 = true
    this.verifyPasswordForm.reset();
  }

  goBack() {
    this.dialogRef.close();
  }

  sendRequest() {
    this.apiError = undefined;
    this.apiSuccess = undefined;
    this.facade.forgotPassword(this.forgotPasswordForm.value.faxNumber, this.brandConfig.id).pipe(
      map(v => v.data),
      take(1))
      .subscribe((result: any) => {
        if (result?.passwordForgot !== undefined) {
          this.manageTokenSentResponse(result.passwordForgot as PasswordForgot);
        } else {
          this.apiError = (result?.apiError as ApiError);
        }
      }, () => {
        this.apiError = {
          code: 'A1',
          message: 'Request was not processed',
          object_type: 'ApiError'
        }
      });
  }

  private manageTokenSentResponse(response: PasswordForgot) {
    if (response.emailSent) {
      this.step1 = false;
      this.verifyPasswordForm.patchValue({ faxNumber: this.forgotPasswordForm.value.faxNumber, brand: this.brandConfig.id })
      this.recoveryCodeSentSuccess$.next(response);
      setTimeout(() => this.recoveryCodeSentSuccess$.next(undefined), USER_FEEDBACK_NOTIFICATION_DURATION_MS);
    } else {
      this.apiError = {
        code: 'A0',
        message: 'forgot.password.uncertain.message.token',
        object_type: 'ApiError'
      }
    }
  }

  verifyRequest() {
    this.apiError = undefined;
    this.apiSuccess = undefined;
    this.recoveryCodeSentSuccess$.next(undefined);
    const value = this.verifyPasswordForm.getRawValue();
    const passwordVerify = this.facade.verifyPassword(value.faxNumber, value.securityCode, value.password, value.confirmPassword, value.brand);
    passwordVerify.pipe(map(v => v.data), takeUntil(this.terminated$)).subscribe((result: any) => {
      if (result?.passwordVerify !== undefined) {
        this.evaluateResponse(result.passwordVerify as PasswordVerify);
      } else {
        this.apiError = {
          code: 'B0',
          message: 'Response could not be processed',
          object_type: 'ApiError'
        }
      }
    })
  }

  private evaluateResponse(value: PasswordVerify) {
    if (value.status) {
      this.apiSuccess = true;
      setTimeout(() => this.goBack(), 1000);
    } else {
      this.apiError = {
        object_type: value.object_type,
        code: 'B1',
        message: (value as any).message || value.messageCode
      }
    }
  }

  get passwordField() { return this.verifyPasswordForm.get('password') }
  get confirmPasswordField() { return this.verifyPasswordForm.get('confirmPassword') }
}
