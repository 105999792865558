import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import {
  loadAuth, loadAuthFailure, loadAuthSuccess,
  loadUserSingle, refreshAuth, refreshAuthComplete,
  refreshAuthFailure, removeAuth, removeAuthComplete,
  setAuth, setAuthComplete,
  authenticationError,
  authenticationSuccedded,
  updateUserSingle,
  updateUserSingleRecurrent,
  fireLegacyUrlCall
} from '../actions/auth.actions';
import { AuthPayload } from '../models/auth.payload';
import { initialState } from '../models/auth.state';
import { AuthApi } from '../../api/auth.api';
import { LoginFacade } from 'src/app/login/facade/login.facade';
import { AuthFacadeService } from '../../services/auth-facade.service';
import { Router } from '@angular/router';
import { MessagesService } from '../../../shared/messages/messages.service';
import { UserSingle } from '../../models/user.single.model';
import { StateStoreKeys, TOKEN_EXPIRATION_SECONDS, UNEXPECTED_ERROR_MESSAGE } from 'src/app/shared/constants/constants';

import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthEffects {
  private readonly COOKIE_CONSENT_NAME = '_vis_opt_cookie';

  constructor(private actions$: Actions, private authApi: AuthApi,
    private loginFacade: LoginFacade, private router: Router,
    private authFacade: AuthFacadeService,
    private messagesService: MessagesService,
    private cookieService: CookieService) { }

  loadAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAuth),
      switchMap(() => {
        return this.authFacade.authState$.pipe(
          take(1),
          map(value => {
            if (!!value.authorized && !!value.access_token) {
              const setIn = new Date().valueOf();
              if (setIn < value.expires_in && setIn > value.set_in) {
                const payload: AuthPayload = {
                  token: value.access_token,
                  set_in: value.set_in,
                  expires: value.expires_in,
                  is_logged: value.authorized,
                  username: value.user_name,
                  suspendedFlag: value.suspendedFlag,
                  fusionToken: value.fusionToken
                }
                return loadAuthSuccess({ auth: payload });
              } else {
                return loadAuthFailure();
              }
            } else {
              return loadAuthFailure();
            }
          })
        )
      })
    )
  );

  loadAuthFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAuthFailure),
      tap(() => {
        this.loginFacade.logOut();
      })
    ),
    { dispatch: false }
  );

  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshAuth),
      switchMap(() =>
        this.renewToken()
      )
    )
  );

  removeAuth$ = createEffect(() => this.actions$.pipe(
    ofType(removeAuth),
    map(() => removeAuthComplete())
  ));

  removeAuthComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeAuthComplete),
      tap(() => {
        // localStorage.setItem(StateStoreKeys.AUTH_STORE, JSON.stringify(initialState));
        localStorage.removeItem("portalSessionID");
      })
    ),
    { dispatch: false }
  )

  setAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setAuth),
      map((action) => setAuthComplete({ auth: action.auth }))
    )
  );

  setAuthComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setAuthComplete),
      switchMap((payload) => combineLatest([this.authApi.getUserSingle(), of(payload)])),
      map((res: any) => {
        if (res[0]?.error) {
          return loadUserSingle({ user: undefined, error: res[0].error, userName: "", flag: 0 });
        } else {
          const user: UserSingle = { ...res[0] };
          return loadUserSingle({ user: user, error: undefined, userName: res[1].auth.username, flag: res[1].auth.suspendedFlag })
        }
      }),
      catchError((err) => {
        let error = err?.error;
        // if (err?.error?.errors?.length > 0) {
        //   error.message = err.error.errors[0]?.message ? err.error.errors[0]?.message : "Single User Information";
        // }
        // this.router.navigate([this.buildErrorUrl(error)], { skipLocationChange: true });
        return of(loadUserSingle({ user: undefined, error: error, userName: "", flag: 0 }));
      })
    )
  );

  loadUserSingle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserSingle),
      map(value => {
        if (value.user !== undefined) {
          let loggedUser = undefined;
          if (value.userName !== undefined) {
            loggedUser = value.user.userSingle.user.services.find(ix => ix.phoneNumber.slice(-1 * (value.userName !== undefined ? value.userName.length : 0)) === (value.userName || ""));
          }
          if (loggedUser === undefined) {
            loggedUser = value.user.userSingle.user.services[0];
          }

          this.authFacade.setCurrentService(loggedUser);
          return authenticationSuccedded({ initialService: loggedUser, flag: value.flag });
        } else {
          const newError = value.error !== undefined ? JSON.parse(JSON.stringify(value.error)) : { message: 'Unexpected error.' };
          return authenticationError({ error: newError });
        }
      })
    )
  );

  authenticationError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticationError),
      tap(value => {
        this.router.navigate([this.buildErrorUrl(value)], { skipLocationChange: true });
      })
    ),
    { dispatch: false }
  )

  updateUserSingle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserSingle),
      switchMap((action) => {
        return this.authApi.getUserSingle().pipe(
          map((res) => {
            let loggedUser = res.userSingle.user.services.find(ix => ix.phoneNumber.slice(-1 * (action.currentDID.length)) === (action.currentDID));
            if (loggedUser === undefined) {
              loggedUser = res.userSingle.user.services[0];
            }
            return updateUserSingleRecurrent({ user: res, current: loggedUser })
          }),
          catchError((err) => {
            let error: any;
            if (err?.error?.errors?.length > 0) {
              error.message = err.error.errors[0]?.message ? err.error.errors[0]?.message : "";
            }
            //this.router.navigate([this.buildErrorUrl(error)]);
            return of(err);
          })
        )
      }),
    )
  );

  authenticationSuccedded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticationSuccedded),
      tap(result => {
        if (result.initialService.features.isSendEnabled && result.flag === 0) {
          this.webMyAccountLogin('/compose');
        } else {
          this.webMyAccountLogin('/view-messages');
        }
      }), switchMap(result => this.authApi.getWebLoginHash({ serviceKey: result.initialService.serviceKey, isIntPortal: true, current: false })),
      switchMap((result: any) => {
        let url = "";
        if (result !== undefined && !!result?.getWebAutoLoginHash?.hash) {
          url = environment.accountDetailsUrl + 'loginInfo=' + result.getWebAutoLoginHash.hash + '&isPortalSendFax=true';
        }
        return of(fireLegacyUrlCall({ typeNumber: 1, locationUrl: url }))
      })
    )
  );

  private webMyAccountLogin(redirectPage: string) {

    //this.loginFacade.setAutoLoginPortalURL(webMyAcountLoginUrl);
    /*const iFrame = document.createElement("iframe");
    iFrame.src = webMyAcountLoginUrl;
    document.body.append(iFrame);*/

    if (redirectPage !== undefined) {
      this.router.navigate([redirectPage]).then(() => {
        // FMA-15595 Hard refresh for Whatfix plug-in, happen only once after authentication
        // window.location.reload();
      });
    }

  }

  private buildErrorUrl(error: any) {
    let baseUrlError = '/error'
    if (error?.code !== undefined || error?.message !== undefined) {
      baseUrlError += '?'
    }
    if (error?.code !== undefined) {
      baseUrlError += 'error_code=' + error?.code
    }
    if (error?.message !== undefined) {
      baseUrlError += error?.code !== undefined ? '&error_msg=' : 'message=' + error?.message
    }
    console.log('baseerrorurl->', baseUrlError)
    return baseUrlError
  }

  // fireLegacyCall$ = createEffect(() => this.actions$.pipe(
  //   ofType(fireLegacyUrlCall),
  //   map(() => setLegacyUrlNone())
  // ));

  refreshTokenErrorAction$ = createEffect(() => this.actions$.pipe(
    ofType(refreshAuthFailure),
    tap(() => {
      console.log('Unable to renew the token auth');
      this.loginFacade.logOut();
    })),
    { dispatch: false }
  );

  private renewToken = () => {
    return this.authApi.refreshToken().pipe(
      map(value => this.messagesService.deepShallowCopy(value)),
      map((res: any) => {
        if (res?.data?.refreshToken?.__typename === 'Authenticate') {
          const currentSetIn = new Date().valueOf();
          const expiresIn = currentSetIn + (TOKEN_EXPIRATION_SECONDS * 1000);
          return refreshAuthComplete({ newToken: res.data.refreshToken.token, setIn: currentSetIn, expires: expiresIn });
        } else if (res?.data?.refreshToken?.__typename === 'ApiError') {
          const errorMessage = res?.data?.refreshToken?.message;
          return refreshAuthFailure({ error: errorMessage });
        } else {
          const errorMessage = UNEXPECTED_ERROR_MESSAGE;
          return refreshAuthFailure({ error: errorMessage });
        }
      })
    );
  }
}
