<h4 class="title-dialog" mat-dialog-title align="center">{{ 'general.forwardByMail' | transloco | titlecase }}</h4>
<div mat-dialog-content>
    <form [formGroup]="forwardEmailForm">
        <div class="button-row">
            <mat-form-field appearance="outline" class="primer">
                <mat-label>{{ "general.email" | transloco }}</mat-label>
                <input matInput cdkFocusInitial autofocus id="email" type="email" formControlName="email" />
                <!-- <mat-icon matSuffix (click)="addEmail()"
                    [ariaDisabled]="!forwardEmailForm.controls.email.valid">add_to_queue</mat-icon> -->
                <mat-error *ngIf="forwardEmailForm.controls.email.touched &&
                    forwardEmailForm.controls.email.errors?.required">
                    {{ "general.error.required" | transloco }}
                </mat-error>
                <mat-error *ngIf="forwardEmailForm.controls.email.touched &&
                    forwardEmailForm.controls.email.errors?.invalid">
                    {{ "general.error.required" | transloco }}
                </mat-error>
                <mat-error *ngIf="forwardEmailForm.controls.email.touched &&
                    forwardEmailForm.controls.email.errors?.pattern">
                    {{ 'contacts.email.invalid' | transloco }}
                </mat-error>
            </mat-form-field>
            <div class="column">
                <button mat-button (click)="addEmail()" ariaLabel="{{ 'general.addEmail' | transloco }}"
                    matTooltip="{{'general.addEmail' | transloco }}"
                    [disabled]="emailField?.dirty && emailField?.invalid"><mat-icon>add_circle_outline</mat-icon>
                </button>
                <button mat-button (click)="fromContacts()" matTooltip="{{'general.fromContacts' | transloco }}"
                    ariaLabel="{{ 'general.fromContacts' | transloco }}"><mat-icon>contact_mail</mat-icon> </button>
            </div>
        </div>
        <div class="chip-list">
            <mat-chip *ngFor="let mail of emailList; let i = index" aria-label="{{mail}}" selectable="selectable"
                removable="removable" (removed)="removeEmail(i)">
                {{mail}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </div>

        <!-- <mat-form-field appearance="outline" hidden="hidden">
            <mat-label> {{ 'general.subject' | transloco }} </mat-label>
            <input matInput id="subject" type="text" formControlName="subject" />
        </mat-form-field>

        <mat-form-field appearance="outline" hidden="hidden">
            <mat-label> {{ 'general.message' | transloco }} </mat-label>
            <textarea matInput id="message" type="text" formControlName="message"></textarea>
        </mat-form-field> -->
    </form>
</div>
<div mat-dialog-actions align="end">
    <label hidden id="forward-mail-cancel-label">{{ 'contacts.cancel' | transloco }}</label>
    <label hidden id="forward-mail-confirm-label">{{ 'contacts.confirm' | transloco }}</label>
    <button mat-button class="btn btn-md" [mat-dialog-close]="'cancel'" aria-labelledby="forward-mail-cancel-label">
        {{ 'contacts.cancel' | transloco }}
    </button>
    <button mat-button class="btn btn-info btn-md"
        [mat-dialog-close]="{ mailsList: emailList, subject: subjectField?.value, message: messageField?.value }"
        [disabled]="emailList.length === 0" aria-labelledby="forward-mail-confirm-label">
        {{ 'contacts.confirm' | transloco }}
    </button>
</div>