import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { BillingSummary } from '../summary-table/summary-table.component';


@Component({
  selector: 'myportal-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {

  filter: any = {

  };
  dataSource: MatTableDataSource<BillingSummary>;
  dataList: BillingSummary[] = [];


  ngOnInit(): void {

    this.setupDefaultFilters();
    const data: BillingSummary[] = [{
      date: new Date().toISOString(),
      description: 'Credit Card Payment',
      period: `${new Date(this.filter.start).toLocaleDateString()} - ${new Date(this.filter.end).toLocaleDateString()}`,
      number: '-',
      amount: -596.56
    },
    {
      date: new Date().toISOString(),
      description: 'Monthly Subscription Fee',
      period: `${new Date(this.filter.start).toLocaleDateString()} - ${new Date(this.filter.end).toLocaleDateString()}`,
      number: '16265236542',
      amount: 22.99
    },
    {
      date: new Date().toISOString(),
      description: 'Monthly Subscription Fee',
      period: `${new Date(this.filter.start).toLocaleDateString()} - ${new Date(this.filter.end).toLocaleDateString()}`,
      number: '17565236542',
      amount: 19.95
    },
    {
      date: new Date().toISOString(),
      description: 'Monthly Subscription Fee',
      period: `${new Date(this.filter.start).toLocaleDateString()} - ${new Date(this.filter.end).toLocaleDateString()}`,
      number: '18865236542',
      amount: 19.95
    },
    {
      date: new Date().toISOString(),
      description: 'Monthly Subscription Fee',
      period: `${new Date(this.filter.start).toLocaleDateString()} - ${new Date(this.filter.end).toLocaleDateString()}`,
      number: '12265236542',
      amount: 22.95
    },
    {
      date: new Date().toISOString(),
      description: 'Monthly Subscription Fee',
      period: `${new Date(this.filter.start).toLocaleDateString()} - ${new Date(this.filter.end).toLocaleDateString()}`,
      number: '18165236542',
      amount: 22.99
    },
    {
      date: new Date().toISOString(),
      description: 'Monthly Subscription Fee',
      period: `${new Date(this.filter.start).toLocaleDateString()} - ${new Date(this.filter.end).toLocaleDateString()}`,
      number: '13065236542',
      amount: 22.99
    },
    {
      date: '2024-08-01T14:48:00.000Z',
      description: 'Monthly Subscription Fee',
      period: `${new Date(this.filter.start).toLocaleDateString()} - ${new Date(this.filter.end).toLocaleDateString()}`,
      number: '16265236542',
      amount: 19.95
    },
    {
      date: new Date().toISOString(),
      description: 'Monthly Subscription Fee',
      period: `${new Date(this.filter.start).toLocaleDateString()} - ${new Date(this.filter.end).toLocaleDateString()}`,
      number: '13565236542',
      amount: 22.99
    },
    {
      date: new Date().toISOString(),
      description: 'Monthly Subscription Fee',
      period: `${new Date(this.filter.start).toLocaleDateString()} - ${new Date(this.filter.end).toLocaleDateString()}`,
      number: '18165236542',
      amount: 22.99
    },
    {
      date: new Date().toISOString(),
      description: 'Monthly Subscription Fee',
      period: `${new Date(this.filter.start).toLocaleDateString()} - ${new Date(this.filter.end).toLocaleDateString()}`,
      number: '13065236542',
      amount: 22.99
    },
    {
      date: new Date().toISOString(),
      description: 'Monthly Subscription Fee',
      period: `${new Date(this.filter.start).toLocaleDateString()} - ${new Date(this.filter.end).toLocaleDateString()}`,
      number: '16265236542',
      amount: 19.95
    },
    ]
    this.dataList = data;
    this.dataSource = new MatTableDataSource(data);
  }

  setupDefaultFilters() {
    const today = new Date();
    const startDate = new Date();
    startDate.setDate(today.getDate() - 30);
    this.filter.start = startDate.toISOString();
    this.filter.end = today.toISOString();
  }

  applyFilter(filter: { start: any; end: any; }) {
    this.dataSource.data = this.dataList
      .filter((e: BillingSummary) => {
        const messageTime: Date = new Date(e.date);
        return messageTime >= filter.start && messageTime <= filter.end;
      });
  }

  print() {
    window.print();
  }

}
