import { FaxSortInput, FaxFilterInput } from "./fax.model";
import { Folder } from "./folder.model";
import { SortBy, SortDirection } from "./shared.model";

export interface ViewMessagesTableData {
  subject: string;
  from: string;
  tags: string[];
  date: string;
  isRead: boolean;
  pageCount: number,
  faxId: string,
  imageUrl: string,
  thumbnails: FaxThumbnail[],
  originatingFaxMachine: string,
  destinationFaxNumber: string,
  transmissionStatus: string,
  folder: string
}

export interface FaxThumbnail {
  faxId: string,
  imageUrl: string,
}

export const CHECK_NEW_MESSAGES_INTERVAL = 300000;
export const TABLE_DATA: ViewMessagesTableData[] = [];
export const FOLDER_DEFAULT_COLUMNS = ['select', 'type', 'from', 'subject', 'page count', 'tags', 'date'];
export const FOLDER_SENT_COLUMNS = ['select', 'recipient', 'type', 'subject', 'status', 'page count', 'tags', 'date'];
export const FOLDER_SEARCH_COLUMNS = ['select', 'folder', 'from', 'type', 'subject', 'page count', 'tags', 'date'];
export const SORTABLE_COLUMNS = ['from', 'date'];
export const FOLDER_DEFAULT_SELECTED: Folder = { id: 'INBOX', name: 'INBOX', faxCountRead: 0, faxCountUnread: 0, faxTotalCount: 0, faxes: undefined };
export const FOLDER_DEFAULT_SEARCH: Folder = { id: 'SEARCH', name: 'SEARCH', faxCountRead: 0, faxCountUnread: 0, faxTotalCount: 11, faxes: [] };
export const SNACK_BAR_DURATION_MS = 3000;
export const LIMIT_TOTAL_COUNT_DEFAULT = 15;
export const OFFSET_DEFAULT = 0;
export const DEFAULT_FAX_SORT_INPUT: FaxSortInput = { sortBy: SortBy.DATE, sortDirection: SortDirection.DESC };

export const DEFAULT_FAX_FILTER_INPUT: FaxFilterInput = {

}
