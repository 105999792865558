import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'myportal-work-number',
  templateUrl: './work-number.component.html',
  styleUrls: ['./work-number.component.scss']
})
export class WorkNumberComponent {
  constructor(private dialogRef: DialogRef) {

  }

  close() {
    this.dialogRef.close();
  }
}
