import { Component, Input } from '@angular/core';

@Component({
  selector: 'view-messages-errors',
  templateUrl: 'view-messages-errors.component.html',
  styleUrls: ['./view-messages-errors.component.scss']
})
export class ViewMessagesErrorsComponent {

  @Input() showFolderError: boolean = false
  @Input() showRenameFolderError: boolean = false
  @Input() showFolderSuccess: boolean = false
  @Input() showFolderDeleteSuccess: boolean = false

  private messageTime: number = 5 * 1000

  constructor() { }

  showSnackForCreateFolderSuccess() {
    this.showFolderSuccess = true;
    setTimeout(() => {
      this.showFolderSuccess = false;
    }, this.messageTime);
  }

  showSnackForCreateFolderError() {
    this.showFolderError = true;
    setTimeout(() => {
      this.showFolderError = false;
    }, this.messageTime);
  }

  showSnackForRenameFolderError() {
    this.showRenameFolderError = true;
    setTimeout(() => {
      this.showRenameFolderError = false;
    }, this.messageTime);
  }

  showSnackForDeleteFolderSuccess() {
    this.showFolderDeleteSuccess = true;
    setTimeout(() => {
      this.showFolderDeleteSuccess = false;
    }, this.messageTime);
  }

  showSnackForDeleteFolderError() {
    this.showFolderError = true;
    setTimeout(() => {
      this.showFolderError = false;
    }, this.messageTime);
  }

}
