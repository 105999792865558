import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TabService implements OnDestroy {
  private tabKey = 'sessionTab';
  private tabId: string;

  constructor(private snackBar: MatSnackBar, private translocoService: TranslocoService) { }

  init() {
    this.tabId = new Date().getTime().toString();
    this.checkDuplicateTab();
    window.addEventListener('storage', this.onStorageEvent.bind(this));
    window.addEventListener('beforeunload', this.onBeforeUnload.bind(this));
  }

  private checkDuplicateTab() {
    const existingTab = localStorage.getItem(this.tabKey);
    if (existingTab && existingTab !== this.tabId) {
      this.openDuplicateTabSnackBar();
    } else {
      localStorage.setItem(this.tabKey, this.tabId);
    }
  }

  private onStorageEvent(event: StorageEvent) {
    if (event.key === this.tabKey && event.newValue !== this.tabId) {
      this.openDuplicateTabSnackBar();
    }
  }

  private onBeforeUnload() {
    if (localStorage.getItem(this.tabKey) === this.tabId) {
      localStorage.removeItem(this.tabKey);
    }
  }

  private openDuplicateTabSnackBar() {
    this.translocoService.selectTranslate('duplicated.tab').pipe(take(1)).subscribe(message => {
      this.snackBar.open(message, 'X', {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
    });
  }

  ngOnDestroy() {
    window.removeEventListener('storage', this.onStorageEvent.bind(this));
    window.removeEventListener('beforeunload', this.onBeforeUnload.bind(this));
    if (localStorage.getItem(this.tabKey) === this.tabId) {
      localStorage.removeItem(this.tabKey);
    }
  }
}
