<form [formGroup]="range">
  <div class="horizontal">
    <mat-form-field>
      <mat-label>{{startLabel}}</mat-label>
      <input name="startDate" formControlName="start" [min]="minForStart" [max]="maxForStart"
        (ngModelChange)="startChange.emit($event)" matInput [matDatepicker]="startPicker" #startDate>
      <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
      <mat-datepicker name="startDate" #startPicker></mat-datepicker>
      <mat-error *ngIf="inputFrom?.hasError('matDatePickerMax')">Date should be inferior</mat-error>
      <mat-error *ngIf="inputFrom?.hasError('matDatePickerMin')">Date should be superior</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{endLabel}}</mat-label>
      <input name="endDate" formControlName="end" [min]="minForEnd" [max]="maxForStart"
        (ngModelChange)="endChange.emit($event)" matInput [matDatepicker]="endPicker" #endDate>
      <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
      <mat-datepicker #endPicker [ariaValueMin]="0"></mat-datepicker>
      <mat-error *ngIf="inputTo?.hasError('matDatePickerMax')">Date should be inferior</mat-error>
      <mat-error *ngIf="inputTo?.hasError('matDatePickerMin')">Date should be superior</mat-error>
    </mat-form-field>
    <button class="btn btn-md btn-info" (click)="onClick()" [disabled]="!isValid">
      {{ "reports.button.go" | transloco }}
    </button>
  </div>
</form>