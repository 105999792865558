import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthFacadeService } from '../../auth/services/auth-facade.service';

@Injectable()
export class LoginGuard implements CanActivate {

  constructor(private authFacade: AuthFacadeService, private router: Router) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authFacade.token$.pipe(take(1), map((value: string) => {
      if (value !== undefined && !!value) {
        return this.router.createUrlTree(['/view-messages']);
      } else {
        return true;
      }
    })
    );
  }

}
