import {Directive, ElementRef} from "@angular/core";
import {BrandService} from "../services/brand.service";
import {PermissionDirective} from "./permission.directive";

@Directive({
  selector: '[disablePermission]'
})
export class DisablePermissionDirective extends PermissionDirective{

  constructor(protected elementRef: ElementRef,
              protected brandService: BrandService) {
    super(elementRef, brandService)

    if (this.brandsArray.indexOf(this.brandName) > -1){
      this.elementRef.nativeElement.disable();
    }

  }

}
