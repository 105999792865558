import {Directive, ElementRef, HostListener, Renderer2} from "@angular/core";
import {GoogleTagEvent} from "../google-tag-event";
import {GoogleTagAction} from "../google-tag-action";
import {GoogleTagDirective} from "./google-tag.directive";

@Directive({
  selector: '[googleTag-onClick]'
})
export class GoogleTagOnclickDirective extends GoogleTagDirective{

  @HostListener('click', ['$event'])
  onClick(event: any) {
    let googleTagHit = {
      event: GoogleTagEvent.BUTTON_CLICK,
      action: GoogleTagAction.CLICK,
      category: "",
      label: event.currentTarget.id
    }
    this.gtmService.logEvent(googleTagHit);
  }

}
