import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { QueryRef } from 'apollo-angular';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FaxListResult, FaxFilterInput, FaxSortInput, FaxUpdateParams, FaxSingleResult, FolderSingleResult, ForwardFaxToEmailResult } from '../models/fax.model';
import {
  FolderCreateInput,
  FolderDeleteInput,
  FolderListResult,
  FolderRenameInput
} from '../models/folder.model';
import { DEFAULT_FAX_FILTER_INPUT, DEFAULT_FAX_SORT_INPUT, LIMIT_TOTAL_COUNT_DEFAULT, OFFSET_DEFAULT } from '../models/table.model';
import {
  BULK_DOWNLOAD_QUERY,
  faxSingleComplete,
  faxSingleImages,
  getFolderSingleQuery,
  VIEW_MESSAGES_CREATE_FOLDER_MUTATION,
  VIEW_MESSAGES_DELETE_FOLDER_MUTATION,
  VIEW_MESSAGES_FAX_LIST_DELETE,
  VIEW_MESSAGES_FAX_LIST_QUERY,
  VIEW_MESSAGES_FAX_SINGLE,
  VIEW_MESSAGES_FAX_SINGLE_DELETE,
  VIEW_MESSAGES_FAX_TAG_ADD,
  VIEW_MESSAGES_FAX_TAG_DELETE,
  VIEW_MESSAGES_FAX_UPDATE,
  VIEW_MESSAGES_FOLDER_LIST_QUERY,
  VIEW_MESSAGES_FOLDER_SINGLE,
  VIEW_MESSAGES_FORWARD_FAX_TO_EMAIL,
  VIEW_MESSAGES_MOVE_FAXES_MUTATION,
  VIEW_MESSAGES_RENAME_FOLDER_MUTATION
} from './view-messages.query';
import { ApolloIntportal } from "../../shared/apollo-intportal";

@Injectable()
export class ViewMessagesApi {

  private folderListQuery: QueryRef<FolderListResult, { serviceKey: string }>;
  private faxListQuery: QueryRef<FaxListResult, { serviceKey: string, filter: FaxFilterInput, offset: number, limit: number, sort: FaxSortInput }>;
  private faxSingleQuery: QueryRef<FaxSingleResult, { serviceKey: string, faxId: string }>;
  private folderSingleQuery: QueryRef<FolderSingleResult, { name: string, serviceKey: string }>;
  private downloadBulkQuery: QueryRef<any, { serviceKey: string, faxIds: string[] }>;

  constructor(private apollo: ApolloIntportal, private http: HttpClient) {
    this.folderListQuery = this.getFolderList('');
    this.faxListQuery = this.getFaxList();
    this.faxSingleQuery = this.getFaxSingle();
    this.folderSingleQuery = this.getFolderSingle();
    this.downloadBulkQuery = this.getDownloadBulk();
  }

  private getFolderList(serviceKey: string): QueryRef<FolderListResult, { serviceKey: string }> {
    return this.apollo.watchQuery<FolderListResult, { serviceKey: string }>({
      query: VIEW_MESSAGES_FOLDER_LIST_QUERY,
      variables: { serviceKey: serviceKey },
      fetchPolicy: 'no-cache'
    });
  }

  fetchFolderList(serviceKey: string): Observable<FolderListResult> {
    return from(this.refreshFolderList(serviceKey))
      .pipe(map(result => result.data));
  }

  private refreshFolderList(serviceKey: string): Promise<ApolloQueryResult<FolderListResult>> {
    return this.folderListQuery.refetch({ serviceKey: serviceKey });
  }

  /// deprecated by queryFolderSingle; but used only for search purposes
  fetchFaxList(variables: { serviceKey: string, filter: FaxFilterInput, offset: number, limit: number, sort: FaxSortInput }): Observable<FaxListResult> {
    return from(this.refreshFaxList(variables))
      .pipe(map(result => result.data));
  }

  private getFaxList(): QueryRef<FaxListResult, { serviceKey: string, filter: FaxFilterInput, offset: number, limit: number, sort: FaxSortInput }> {
    return this.apollo.watchQuery<FaxListResult, { serviceKey: string, filter: FaxFilterInput, offset: number, limit: number, sort: FaxSortInput }>({
      query: VIEW_MESSAGES_FAX_LIST_QUERY,
      variables: { serviceKey: '', filter: DEFAULT_FAX_FILTER_INPUT, offset: OFFSET_DEFAULT, limit: LIMIT_TOTAL_COUNT_DEFAULT, sort: DEFAULT_FAX_SORT_INPUT }
    });
  }

  private refreshFaxList(variables: { serviceKey: string, filter: FaxFilterInput, offset: number, limit: number, sort: FaxSortInput }): Promise<ApolloQueryResult<FaxListResult>> {
    return this.faxListQuery.refetch(variables);
  }

  private getFaxSingle(): QueryRef<FaxSingleResult, { serviceKey: string, faxId: string }> {
    return this.apollo.watchQuery<FaxSingleResult, { serviceKey: string, faxId: string }>({
      query: VIEW_MESSAGES_FAX_SINGLE,
      variables: { serviceKey: '', faxId: '' }
    });
  }

  private getFolderSingle(): QueryRef<FolderSingleResult, { name: string, serviceKey: string }> {
    return this.apollo.watchQuery<FolderSingleResult, { name: string, serviceKey: string }>({
      query: VIEW_MESSAGES_FOLDER_SINGLE,
      variables: { name: '', serviceKey: '' }
    });
  }

  public queryFolderSingle(payload: { serviceKey: string, name: string, offset: number, limit: number, sort: FaxSortInput }) {
    // this.folderSingleQuery.options.query = getFolderSingleQuery(payload);
    // return this.fetchFolderSingle((payload.filter.folder !== undefined ? payload.filter.folder : 'INBOX'), payload.serviceKey);
    return this.apollo.query<FolderSingleResult, { serviceKey: string, name: string }>({
      query: getFolderSingleQuery(payload),
      variables: { serviceKey: payload.serviceKey, name: payload.name },
      fetchPolicy: 'no-cache'
    }).pipe(map(d => d.data));
  }

  private refreshFolderSingle(name: string, serviceKey: string): Promise<ApolloQueryResult<FolderSingleResult>> {
    return this.folderSingleQuery.refetch({ name: name, serviceKey: serviceKey });
  }

  fetchFolderSingle(name: string, serviceKey: string) {
    return from(this.refreshFolderSingle(name, serviceKey))
  }

  private getDownloadBulk() {
    return this.apollo.watchQuery<any, { serviceKey: string, faxIds: string[] }>({
      query: BULK_DOWNLOAD_QUERY,
      variables: { serviceKey: '', faxIds: [] }
    });
  }

  private refreshDownloadBulk(serviceKey: string, faxIds: string[]) {
    return this.downloadBulkQuery.refetch({ serviceKey: serviceKey, faxIds: faxIds });
  }


  downloadBulk(serviceKey: string, faxIds: string[]) {
    // return this.apollo.query({
    //   query: BULK_DOWNLOAD_QUERY,
    //   variables: { serviceKey: serviceKey, faxIds: faxIds },
    //   fetchPolicy: 'no-cache'
    // });
    return from(this.refreshDownloadBulk(serviceKey, faxIds));
  }

  private refreshFaxSingle(serviceKey: string, faxId: string): Promise<ApolloQueryResult<FaxSingleResult>> {
    return this.faxSingleQuery.refetch({ serviceKey: serviceKey, faxId: faxId });
  }

  fetchSingleFax(serviceKey: string, faxId: string): Observable<FaxSingleResult> {
    return from(this.refreshFaxSingle(serviceKey, faxId))
      .pipe(map(result => result.data));
  }

  createNewFolder(variables: { serviceKey: string, input: FolderCreateInput }) {
    return this.apollo.mutate({
      mutation: VIEW_MESSAGES_CREATE_FOLDER_MUTATION,
      variables: { serviceKey: variables.serviceKey, folderName: variables.input.name },
    });
  }

  renameFolder(variables: { serviceKey: string, input: FolderRenameInput }) {
    return this.apollo.mutate({
      mutation: VIEW_MESSAGES_RENAME_FOLDER_MUTATION,
      variables: {
        serviceKey: variables.serviceKey,
        folderId: variables.input.id,
        input: {
          name: variables.input.name
        }
      },
    });
  }

  deleteFolder(variables: { serviceKey: string, input: FolderDeleteInput }) {
    return this.apollo.mutate({
      mutation: VIEW_MESSAGES_DELETE_FOLDER_MUTATION,
      variables: { serviceKey: variables.serviceKey, folderId: variables.input.id },
    });
  }

  moveFaxes(variables: { serviceKey: string, folderId: string, faxIds: string[] }) {
    return this.apollo.mutate({
      mutation: VIEW_MESSAGES_MOVE_FAXES_MUTATION,
      variables: { serviceKey: variables.serviceKey, folderId: variables.folderId, faxIds: variables.faxIds, allowCustomFolderToInbox: variables.folderId === 'INBOX' },
    });
  }

  updateFaxReadUnRead(serviceKey: string, faxId: string, updateValue: boolean) {
    const updateParam: FaxUpdateParams = { isRead: updateValue, apiClientIpAddress: '0.0.0.0' };
    return this.apollo.mutate({
      mutation: VIEW_MESSAGES_FAX_UPDATE,
      variables: { serviceKey: serviceKey, faxId: faxId, input: updateParam }
    });
  }

  deleteSingleFax(serviceKey: string, faxId: string, flush: boolean, folderId: string) {
    return this.apollo.mutate({
      mutation: VIEW_MESSAGES_FAX_SINGLE_DELETE,
      variables: { serviceKey: serviceKey, faxId: faxId, purgeImmediately: flush }
    });
  }

  deleteListFax(serviceKey: string, faxIds: string[], flush: boolean, folderId: string) {
    return this.apollo.mutate({
      mutation: VIEW_MESSAGES_FAX_LIST_DELETE,
      variables: { serviceKey: serviceKey, faxIds: faxIds, purgeImmediately: flush }
    });
  }

  tagAdd(serviceKey: string, faxId: string, tag: string) {
    return this.apollo.mutate({
      mutation: VIEW_MESSAGES_FAX_TAG_ADD,
      variables: { serviceKey: serviceKey, faxId: faxId, tag: tag }
    });
  }

  tagDelete(serviceKey: string, faxId: string, tag: string) {
    return this.apollo.mutate({
      mutation: VIEW_MESSAGES_FAX_TAG_DELETE,
      variables: { serviceKey: serviceKey, faxId: faxId, tag: tag }
    });
  }

  downloadFileFromUrl(url: string) {
    return this.http.get(url, { responseType: 'blob' });
  }

  postSigningFax(url: string, loginInfo: string, mailbox: string, faxId: string) {
    const body = {
      isIntPortal: true,
      isPortalSignature: true,
      loginInfo: loginInfo,
      mailbox: mailbox,
      themid: faxId
    };
    return this.http.post(url, body)
  }

  forwardFaxToEmail(serviceKey: string, subject: string, message: string, faxIds: string[], emails: string[]): Observable<ForwardFaxToEmailResult> {
    return this.apollo.mutate<ForwardFaxToEmailResult>({
      mutation: VIEW_MESSAGES_FORWARD_FAX_TO_EMAIL,
      variables: { faxIds: faxIds, serviceKey: serviceKey, emails: emails, subject: subject, message: message }
    }).pipe(map(result => (result.data as ForwardFaxToEmailResult)));
  }

  updateFolderSingleCache() {
    return from(this.apollo.client.refetchQueries({
      include: ["folderSingle"]
    }).results.at(0) as Promise<ApolloQueryResult<any>>);
  }

  fetchFaxSingleImages(serviceKey: string, faxId: string) {
    return this.apollo.query({
      query: faxSingleImages(0, 25),
      fetchPolicy: 'network-only',
      variables: { serviceKey: serviceKey, faxId: faxId }
    }).pipe(map((v: any) => v.data))
  }

  fetchFaxSingleComplete(serviceKey: string, faxId: string, pages: number) {
    return this.apollo.query<FaxSingleResult>({
      query: faxSingleComplete(pages),
      variables: { serviceKey: serviceKey, faxId: faxId }
    }).pipe(map(r => r.data))
  }
}
