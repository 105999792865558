import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class LoadingService {
  private isLoading$$ = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoading$$.asObservable();

  setLoading(isLoading: boolean) {
    //Solving Angular issue NG0100
    Promise.resolve().then(() => {
      this.isLoading$$.next(isLoading);
    });
  }

  get isLoading(): boolean {
    return this.isLoading$$.getValue();
  }
}
