import { Injectable } from "@angular/core";
import { catchError, switchMap, timeoutWith } from "rxjs/operators";
import { Observable, of, Subject, throwError, TimeoutError } from "rxjs";
import { LoginEffects } from "../../store/effects/login.effects";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { RECAPTCHA_MUTATION } from "../../api/login.mutation";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { RecaptchaV3Component } from "./recaptchaV3.component";
import { environment } from "../../../../environments/environment";
import { RecaptchaModel } from "./recaptcha-model";
import { ApolloIntportal } from "../../../shared/apollo-intportal";

@Injectable()
export class RecaptchaService<T extends RecaptchaModel> {

  private _afterClosed = new Subject<T>();
  private _ifSkipped = new Subject<T>();
  private skipRecaptcha = false;
  private skipCaptchaAutomationList: string[];

  constructor(
    private apollo: ApolloIntportal,
    public dialog: MatDialog,
    private recaptchaV3Service: ReCaptchaV3Service) {

    this.skipRecaptcha = environment.skipRecaptcha;
    this.skipCaptchaAutomationList = environment.skipCaptchaAutomationList;
  }

  get afterClosed(): Observable<T> {
    return this._afterClosed.asObservable();
  }
  get ifSkipped(): Observable<T> {
    return this._ifSkipped.asObservable();
  }

  displayIfNeed(recaptchaModel: T) {
    const shouldSkipRecaptchaForUser = this.skipCaptchaAutomationList.indexOf(recaptchaModel.skipCaptcha || "") !== -1;

    if (this.skipRecaptcha || shouldSkipRecaptchaForUser) {
      this._ifSkipped.next(recaptchaModel)
      return;
    }

    const token = this.recaptchaV3Service.execute('loginAction')
      .pipe(
        timeoutWith(4000, throwError(() => new TimeoutError())),
        switchMap(res => {
          return this.verifyToken(res);
        }),
        catchError(err => {
          return of(LoginEffects.loginErrorDefault)
        })
      )
      .subscribe((res: any) => {
        if ([undefined, null].includes(res)) {
          this.showRecaptchaLoadFailDialog();
          return
        }
        const response = JSON.parse(JSON.stringify(Object.assign({}, res)));
        if (parseFloat(response.data?.reCaptchaVerification?.score) < 0.7) {
          this.renderizarReCaptcha(recaptchaModel);
        } else {
          this._ifSkipped.next(recaptchaModel)
        }
      });
  }

  getToken() {
    return this.recaptchaV3Service.execute('loginAction')
  }

  private verifyToken(token: string) {
    return this.apollo.mutate({
      mutation: RECAPTCHA_MUTATION,
      variables: { reCaptchaClient: token }
    });
  }

  private renderizarReCaptcha(recaptchaModel: T) {
    const matDialogRef = this.getRecaptchaComponent(true)

    matDialogRef.afterOpened().subscribe(() => {
      matDialogRef.componentInstance.show()
    })

    matDialogRef.afterClosed().subscribe(() => {
      this._afterClosed.next(recaptchaModel)
    })
  }

  private showRecaptchaLoadFailDialog() {
    const matDialogRef = this.getRecaptchaComponent(false)

    matDialogRef.afterOpened().subscribe(() => {
      matDialogRef.componentInstance.isError = true
      matDialogRef.componentInstance.show()
    })
  }

  private getRecaptchaComponent(disableClose: boolean): MatDialogRef<RecaptchaV3Component> {
    return this.dialog.open(RecaptchaV3Component, {
      backdropClass: 'cdk-overlay-transparent-backdrop',
      hasBackdrop: true,
      disableClose: disableClose,
    });
  }

}
