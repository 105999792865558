import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { BrandService } from 'src/app/shared/services/brand.service';

@Component({
  selector: 'error-login-component',
  templateUrl: './error-login.component.html',
  styleUrls: ['./error-login.component.scss']
})
export class ErrorLoginComponent implements OnInit, AfterViewInit {
  error_code: string | null = null;
  error_msg: string | null = null;
  brandConfig: any = undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private brandService: BrandService) {
  }
  ngAfterViewInit(): void {
    /* const counting = this.window.
      setTimeout(() => {
        clearTimeout(counting);
        this.logout();
      }, 5000); */
  }

  /* private logout() {
    this.authService.timerTriggered = false;
    this.bnIdle.stopTimer();
    const portalSessionID = localStorage.getItem("portalSessionID") || "";
    if (portalSessionID !== undefined) {
      try {
        this.authService.webMyAccountLogout(portalSessionID);
        sessionStorage.clear();
      } catch (err) {
        console.log(`Error legacy logout`, err);
      }
    }
    this.loginService.logOut();
  } */

  redirectToLogin() {
    // this.logout();
    this.router.navigate(["/login"]);
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((value: ParamMap) => {
      this.error_code = value.get("error_code");
      this.error_msg = value.get("error_msg");
    });

    this.brandService.config$
      .pipe(take(1))
      .subscribe((config: any) => {
        this.brandConfig = config.name;
      });
  }
}
