import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ComposeAction, ComposeModel, FaxCreate, ForwardFaxMultiple, ForwardFaxMultipleFaxesResult, ForwardFaxSingle, ForwardFaxSingleFaxResult } from '../models/compose.model';
import { contactComposeStart, createFaxStart, forwardFaxMultipleSend, forwardFaxMultipleStart, forwardFaxSingleSend, forwardFaxSingleStart, replyComposeStart, setDefaultState, startingFaxCreation, updatingFaxCreation } from '../store/actions/compose.actions';
import * as fromFeature from '../store/reducers/compose.reducer';
import {
  selectFaxCreate, selectFaxCreateBeingSent,
  selectFaxCreatedSucceed, selectFaxCreatedError, selectFormAction, selectFaxForwardSingle, selectFaxForwardMultiple
} from '../store/selectors/compose.selector';
import { from, Observable } from 'rxjs';
import { ComposeApi } from '../api/compose.api';
import { map, tap } from 'rxjs/operators';
import { Fax } from 'src/app/view-messages/models/fax.model';
import { Contact } from 'src/app/contacts/models/contact.model';
import { Recipient } from '../models/recipient.model';
import { ForwardRecipient } from '../models/recipient.model';

@Injectable()
export class ComposeFacadeService {

  composeModel$: Observable<ComposeModel | undefined>;
  faxCreated$: Observable<FaxCreate | undefined>;
  isBusy$: Observable<boolean>;
  isFaxSent$: Observable<boolean | undefined>;
  isFaxError$: Observable<boolean | undefined>;
  formAction$: Observable<ComposeAction | undefined>;
  faxForwardSingle$: Observable<ForwardFaxSingleFaxResult | undefined>;
  faxForwardMultiple$: Observable<ForwardFaxMultipleFaxesResult | undefined>;

  constructor(private readonly store: Store<fromFeature.ComposeState>,
    private composeApi: ComposeApi) {
    this.faxCreated$ = store.pipe(select(selectFaxCreate));
    this.isBusy$ = store.pipe(select(selectFaxCreateBeingSent));
    this.isFaxSent$ = store.pipe(select(selectFaxCreatedSucceed));
    this.isFaxError$ = store.pipe(select(selectFaxCreatedError));
    this.formAction$ = store.pipe(select(selectFormAction));
    this.faxForwardSingle$ = store.pipe(select(selectFaxForwardSingle));
    this.faxForwardMultiple$ = store.pipe(select(selectFaxForwardMultiple));
  }

  startFaxCreation(formData: ComposeModel, serviceKey: string) {
    this.store.dispatch(createFaxStart(
      {
        serviceKey: serviceKey,
        input: {
          apiClientIpAddress: "127.0.0.1",
          resolution: formData.additionalSettings.faxQuality,
          toName: `${formData.recipients[0].firstName} ${formData.recipients[0].lastName}`,
          toCompany: formData.recipients[0].company,
          subject: formData.coverPage?.coverTitle ?? "Subject",
          billingCode: serviceKey,
          clientCode: serviceKey,
          clientId: "clientid",
          clientMatter: formData.coverPage?.coverTitle ?? "Matter",
          clientName: `${formData.recipients[0].firstName} ${formData.recipients[0].lastName}`,
          clientReferenceId: formData.additionalSettings?.referenceId ?? "xyz"
        }
      }));
  }

  replyCompose(fax: Fax, includeAttachments: boolean = false) {
    let model = this.createComposeModel(fax);
    if (includeAttachments) {
      model = this.attachOriginalFiles(model, fax);
    }
    this.store.dispatch(replyComposeStart({ model, faxId: fax.faxId, serviceKey: fax.serviceKey }));
  }

  private attachOriginalFiles(model: ComposeModel, fax: Fax): ComposeModel {
    let newModel = { ...model, keepOriginalAttachments: true }
    newModel.originalAttachments = []

    if (fax.images) {
      fax.images.forEach(element => {
        const fileName = element.imageUrl.split('?')[0].split('/').pop() || "";
        newModel.originalAttachments?.push({ fileName: fileName, url: element.imageUrl, faxId: fax.faxId, size: 0 })
      });
    } else {
      fax.thumbnails.forEach(element => {
        const fileName = element.imageUrl.split('?')[0].split('/').pop() || "";
        newModel.originalAttachments?.push({ fileName: fileName, url: element.imageUrl, faxId: fax.faxId, size: 0 })
      });
    }
    return newModel;
  }

  private attachOriginalFilesForMultipleFaxes(model: ComposeModel, faxes: Fax[]): ComposeModel {
    let newModel = { ...model, keepOriginalAttachments: true }

    newModel.originalAttachments = []
    faxes.forEach(fax => {
      if (fax.images) {
        fax.images.forEach(element => {
          const fileName = element.imageUrl.split('?')[0].split('/').pop() || "";
          newModel.originalAttachments?.push({ fileName: fileName, url: element.imageUrl, faxId: fax.faxId, size: 0 })
        });
      } else {
        fax.thumbnails.forEach(element => {
          const fileName = element.imageUrl.split('?')[0].split('/').pop() || "";
          newModel.originalAttachments?.push({ fileName: fileName, url: element.imageUrl, faxId: fax.faxId, size: 0 })
        });
      }
    });

    return newModel;
  }

  forwardCompose(fax: Fax) {
    let model = this.createComposeModel(fax, true);
    model = this.attachOriginalFiles(model, fax);

    this.store.dispatch(forwardFaxSingleStart({
      model: model,
      faxId: fax.faxId,
      serviceKey: fax.serviceKey
    }));
  }

  forwardMultipleCompose(faxes: Fax[]) {
    let composeModel = this.createComposeModelForMultipleRecipients(faxes);
    composeModel = this.attachOriginalFilesForMultipleFaxes(composeModel, faxes);

    const faxIds = faxes.map(fax => fax.faxId);

    const recipients: ForwardRecipient[] = [];

    faxes.forEach(fax => {
      recipients.push({
        name: fax.outboundData.clientName,
        company: fax.outboundData.toCompany,
        fax_number: fax.originatingFaxNumber,
      });
    });

    const serviceKey: string = faxes[0].serviceKey;

    const multipleFaxModel: ForwardFaxMultiple = {
      faxIds: faxIds,
      recipients: recipients,
      serviceKey: serviceKey,
      outboundServiceKey: 'string',
      fromEmail: 'string',
      note: undefined,
      subject: undefined,
    }

    this.store.dispatch(forwardFaxMultipleStart({
      input: multipleFaxModel,
      faxIds: faxIds,
      serviceKey: serviceKey,
      model: composeModel
    }));
  }

  composeForContact(contact: Contact) {
    const model = this.createComposeModelForContact(contact)
    this.store.dispatch(contactComposeStart({ model }));
  }

  private createComposeModelForContact(contact: Contact) {
    return {
      currentRecipient: {
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        fax: '',
        countryCode: '',
        saveContact: false
      },
      recipients: [{
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        company: contact.company,
        fax: contact.fax,
        countryCode: contact.countryCode,
        saveContact: false
      }],
      coverPage: {
        includeCoverPage: false,
        coverTitle: "",
        coverText: "",
      },
      additionalSettings: {
        faxQuality: 'FINE',
        sendEmailAddress: '',
        referenceId: ''
      }
    };
  }

  private createComposeModel(fax: Fax, forwarding: boolean = false): ComposeModel {
    return {
      currentRecipient: {
        firstName: !forwarding ? fax.outboundData.clientName : '',
        lastName: '',
        email: '',
        company: !forwarding ? fax.outboundData.toCompany : '',
        fax: !forwarding ? fax.originatingFaxNumber : '',
        countryCode: '',
        saveContact: false
      },
      recipients: [],
      coverPage: {
        includeCoverPage: false,
        coverTitle: '',
        coverText: ''
      },
      additionalSettings: {
        faxQuality: 'FINE',
        sendEmailAddress: '',
        referenceId: ''
      }
    };
  }

  private createComposeModelForMultipleRecipients(faxes: Fax[]): ComposeModel {

    const recipients: Recipient[] = [];

    const model = {
      currentRecipient: {
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        fax: '',
        countryCode: '',
        saveContact: false
      },
      recipients: recipients,
      coverPage: {
        includeCoverPage: false,
        coverTitle: '',
        coverText: ''
      },
      additionalSettings: {
        faxQuality: 'FINE',
        sendEmailAddress: '',
        referenceId: ''
      }
    };

    return model;
  }

  updateFormData(data: ComposeModel) {
    this.store.dispatch(updatingFaxCreation({ model: data }));
  }

  uploadToAWSS3(faxCreated: FaxCreate, serviceKey: string, model: ComposeModel, files: File[]) {
    return from(this.composeApi.zipFiles(serviceKey, model, files))
      .pipe(
        // tap(zipFile => {
        //   const url = window.URL.createObjectURL(zipFile);
        //   window.open(url, "_blank");
        // }),
        map(zipped =>
          this.composeApi.uploadResourcesToS3(faxCreated.fax.faxId, faxCreated.s3Url, zipped))
      );
  }

  downloadFile(url: string) {
    return this.composeApi.downloadFileFromUrl(url);
  }

  sendForwardFaxSingle(faxSingle: ForwardFaxSingle) {
    this.store.dispatch(forwardFaxSingleSend({ input: faxSingle }));
  }

  sendForwardFaxMultiple(faxMultiple: ForwardFaxMultiple) {
    this.store.dispatch(forwardFaxMultipleSend({ input: faxMultiple }));
  }
  sendFax(formData: any) {
    return this.composeApi.sendFaxUsingMyAccount(formData);
  }
  generateSendToken(sessionHashId: string) {
    return this.composeApi.generateSendToken(sessionHashId);
  }
  dispatchSetInitialState() {
    this.store.dispatch(setDefaultState());
  }
}

