import {
    ActionReducerMap
} from '@ngrx/store';
import * as fromLoginState from '../state/login.state';
import * as fromLoginReducer from './login.reducer';
import * as fromRoot from '../../../auth/store/reducers';
import { LoginAction } from '../actions/login.actions';

export interface LoginState {
    logged: fromLoginState.State;
}

export interface State extends fromRoot.AppState {
    loggedState: LoginState;
}

export const reducers: ActionReducerMap<LoginState, typeof LoginAction> = {
    logged: fromLoginReducer.reducer
};
