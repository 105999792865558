import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { compare } from 'src/app/shared/functions/compare';

export interface BillingSummary {
  date: string;
  description: string;
  period: string;
  number: string;
  amount: number;
}

@Component({
  selector: 'myportal-summary-table',
  templateUrl: './summary-table.component.html',
  styleUrls: ['./summary-table.component.scss']
})
export class SummaryTableComponent implements AfterViewInit {
  @ViewChild('paginator') paginator: MatPaginator;
  displayedColumns: string[] = ['date', 'description', 'period', 'number', 'amount'];
  @Input()
  dataList: BillingSummary[] = [];
  @Input() dataSource: MatTableDataSource<BillingSummary>;

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a: BillingSummary, b: BillingSummary) => {
      const key: string = sort.active;
      const direction = sort.direction === 'asc';
      return compare(a[key as keyof BillingSummary], b[key as keyof BillingSummary], direction);
    });
  }

}
