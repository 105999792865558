<h1 class="title-dialog" mat-dialog-title align="center">{{ 'view-messages.delete.fax' | transloco | titlecase  }}</h1>
<div mat-dialog-content>
  <label id="folder-list-label">{{ data.msg | transloco }}</label>
</div>
<div mat-dialog-actions align="end">
  <label hidden id="cancel-delete-folder-label">{{ 'contacts.cancel' | transloco }}</label>
  <label hidden id="confirm-delete-folder-label"></label>
  <button mat-button class="btn btn-md"  (click)="cancel()" aria-labelledby="cancel-delete-folder-label">{{ 'contacts.cancel' | transloco
    }}</button>
  <button mat-button class="btn btn-info btn-md" [mat-dialog-close]="'confirm'" cdkFocusInitial
    aria-labelledby="confirm-delete-folder-label">{{ 'contacts.confirm' | transloco }}</button>
</div>
