import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'myportal-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {
  ngOnInit(): void {
    this.inputFrom?.setValue(this.start);
    this.inputTo?.setValue(this.end);
  }
  @Input() start!: string;
  @Input() startLabel!: string;
  @Input() end!: string;
  @Input() endLabel!: string;
  @Input() minForStart!: string;
  @Input() maxForStart!: string;
  @Input() minForEnd!: string;
  @Input() maxForEnd!: string;
  @Output() startChange = new EventEmitter<string>();
  @Output() endChange = new EventEmitter<string>();
  @Output() buttonClick = new EventEmitter();

  range = new FormGroup<rangeForm>({
    start: new FormControl(this.start, Validators.required),
    end: new FormControl(this.end, Validators.required)
  })

  get inputFrom() {
    return this.range.get('start');
  }

  get inputTo() {
    return this.range.get('end');
  }

  get isValid() {
    return this.inputFrom?.valid && this.inputTo?.valid
  }

  onClick() {
    this.buttonClick.emit();
  }

  // @ViewChild('startDate') startDateRef!: ElementRef;
  // @ViewChild('endDate') endDateRef!: ElementRef;
}

interface rangeForm {
  start: FormControl<string | null>;
  end: FormControl<string | null>;
}
