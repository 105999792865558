import {Inject, Injectable, Optional} from '@angular/core';
import {GoogleTagManagerConfig} from './google-tag-manager-config';
import {GoogleTagEvent} from "./google-tag-event";
import {GoogleTagHit} from "./google-tag-hit";
import {GoogleTagManagerConfiguration} from "./google-tag-manager-config.service";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  private isLoaded = false;
  private isFirstLoad = true;
  private readonly _config: GoogleTagManagerConfig | null;
  private readonly _scriptId = "GTMscript"

  private browserGlobals = {
    windowRef(): any {
      return window;
    },
    documentRef(): any {
      return document;
    },
  };

  constructor(
    @Optional()
    @Inject(GoogleTagManagerConfiguration)
    public googleTagManagerConfiguration: GoogleTagManagerConfiguration,
    @Optional() @Inject('googleTagManagerId') public googleTagManagerId: string,
  ) {
    this._config = this.googleTagManagerConfiguration?.get();
    if (this._config == null) {
      this._config = {id: null};
    }

    this._config = {
      ...this.config,
      id: googleTagManagerId || this.config.id,
    };
    if (this.config.id == null) {
      return;
    }
  }

  public removeGtmFromDom() {
    try {
      const doc = this.browserGlobals.documentRef();
      doc.getElementById(this._scriptId).remove()
    } catch (e) {
      console.warn(`Can not remove: '${this._scriptId}'`);
    }
  }

  public addGtmToDom(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.isLoaded) {
        return resolve(this.isLoaded);
      } else if (!this.checkForId()) {
        return resolve(false);
      }
      const doc = this.browserGlobals.documentRef();

      if (this.isFirstLoad) {
        this.pushOnDataLayer({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        });
        this.isFirstLoad = false
      }

      const gtmScript = doc.createElement('script');
      gtmScript.id = this._scriptId;
      gtmScript.async = true;
      gtmScript.src = this.applyGtmQueryParams(
        this.config.gtm_resource_path
          ? this.config.gtm_resource_path
          : 'https://www.googletagmanager.com/gtm.js'
      );
      gtmScript.addEventListener('load', () => {
        return resolve((this.isLoaded = true));
      });
      gtmScript.addEventListener('error', () => {
        return reject(false);
      });
      doc.head.insertBefore(gtmScript, doc.head.firstChild);
    });
  }

  public logEvent(googleTagHit: GoogleTagHit,
                  force: boolean = false) {
    this.pushTag(googleTagHit, force);
  }

  public logPageView(url: string, force: boolean = false) {
    const gtmTag = {
      event: GoogleTagEvent.CONTENT_VIEW,
      pageName: url
    };
    this.pushTag(gtmTag, force)
  }

  protected pushTag(item: object, force: boolean = false): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!this.checkForId()) {
        return resolve();
      }

      item = {
        ...item,
        env: environment.name
      }

      if (this.isLoaded && force){
        this.removeGtmFromDom()
        this.isLoaded = false
      }

      if (!this.isLoaded) {
        this.addGtmToDom()
          .then(() => {
            this.pushOnDataLayer(item);
            return resolve();
          })
          .catch(() => reject());
      } else {
        this.pushOnDataLayer(item);
        return resolve();
      }
    });
  }

  private applyGtmQueryParams(url: string): string {
    if (url.indexOf('?') === -1) {
      url += '?';
    }

    return (
      url +
      Object.keys(this.config)
        .filter((k) => this.config[k])
        .map((k) => `${k}=${this.config[k]}`)
        .join('&')
    );
  }

  public getDataLayer(): any[] {
    const window = this.browserGlobals.windowRef();
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer;
  }

  private get config(): GoogleTagManagerConfig {
    if (!this._config) {
      throw new Error('Google tag manager config not provided.');
    }
    return this._config;
  }

  private checkForId(): boolean {
    if (!this.config.id) {
      throw new Error("Can not init data layer. GTM is missing.")
    }
    return true;
  }

  private pushOnDataLayer(obj: object): void {
    const dataLayer = this.getDataLayer();
    dataLayer.push(obj);
  }
}
