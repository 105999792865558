import { Component, Input } from '@angular/core';
@Component({
  selector: 'snack-success',
  templateUrl: './snack.success.html',
  styleUrls: ['./snack.success.scss']
})
export class SnackSuccess {
  @Input() 
  message: string;
  
  dissmiss(): void {}
}