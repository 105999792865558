import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BillingService, CardDetails } from '../../services/billing.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'myportal-add-payment',
  templateUrl: './add-payment.component.html',
  styleUrls: ['./add-payment.component.scss']
})
export class AddPaymentComponent implements OnInit {
  @Input() cardDetails: CardDetails | null;
  @Input() cardType: string | null = '';
  @Output() closeForm = new EventEmitter<CardDetails | null>();
  @Output() newCardAdded = new EventEmitter<CardDetails>();
  selectedPaymentType: string;
  errorFormBlank: boolean = false;
  minDate: Date = new Date();
  maxDate: Date = new Date(new Date().getFullYear() + 20, 11, 31);

  constructor(
    private snackBar: MatSnackBar,
    private translocoService: TranslocoService,
    private billingService: BillingService,
  ) {}

  ngOnInit(): void {
    if (!this.cardDetails) {
      this.resetForm();
    }
    this.selectedPaymentType = this.cardType || 'creditCard';
  }

  resetForm() {
    this.cardDetails = {
      name: '',
      number: '',
      expDate: '',
      cvc: '',
      billingAddress: '',
      city: '',
      zipCode: '',
      state: 'California', 
      country: 'USA'
    };
  }

  savePaymentMethod() {
    this.errorFormBlank = false;
    if (this.cardDetails && this.areAllFieldsFilled()) {
      const msgSuccess = this.translocoService.translate("billing.method.added.saved");
      this.snackBar.open(msgSuccess, 'X', {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000
      });
      this.billingService.addNewCard(this.cardDetails);
      this.newCardAdded.emit(this.cardDetails);
      this.closeForm.emit(this.cardDetails);
      this.close();
    } else {
      this.errorFormBlank = true;
    }
  }

  areAllFieldsFilled(): boolean {
    return !!this.cardDetails &&
           !!this.cardDetails.name &&
           !!this.cardDetails.number &&
           !!this.cardDetails.expDate &&
           !!this.cardDetails.cvc &&
           !!this.cardDetails.billingAddress &&
           !!this.cardDetails.city &&
           !!this.cardDetails.zipCode &&
           !!this.cardDetails.state;
  }

  updateSelection(value: string): void {
    this.selectedPaymentType = value;
  }

  chosenYearHandler(normalizedYear: Date, datepicker: MatDatepicker<Date>) {
    if (this.cardDetails) {
      const value = new Date();
      value.setFullYear(normalizedYear.getFullYear(), value.getMonth(), 1); 
      this.cardDetails.expDate = this.formatDateToString(value)
      datepicker.startAt = value;
      datepicker.open();
    }
  }

  chosenMonthHandler(normlizedMonth: Date, datepicker: MatDatepicker<Date>) {
    if (this.cardDetails) {
      const value = new Date(normlizedMonth.getFullYear(), normlizedMonth.getMonth(), 1);
      this.cardDetails.expDate = this.formatDateToString(value);
      datepicker.close();
    }
  }

  formatDateToString(date: Date): string {
    return `${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  close() {
    this.closeForm.emit();
    this.resetForm();
    this.errorFormBlank = false;
  }
}