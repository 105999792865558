import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { DEFAULT_BRAND_NAME, ENABLED_BRANDS } from '../constants/constants';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class BrandService {
  config$ = new BehaviorSubject<any>(undefined);
  private baseURL = environment.eFaxUrl;

  constructor(private http: HttpClient) { }

  setConfig(brandName: string) {
    this.http.get<any>(`assets/brands/${brandName}.json`).pipe(take(1)).subscribe(file => {
      this.config$.next(file);
    });
  }

  discoverBrand(hostnameSlices: string[]) {
    const enabledBrands = [...ENABLED_BRANDS];
    enabledBrands.sort((a, b) => b.length - a.length);
    for (const slice of hostnameSlices) {
      const found: string | undefined = enabledBrands.find(brand => brand === slice || slice.includes(brand));
      if (found) {
        return found;
      }
    }
    return DEFAULT_BRAND_NAME;
  }

  get brandName(): string{
    const hostname: string[] = location.hostname.split(".");
    return this.discoverBrand(hostname)
  }

  getBrandLinks(brandId: string) {
    switch (brandId) {
      case 'efax':
        return {
          termsUrl: this.baseURL + 'legal#agreement',
          privacyUrl: this.baseURL + 'legal#privacy'
        };
      case 'myfax':
        return {
          termsUrl: this.baseURL.replace('efax', 'myfax') + 'legal/terms',
          privacyUrl: this.baseURL.replace('efax', 'myfax') + 'legal/privacy'
        };
      case 'rapidfax':
        return {
          termsUrl: `${this.baseURL}${brandId}/customer-agreement`,
          privacyUrl: `${this.baseURL}${brandId}/privacy`
        };
      default:
        return {
          termsUrl: `${this.baseURL}${brandId}/customer-agreement`,
          privacyUrl: `${this.baseURL}${brandId}/privacy-policy`
        };
    }
  }

}

