import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Contact} from "../../../contacts/models/contact.model";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {FormatPhoneNumberPipe} from "../../../shared/pipes/format-phone-number.pipe";
import {AbstractControl} from "@angular/forms";
import {Recipient} from "../../models/recipient.model";

@Component({
  selector: 'recipients',
  templateUrl: './recipients.component.html',
  styleUrls: ['./recipients.component.scss'],
  animations: [
    trigger('showHideRow', [
      state('close',
        style({
          marginTop: '-9%',
        }),
      ),
      state('open',
        style({
          marginTop: '0px'
        }),
      ),
      transition('close <=> open', [
        style({
          display: 'block'
        }),
        animate('.5s ease')
      ]),

      transition(':enter', [
        style({
          marginTop: '-8%',
          position: 'relative',
          zIndex: -1
        }),
        animate('500ms ease-in-out',
          style({
            marginTop: '0px'
          })
        )
      ]),
      transition(':leave',
        [
          style({opacity: 1}),
          animate('500ms',
            style(
              {
                opacity: 0
              })
          )
        ]
      )
    ])
  ]
})
export class RecipientsComponent {

  @Input() contactList: AbstractControl<Recipient>[]
  @Input() maxAvailableSelection: number
  @Output() contactListChange = new EventEmitter<AbstractControl<Recipient>[]>();
  protected hideSection: boolean = true;

  constructor(
    private formatPhoneNumberPipe: FormatPhoneNumberPipe,
  ) {}

  get contactListCount(){
    return this.contactList?.length ?? 0
  }

  removeRecipient(index: number) {
    if (index > -1) {
      this.contactList.splice(index, 1);
    }
    this.contactListChange.emit(this.contactList)
  }

  formatName(contact: AbstractControl<Recipient>) {
    const { firstName = '', lastName = '', company = '', fax = '', countryCode = '' } = contact.value;

    const displayResult = [
      countryCode,
      this.formatPhoneNumberPipe.transform(fax, ""),
      firstName?.substring(0, Math.min(firstName?.length, 20)),
      lastName?.substring(0, Math.min(lastName?.length, 20))];
    if (company && company.length > 0) {
      displayResult.push('-', company.substring(0, Math.min(company.length, 20)));
    }
    return displayResult.join(' ');
  }
}
