import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { Contact, ContactListParams, ContactSortBy } from '../../models/contact.model';
import { ContactsFacadeService } from '../../services/contacts-facade.service';
import { DialogContactDelete } from '../delete-contact-dialog.component';
import { ComposeFacadeService } from 'src/app/compose/services/compose-facade.service';
import { NOT_EMPTY, FEEDBACK_DURATION, EMAIL_PATTERN, FAX_NUMBER_PATTERN } from 'src/app/shared/constants/constants';
import { ContactOperationStatus, SortDirection } from '../../models/shared.model';
import { LIMIT_TOTAL_COUNT_DEFAULT, OFFSET_DEFAULT } from '../../models/table.model';
import { countries } from '../../../compose/models/countries.data-store';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthFacadeService } from 'src/app/auth/services/auth-facade.service';

@Component({
  selector: 'add-edit-contact',
  templateUrl: 'add-edit-contact.component.html',
  styleUrls: ['add-edit-contact.component.scss']
})
export class AddEditContactComponent implements OnInit, OnDestroy {

  formGroup: UntypedFormGroup;
  contact: Contact = this.emptyContact();
  addingFlag: boolean;
  editingFlag: boolean;
  serviceKey = "";
  private localSubscriptions: Subscription[] = [];
  hasComposePermission = false;
  hasFaxnumber = false;
  contactOperationStatus: ContactOperationStatus | undefined;
  public countries: any = countries;
  readonly terminated$: Subject<boolean> = new Subject<boolean>();
  data: string | undefined;
  userSuspendedFlag: number = 0;

  constructor(
    readonly contactsFacadeService: ContactsFacadeService,
    private formBuilder: UntypedFormBuilder,
    private authFacadeService: AuthFacadeService,
    public dialog: MatDialog,
    private readonly composeFacadeService: ComposeFacadeService,
    private router: Router
  ) {
    this.createForm();
    this.subscribeToCountryCodeChanges();
    this.subscribeToContactData();
    this.subscribeToContactOperationStatus();
    this.authFacadeService.authState$.pipe(map(s => s.suspendedFlag), takeUntil(this.terminated$)).subscribe(value => this.userSuspendedFlag = value);
  }

  ngOnInit() {
    this.subscribeToCurrentService();
  }

  ngOnDestroy(): void {
    this.localSubscriptions.forEach(s => s.unsubscribe());
    this.terminated$.next(true);
    this.terminated$.complete();
  }

  private subscribeToContactOperationStatus() {
    this.contactsFacadeService.contactOperationStatus$
      .pipe(
        filter(value => value !== undefined),
        takeUntil(this.terminated$)
      )
      .subscribe(value => {
        this.showOperationStatus(value);
      })
  }

  private subscribeToCurrentService() {
    this.localSubscriptions.push(
      this.authFacadeService.userSingle$
        .pipe(map(result => result?.userSingle?.user?.services), map(svcs => svcs?.filter(svc => svc.serviceType === "OUTBOUND_FAXING")))
        .subscribe(value => {
          if (value !== undefined) {
            this.serviceKey = value[0].serviceKey;
            this.hasComposePermission = value[0].emailAddresses.filter(em => em.trim() !== "")?.length > 0;
          }
        })
    );
  }

  private showOperationStatus(status: ContactOperationStatus | undefined) {
    this.contactOperationStatus = status;
    setTimeout(() => this.contactOperationStatus = undefined, FEEDBACK_DURATION);
  }

  selectAddEdit() {
    if (this.data === undefined) {
      this.addingFlag = true;
    } else {
      this.addingFlag = false;
      this.formGroup.disable();
    }
  }

  toggleEdit(): void {
    this.formGroup.enable();
    this.editingFlag = true;
    if (!this.isFaxRequired()) {
      this.formGroup.controls.fax.disable();
    }
  }

  checkFaxNumbervalue(evt: any) {
    if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57) {
      evt.preventDefault();
    }
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      firstName: [null, [Validators.pattern(NOT_EMPTY), Validators.minLength(2), Validators.maxLength(50), Validators.required]],
      lastName: [null, [Validators.pattern(NOT_EMPTY), Validators.minLength(2), Validators.maxLength(50)]],
      company: [null, [Validators.pattern(NOT_EMPTY), Validators.minLength(1), Validators.maxLength(100)]],
      countryCode: [null, [Validators.pattern(NOT_EMPTY), Validators.minLength(1), Validators.maxLength(100)]],
      fax: [{ value: undefined, disabled: true }, [
        Validators.pattern(FAX_NUMBER_PATTERN),
        Validators.minLength(11),
        Validators.maxLength(11)
      ]],
      email: [null, [
        Validators.required,
        Validators.pattern(NOT_EMPTY),
        Validators.email,
        Validators.pattern(EMAIL_PATTERN),
      ]]
    });
  }

  private subscribeToCountryCodeChanges() {
    this.formGroup.controls.countryCode.valueChanges
      .pipe(takeUntil(this.terminated$))
      .subscribe((value) => {
        const isCountryCodeSelected = !['', null, undefined].includes(value);
        if (isCountryCodeSelected) {
          this.setFaxNumberRequired();
          this.setFaxNumberPrefix();
          this.setEmailNotRequired();
        } else {
          this.setFaxNumberNotRequired();
          this.clearFaxNumber();
          this.setEmailRequired();
        }
      });
  }

  getCountryCodeFromDialCode() {
    const contryCode = this.formGroup.controls.countryCode.value;
    const country = countries.find( c => c.code === contryCode);
    if(country) {
      return country?.dial_code.replace(/^\D+/g, '');
    }
    return undefined;
  }

  setFaxNumberPrefix() {
    const countryCode = this.getCountryCodeFromDialCode();
    if(countryCode) {
      this.formGroup.controls.fax.setValue(countryCode);
    }
  }

  private clearFaxNumber() {
    this.formGroup.controls.fax.reset();
    this.formGroup.controls.fax.markAsPristine();
    this.formGroup.controls.fax.markAsUntouched();
  }

  private setEmailRequired() {
    this.formGroup.controls.email.addValidators([Validators.required, Validators.pattern(NOT_EMPTY)]);
    this.formGroup.controls.email.updateValueAndValidity({ emitEvent: false });
  }

  private setEmailNotRequired() {
    this.formGroup.controls.email.removeValidators([Validators.required, Validators.pattern(NOT_EMPTY)]);
    this.formGroup.controls.email.updateValueAndValidity({ emitEvent: false });
  }

  private setFaxNumberRequired() {
    this.formGroup.controls.fax.setValidators([Validators.required, Validators.pattern(FAX_NUMBER_PATTERN)]);
    this.formGroup.controls.fax.enable();
    this.formGroup.controls.fax.updateValueAndValidity({ emitEvent: false });
  }

  private setFaxNumberNotRequired() {
    this.formGroup.controls.fax.clearValidators();
    this.formGroup.controls.fax.disable();
    this.formGroup.controls.fax.updateValueAndValidity({ emitEvent: false });
  }

  isEmailRequired() {
    return this.formGroup.controls.email.hasValidator(Validators.required);
  }

  isFaxRequired() {
    return this.formGroup.controls.fax.hasValidator(Validators.required);
  }

  fillForm(contact: Contact) {
    this.formGroup.patchValue(contact);
    if (contact.fax !== null && contact.fax !== "") {
      this.hasFaxnumber = true;
    }
  }

  onSubmit(contact: Contact) {
    const newContact = {
      fax: (contact?.countryCode && contact?.fax) ? contact?.fax?.toString().trim() : '',
      contactId: contact?.contactId ? contact?.contactId?.trim() : '',
      firstName: contact?.firstName?.trim(),
      lastName: contact?.lastName?.trim(),
      company: contact?.company?.trim(),
      email: contact?.email?.trim(),
      countryCode: contact?.countryCode ? contact?.countryCode?.trim() : ''
    };

    if (this.addingFlag) {
      this.submitCreateNewContact(newContact);
    } else {
      this.submitUpdateContact(newContact)
    }
    this.formGroup.patchValue(newContact);
  }

  composeFax() {
    this.composeFacadeService.composeForContact(this.contact);
  }

  cancelAdd() {
    if (this.editingFlag == true) {
      this.formGroup.disable();
      this.editingFlag = false;
      if (this.data !== undefined) {
        this.getContactById(this.data);
      }
    } else {
      this.router.navigate(["/contacts"]);
    }
  }

  private getContactById(contactId: string) {
    this.contactsFacadeService.dispatchGetSingleContact({
      contactId: contactId
    });
  }

  private submitCreateNewContact(contact: Contact) {
    this.contactsFacadeService.dispatchCreateNewContact({
      input: contact
    });
    this.cancelAdd();
  }

  private submitUpdateContact(contact: Contact) {
    if (this.data !== undefined) {
      this.contactsFacadeService.dispatchUpdateContact({
        contactId: this.data,
        input: contact
      });
    }
    this.cancelAdd();
  }

  private subscribeToContactData() {
    this.contactsFacadeService.contactData$
      .pipe(
        filter(data => data !== undefined),
        takeUntil(this.terminated$)
      )
      .subscribe(value => {
        this.data = value?.contactId;
        this.contact = value?.selectedContact ?? this.emptyContact();
        this.fillForm(this.contact);
        this.selectAddEdit();
      })
  }

  private emptyContact(): Contact {
    return {
      contactId: '', firstName: '', lastName: '', fax: '', email: '', company: '', countryCode: ''
    }
  }

  startDeleteContact() {
    const dialog = this.dialog.open(DialogContactDelete, {
      backdropClass: 'cdk-overlay-transparent-backdrop',
      hasBackdrop: true,
      disableClose: true,
      data: {
        contacts: [{
          ...this.contact,
          name: this.contact.firstName ?
            this.contact.firstName + (this.contact.lastName ? " " + this.contact.lastName : "")
            : ""

        }]
      }
    });

    dialog.afterClosed().subscribe(resultList => {
      if (resultList) {
        for (const item of resultList) {
          this.submitDeleteContact(item.contactId)
        }
      }
    });
  }

  private submitDeleteContact(contactId: string) {
    const listOptions: ContactListParams = {
      limit: LIMIT_TOTAL_COUNT_DEFAULT,
      offset: OFFSET_DEFAULT,
      sortBy: ContactSortBy.FIRST_NAME,
      sortDirection: SortDirection.ASC
    }
    this.contactsFacadeService.dispatchDeleteContact({
      input: { id: contactId }, list: listOptions
    });
    this.cancelAdd();
  }

}
