import { createReducer, on } from '@ngrx/store';
import * as viewMessagesActions from '../actions/view-messages.actions';
import {Folder, FolderCreate, FolderList, FolderRename} from '../../models/folder.model';
import { ApiError } from '../../models/shared.model';
import { Fax, FaxList } from '../../models/fax.model';
import { FOLDER_DEFAULT_SELECTED } from '../../models/table.model';


export interface ViewMessagesState {
    errorMessage: ApiError | undefined;
    loadingFolderList: boolean;
    folderList: FolderList | undefined;
    loadingFaxList: boolean;
    faxList: FaxList | undefined;
    isCreatingFolder: boolean | undefined
    renameFolderSuccess: FolderRename | undefined,
    renameFolderError: ApiError | undefined,
    createFolderSuccess: FolderCreate | undefined,
    createFolderError: ApiError | undefined,

  isDeletingFolder: boolean | undefined
  deleteFolderSuccess: FolderCreate | undefined,
  deleteFolderError: ApiError | undefined,

    selectedFolder: Folder;
    selectedFaxes: Fax[] | undefined;
    tagList: string[] | undefined;

    currentPagination: any;

    updateFolderSingleCache: boolean
  }

  export const initialViewMessagesState: ViewMessagesState = {
    loadingFolderList: false,
    folderList: undefined,
    loadingFaxList: false,
    faxList: undefined,
    errorMessage: undefined,
    isCreatingFolder: undefined,
    renameFolderSuccess: undefined,
    createFolderSuccess: undefined,
    renameFolderError: undefined,
    createFolderError: undefined,
    isDeletingFolder: undefined,
    deleteFolderSuccess: undefined,
    deleteFolderError: undefined,
    selectedFolder: FOLDER_DEFAULT_SELECTED,
    selectedFaxes: undefined,
    tagList: undefined,
    updateFolderSingleCache: false,
    currentPagination: undefined,
  }

export const reducer = createReducer(
    initialViewMessagesState,
    on(
        viewMessagesActions.loadFolderListBegin,
        (state) =>
        ({
            ...state, folderList: undefined,
            loadingFolderList: true, isCreatingFolder: false, createFolderError: undefined,
            createFolderSuccess: undefined, isDeletingFolder: false, errorMessage: undefined,
            deleteFolderError: undefined, deleteFolderSuccess: undefined, selectedFaxes: undefined
        })
    ),
    on(
        viewMessagesActions.loadFolderListSuccess,
        (state, { folderList }) =>
        ({
            ...state, folderList: folderList,
            loadingFolderList: false
        })
    ),
    on(
        viewMessagesActions.loadFolderListFailed,
        (state, { errorMessage }) =>
        ({
            ...state, errorMessage: errorMessage,
            loadingFolderList: false
        })
    ),

    on(
        viewMessagesActions.loadFaxListBegin,
        (state) =>
        ({
            ...state, loadingFaxList: true, errorMessage: undefined,
            isCreatingFolder: false, createFolderError: undefined,
            createFolderSuccess: undefined, isDeletingFolder: false,
            deleteFolderError: undefined, deleteFolderSuccess: undefined, selectedFaxes: undefined
        })
    ),
    on(
        viewMessagesActions.loadFaxListSuccess,
        (state, { faxList }) =>
        ({
            ...state, faxList: faxList,
            loadingFaxList: false
        })
    ),
    on(
        viewMessagesActions.loadFaxListFailed,
        (state, { errorMessage }) =>
        ({
            ...state, errorMessage: errorMessage,
            loadingFaxList: false
        })
    ),

    on(
        viewMessagesActions.createNewFolderBegin,
        (state) =>
        ({
            ...state, isCreatingFolder: true, errorMessage: undefined, createFolderError: undefined
        })
    ),

    on(
        viewMessagesActions.renameFolderBegin,
        (state) =>
        ({
            ...state, isRenamingFolder: true,
          errorMessage: undefined,
          renameFolderError: undefined
        })
    ),

    on(
        viewMessagesActions.createNewFolderSuccess,
        (state, { payload }) =>
        ({
            ...state,
            isCreatingFolder: false,
            createFolderSuccess: payload?.folder,
            createFolderError: undefined
        })
    ),

    on(
        viewMessagesActions.renameFolderSuccess,
        (state, { payload }) =>
        ({
            ...state,
            isRenamingFolder: false,
            renameFolderSuccess: payload?.folder,
            renameFolderError: undefined
        })
    ),
    on(
        viewMessagesActions.createNewFolderFailed,
        (state, { errorMessage }) =>
        ({
            ...state, errorMessage: errorMessage,
            isCreatingFolder: false,
            createFolderError: errorMessage,
            createFolderSuccess: undefined
        })
    ),
    on(
        viewMessagesActions.renameFolderFailed,
        (state, { errorMessage }) =>
        ({
            ...state,
          errorMessage: errorMessage,
          isRenamingFolder: false,
          renameFolderError: errorMessage,
          renameFolderSuccess: undefined
        })
    ),

    on(
        viewMessagesActions.deleteFolderBegin,
        (state) =>
        ({
            ...state, isDeletingFolder: true,
            errorMessage: undefined,
            deleteFolderError: undefined, deleteFolderSuccess: undefined
        })
    ),
    on(
        viewMessagesActions.deleteFolderSuccess,
        (state, { payload }) =>
        ({
            ...state,
            isDeletingFolder: false,
            deleteFolderSuccess: payload?.folder,
            deleteFolderError: undefined, updateFolderSingleCache: true
        })
    ),
    on(
        viewMessagesActions.deleteFolderFailed,
        (state, { errorMessage }) =>
        ({
            ...state,
            errorMessage: errorMessage,
            isDeletingFolder: false,
            deleteFolderError: errorMessage,
            deleteFolderSuccess: undefined
        })
    ),
    on(viewMessagesActions.printingSelectedFaxes,
        (state, { selectedFaxes }) => ({
            ...state, selectedFaxes: selectedFaxes
        })
    ),
    on(viewMessagesActions.markFaxReadUnreadBegun,
        (state) => ({
            ...state,
            loadingFaxList: true
        })
    ),
    on(viewMessagesActions.markFaxReadUnreadEnded,
        (state) => ({
            ...state,
            loadingFaxList: false, updateFolderSingleCache: true
        })
    ),
    on(viewMessagesActions.deleteFaxSingleBegin,
        (state) => ({
            ...state,
            loadingFaxList: true
        })
    ),
    on(viewMessagesActions.deleteFaxSingleEnd,
        (state, { errorCode, errorMessage }) => ({
            ...state,
            loadingFaxList: false, updateFolderSingleCache: true,
            errorMessage: errorCode !== undefined && errorMessage !== undefined ? { object_type: 'ApiError', code: errorCode, message: errorMessage } : undefined
        })
    ),
    on(viewMessagesActions.deleteFaxListBegin,
        (state) => ({
            ...state,
            loadingFaxList: true
        })
    ),
    on(viewMessagesActions.deleteFaxListEnd,
        (state, { errorCode, errorMessage }) => ({
            ...state,
            loadingFaxList: false, updateFolderSingleCache: true,
            errorMessage: errorCode !== undefined && errorMessage !== undefined ? { object_type: 'ApiError', code: errorCode, message: errorMessage } : undefined
        })
    ),
    on(viewMessagesActions.createTagBegin,
        (state) => ({
            ...state,
            loadingFaxList: true, tagList: undefined
        })
    ),
    on(viewMessagesActions.createTagEnd,
        (state, { errorCode, errorMessage, tags }) => ({
            ...state,
            loadingFaxList: false, updateFolderSingleCache: true,
            errorMessage: errorCode !== undefined && errorMessage !== undefined ? { object_type: 'ApiError', code: errorCode, message: errorMessage } : undefined,
            tagList: tags
        })
    ),
    on(viewMessagesActions.deleteTagBegin,
        (state) => ({
            ...state,
            loadingFaxList: true, tagList: undefined
        })
    ),
    on(viewMessagesActions.deleteTagEnd,
        (state, { errorCode, errorMessage, tags }) => ({
            ...state,
            loadingFaxList: false, updateFolderSingleCache: true,
            errorMessage: errorCode !== undefined && errorMessage !== undefined ? { object_type: 'ApiError', code: errorCode, message: errorMessage } : undefined,
            tagList: tags
        })
    ),
    on(viewMessagesActions.downloadFaxStart,
        (state) => ({
            ...state,
            loadingFaxList: true
        })
    ),
    on(viewMessagesActions.downloadFaxEnd,
        (state) => ({
            ...state,
            loadingFaxList: false
        })
    ),
    on(viewMessagesActions.displaySingleFax,
        (state, { currentFax }) => ({
            ...state, selectedFaxes: [currentFax]
        })
    ),
    on(viewMessagesActions.reloadFaxSingleStart,
        (state) => ({ ...state, loadingFaxList: true, errorMessage: undefined, selectedFaxes: undefined })
    ),
    on(viewMessagesActions.reloadFaxSingleEnd,
        (state, { fax, errorCode, errorMessage }) => ({
            ...state, loadingFaxList: false,
            errorMessage: errorCode !== undefined && errorMessage !== undefined ? { object_type: 'ApiError', code: errorCode, message: errorMessage } : undefined,
            selectedFaxes: fax !== undefined ? [fax] : []
        })
    ),
    on(
        viewMessagesActions.reloadFolderStarts,
        (state) => ({ ...state, loadingFolderList: true, updateFolderSingleCache: false })
    ),
    on(
        viewMessagesActions.reloadFolderEnds,
        (state, { folder }) => ({ ...state, loadingFolderList: false, selectedFolder: folder !== undefined && folder.id !== undefined ? folder : state.selectedFolder })
    ),
    on(
        viewMessagesActions.moveFaxesSuccess,
        (state) => ({ ...state, updateFolderSingleCache: true })
    ),
    on(
        viewMessagesActions.refreshCacheFolder,
        (state) => ({ ...state, updateFolderSingleCache: false, loadingFaxList: true })
    ),
    on(
        viewMessagesActions.refreshCacheFolderEnds,
        (state, { folder }) => ({ ...state, selectedFolder: folder !== undefined ? folder : state.selectedFolder, loadingFaxList: false })
    ),
    on(
      viewMessagesActions.setCurrentPagination,
      (state, { pageIndex, pageNumber, currentOffset }) =>
      ({
          ...state,
          currentPagination: {
            pageIndex: pageIndex,
            pageNumber: pageNumber,
            currentOffset: currentOffset
          }
      })
  ),
);

export const getViewMessagesState = (state: ViewMessagesState) => state;
