import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { AuthFacadeService } from '../services/auth-facade.service';
import { ApiError } from '../models/api.error.model';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private facade: AuthFacadeService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.match(/\/graphql/)) {
      return this.facade.token$.pipe(
        take(1),
        mergeMap(token => {
          const authReq = !!token ? request.clone({
            setHeaders: { Authorization: 'Bearer ' + token },
          }) : request;
          return next.handle(authReq).pipe(
            tap((event: HttpEvent<any>) => {
              if (event instanceof HttpResponse && event.body.data) {
                for (const [key, value] of Object.entries(event.body.data)) {
                  if (value) {
                    const errorVar = value as ApiError;
                    if (errorVar?.object_type === 'API_ERROR' && errorVar?.code === '401' && errorVar?.message === 'ACCESS_TOKEN_EXPIRED') {
                      console.log('renewing token');
                      this.facade.refreshToken();
                      break;
                    }
                  }
                }
              }
            })
          )
        })
      )
    } if (request.url.match(/\/api/)) {
      return this.facade.fusionToken$.pipe(
        take(1),
        mergeMap(token => {
          const customReq = !!token ? request.clone({
            setHeaders: { Authorization: `Bearer ${token}` }
          }) : request;
          return next.handle(customReq);
        })
      )
    } else {
      return next.handle(request);
    }
  }
}
