import { Injectable } from '@angular/core';

@Injectable()
export class CsvService {

  export(filename: string, headers: string[], keys: string[], rows: any[],) {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ",";

    if (keys.length === 0) {
      keys = Object.keys(rows[0]);
    }

    let columHearders: string[];

    if (headers) {
      columHearders = headers;
    } else {
      columHearders = keys;
    }

    const csvHeader = columHearders.join(separator);

    let csvBody = "";

    rows.forEach(row => {
      keys.forEach((key, index) => {
        csvBody += row[key] || '';
        if (index < keys.length - 1) {
          csvBody += separator;
        }
      });
      csvBody += '\n';
    });

    const csvContent = csvHeader.concat('\n').concat(csvBody);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename.concat('.csv'));
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
