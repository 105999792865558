import {
    createFeatureSelector,
    createSelector
} from '@ngrx/store';
import { LoginState } from '../reducers';
import * as fromLoginReducer from '../reducers/login.reducer';

export const selectLoginState = createFeatureSelector<LoginState>("loggedState");

export const selectLogged = createSelector(
    selectLoginState,
    state => state.logged
);

export const loginPageViewModel = createSelector(
    selectLogged,
    fromLoginReducer.getLoginState
);

export const failedAttemptsSelector = createSelector(selectLoginState, state => state.logged?.failedAttempts);
