import { Component, OnDestroy, Output, EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { AuthFacadeService } from 'src/app/auth/services/auth-facade.service';
import { IDLE_WAITING_CONFIRMATION_TIME } from "../constants/constants";

@Component({
  selector: 'myportal-idle',
  templateUrl: './idle.component.html',
  styleUrls: ['./idle.component.scss']
})
export class IdleComponent implements OnDestroy, OnInit {

  @Output() continue = new EventEmitter();
  @Output() cancel = new EventEmitter();
  private _counter: number = IDLE_WAITING_CONFIRMATION_TIME;

  sessionMessage: string;
  continueMessage: string;
  cancelMessage: string;
  closingMessage: string;
  secondsMessage: string;

  constructor(public dialogRef: MatDialogRef<IdleComponent>,
    private authFacadeService: AuthFacadeService, private translocoService: TranslocoService) {
    this.sessionMessage = this.translocoService.translate('session.expiration.message');
    this.continueMessage = this.translocoService.translate('button.continue');
    this.cancelMessage = this.translocoService.translate('button.logout');
    this.closingMessage = this.translocoService.translate('session.expiration.closing');
    this.secondsMessage = this.translocoService.translate('seconds.label');
  }

  ngOnInit(): void {
    // FMA-16300 - checking if default text will solve it, also removing it from entryComponents
    if (this.sessionMessage === '') this.sessionMessage = "Your session is about to expire.";
    if (this.continueMessage === '') this.continueMessage = "Continue";
    if (this.cancelMessage === '') this.cancelMessage = "Log out";
    if (this.closingMessage === '') this.closingMessage = "Closing in";
    if (this.secondsMessage === '') this.secondsMessage = "seconds";
    this.startCounting();
  }

  ngOnDestroy(): void {
    this.stopCounting();
  }

  get counter(): number {
    return this._counter;
  }

  private startCounting() {
    this._counter = IDLE_WAITING_CONFIRMATION_TIME + 1;
    const countdown = () => {
      if (this._counter === 0) {
        this.onCancel();
      } else {
        this._counter--;
        setTimeout(countdown, 1000);
      }
    };
    countdown();
  }


  private stopCounting() {
    this.authFacadeService.endExpiringProcess();
  }

  onContinue() {
    this.stopCounting();
    this.dialogRef.close(true);
  }

  onCancel() {
    this.stopCounting();
    this.dialogRef.close();
  }
}
