import {Apollo} from "apollo-angular";
import type {EmptyObject, MutationOptions, MutationResult} from "apollo-angular/types";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable()
export class ApolloIntportal extends Apollo{

  mutate<T, V = EmptyObject>(options: MutationOptions<T, V>): Observable<MutationResult<T>>{
    if (options?.variables?.hasOwnProperty('brand')){
      const brand = Object.getOwnPropertyDescriptor(options.variables, 'brand')?.value

      options.variables = {
        ...options.variables,
        'brand': this.changeBrand(brand)
      }
    }

    return super.mutate(options)
  }

  private changeBrand(brandName: string): string{
    switch (brandName.toUpperCase()) {
      case "TRUSTFAX": return "TRUST";
      default: return brandName;
    }
  }

}
