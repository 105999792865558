import { ActionReducer } from '@ngrx/store';
import { LoginActionTypes } from '../../../login/store/actions/login.actions';

export function clearFullState(reducer: ActionReducer<any>): ActionReducer<any> {
    return (state, action) => {
        if (action.type === LoginActionTypes.LogOutComplete) {
            localStorage.clear();
            state = undefined;
        }
        return reducer(state, action);
    };
}