import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginModule } from './login/login.module';
import { ErrorModule } from './error/error.module';
import { SharedModule } from './shared/shared.module';
import { ComposeModule } from './compose/compose.module';
import { ViewMessagesModule } from './view-messages/view-messages.module';
import { ContactsModule } from './contacts/contacts.module';
import { HealthcheckModule } from './healthcheck/healthcheck.module';
import { StatusPageModule } from './statuspage/statuspage.module';
import { TranslocoRootModule } from '../transloco-root.module';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers, CustomSerializer } from './auth/store/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './auth/store/effects/auth.effects';
import { AuthFacadeService } from './auth/services/auth-facade.service';
import { AuthInterceptor } from './auth/interceptor/auth.interceptor';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { AuthApi } from './auth/api/auth.api';
import { ApolloModule } from 'apollo-angular'
import { CookieService } from 'ngx-cookie-service';
import { BnNgIdleService } from 'bn-ng-idle';
import { RECAPTCHA_SETTINGS, RECAPTCHA_V3_SITE_KEY, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RecaptchaV3Module } from 'ng-recaptcha';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { LoadingInterceptor } from "./interceptors/loading-interceptor";

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ApolloModule,
    BrowserAnimationsModule,
    SharedModule,
    LoginModule,
    ErrorModule,
    ComposeModule,
    ViewMessagesModule,
    ContactsModule,
    HealthcheckModule,
    StatusPageModule,
    TranslocoRootModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([AuthEffects]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    RecaptchaV3Module,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatDialogModule,
    MatChipsModule,
  ],
  providers: [
    AuthFacadeService, AuthApi,
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    CookieService,
    BnNgIdleService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKeyV3,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKeyV2,
      } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
