<div class="content">

   <body>
      <div id="error-page">
         <div class="content">
            <div class="logo-container" *ngIf="brandConfig !== undefined">
              <img src="../../assets/images/{{brandConfig.logo.name}}" role="img" alt="{{brandConfig.logo.alt}}">
           </div>
            <h4>{{ 'error.page.title' | transloco }}</h4>
            <p>{{ 'error.page.default.message' | transloco }}</p>
            <p *ngIf="error_code !== null">{{ error_code }}</p>
            <p *ngIf="error_msg !== null">{{ error_msg }}</p>
            <div class="btns">
               <button mat-button (click)="redirectToLogin()">{{ 'error.page.button' | transloco }}</button>
            </div>
         </div>
      </div>
   </body>
</div>
