import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactsFacadeService } from './services/contacts-facade.service';
import { ContactsComponent } from './components/contacts.component';
import { DialogContactDelete } from './components/delete-contact-dialog.component';
import { ContactsEffects } from './store/effects/contacts.effects';
import { ContactsRoutingModule } from './contacts-routing.module';
import { ContactsApi } from './api/contacts.api';
import { SharedModule } from '../shared/shared.module';
import * as fromStore from './store/index';
import { PipesModule } from '../shared/pipes/pipes.module';
import { AddEditContactComponent } from './components/add-edit-contact/add-edit-contact.component';
import { FormatPhoneNumberPipe } from "../shared/pipes/format-phone-number.pipe";



@NgModule({
  declarations: [
    ContactsComponent,
    AddEditContactComponent,
    DialogContactDelete
  ],
  providers: [ContactsApi, ContactsFacadeService, FormatPhoneNumberPipe
  ],

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ContactsRoutingModule,
    SharedModule,
    StoreModule.forFeature('contacts', fromStore.reducers),
    EffectsModule.forFeature([ContactsEffects]),
    PipesModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ContactsModule { }
