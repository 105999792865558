import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'healthcheck-component',
  templateUrl: './healthcheck.component.html',
})
export class HealthcheckComponent implements OnInit {

  data: any
  readonly terminated$: Subject<boolean> = new Subject<boolean>();

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.checkServerHealth();
  }

  checkServerHealth() {
    const url = `${environment.faxClientAPIHost}/health`;
    this.http.get(url, { responseType: 'text' })
      .pipe(takeUntil(this.terminated$))
      .subscribe(response => {
        this.data = {
          serverUrl: url,
          status: 200,
          message: response
        }
      });
  }
}
