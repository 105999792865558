import { Component, Input } from '@angular/core';
@Component({
  selector: 'snack-error',
  templateUrl: './snack.error.html',
  styleUrls: ['./snack.error.scss']
})
export class SnackError {
  @Input() 
  message: string;
  
  dissmiss(): void {}
}