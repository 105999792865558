import { PhoneNumberPipe } from './phone-number.pipe';
import { IconMapPipe } from './icon-map.pipe';
import { MessageCountPipe } from './message-count.pipe';
import { LongFileNamePipe } from './long-file-name.pipe';
import { I18nPipe } from './i18n.pipe';
import { SafePipe } from './safe.pipe'


import { NgModule } from '@angular/core';
import {FormatPhoneNumberPipe} from "./format-phone-number.pipe";
import { SecondsFormatterPipe } from './seconds-formatter.pipe';

@NgModule({
    imports: [],
    declarations: [
        PhoneNumberPipe,
        IconMapPipe,
        I18nPipe,
        MessageCountPipe,
        FormatPhoneNumberPipe,
        LongFileNamePipe,
        SafePipe,
        SecondsFormatterPipe
    ],
    exports: [
        PhoneNumberPipe,
        IconMapPipe,
        I18nPipe,
        MessageCountPipe,
        FormatPhoneNumberPipe,
        LongFileNamePipe,
        SafePipe,
        SecondsFormatterPipe
    ]
})
export class PipesModule { }
