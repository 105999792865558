import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { AuthFacadeService } from '../../../auth/services/auth-facade.service';
import { TranslocoService } from '@ngneat/transloco';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Brands, SUSPEND_FLAG_ALLOW_LIMIT } from "../../constants/constants";


@Injectable({
  providedIn: 'root'
})
export class FileshareGuard implements CanActivate, CanLoad {

  protected readonly brands = Brands;

  constructor(private authFacade: AuthFacadeService,
    private router: Router,
    private translocoService: TranslocoService,
    private _snackBar: MatSnackBar
  ) {
    this.translocoService.setTranslationKey('access.not.allowed', "You don't have access to this resource, check your account");
  }

  canLoad() {
    return this.authFacade.authState$.pipe(
      filter(state => state !== undefined),
      take(1),
      map(state => { return { hasToken: !!state.access_token, flag: state.suspendedFlag } }),
      map(result => {
        if (result.flag > SUSPEND_FLAG_ALLOW_LIMIT) {
          return this.router.createUrlTree(['/error'], { queryParams: { message: "Access not allowed - account suspended" } });
        } else if (result.flag > 0) {
          return this.router.parseUrl('/view-messages')
        } else {
          if (result.hasToken)
            return true;
          else
            return this.router.parseUrl('/login');
        }
      })
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authFacade.userSingle$.pipe(
      take(1),
      switchMap(result => {
        const isAllowed = result?.userSingle?.user?.account?.brand === this.brands.EFAX.toUpperCase();
        if (!isAllowed) {
          return of(this.redirect());
        } else {
          return of(true);
        }
      })
    );
  }

  redirect() {
    this._snackBar.open(this.translocoService.translate(["access.not.allowed"]), "X", { duration: 5000 });
    return this.router.createUrlTree(['/compose']);
  }

}


