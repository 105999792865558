<div class="section">
  <mat-icon matListItemIcon aria-label="{{ originalFolderName }}">
    {{ originalFolderName | iconMap }}
  </mat-icon>

  <input
    #renameInput
    [value]="originalFolderName"
    class="rename-form-input "
    type="text"
    [ngModel]="originalFolderName"
    (ngModelChange)="onChange($event)">

  <span #renameApproveButton class="enabled approve material-icons" (click)="approve()">done</span>
</div>
