import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusPageRoutingModule } from './statuspage-routing.module';
import { StatusPageComponent } from './components/statuspage.component';
import { TranslocoRootModule } from 'src/transloco-root.module';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [
    StatusPageComponent
  ],
  providers: [],
  imports: [
    CommonModule,    
    StatusPageRoutingModule,
    TranslocoRootModule,
    SharedModule
  ]
})
export class StatusPageModule { }
