<div class="content pb-30">
  <main class="main" *ngIf="!showAddPayment">
    <!-- Upper Section -->
    <div class="upper-section">
      <div>
        {{ 'sidebar.menu.billing.text' | transloco }}
      </div>
      <hr class="separator-line">
      <div class="option-section">
        {{ 'billing.update.info' | transloco }}
      </div>
      <p class="info-section">{{ 'billing.note.change' | transloco }}</p>
    </div>
    <!-- Billing Information -->
    <div class="billing-info">
      <div class="billing-plan">
        <span>{{'billing.current.plan' | transloco | uppercase }}</span>
        <span class="plan-name">{{ 'billing.plan.efax.plus' | transloco }}</span>
        <button class="add-number-btn"><span class="material-icons add">add_box</span>
          {{'billing.add.number' | transloco }}</button>
      </div>
      <hr class="separator-line">
      <!-- Billing Methods -->
      <span>{{'billing.method' | transloco | uppercase }}</span>
      <div class="billing-methods">
        <!-- Direct Debit -->
        <div class="billing-card direct-debit" [ngClass]="{'selected': selectedPaymentType === 'directCard'}">
          <div class="card-header">
            <span>{{'billing.direct.debit' | transloco }}</span>
            <label class="switch">
              <input type="checkbox" [checked]="selectedPaymentType === 'directCard'"
                (change)="updateSelection(CardType.DirectCard, $event)">
              <span class="slider round"></span>
            </label>
          </div>
          <div class="card-info">
            <p class="info-text">{{'billing.routing.number' | transloco }}</p>
            <p class="last-digits">{{ billingValues.directCard.lastDigitsRouting }}</p>
            <p class="info-text">{{'billing.account.number' | transloco }}</p>
            <p class="last-digits">{{ billingValues.directCard.lastDigitsAccount }}</p>
          </div>
          <span class="material-icons edit-btn" (click)="editPayment(CardType.DirectCard)">edit</span>
        </div>
        <!-- Debit Card -->
        <div class="billing-card debit-card" [ngClass]="{'selected': selectedPaymentType === 'debitCard'}">
          <div class="card-header">
            <span>{{'billing.debit.card' | transloco }}</span>
            <label class="switch">
              <input type="checkbox" [checked]="selectedPaymentType === 'debitCard'"
                (change)="updateSelection(CardType.DebitCard, $event)">
              <span class="slider round"></span>
            </label>
          </div>
          <div class="card-info">
            <p class="card-number">{{ billingValues.debitCard.number }}</p>
            <p class="info-text">{{'billing.expiration.date' | transloco }}</p>
            <p>{{ billingValues.debitCard.expDate }}</p>
          </div>
          <span class="material-icons edit-btn" (click)="editPayment(CardType.DebitCard)">edit</span>
        </div>
        <!-- Credit Card -->
        <div class="billing-card credit-card" [ngClass]="{'selected': selectedPaymentType === 'creditCard'}">
          <div class="card-header">
            <span>{{'billing.credit.card' | transloco }}</span>
            <label class="switch">
              <input type="checkbox" value="Credit Card" [checked]="selectedPaymentType === 'creditCard'"
                (change)="updateSelection(CardType.CreditCard, $event)">
              <span class="slider round"></span>
            </label>
          </div>
          <div class="card-info">
            <p class="card-number">{{ billingValues.creditCard.number }}</p>
            <p class="info-text">{{'billing.expiration.date' | transloco }}</p>
            <p>{{ billingValues.creditCard.expDate }}</p>
          </div>
          <span class="material-icons edit-btn" (click)="editPayment(CardType.CreditCard)">edit</span>
        </div>
        <!-- New Card Added -->
        <div *ngIf='newCard' class="billing-card new-card" [ngClass]="{'selected': selectedPaymentType === 'newCard'}">
          <div class="card-header">
            <span>{{'billing.credit.card' | transloco }}</span>
            <label class="switch">
              <input type="checkbox" value="New Card" [checked]="selectedPaymentType === 'newCard'"
                (change)="updateSelection(CardType.newCard, $event)">
              <span class="slider round"></span>
            </label>
          </div>
          <div class="card-info">
            <p class="card-number">{{ billingValues.newCard.number }}</p>
            <p class="info-text">{{'billing.expiration.date' | transloco }}</p>
            <p>{{ billingValues.newCard.expDate }}</p>
          </div>
          <span class="material-icons edit-btn" (click)="editPayment(CardType.newCard)">edit</span>
        </div>
        <!-- Add Payment -->
        <div class="billing-card add-payment">
          <button class="add-payment-btn" (click)="addPayment()">
            <span class="material-icons add">add_box</span>{{'billing.add.payment' | transloco }}
          </button>
        </div>
      </div>
      <hr class="separator-line">
      <!-- Billing Statements -->
      <div class="billing-container">
        <div class="billing-statements">
          <span class="billing-title">{{'billing.statements' | transloco | uppercase }}</span>
          <span class="value">
            <p *ngIf="viewStatement" class="info-text">{{ billingDetails.statements}}</p>
          </span>
          <span class="group-icon-text action">
            <span (click)="viewStatements()" class="material-icons" style="margin-right: 6px">visibility</span>
            {{'billing.view' | transloco }}</span>
        </div>
        <hr class="separator-line">
        <!-- Billing Settings -->
        <div class="billing-settings">
          <span class="billing-title">{{'billing.settings' | transloco | uppercase }}</span>
          <p class="info-text value">{{'billing.via.email' | transloco }}</p>
          <span class="material-icons action" (click)="editBalanceSettings()">edit</span>
        </div>
        <hr class="separator-line">
        <!-- Usage Balance -->
        <div class="usage-balance">
          <span class="billing-title">{{'billing.usage.balance' | transloco | uppercase }}</span>
          <p class="info-text value">{{ billingDetails.usageBalanceValue }}</p>
          <span class="material-icons action" (click)="editUsageBalance()">edit</span>
        </div>
        <hr class="separator-line">
        <!-- Subscription Rate -->
        <div class="subscription-rate">
          <span class="billing-title">{{'billing.subscription.rate' | transloco | uppercase }}</span>
          <p class="info-text value">{{ billingDetails.subscriptionRate }}</p>
          <button class="summary-btn action" (click)="openBillingSummary()"><span class="material-icons arrow">arrow_right</span>
            {{'billing.summary' | transloco | uppercase }}</button>
        </div>
        <hr class="separator-line">
        <!-- Next Payment Date -->
        <div class="next-payment">
          <span class="billing-title">{{'billing.next.payment.date' | transloco | uppercase }}</span>
          <p class="info-text value">{{ billingDetails.usageBalanceValue }}</p>
          <button class="cancel-btn action"><span class="material-icons arrow">arrow_right</span>
            {{'billing.cancel.account' | transloco | uppercase }}</button>
        </div>
      </div>
      <hr class="separator-line">
    </div>
  </main>
</div>

<myportal-add-payment *ngIf="showAddPayment" [cardDetails]="cardDetails" (closeForm)="closePaymentForm()"
  [cardType]="cardTypeSelected" (newCardAdded)="handleNewCardAdded()">
</myportal-add-payment>