import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReportsService, SentFax } from '../../services/reports.service';
import { map, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CsvService } from 'src/app/shared/services/csv.service';
import { AuthFacadeService } from 'src/app/auth/services/auth-facade.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { Subject } from 'rxjs';
import { PrintableTableComponent } from 'src/app/shared/components/printable-table/printable-table.component';
import { MatDialog } from '@angular/material/dialog';
import { ReceivedLogsTableComponent } from '../received-logs-table/received-logs-table.component';
import { SentLogsTableComponent } from '../sent-logs-table/sent-logs-table.component';

@Component({
  selector: 'myportal-inboud-activity-logs',
  templateUrl: './inboud-activity-logs.component.html',
  styleUrls: ['./inboud-activity-logs.component.scss'],
})
export class InboudActivityLogsComponent implements OnInit, OnDestroy {
  @ViewChild('receivedLogsTable') receivedLogsTable: ReceivedLogsTableComponent;
  @ViewChild('sentLogsTable') sentLogsTable: SentLogsTableComponent;


  segmentIndex: number = 0;

  displayedColumnsReceivedLogs: string[] = ['date', 'pages', 'type', 'duration', 'from'];
  receivedLogsFilter: any = {
    start: undefined,
    minForStart: undefined,
    maxForStart: undefined,
    end: undefined,
    minForEnd: undefined,
    maxForEnd: undefined
  };

  displayedColumnsSentLogs: string[] = ['date', 'to', 'pages', 'charge', 'subject', 'status'];
  sentLogsFilter: any = {
    start: undefined,
    minForStart: undefined,
    maxForStart: undefined,
    end: undefined,
    minForEnd: undefined,
    maxForEnd: undefined
  };

  private terminated$: Subject<boolean> = new Subject<boolean>();
  currentDID: string;

  constructor(private reportsService: ReportsService, private loadingService: LoadingService,
    private route: ActivatedRoute, private csvService: CsvService,
    private router: Router, private authService: AuthFacadeService,
    public dialog: MatDialog,) {
  }
  ngOnDestroy(): void {
    this.terminated$.next(true);
  }

  ngOnInit(): void {
    this.setupDefaultFilters();

    this.route.queryParamMap.pipe(map(p => p.get("outbound"))).subscribe(value => {
      this.segmentIndex = value === "true" ? 1 : 0;
    });

    this.authService.currentService$.pipe(takeUntil(this.terminated$)).subscribe(svc => {
      if (svc !== undefined) {
        this.currentDID = svc.phoneNumber;
      }
    });
  }

  setupDefaultFilters() {
    const today = new Date();
    const startDate = new Date();
    startDate.setDate(today.getDate() - 30);
    const minDate = new Date(1998, 1, 1);

    this.receivedLogsFilter.start = startDate.toISOString();
    this.receivedLogsFilter.minForStart = minDate.toISOString();
    this.receivedLogsFilter.maxForStart = today.toISOString();

    this.receivedLogsFilter.end = today.toISOString();
    this.receivedLogsFilter.minForEnd = this.receivedLogsFilter.start;
    this.receivedLogsFilter.maxForEnd = today.toISOString();

    this.sentLogsFilter.start = startDate.toISOString();
    this.sentLogsFilter.minForStart = minDate.toISOString();
    this.sentLogsFilter.maxForStart = today.toISOString();

    this.sentLogsFilter.end = today.toISOString();
    this.sentLogsFilter.minForEnd = this.sentLogsFilter.start;
    this.sentLogsFilter.maxForEnd = today.toISOString();
  }

  ngAfterViewInit(): void {

  }

  filterReceivedLogs(range: any) {
    this.reportsService.callReceiveReport(range.start, range.end, this.currentDID);
  }

  filterSentLogs(range: any) {
    this.reportsService.callSendReport(range.start, range.end);
  }

  goBack() {
    this.router.navigate(["/reports"]);
  }

  print() {
    if (this.segmentIndex === 0) {
      this.openPrintableModal(this.displayedColumnsReceivedLogs,
        {
          date: 'formattedMessageTime',
          pages: 'pages',
          type: 'msgType',
          duration: 'durationInSeconds',
          from: 'csid'
        }, this.receivedLogsTable.dataSource.data);
    } else if (this.segmentIndex === 1) {
      this.openPrintableModal(this.displayedColumnsSentLogs,
        {
          date: 'formattedMessageTime',
          to: 'destination',
          pages: 'pages',
          charge: 'formattedChargeAmount',
          subject: 'subject',
          status: 'status'
        }, this.sentLogsTable.dataSource.data);
    }
  }

  async openPrintableModal(columns: string[], columnsMap: any, dataList: SentFax[]) {
    this.dialog.open(PrintableTableComponent,
      {
        width: '100%',
        height: '100%',
        hasBackdrop: true,
        data: {
          columns: columns,
          columnsMap: columnsMap,
          dataSource: dataList,
        }
      }
    );
  }

  isPrintDisabled() {
    return (this.segmentIndex === 0 && !this.hasDataLoaded(this.receivedLogsTable?.dataSource.data) ||
      (this.segmentIndex === 1 && !this.hasDataLoaded(this.sentLogsTable?.dataSource.data)));
  }

  private hasDataLoaded(data: any) {
    return data && data.length > 0;
  }

  isDownloadDisabled() {
    return (this.segmentIndex === 0 && !this.hasDataLoaded(this.receivedLogsTable?.dataSource.data) ||
      (this.segmentIndex === 1 && !this.hasDataLoaded(this.sentLogsTable?.dataSource.data)));
  }

  download() {
    if (this.segmentIndex === 0) {
      this.downloadReceivedLogsTable();
    } else if (this.segmentIndex === 1) {
      this.downloadSentLogsTable();
    }
  }

  private downloadReceivedLogsTable() {
    const filename = 'received_logs_'.concat(new Date().toISOString());
    this.csvService.export(filename, this.displayedColumnsReceivedLogs,
      ['formattedMessageTime', 'pages', 'msgType',
        'durationInSeconds', 'csid'], this.receivedLogsTable.dataSource.data);
  }

  private downloadSentLogsTable() {
    const filename = 'sent_logs_'.concat(new Date().toISOString());
    this.csvService.export(filename, this.displayedColumnsSentLogs,
      ['formattedMessageTime', 'destination', 'pages',
        'formattedChargeAmount', 'subject', 'status'
      ], this.sentLogsTable.dataSource.data);
  }

  changeStartDateForReceived(selectedDate: string) {
    const value = new Date(selectedDate);
    this.receivedLogsFilter.start = value;
    this.receivedLogsFilter.minForEnd = value;
  }
  changeEndDateForReceived(selectedDate: string) {
    const value = new Date(selectedDate);
    this.receivedLogsFilter.end = value;
  }

  changeStartDateForSent(selectedDate: string) {
    const value = new Date(selectedDate);
    this.sentLogsFilter.start = value;
    this.sentLogsFilter.minForEnd = value;
  }
  changeEndDateForSent(selectedDate: string) {
    const value = new Date(selectedDate);
    this.sentLogsFilter.end = value;
  }
}
