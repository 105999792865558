import {Component, EventEmitter, Output} from '@angular/core';
import {BrandService} from "../services/brand.service";

@Component({
  selector: 'myportal-consent-popup',
  templateUrl: './consent-popup.component.html',
  styleUrls: ['./consent-popup.component.scss']
})
export class ConsentPopupComponent {
  @Output() consent = new EventEmitter();

  selectedBrand: string

  constructor(private brandService: BrandService) {
  }

  ngOnInit(): void {
    this.selectedBrand = this.brandService.brandName
    if (this.selectedBrand === 'efax' || this.selectedBrand === 'myfax') {
      this.selectedBrand = this.selectedBrand.replace(/(efax|myfax)/, "legal?_ga=2.240710467.1577476357.1688052964-54697665.1639755455");
    } else {
      this.selectedBrand += this.selectedBrand === 'rapidfax' ? '/privacy' : '/privacy-policy';
    }
  }

  clickOnConsent() {
    this.consent.emit();
  }

}
