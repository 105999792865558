<div class="header-container">
  <div *ngIf="brandConfig !== undefined">
    <img [src]="'../../../assets/images/' + brandConfig.logo.name" role="img" [alt]="brandConfig.logo.alt">
 </div>

  <button mat-icon-button aria-label="close dialog" (click)="goBack()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div
  id="step1"
  mat-dialog-content
  *ngIf="step1"
  class="forgot-password-content"
>
  <div class="title">
    <span>
      <b>{{ "login.forgot.password.title" | transloco }}</b>
    </span>
  </div>
  <ng-container>
    <form [formGroup]="forgotPasswordForm">
      <mat-form-field class="fax-form-field" appearance="outline">
        <mat-label>{{ "login.forgot.password.fax" | transloco }}</mat-label>
        <input
          id="fax-number"
          class="input-text"
          matInput
          autofocus
          type="text"
          formControlName="faxNumber"
          required
        />
      </mat-form-field>
      <ng-container>
        <mat-error
          *ngIf="
            forgotPasswordForm.controls.faxNumber.touched &&
            forgotPasswordForm.controls.faxNumber.errors?.required
          "
        >
          {{ "forgot.password.error.message.valid_faxnumber" | transloco }}
        </mat-error>
        <mat-error
          *ngIf="
            forgotPasswordForm.controls.faxNumber.touched &&
            forgotPasswordForm.controls.faxNumber.errors?.pattern
          "
        >
          {{ "forgot.password.error.message.valid_faxnumber" | transloco }}
        </mat-error>
      </ng-container>

      <div class="button-row">
        <mat-label id="button-forgot-password-send-label" hidden>
          {{ "login.forgot.password.send" | transloco }}
        </mat-label>
        <button
          id="forgot-password-button-send"
          name="forgot-password-button-send"
          (click)="sendRequest()"
          class="btn btn-md btn-info button-right send"
          aria-labelledby="button-forgot-password-send-label"
          [disabled]="!forgotPasswordForm.valid || this.loadingService.isLoading"
        >
          {{ "login.forgot.password.send" | transloco }}
        </button>
      </div>
    </form>
  </ng-container>

  <div class="forgot-login-errors-container">
    <small class="error" *ngIf="apiError !== undefined">
      <b *ngIf="apiError?.message === 'ma_token_mismatch'">
        {{ "forgot.password.error.message.token" | transloco }}
      </b>
    </small>

    <small class="success">
      <b *ngIf="apiError?.message !== 'ma_token_mismatch'">
        {{ apiError?.message | transloco}}
      </b>
    </small>

  </div>
</div>

<div
  id="step2"
  mat-dialog-content
  *ngIf="!step1"
  class="forgot-password-content fixed-size"
>
  <div class="title">
    <span>
      <b>{{ "login.forgot.password.title" | transloco }}</b>
    </span>
  </div>

  <ng-container>
    <form [formGroup]="verifyPasswordForm">
      <input
        id="fax-number-verify"
        matInput
        type="text"
        formControlName="faxNumber"
        maxlength="20"
        hidden
      />
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "login.forgot.password.code" | transloco }}
        </mat-label>
        <input
          id="security-code"
          class="input-text"
          matInput
          autofocus
          type="text"
          maxlength="10"
          formControlName="securityCode"
        />
      </mat-form-field>
      <ng-container>
        <mat-error
          *ngIf="
            verifyPasswordForm.controls.securityCode.touched &&
            verifyPasswordForm.controls.securityCode.errors?.required
          "
        >
          {{ "forgot.password.error.message.valid_securityCode" | transloco }}
        </mat-error>
        <mat-error
          *ngIf="
            verifyPasswordForm.controls.securityCode.touched &&
            verifyPasswordForm.controls.securityCode.errors?.pattern
          "
        >
          {{ "forgot.password.error.message.valid_securityCode" | transloco }}
        </mat-error>
      </ng-container>

      <mat-form-field appearance="outline">
        <mat-label>
          {{ "login.forgot.password.password" | transloco }}
        </mat-label>
        <input
          id="verify-password"
          class="input-text"
          [errorStateMatcher]="matcher"
          matInput
          [type]="hidePassword ? 'password' : 'text'"
          formControlName="password"
          maxlength="20"
        />
        <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{
          hidePassword ? "visibility_off" : "visibility"
        }}</mat-icon>
      </mat-form-field>

      <ng-container
        *ngIf="
          passwordField?.invalid &&
          (passwordField?.touched || passwordField?.dirty)
        "
      >
        <mat-error *ngIf="passwordField?.hasError('required')">
          {{ "forgot.password.error.message.valid_password" | transloco }}
        </mat-error>

        <mat-error
          *ngIf="verifyPasswordForm.controls.password.hasError('pattern')"
        >
          <span class="danger">{{
            "forgot.password.error.message.valid_password" | transloco
          }}</span>
          <ul>
            <li
              [ngClass]="{
                'text-success': checkValidations(passwordField, 'lowercase'),
                danger: !checkValidations(passwordField, 'lowercase')
              }"
            >
              {{ "forgot.min1lowercase" | transloco }}
            </li>
            <li
              [ngClass]="{
                'text-success': checkValidations(passwordField, 'length'),
                danger: !checkValidations(passwordField, 'length')
              }"
            >
              {{ "forgot.min8" | transloco }}.
            </li>
            <li
              [ngClass]="{
                'text-success': checkValidations(passwordField, 'length'),
                danger: !checkValidations(passwordField, 'length')
              }"
            >
              {{ "forgot.min40" | transloco }}
            </li>
          </ul>
        </mat-error>
      </ng-container>

      <mat-form-field appearance="outline">
        <mat-label>
          {{ "login.forgot.password.confirm" | transloco }}
        </mat-label>
        <input
          id="verify-confirm-password"
          [errorStateMatcher]="matcher"
          class="input-text"
          matInput
          [type]="hideConfirmPassword ? 'password' : 'text'"
          formControlName="password"
          formControlName="confirmPassword"
          maxlength="20"
        />
        <mat-icon
          matSuffix
          (click)="hideConfirmPassword = !hideConfirmPassword"
          >{{ hideConfirmPassword ? "visibility_off" : "visibility" }}</mat-icon
        >
      </mat-form-field>
      <ng-container
        *ngIf="
          verifyPasswordForm.hasError('notMatched') ||
          (confirmPasswordField?.invalid &&
            (confirmPasswordField?.touched || confirmPasswordField?.dirty))
        "
      >
        <mat-error *ngIf="confirmPasswordField?.errors?.required">
          {{
            "forgot.password.error.message.valid_confirmPassword" | transloco
          }}
        </mat-error>

        <mat-error *ngIf="confirmPasswordField?.errors?.pattern">
          {{
            "forgot.password.error.message.valid_confirmPassword" | transloco
          }}
        </mat-error>

        <mat-error *ngIf="verifyPasswordForm.hasError('notMatched')">
          {{
            "forgot.password.error.message.notmatched_confirmPassword"
              | transloco
          }}
        </mat-error>

      </ng-container>

      <input
        id="brand-name"
        matInput
        type="text"
        formControlName="brand"
        hidden
      />
    </form>
  </ng-container>

  <div class="button-row stick-bottom">
    <button
      name="forgot-password-button-send"
      (click)="verifyRequest()"
      class="btn btn-md btn-info button-right send"
      [disabled]="!verifyPasswordForm.valid || this.loadingService.isLoading"
    >
      {{ "general.submit" | transloco }}
    </button>
    <button
      name="go-back-button"
      aria-labelledby="button-forgot-login-go-back-label"
      class="btn btn-md go-back-button"
      type="button"
      (click)="cancelVerify()"
    >
      {{ "login.sso.back" | transloco }}
    </button>
  </div>

  <div class="forgot-login-errors-container">
    <small class="error" *ngIf="apiError !== undefined">
      <b *ngIf="apiError?.message === 'ma_new_password_reuse'">
        {{ "forgot.password.error.message.reuse" | transloco }}
      </b>
    </small>

    <small class="error" *ngIf="apiError !== undefined">
      <b *ngIf="apiError?.message === 'ma_token_mismatch'">
        {{ "forgot.password.error.message.token" | transloco }}
      </b>
    </small>

    <small *ngIf="recoveryCodeSentSuccess$ | async" class="success">
      {{ "forgot.password.success.message.token" | transloco }}
    </small>

    <ng-container *ngIf="apiSuccess !== undefined">
      <small *ngIf="apiSuccess" class="success"
        >{{ "forgot.password.success.message.explanation" | transloco }}
      </small>
      <small *ngIf="!apiSuccess" class="error">{{
        "forgot.password.fail.message.send.email" | transloco
      }}</small>
    </ng-container>
  </div>
</div>
