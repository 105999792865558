<div class="table-container">
  <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z1" (matSortChange)="sortData($event)">
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'reports.table.header.date' | transloco}}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.formattedMessageTime }}
      </td>
    </ng-container>

    <ng-container matColumnDef="pages">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'reports.table.header.pages' | transloco }}</th>
      <td mat-cell *matCellDef="let element">{{ element.pages }}</td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'reports.table.header.type' | transloco }}</th>
      <td mat-cell *matCellDef="let element">{{ element.msgType }}</td>
    </ng-container>

    <ng-container matColumnDef="duration">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'reports.table.header.duration' | transloco }}</th>
      <td mat-cell *matCellDef="let element">{{ element.durationInSeconds }}</td>
    </ng-container>

    <ng-container matColumnDef="from">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'reports.table.header.from' | transloco }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.csid }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<mat-paginator #paginator class="paginator no-printable" [pageSize]="pageSize" showFirstLastButtons="true"
  [length]="totalElements" (page)="pageChanged($event)"></mat-paginator>
