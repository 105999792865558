<div class="banner-position-container">
    <div id="cookie-banner-container" class="cookie-banner-container display-cookie-banner">
        <div id="cookie-banner-text">
            We use cookies to ensure that we give you the best experience on our website. By continuing to use this
            website you are giving consent to cookies being used. To learn more visit our
            <a id="cookie-banner-policy"
                href="https://www.efax.com/{{this.selectedBrand}}"
                target="_blank">Cookie Policy &gt;&gt;</a>
        </div>
        <div id="cookie-banner-button">
            <button id="cookie-understand" (click)="clickOnConsent()">I Understand </button>
        </div>
        <div class="clear-cookie"></div>
    </div>
</div>
