import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { LoginApi } from '../api/login.api';
import { LoginModel } from '../models/login.model';
import { State } from '../store/state/login.state';
import * as fromStore from '../store/reducers';
import * as fromSelector from '../store/selectors/login.selector';
import { ForgotLoginIdStart, ForgotPasswordStart, LoginAction, LoginSSOStart, LogOutAction, resetModalStatus, SSOResponseValidated } from '../store/actions/login.actions';
import { MatDialog } from '@angular/material/dialog';
import { SafeResourceUrl } from "@angular/platform-browser";

@Injectable()
export class LoginFacade {

  readonly loginStateViewModel$: Observable<State>;
  readonly autologinCurrentUrl$: Subject<SafeResourceUrl> = new Subject<SafeResourceUrl>();
  readonly failedAttempts$: Observable<number>;

  constructor(
    private loginApi: LoginApi,
    private readonly store: Store<fromStore.State>,
    private dialog: MatDialog) {
    this.loginStateViewModel$ = this.store.select(fromSelector.loginPageViewModel);
    this.failedAttempts$ = this.store.select(fromSelector.failedAttemptsSelector);
  }

  login(loginModel: LoginModel, brand: string) {
    this.store.dispatch(LoginAction({ payload: loginModel, brand }));
  }

  logOut() {
    this.dialog.closeAll();
    this.store.dispatch(LogOutAction());
  }

  validateSSO(email: string) {
    this.store.dispatch(LoginSSOStart({ user_email: email }));
  }

  loginBySSO(code: string, correlation_id: string) {
    this.store.dispatch(SSOResponseValidated({ response: { code: code, correlation_id: correlation_id } }));
  }

  forgotLoginIdSendRequest(email: string, brandName: string) {
    this.store.dispatch(ForgotLoginIdStart({ payload: { user_email: email, brand: brandName } }));
  }

  forgotPasswordSendRequest(faxNumber: string, brandName: string) {
    this.store.dispatch(ForgotPasswordStart({ payload: { fax_number: faxNumber, brand: brandName } }));
  }

  forgotPassword(faxNumber: string, brand: string) {
    return this.loginApi.forgotPasswordRequest(faxNumber, brand);
  }
  verifyPassword(faxNumber: string, securityCode: string,
    password: string, confirmPassword: string, brand: string) {
    return this.loginApi.passwordVerifyRequest(faxNumber, securityCode, password, confirmPassword, brand);
  }
  webMyAccountLogin(url: string) {
    return this.loginApi.webMyAccountLogin(url);
  }
  resetLoginStatus() {
    this.store.dispatch(resetModalStatus());
  }
}
