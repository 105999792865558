import { Component, EventEmitter, Input, OnInit, ViewChild, ViewContainerRef, AfterViewInit } from '@angular/core';
import { PrintingItemComponent } from "./printing-item/printing-item.component";

@Component({
  selector: 'myportal-printing-area',
  templateUrl: './printing-area.component.html',
  styleUrls: ['./printing-area.component.scss']
})
export class PrintingAreaComponent implements AfterViewInit {

  @Input() show = true;
  @ViewChild('printingArea') printingArea: any;
  @ViewChild('placeToRender', { read: ViewContainerRef }) placeToRender: ViewContainerRef;

  rotation: number = 0;
  zoom: number = 1;
  totalPages: number = 0;

  constructor() { }

  ngAfterViewInit() {
  }

  @Input('imageList')
  set imageList(val: string[]) {
    var _this = this;
    window.setTimeout(function() {
      if (val && val.length > 0) {
        _this.totalPages = val.length;
        _this.setValue(val);
      } else {
        _this.totalPages = 0;
      }
    }, 1000);
  }

  async setValue(val: string[], page: number = -1): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (val != undefined && val.length > 0) {

        this.display(false);

        const componentRef = this.placeToRender.createComponent(PrintingItemComponent);

        componentRef.instance.onLoadAll.subscribe(() => {
          const htmlString = componentRef.location.nativeElement.innerHTML;
          componentRef.destroy();

          this.display(true);

          if (this.printingArea) {
            this.printingArea.nativeElement.contentWindow.document.open();
            this.printingArea.nativeElement.contentWindow.document.write(htmlString);

            this.printingArea.nativeElement.contentDocument.head.insertAdjacentHTML("beforeend",
              `<style>
                  @page {
                      margin: 10px;
                      size: a4 portrait;
                  }
                  body {
                      overflow: hidden; /* Evita el scroll dentro del iframe */
                  }
                </style>`
            )

            this.printingArea.nativeElement.contentWindow.onload = () => {
              this.printingArea.nativeElement.style.height = this.printingArea.nativeElement.contentDocument.body.scrollHeight + 'px';
              resolve(true);
            };

            this.printingArea.nativeElement.contentWindow.document.close();
          } else {
            reject('Printing area is undefined');
          }
        });

        if (page >= 0 && page < val.length) {
          componentRef.instance.imageList = [val[page]];
        } else {
          componentRef.instance.imageList = val;
        }
      }
    });
  }

  printing() {
    if (this.printingArea) {
      this.printingArea.nativeElement.contentWindow.print();
    } else {
      console.error('Printing area is undefined');
    }
  }

  private display(display: boolean): void {
    if (this.printingArea) {
      this.printingArea.nativeElement.style.display = display ? 'block' : 'none';
    }
  }

  updateTransform(rotation: number, zoom: number) {
    this.rotation = rotation;
    this.zoom = zoom;
    const transform = `rotate(${this.rotation}deg) scale(${this.zoom})`;

    if (this.printingArea) {
      const iframeDocument = this.printingArea.nativeElement.contentDocument || this.printingArea.nativeElement.contentWindow.document;
      const images = iframeDocument.querySelectorAll('img');

      images.forEach((img: HTMLImageElement) => {
        img.style.transform = transform;
      });
    }
  }
}
