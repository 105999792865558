import { createAction, props } from '@ngrx/store';
import { Contact, ContactCreate, ContactDeleteInput, ContactList, ContactListParams, ContactSingle, ContactSortBy } from '../../models/contact.model';
import { ApiError } from '../../models/shared.model';

enum Actions {
    LOAD_CONTACT_LIST_BEGIN = "[Contacts] Load contact list begin",
    LOAD_CONTACT_LIST_SUCCESS = "[Contacts] Load contact list success",
    LOAD_CONTACT_LIST_FAILED = "[Contacts] Load contact list failed",

    LOAD_CONTACT_SINGLE_BEGIN = "[Contacts] Load contact single begin",
    LOAD_CONTACT_SINGLE_SUCCESS = "[Contacts] Load contact single success",
    LOAD_CONTACT_SINGLE_FAILED = "[Contacts] Load contact single failed",

    CREATE_CONTACT_BEGIN = "[Contacts] Create contact begin",
    CREATE_CONTACT_SUCCESS = "[Contacts] Create contact success",
    CREATE_CONTACT_FAILED = "[Contacts] Create contact failed",

    UPDATE_CONTACT_BEGIN = "[Contacts] Update contact begin",
    UPDATE_CONTACT_SUCCESS = "[Contacts] Update contact success",
    UPDATE_CONTACT_FAILED = "[Contacts] Update contact failed",

    DELETE_CONTACT_BEGIN = "[Contacts] Delete contact begin",
    DELETE_CONTACT_SUCCESS = "[Contacts] Delete contact success",
    DELETE_CONTACT_FAILED = "[Contacts] Delete contact failed",
    DELETE_CONTACT_LIST_BEGIN = "[Contacts] Delete contact list begin",

    ADD_EDIT_SINGLE_CONTACT = "[Contacts] Adding or editing single contact"
}

export const loadContactListBegin =
    createAction(Actions.LOAD_CONTACT_LIST_BEGIN, props<{ payload: ContactListParams }>());
export const loadContactListSuccess =
    createAction(Actions.LOAD_CONTACT_LIST_SUCCESS, props<{ contactList: ContactList }>());
export const loadContactListFailed =
    createAction(Actions.LOAD_CONTACT_LIST_FAILED, props<{ errorMessage: undefined }>());

export const loadContactSingleBegin =
    createAction(Actions.LOAD_CONTACT_SINGLE_BEGIN, props<{ payload: { contactId: string } }>());
export const loadContactSingleSuccess =
    createAction(Actions.LOAD_CONTACT_SINGLE_SUCCESS, props<{ contactSingle: ContactSingle }>());
export const loadContactSingleFailed =
    createAction(Actions.LOAD_CONTACT_SINGLE_FAILED, props<{ errorMessage: undefined }>());

export const createNewContactBegin =
    createAction(Actions.CREATE_CONTACT_BEGIN, props<{ payload: { input: Contact } }>());
export const createNewContactSuccess =
    createAction(Actions.CREATE_CONTACT_SUCCESS, props<{ contact: ContactCreate }>());
export const createNewContactFailed =
    createAction(Actions.CREATE_CONTACT_FAILED, props<{ errorMessage: ApiError }>());

export const updateContactBegin =
    createAction(Actions.UPDATE_CONTACT_BEGIN, props<{ payload: { contactId: string, input: Contact } }>());
export const updateContactSuccess =
    createAction(Actions.UPDATE_CONTACT_SUCCESS, props<{ contact: Contact }>());
export const updateContactFailed =
    createAction(Actions.UPDATE_CONTACT_FAILED, props<{ errorMessage: ApiError }>());

export const deleteContactBegin =
    createAction(Actions.DELETE_CONTACT_BEGIN, props<{ payload: { input: ContactDeleteInput, list: ContactListParams } }>());
export const deleteContactSuccess =
    createAction(Actions.DELETE_CONTACT_SUCCESS, props<{ contact: ContactCreate, list: ContactListParams }>());
export const deleteContactFailed =
    createAction(Actions.DELETE_CONTACT_FAILED, props<{ errorMessage: ApiError }>());

export const deleteContactListBegin = createAction(Actions.DELETE_CONTACT_LIST_BEGIN, props<{ payload: { contactsList: string[], paramsList: ContactListParams } }>());

export const addEditSingleContact = createAction(Actions.ADD_EDIT_SINGLE_CONTACT, props<{ content: { contact: Contact | undefined, contactId: string | undefined } | undefined }>());
