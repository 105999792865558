<div class="modal">
  <div class="toolbar space-between">
    <h3 class="title">
      {{ "company.size.title" | transloco }}
    </h3>
    <button mat-icon-button aria-label="close dialog" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>


  <div class="hr"></div>

  <div class="explanation">
    <p>
      <label
        >{{ "company.size.explanation" | transloco }}
      </label>
    </p>
  </div>

  <div class="fields">

    <div class="fieldset">
      <label for="companySize">{{"company.size.label" | transloco}}:</label>
      <select name="companySize" ng-model="data.companySize">
        <option value="1-10">1 - 10</option>
        <option value="11-50">11 - 50</option>
        <option value="51-250">51 - 250</option>
        <option value="251">251+</option>
      </select>
    </div>

  </div>

  <div class="hr"></div>

  <div class="update-button-container">
    <button
      id="update-button"
      name="update-button"
      aria-labelledby="update-button-label"
      class="btn btn-md btn-info update-button">
      <label>
        {{ "billing.usage.update" | transloco }}
      </label>
      <mat-icon>play_circle_filled</mat-icon>
    </button>
  </div>
</div>

