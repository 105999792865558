import { LoginModel, ssoResponseError, ssoResponseSuccess } from "../../models/login.model";
import { LoginResponseModel } from "../../models/login.response.model";

export interface State {
  login: LoginModel;
  response: LoginResponseModel;
  isBusy: boolean;
  isLoggedIn: boolean;
  isLoggedOut?: boolean | undefined;
  isError: boolean;
  apiError?: any;
  ssoSuccess: ssoResponseSuccess;
  ssoErrors: ssoResponseError[] | undefined;
  forgotLoginId: boolean;
  forgotLoginIdPayload: { user_email: string | undefined, brand: string | undefined };
  forgotLoginIdSent: boolean;
  forgotLoginIdFailed: boolean;
  failedAttempts: number;
}

export const initialState: State = {
  login: { username: "", password: "" },
  response: new LoginResponseModel(),
  isBusy: false,
  isLoggedIn: false,
  isLoggedOut: true,
  isError: false,
  apiError: undefined,
  ssoSuccess: { redirect_url: "", connection_id: "" },
  ssoErrors: undefined,
  forgotLoginId: false,
  forgotLoginIdPayload: { user_email: undefined, brand: undefined },
  forgotLoginIdSent: false,
  forgotLoginIdFailed: false,
  failedAttempts: 0
};
