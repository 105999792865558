import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import {Contact, ContactList, ContactListParams, ContactSortBy, ContactSortInput} from '../models/contact.model';
import {
  ContactsTableData,
  TABLE_DATA,
  CONTACT_DEFAULT_COLUMNS,
  CHECK_NEW_MESSAGES_INTERVAL,
  SORTABLE_COLUMNS,
  OFFSET_DEFAULT,
  DEFAULT_CONTACTS_SORT_INPUT
} from '../models/table.model';
import { filter, take } from 'rxjs/operators';
import { ContactsFacadeService } from '../services/contacts-facade.service';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ContactOperationStatus, SortDirection } from '../models/shared.model';
import { AuthFacadeService } from 'src/app/auth/services/auth-facade.service';
import { MatListOption } from '@angular/material/list';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogContactDelete } from './delete-contact-dialog.component';
import { DEFAULT_LIMIT_CONTACTS_QUERY, FEEDBACK_DURATION } from 'src/app/shared/constants/constants';
import { MatDialog } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { LoadingService } from "../../shared/services/loading.service";

@Component({
  selector: 'contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit, AfterViewInit, OnDestroy {
  serviceKey: string;
  contactsObject: ContactList;
  contacts: Contact[] | [];
  hovered: boolean;
  contact: Contact;
  recentCheckedId: string;
  addEditFlag = false;
  contactOperationStatus: ContactOperationStatus | undefined;

  protected readonly contactsHeader = CONTACT_DEFAULT_COLUMNS;
  protected readonly rowPerPage = DEFAULT_LIMIT_CONTACTS_QUERY;

  dataSource = new MatTableDataSource(TABLE_DATA);
  selection = new SelectionModel<ContactsTableData>(true, []);
  private currentSort$: Subject<ContactSortInput> = new Subject<ContactSortInput>();
  private subscriptions: Subscription[] = [];
  private terminated$: Subject<boolean> = new Subject<boolean>();
  private periodicalTimer = 0;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  search: any;
  sortState: any;
  searchText: any;
  isPageNumberOutOfPage: boolean

  constructor(
    readonly contactsFacadeService: ContactsFacadeService,
    private authFacadeService: AuthFacadeService,
    public _MatPaginatorIntl: MatPaginatorIntl,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    public loadingService: LoadingService,
    public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.subscribeToCurrentService();
    this.subscribeToQueryParams();
    this.subscribeToContactOperationStatus();
    clearInterval(this.periodicalTimer);
    this.periodicalTimer = this.setupPeriodicalContactsCheck();
    this.subscribeToContactList();
    this.currentSort$.next(DEFAULT_CONTACTS_SORT_INPUT);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  get pageIndex(){
    if (!this.paginator){
      return 1
    } else {
      return this.paginator?.pageIndex + 1
    }
  }

  private subscribeToContactOperationStatus() {
    this.subscriptions.push(
      this.contactsFacadeService.contactOperationStatus$
        .pipe(filter(value => !!value))
        .subscribe(value => {
          this.showOperationStatus(value);
        })
    );
  }

  private showOperationStatus(status: ContactOperationStatus | undefined) {
    this.contactOperationStatus = status;
    setTimeout(() => this.contactOperationStatus = undefined, FEEDBACK_DURATION);
  }

  private subscribeToQueryParams() {
    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        this.search = params.search;
      })
    );
  }

  goToPage($event: any) {
    const pageNumber = $event.target.value
    const isInvalidPage = pageNumber < 1 || pageNumber > (Math.ceil(this.paginator.length / DEFAULT_LIMIT_CONTACTS_QUERY));
    if (!isInvalidPage) {
      this.isPageNumberOutOfPage = false;
      this.paginator.pageIndex = pageNumber - 1;
      this.paginator.page.next({
        pageIndex: this.paginator.pageIndex,
        pageSize: this.paginator.pageSize,
        length: this.paginator.length
      });
    } else {
      this.isPageNumberOutOfPage = true
    }
  }

  private subscribeToCurrentService() {
    this.subscriptions.push(
      this.authFacadeService.currentService$.subscribe(service => {
        if (service !== undefined) {
          this.serviceKey = service.serviceKey;
          const contactParams = this.createContactListParams()
          this.contactsFacadeService.dispatchLoadContactsList(contactParams);
        }
      })
    );
  }

  private subscribeToContactList() {
    this.subscriptions.push(
      this.contactsFacadeService.contactsList$
        .subscribe(contactsObject => {
          if (contactsObject != undefined) {
            this.contacts = contactsObject.contacts;
            this.contactsObject = contactsObject;
            this.populateTable(this.contacts);
          }
        })
    );
  }

  private createContactListParams(
    offset: Number = this.contactsObject !== undefined ? Number(this.contactsObject.offset) : Number(OFFSET_DEFAULT),
    sortBy: ContactSortBy = ContactSortBy.FIRST_NAME,
    sortDirection: SortDirection = SortDirection.ASC
  ): ContactListParams {
    return  {
      limit: (DEFAULT_LIMIT_CONTACTS_QUERY < this.contacts?.length) ? this.contacts?.length ?? DEFAULT_LIMIT_CONTACTS_QUERY : DEFAULT_LIMIT_CONTACTS_QUERY,
      offset: offset,
      sortBy: sortBy,
      sortDirection: sortDirection
    }
  }

  addEditContact() {
    this.contactsFacadeService.dispatchAddEditContact(undefined, undefined);
  }

  protected get pageCount() {
    if (this.paginator === undefined) {
      return 1;
    }
    return Math.ceil(this.paginator.length / DEFAULT_LIMIT_CONTACTS_QUERY);
  }

  startDeleteContact() {
    const dialog = this.dialog.open(DialogContactDelete, {
      backdropClass: 'cdk-overlay-transparent-backdrop',
      hasBackdrop: true,
      disableClose: true,
      data: { contacts: this.selection.selected }
    });

    dialog.afterClosed().pipe(take(1)).subscribe((resultList: ContactsTableData[]) => {
      if (resultList) {
        if (resultList.length === 1) {
          this.submitDeleteContact(resultList[0].contactId);
        } else {
          this.submitDeleteList(resultList.map(v => v.contactId));
        }
      }
      this.selection.clear();
    });
  }

  private submitDeleteContact(contactId: string) {
    this.selection.clear();
    const listParams = this.createContactListParams(this.paginator.pageIndex * this.paginator.pageSize);
    this.contactsFacadeService.dispatchDeleteContact({
      input: { id: contactId }, list: listParams
    });
  }

  private submitDeleteList(contactList: string[]) {
    this.selection.clear();
    const listParams = this.createContactListParams(this.paginator.pageIndex * this.paginator.pageSize);
    this.contactsFacadeService.dispatchDeleteContactList(contactList, listParams);
  }

  pageChanged($event: PageEvent) {
    this.selection.clear();
    this.isPageNumberOutOfPage = false
    const contactPrams = this.createContactListParams($event.pageIndex * $event.pageSize)
    this.contactsFacadeService.dispatchLoadContactsList(contactPrams)
  }

  private setupPeriodicalContactsCheck() {
    return window.setInterval((a: number) => {
      this.contactsFacadeService.dispatchLoadContactsList(this.createContactListParams());
    }, CHECK_NEW_MESSAGES_INTERVAL, 1);
  }

  /**
   * Fill contacts table
   * @param contacts list of contacts
   * @returns undefined if no contact
   */
  private populateTable(contacts: Contact[] | undefined) {

    if (!contacts) {
      return;
    }

    const array: ContactsTableData[] = contacts.map(el => {
      return {
        ...el,
        name: (el.firstName !== null ? el.firstName + ' ' : '') + (el.lastName !== null ? el.lastName : ''),
      }
    })

    this.dataSource = new MatTableDataSource(array);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(event: MatCheckboxChange) {
    if (!event.checked) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  checkboxLabel(row?: ContactsTableData): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
  }

  tdClicked(clickedId: string) {
    const selectedContact = this.contacts.find(v => v.contactId === clickedId);
    if (selectedContact !== undefined) {
      this.contactsFacadeService.dispatchAddEditContact(selectedContact, clickedId);
    }
    this.selection.clear();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  toggleDirection(direction: string) {
    this.selection.clear();

    if (direction === 'asc') {
      return SortDirection.ASC
    } else {
      return SortDirection.DESC
    }
  }

  announceSortChange(sortState: any) {
    this.sortState = sortState;

    if (SORTABLE_COLUMNS.includes(this.sortState.active)) {
      const contactParams = this.createContactListParams(
        (this.paginator.pageIndex * this.paginator.pageSize),
        sortState.active == "name" ? ContactSortBy.FIRST_NAME : sortState.active,
        this.toggleDirection(sortState.direction)
      )
      this.contactsFacadeService.dispatchLoadContactsList(contactParams)
    }
  }

  searchMessages(search: string) {
    if (search != '') {
      this.router.navigate(['/contacts'], { queryParams: { search: search } });
    } else {
      this.router.navigate(['/contacts']);
    }
  }

  resetTable() {
    this.paginator.firstPage();
    this.searchMessages('');
  }

  contactChange(options: MatListOption[]) {
    this.resetTable();
  }

  ngOnDestroy(): void {
    this.terminated$.next(true);
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    clearInterval(this.periodicalTimer);
    this.periodicalTimer = 0;
  }

  get recordsLength() {
    return Number(this.contactsObject !== undefined && this.contactsObject !== null? this.contactsObject.totalRecords : 0)
  }

}
