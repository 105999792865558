import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { Language } from './language';
import { LOCAL_STORAGE_KEY_CURRENT_LANGUAGE } from '../constants/constants';

@Injectable()
export class LangStateService {
  
  private changing$ = new BehaviorSubject<string>(this.translocoService.getDefaultLang());

  constructor(private translocoService: TranslocoService) {}

  isChanging$() {
    return this.changing$.asObservable();
  }

  setActiveLang(language: string) {
    this.translocoService.setActiveLang(language);
    localStorage.setItem(LOCAL_STORAGE_KEY_CURRENT_LANGUAGE, language)
    this.changing$.next(language);
  }

  getSelectedLanguage(): string {
    return this.translocoService.getActiveLang();
  }

  getAvailableLanguages() : Observable<Language[]> {   
    return this.translocoService.selectTranslateObject('available.languages') as Observable<Language[]>           
  }
  
  translate(id: string): Observable<string> {        
    return this.translocoService.selectTranslateObject(id);
  }
}

