import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ReportsService, SentFax } from '../../services/reports.service';
import { compare } from '../../../shared/functions/compare';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { stringToDateStandard } from 'src/app/shared/functions/stringToDateStandard';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { formatPhoneNumber } from 'src/app/shared/pipes/format-phone-number.pipe';

@Component({
  selector: 'myportal-sent-logs-table',
  templateUrl: './sent-logs-table.component.html',
  styleUrls: ['./sent-logs-table.component.scss']
})
export class SentLogsTableComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('paginator') paginator: MatPaginator;

  terminated$: Subject<boolean> = new Subject<boolean>();
  displayedColumns: string[] = ['date', 'to', 'pages', 'charge', 'subject', 'status'];
  dateRange: { start: string, end: string };
  totalElements: number;
  dataListSentLogs: SentFax[] = [];
  dataSource = new MatTableDataSource(this.dataListSentLogs);

  readonly pageSize: number = 15;
  pageIndex: number = 0;

  constructor(private loadingService: LoadingService,
    private reportsService: ReportsService
  ) { }
  ngOnDestroy(): void {
    this.terminated$.next(true);
  }

  ngOnInit(): void {
    this.reportsService.sendFilter$.pipe(takeUntil(this.terminated$)).subscribe(range => {
      this.dateRange = { start: range.start, end: range.end };
      this.reload();
    });
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a: SentFax, b: SentFax) => {
      const key: string = sort.active;
      const direction = sort.direction === 'asc';
      return compare(a[key as keyof SentFax], b[key as keyof SentFax], direction);
    });
  }
  get getTotalPage(): number {
    return Math.ceil(this.totalElements / this.pageSize);
  }

  isPageNumberOutOfPage() {
    return this.pageIndex < 0 || this.pageIndex >= this.getTotalPage;
  }

  pageChanged(event: PageEvent) {
    if (this.isPageNumberOutOfPage()) {
      return
    }
    this.pageIndex = event.pageIndex;
    this.reload();
  }

  private reload() {
    this.loadingService.setLoading(true);
    const convertedStart = stringToDateStandard(this.dateRange.start);
    const convertedEnd = stringToDateStandard(this.dateRange.end);
    this.reportsService.getUsageSentLogs(convertedStart,
      convertedEnd, this.pageIndex, this.pageSize).subscribe(res => {
        this.dataSource = new MatTableDataSource(res.content);
        this.dataSource.data.forEach(d => {
          d.destination = formatPhoneNumber(d.destination);
          d.formattedMessageTime = this.formatMessageTime(d.formattedMessageTime);
          d.pages = (d.pages === null || d.pages === undefined) ? 0 : d.pages;
        });
        this.totalElements = res.totalElements;
      },
        (err) => {
          console.log("Error on getting page:", err);
        },
        () => {
          this.loadingService.setLoading(false);
        })
  }

  formatMessageTime(messageTime: string): string {
    const dateTime = new Date(Date.parse(messageTime));
    const date = dateTime.toISOString();
    const time = dateTime.toTimeString().split(' ')[0];
    return `${date.substring(5, 7)}/${date.substring(8, 10)}/${date.substring(0, 4)} ` + time.substring(0, 5);
  }
}
