<div class="error-snack" *ngIf="showFolderError">
  <span>{{ 'error.folder.createErrorMessage' | transloco }}</span>
</div>

<div class="error-snack" *ngIf="showRenameFolderError">
  <span>{{ 'error.folder.renameErrorMessage' | transloco }}</span>
</div>

<div class="snack-success" *ngIf="showFolderSuccess">
  <span>{{ 'sucess.createFolderSuccessMessage' | transloco }}</span>
</div>

<div class="snack-success" *ngIf="showFolderDeleteSuccess">
  <span>{{ 'sucess.deleteFolderSuccessMessage' | transloco }}</span>
</div>