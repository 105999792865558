import { ForwardRecipient, Recipient } from './recipient.model';

export interface ApiError {
    object_type: string
    code: string
    message: string
}

export class AttachmentModel {
    url: string;
    fileName: string;
    faxId: string;
    size: number = 0;
}

export interface ComposeModel {
    currentRecipient: Recipient;
    recipients: Recipient[],
    coverPage: {
        includeCoverPage: boolean,
        coverTitle: string,
        coverText: string,
    },
    additionalSettings: {
        faxQuality: string,
        sendEmailAddress: string,
        referenceId: string
    },
    keepOriginalAttachments?: boolean,
    originalAttachments?: AttachmentModel[],
    userId?: string
}

export interface CreateFax {
    apiClientIpAddress: string;
    resolution: string
    toName: string
    toCompany: string
    subject: string
    billingCode: string
    clientCode: string
    clientId: string
    clientMatter: string
    clientName: string
    clientReferenceId: string
}

export interface Fax {
    faxId: string
}

export interface FaxCreate {
    object_type: string
    fax: Fax
    s3Url: string
}

export interface ForwardFaxSingle {
    faxId: string
    recipients: ForwardRecipient[]
    serviceKey: string
    outboundServiceKey: string
    fromEmail: string
    note: string | undefined
}

export interface ForwardFaxMultiple {
    faxIds: string[]
    recipients: ForwardRecipient[]
    serviceKey: string
    outboundServiceKey: string
    fromEmail: string
    note: string | undefined
    subject: string | undefined
}

export interface ForwardFaxSingleFax {
    object_type: string
    status: boolean
}

export interface ForwardFaxMultipleFax {
    object_type: string
    faxIdSuccess: string[]
    faxIdFail: string[]
}

export interface ForwardFaxToEmail {
    object_type: string
    emailSuccess: string[]
    emailFail: string[]
}

export enum ComposeActionTypes {
    NEW,
    UPDATE,
    REPLY,
    FOWARD_FAX_SINGLE,
    FOWARD_FAX_MULTIPLE
}

export interface ComposeAction {
    type: ComposeActionTypes | undefined;
    form: ComposeModel | undefined;
    faxId: string | string[] | undefined;
    serviceKey: string | undefined
}

export type ForwardFaxSingleFaxResult = ForwardFaxSingleFax | ApiError;

export type ForwardFaxMultipleFaxesResult = ForwardFaxMultipleFax | ApiError;

