<div class="section">
  <a tabindex="0"
     href="javascript:void(0)"
     class="{{!contactListCount ? 'inactive': ''}} box-title box-opener"
     (click)="this.hideSection = !this.hideSection">
    <h5 id="selected-contacts-title">{{ 'compose.h5.selected.contacts.title.text' | transloco }}
      <span>{{this.contactListCount}} </span>
      <span>{{ 'of' | transloco }}</span>
      <span> {{this.maxAvailableSelection}}</span>
    </h5>
      <div *ngIf="hideSection" class="icon icon-chevron-down"></div>
      <div *ngIf="!hideSection" class="icon icon-chevron-up"></div>
  </a>

  <div class="recipients">
    <div [@showHideRow]="hideSection ? 'open' : 'close'"
         class="recipient-item" *ngFor="let contact of contactList; let i = index;">
      <div class="text-ellipsis">
        <span class="icon icon-contacts"></span>
        {{ formatName(contact) }}
        <button tabindex="0" class="singular remove-recipient-button" (click)="removeRecipient(i)">X</button>
      </div>
    </div>
  </div>
</div>
