import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingService } from './services/billing.service';
import { BillingRoutingModule } from './billing-routing.module';
import { BillingComponent } from './components/billing/billing.component';
import { AddPaymentComponent } from './components/add-payment/add-payment.component';
import { TranslocoModule } from '@ngneat/transloco';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { SummaryComponent } from './components/summary/summary.component';
import { SummaryTableComponent } from './components/summary-table/summary-table.component';
import { RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { UsageBalanceComponent } from './components/usage-balance/usage-balance.component';
import { BillingSettingsComponent } from './components/billing-settings/billing-settings/billing-settings.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    BillingComponent,
    AddPaymentComponent,
    UsageBalanceComponent,
    SummaryComponent,
    SummaryTableComponent,
    BillingSettingsComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    BillingRoutingModule,
    FormsModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatIconModule,
    SharedModule,
    ReactiveFormsModule,
    RouterLink,
    SharedModule
  ],
  providers: [BillingService]
})
export class BillingModule { }


