<div class="items">
    <ng-container *ngFor="let imx of _imageList; let i = index">
      <img (load)="onLoad(i)" src="{{imx}}" class="fax center" id="image-{{i}}" [alt]="i" style="width: 100%; "/>
    </ng-container>

  <ng-container *ngIf="_imageList?.length === 0">
    <img src="{{defaultPreview}}" class="center-no-preview" id="image-no-preview" [alt]="'no-preview'"/>
  </ng-container>
</div>

