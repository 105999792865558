<main class="main">
    <div class="content pb-30">

        <div class="upper-section">
            <div>
                {{'sidebar.menu.reports.text' | transloco }}
            </div>
            <hr class="separator-line">
            <div class="option-section">
                {{ 'reports.usage' | transloco }}
            </div>
        </div>

        <div class="listing">
            <!-- First column, report data -->
            <div class="article">
                <div class="subtitle-section">
                    {{ 'reports.description' | transloco }}
                </div>
                <div class="brief-section">
                    {{ 'reports.sidenote' | transloco }}
                </div>
                <div class="pb-30"></div>
                <!-- plans -->
                <div class="local-row">
                    <!-- <div>CURRENT PLAN</div>
                    <div>eFax Plus</div>
                    <div></div> -->
                </div>
                <!-- received faxes -->
                <div class="local-row">
                    <div>{{ 'reports.received' | transloco }}</div>
                    <div>{{ usageData.receivedFaxes }}</div>
                    <div class="hand" (click)="goToInbound()">
                        <mat-label>&#x2BC8;{{ 'reports.activity' | transloco }}</mat-label>
                    </div>
                </div>
                <!-- sent faxes -->
                <div class="local-row">
                    <div>{{ 'reports.sent' | transloco }}</div>
                    <div>{{ usageData.sentFaxes }}</div>
                    <div class="hand" (click)="goToOutbound()">
                        <mat-label>&#x2BC8;{{ 'reports.activity' | transloco }}</mat-label>
                    </div>
                </div>
                <!-- storage -->
                <div class="local-row">
                    <div>{{ 'reports.storage' | transloco }}</div>
                    <div>{{ usageData.storageCode | titlecase }}</div>
                    <div></div>
                </div>
            </div>
            <!-- Second column, buttons
            <div class="article second-column"></div> -->
            <!-- third column, extra space -->
            <!-- <div class="article"></div> -->
        </div>
    </div>
</main>