import * as fromViewMessages from './reducers/view-messages.reducer';
import * as fromRoot from '../../auth/store/reducers';
import { ActionReducerMap } from '@ngrx/store';
import { loadFolderListBegin } from './actions/view-messages.actions';

export interface ViewMessagesFeatureState {
    viewMessagesFeatureState: fromViewMessages.ViewMessagesState;    
}

export interface State extends fromRoot.AppState {
    viewMessagesFeatureState: ViewMessagesFeatureState;
}

export const reducers:
    ActionReducerMap<ViewMessagesFeatureState, typeof loadFolderListBegin> = {
    viewMessagesFeatureState: fromViewMessages.reducer
}
