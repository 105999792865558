import { Injectable } from '@angular/core';

export enum CardType {
  CreditCard = 'creditCard',
  DebitCard = 'debitCard',
  DirectCard = 'directCard',
  newCard = 'newCard'
}

export interface BillingDetails {
  usageBalanceValue: string,
  subscriptionRate: string,
  nextPaymentDate: string,
  statements: string,
}

export interface CardDetails {
  name: string;
  number: string;
  expDate: string;
  cvc: string;
  billingAddress: string;
  city: string;
  zipCode: string;
  state: string;
  country: string;
  lastDigitsRouting?: string;
  lastDigitsAccount?: string;
}

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  
  private cards: Record<CardType, CardDetails> = {
    [CardType.CreditCard]: {
      name: 'John Doe',
      number: '•••• •••• •••• 1111',
      expDate: '12/24',
      cvc: '123',
      billingAddress: '1234 Main St',
      city: 'Anytown',
      zipCode: '12345',
      state: 'California',
      country: 'USA'
    },
    [CardType.DebitCard]: {
      name: 'Jane Doe',
      number: '•••• •••• •••• 2222',
      expDate: '11/23',
      cvc: '321',
      billingAddress: '5678 Side St',
      city: 'Othertown',
      zipCode: '54321',
      state: 'California',
      country: 'USA'
    },
    [CardType.DirectCard]: {
      name: 'Jim Beam',
      number: '•••• •••• •••• 3333',
      expDate: '10/22',
      cvc: '456',
      billingAddress: '7890 Circle Ave',
      city: 'Roundtown',
      zipCode: '67890',
      state: 'California',
      country: 'USA',
      lastDigitsAccount: 'XXXX',
      lastDigitsRouting: 'XXXX'
    },
    [CardType.newCard]: {
      name: '',
      number: '•••• •••• •••• 0000',
      expDate: '',
      cvc: '',
      billingAddress: '',
      city: '',
      zipCode: '',
      state: '',
      country: ''
    },
  };

  private billing: BillingDetails = {
    usageBalanceValue: '$4.50 (USD)',
    subscriptionRate: '$0.00 (USD) PER MONTH',
    nextPaymentDate: '05/03/25',
    statements: 'Last Reported in 05/02/25'
  }

  getCards(): Record<CardType, CardDetails> {
    return this.cards;
  }

  getCardDetails(type: CardType): CardDetails {
    return this.cards[type];
  }

  setCardDetails(type: CardType, details: CardDetails): void {
    this.cards[type] = details;
  }

  setBillingDetails(billingDetails: BillingDetails): void {
    this.billing = billingDetails;
  }

  getBillingDetails() {
    return this.billing;
  }

  addNewCard(card: CardDetails) {
    this.cards[CardType.newCard] = card;
    this.getCards();
  }
  
}