import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { catchError, tap, filter, map, switchMap } from 'rxjs/operators';
import { ViewMessagesApi } from '../../api/view-messages.api';
import { FaxList } from '../../models/fax.model';
import { Folder, FolderSingle } from '../../models/folder.model';
import { ApiError } from '../../models/shared.model';
import {
  loadFolderListBegin,
  loadFolderListSuccess,
  loadFolderListFailed,
  loadFaxListBegin,
  loadFaxListFailed,
  loadFaxListSuccess,
  createNewFolderBegin,
  createNewFolderSuccess,
  createNewFolderFailed,
  deleteFolderBegin,
  deleteFolderSuccess,
  deleteFolderFailed,
  moveFaxesBegin,
  moveFaxesFailed,
  moveFaxesSuccess,
  printingSelectedFaxes,
  markFaxReadUnreadBegun,
  markFaxReadUnreadEnded,
  deleteFaxSingleBegin,
  deleteFaxSingleEnd,
  deleteFaxListBegin,
  deleteFaxListEnd,
  downloadFaxStart,
  downloadFaxEnd,
  displaySingleFax,
  createTagBegin,
  createTagEnd,
  deleteTagBegin,
  deleteTagEnd,
  reloadFaxSingleStart,
  reloadFaxSingleEnd,
  bulkDownload,
  reloadFolderStarts,
  reloadFolderEnds,
  refreshCacheFolder,
  refreshCacheFolderEnds, renameFolderSuccess, renameFolderFailed, renameFolderBegin
} from '../actions/view-messages.actions';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ViewMessagesEffects {

  constructor(
    private router: Router,
    private actions$: Actions,
    private viewMessagesApi: ViewMessagesApi,
    private http: HttpClient) { }

  loadFolderListBegin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFolderListBegin),
      switchMap((action) => {
        return combineLatest([this.viewMessagesApi.fetchFolderList(action.serviceKey), this.viewMessagesApi.fetchFolderList(action.outboundServiceKey)])
          .pipe(
            map(([inbox, outbox]) => {
              if ((inbox as any).folderList?.object_type === 'FolderList') {
                try {
                  const folderList: any = (inbox as any).folderList;
                  if ((outbox as any).folderList?.object_type === 'FolderList') {
                    const outboxList = (outbox as any).folderList;
                    const outboxSend = outboxList.folder?.filter((fld: Folder) => fld.id === "SENT");
                    if (outboxSend !== undefined) {
                      folderList.folder?.push(...outboxSend);
                    }
                  }
                  return loadFolderListSuccess({ folderList: folderList })
                } catch (err) {
                  const folderError = inbox as ApiError;
                  return loadFolderListFailed({ errorMessage: folderError })
                }
              } else if ((outbox as any).folderList?.object_type === 'FolderList') {
                try {
                  const folderList: any = (outbox as any).folderList;
                  return loadFolderListSuccess({ folderList: folderList })
                } catch (err) {
                  const folderError = inbox as ApiError;
                  return loadFolderListFailed({ errorMessage: folderError })
                }
              }
              else {
                let folderError: any; //API_ERROR
                if (inbox.object_type === 'API_ERROR') {
                  folderError = inbox as ApiError;
                } else {
                  folderError = outbox as ApiError
                }
                return loadFolderListFailed({ errorMessage: folderError })
              }
            })
          );
      })
    )
  );

  loadFaxListBegin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFaxListBegin),
      switchMap((action) => {
        return this.viewMessagesApi.fetchFaxList({
          serviceKey: action.payload.inboundServiceKey,
          filter: action.payload.filter, sort: action.payload.sort, offset: action.payload.offset, limit: action.payload.limit
        }).pipe(map((r: any) => (r.faxList as FaxList)),
          map(fullList => {
            return loadFaxListSuccess({ faxList: fullList });
          }))
      })
      , catchError((err) => of(loadFaxListFailed({ errorMessage: { object_type: "ApiError", code: "LOAD", message: "Error loading list of search results" } })))
    )
  )

  createFolderBegin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createNewFolderBegin),
      switchMap((action) => {
        return this.viewMessagesApi.createNewFolder(action.payload)
          .pipe(
            map((response: any) => {
              if (response.data.folderCreate?.__typename === 'FolderCreate') {
                return createNewFolderSuccess({
                  payload: {
                    serviceKey: action.payload.serviceKey,
                    folder: response.data.folderCreate.folder
                  }
                })
              }
              return createNewFolderFailed({ errorMessage: response })
            })
          );
      })
    )
  );

  renameFolderBegin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(renameFolderBegin),
      switchMap((action) => {
        return this.viewMessagesApi.renameFolder(action.payload)
          .pipe(
            map((response: any) => {
              if (response.data.folderUpdate?.__typename === 'FolderUpdate') {
                return renameFolderSuccess({
                  payload: {
                    serviceKey: action.payload.serviceKey,
                    folder: response.data.folderUpdate.folder
                  }
                })
              }
              return renameFolderFailed({ errorMessage: response })
            })
          );
      })
    )
  );

  deleteFolderBegin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteFolderBegin),
      switchMap((action) => {
        return this.viewMessagesApi.deleteFolder(action.payload)
          .pipe(
            map((response: any) => {
              if (response.data.folderDelete?.__typename === 'FolderDelete') {
                return deleteFolderSuccess({
                  payload: {
                    serviceKey: action.payload.serviceKey,
                    folder: response.data.folderDelete.folder
                  }
                })
              }
              return deleteFolderFailed({ errorMessage: response })
            })
          );
      })
    )
  );

  moveFaxes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(moveFaxesBegin),
      switchMap((action) => {
        return this.viewMessagesApi.moveFaxes(action.payload)
          .pipe(
            map((response: any) => {
              if (response.data?.faxMultipleMove?.object_type === 'FaxMultipleMove') {
                return moveFaxesSuccess({
                  payload: {
                    input: response.data.folderId
                  }
                })
              }
              return moveFaxesFailed({ errorMessage: response.data.ApiError })
            })
          );
      })
    )
  );

  markFaxReadUnreadStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(markFaxReadUnreadBegun),
      switchMap((action) => {
        return combineLatest(
          action.faxId.map(
            oneFaxId => this.viewMessagesApi.updateFaxReadUnRead(action.serviceKey, oneFaxId, action.input.isRead)
              .pipe(map((r: any) => r?.data?.faxSingleUpdate?.fax?.faxId as string))
          )
        )
          .pipe(
            map(response => {
              return markFaxReadUnreadEnded({ serviceKey: action.serviceKey, faxes: response })
            })
          )
          .pipe(catchError(val => of(createNewFolderFailed({ errorMessage: { object_type: 'ApiError', code: '', message: val } }))))
      })
    )
  );

  deleteFaxSingleStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteFaxSingleBegin),
      switchMap((action) => {
        return this.viewMessagesApi.deleteSingleFax(
          action.serviceKey,
          action.faxId,
          action.flush,
          action.folderId)
          .pipe(
            map((response: any) => {
              if (response?.data?.faxSingleDelete?.__typename === 'FaxSingleDelete') {
                return deleteFaxSingleEnd({ serviceKey: action.serviceKey, filter: action.filter, errorCode: undefined, errorMessage: undefined });
              } else {
                return deleteFaxSingleEnd({ serviceKey: action.serviceKey, filter: undefined, errorCode: response?.data?.apiError?.code, errorMessage: response?.data?.apiError?.message });
              }
            })
          )
      })
    )
  );


  deleteFaxListStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteFaxListBegin),
      switchMap((action) => {
        return this.viewMessagesApi.deleteListFax(action.serviceKey, action.faxIds, action.flush, action.folderId)
          .pipe(
            map((response: any) => {
              if (response?.data?.faxListDelete?.__typename === 'FaxListDelete' || response?.data?.faxMultipleRemoveFromCustomFolder?.__typename === 'FaxMultipleRemoveFromCustomFolder') {
                const ids = response?.data?.faxListDelete?.faxIds | response?.data?.faxMultipleRemoveFromCustomFolder?.faxIds;
                const faxids = ids as unknown as string[];
                return deleteFaxListEnd({ serviceKey: action.serviceKey, faxIds: faxids, filter: action.filter, errorCode: undefined, errorMessage: undefined });
              } else {
                return deleteFaxListEnd({ serviceKey: action.serviceKey, faxIds: [], filter: undefined, errorCode: response?.data?.ApiError?.code, errorMessage: response?.data?.ApiError?.message });
              }
            })
          )
      })
    )
  );

  downloadFaxStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(downloadFaxStart),
      switchMap((action) => {
        return this.viewMessagesApi.downloadFileFromUrl(action.urlToDownload)
          .pipe(
            map(oneFile => downloadFaxEnd({ fileName: action.fileName, fileContent: oneFile })
            ))
      }
      )
    )
  );

  downloadFaxEnd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(downloadFaxEnd),
      map(action => {
        const theFile = new File([action.fileContent], action.fileName);
        const urlToDownload = window.URL.createObjectURL(theFile);
        window.open(urlToDownload, "_blank");
      })
    ),
    { dispatch: false }
  );

  displayCurrentFax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(displaySingleFax),
      tap(() => {
        this.router.navigate(['/view-messages/single'], { skipLocationChange: true });
      }),
      map(action => {
        return markFaxReadUnreadBegun({
          serviceKey: action.currentFax.serviceKey,
          faxId: [action.currentFax.faxId], input: { isRead: true, apiClientIpAddress: '0.0.0.0' }
        });
      })
    )
  );

  createTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createTagBegin),
      switchMap(action => this.viewMessagesApi.tagAdd(action.serviceKey, action.faxId, action.tag)
        .pipe(
          map((result: any) => {
            if (result?.data?.faxTagAdd?.__typename === 'FaxTagAdd') {
              return createTagEnd({ tags: { ...result?.data?.faxTagAdd?.tags }, errorCode: undefined, errorMessage: undefined, fromSingle: action.fromSingle, serviceKey: action.serviceKey, faxId: action.faxId });
            } else {
              return createTagEnd({ tags: [], errorCode: result?.data?.ApiError?.code, errorMessage: result?.data?.ApiError?.message, fromSingle: action.fromSingle, serviceKey: action.serviceKey, faxId: action.faxId });
            }
          })
        )
      )
    )
  );

  createTagEnd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createTagEnd),
      filter(action => action.fromSingle),
      map(action => reloadFaxSingleStart({ serviceKey: action.serviceKey, faxId: action.faxId }))
    )
  );

  deleteTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTagBegin),
      switchMap(action => this.viewMessagesApi.tagDelete(action.serviceKey, action.faxId, action.tag)
        .pipe(
          map((result: any) => {
            if (result?.data?.faxTagDelete?.__typename === 'FaxTagDelete') {
              return deleteTagEnd({ tags: { ...result?.data?.faxTagDelete?.tags }, errorCode: undefined, errorMessage: undefined, fromSingle: action.fromSingle, serviceKey: action.serviceKey, faxId: action.faxId });
            } else {
              return deleteTagEnd({ tags: [], errorCode: result?.data?.ApiError?.code, errorMessage: result?.data?.ApiError?.message, fromSingle: action.fromSingle, serviceKey: action.serviceKey, faxId: action.faxId });
            }
          })
        )
      )
    )
  );

  deleteTagEnd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTagEnd),
      filter(action => action.fromSingle),
      map(action => reloadFaxSingleStart({ serviceKey: action.serviceKey, faxId: action.faxId }))
    )
  );

  downloadBlob(url: string, name: string) {

    const link = document.createElement('a');
    link.href = url;
    link.download = name;

    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(url);
      link.remove();
    }, 100);
  }

  bulkdownload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(bulkDownload),
      switchMap(action => this.viewMessagesApi.downloadBulk(action.serviceKey, action.faxIds)
        .pipe(map((result: any) => result?.data?.multipleFaxDownload),
          map((data: any) => {

            if (!!data?.downloadUrl) {

              window.open(environment.faxClientAPIHost + data.downloadUrl, '_blank');

            } else {
              // it should return an action with a value not a direct value, also is not returning to anything
              // return data?.multipleFaxDownload?.faxIdFail
              console.log(`Can't download file for faxids: `, data?.faxIdFail);
            }
          })
        )
      )
    ), { dispatch: false }
  );

  reloadSingleFax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reloadFaxSingleStart),
      switchMap(action => this.viewMessagesApi.fetchSingleFax(action.serviceKey, action.faxId)
        .pipe(
          map((result: any) => {
            if (result?.faxSingle?.__typename === 'FaxSingle') {
              return reloadFaxSingleEnd({ fax: { ...result?.faxSingle?.fax }, errorCode: undefined, errorMessage: undefined });
            } else {
              return reloadFaxSingleEnd({ fax: undefined, errorCode: result?.ApiError?.code, errorMessage: result?.ApiError?.message });
            }
          })
        )
      )
    )
  );

  reloadFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reloadFolderStarts),
      switchMap(action => this.viewMessagesApi.queryFolderSingle({
        serviceKey: action.serviceKey,
        name: action.name, offset: action.offset, limit: action.limit, sort: action.sort
      })
        .pipe(
          map((r: any) => r.folderSingle),
          map(r => {
            if (r.object_type !== 'API_ERROR') {
              return reloadFolderEnds({ folder: (r as FolderSingle).folder })
            } else {
              return loadFaxListFailed({ errorMessage: r as ApiError })
            }
          })
        )
      ),
      catchError((err) => of(loadFaxListFailed({ errorMessage: { object_type: 'ApiError', code: '150', message: 'Error loading the list.' } })))
    )
  );
}
