<div class="content">
  <h5 class="heading">{{'profile.update.your.preferences' | transloco }}</h5>
  <p class="heading">{{'profile.update.note' | transloco }}</p>

  <ng-container *ngTemplateOutlet="menu;
    context: { $implicit: { title: 'profile.update.name', detail: 'Scott Schweigert', action: changeName}}">
  </ng-container>

  <ng-container
    *ngTemplateOutlet="menu;
    context: { $implicit: { title: 'profile.update.contact.email', detail: email, action: changeContactEmail}}">
  </ng-container>

  <ng-container
    *ngTemplateOutlet="menu;
    context: { $implicit: { title: 'profile.update.physical.address', detail: '700 South Flower Street Los Angeles, CA 90017', action: changePhysicalAddress}}">
  </ng-container>

  <ng-container *ngTemplateOutlet="menu;
    context: { $implicit: { title: 'profile.update.password', detail: '****', action: changePassword}}">
  </ng-container>

  <ng-container *ngTemplateOutlet="menu;
    context: { $implicit: { title: 'profile.update.company.name', detail: 'Consensus Cloud Solutions', action: changeCompanyName}}">
  </ng-container>

  <ng-container *ngTemplateOutlet="menu;
    context: { $implicit: { title: 'profile.update.company.size', detail: '', action: changeCompanySize}}">
  </ng-container>

  <ng-container *ngTemplateOutlet="menu;
    context: { $implicit: { title: 'profile.update.home.number', detail: '', action: changeHomeNumber}}">
  </ng-container>

  <ng-container *ngTemplateOutlet="menu;
    context: { $implicit: { title: 'profile.update.work.number', detail: workNumber, action: changeWorkNumber}}">
  </ng-container>

  <ng-container *ngTemplateOutlet="menu;
    context: { $implicit: { title: 'profile.update.mobile.number', detail: '', action: changeMobileNumber}}">
  </ng-container>

</div>


<ng-template #menu let-data>
  <div class="menu">
    <div class="box">
      <label>
        {{data.title | transloco}}
      </label>
    </div>

    <div class="box">
      <label>
        {{data.detail | transloco }}
      </label>
    </div>

    <div class="box right">
      <button mat-button (click)="data.action()">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </div>
  <div class="line"></div>
</ng-template>
