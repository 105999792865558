import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';

import * as fromAuth from './auth.reducer';
import { State } from '../models/auth.state';
import { Params, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { routerReducer, RouterReducerState, RouterStateSerializer } from '@ngrx/router-store';
import { clearFullState } from './auth.meta.reducers'

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

export interface AppState {
  auth: State;
  router: RouterReducerState<RouterStateUrl>;
}

export const reducers: ActionReducerMap<AppState, any> = {
  auth: fromAuth.reducer,
  router: routerReducer
};

export const metaReducers: MetaReducer<AppState>[] = [clearFullState];

@Injectable()
export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams }
    } = routerState;
    const { params } = route;

    return { url, params, queryParams };
  }
}

export const selectAuthState = createFeatureSelector<State>("auth");

export const getAccessToken = createSelector(
  selectAuthState,
  fromAuth.getAccessToken
);
export const getAuthStateAll = createSelector(
  selectAuthState,
  fromAuth.getAuthState
);
export const getIsBusy = createSelector(selectAuthState, fromAuth.getIsBusy);
export const getRequiresLogin = createSelector(selectAuthState, fromAuth.getRequiresLogin);

export const getUserSingle = createSelector(selectAuthState, fromAuth.getUserSingle);
export const getCurrentService = createSelector(selectAuthState, fromAuth.getCurrentService);

export const getUpdatedUserSingle = createSelector(selectAuthState, fromAuth.getUpdatedUserSingle);

export const getLegacyState = createSelector(selectAuthState, state => state.legacyState);

// Reducer selector for route
export const selectReducerState =
  createFeatureSelector<
    RouterReducerState<
      RouterStateUrl
    >
  >("router");
export const getRouterInfo = createSelector(
  selectReducerState,
  state => state.state
);
