import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsFormatter'
})
export class SecondsFormatterPipe implements PipeTransform {

  transform(value: string): number {
    return new Date(0, 0, 0).setSeconds(parseInt(value));
  }

}
