/// inputDate has to be a convertible string to a Date data type
export function stringToDateStandard(inputDate: string, useUTC: boolean = false) {
    const globalDate = new Date(inputDate);
    if (useUTC) {
        return globalDate.toISOString().split('T')[0].replace(/\-/g, "");
    } else {
        const offset = globalDate.getTimezoneOffset();
        const localDate = new Date(globalDate.getTime() - (offset * 60 * 1000));
        return localDate.toISOString().split('T')[0].replace(/\-/g, "");
    }
}