<div class="content pb-30">
  <main class="main">
    <div class="page-controls">
      <span id="compose-lead" class="lead">{{ 'compose.lead.text' | transloco }}</span>
    </div>
    <form [formGroup]="createFaxForm">
      <div class="row">
        <div class="col col-sm" [ngClass]="{'preview-width': getPreviewFile() }">
          <div formGroupName="currentRecipient" class="box">

            <div class="box-title">
              <h5 id="compose-form-title">{{ 'compose.form.title.text' | transloco }}</h5>
              <div class="drop">
                <a href="javascript:void(0)" attr.aria-label="{{ 'compose.contact.popup' | transloco }}"
                  id="contacts-button" name="contacts-button" class="drop-opener">
                  <span>
                    <i class="icon-contacts" tabindex="0" (click)="onSelectContactsId()"></i>
                  </span>
                </a>
              </div>
            </div>


            <div class="box-content">
              <div class="input-line-holder c-error">
                <mat-label id="first-name-label" hidden>{{ 'First name' | t }}</mat-label>
                <input id="first-name" type="text" name="first-name" formControlName="firstName"
                  aria-labelledby="first-name-label" tabindex="0"
                  placeholder="{{ 'compose.form.field.first.name.placeholder' | transloco }}"
                  [matAutocomplete]="autoFirstName">

                <mat-autocomplete #autoFirstName="matAutocomplete">
                  <mat-option *ngFor="let option of contactOptions" [value]="option.firstName"
                    (onSelectionChange)="selectContact(option)">
                    {{displayContactAutoComplete(option)}}
                  </mat-option>
                </mat-autocomplete>

                <ng-container
                  *ngIf="this.currentRecipient.get('firstName')?.invalid && (this.currentRecipient.get('firstName')?.touched || this.currentRecipient.get('firstName')?.dirty)"
                  class="c-validation">
                  <div class="c-label" *ngIf="currentRecipient.get('firstName')?.errors?.required">
                    {{ 'compose.form.errors.required.label' | transloco }}
                  </div>
                  <div class="c-label" *ngIf="currentRecipient.get('firstName')?.errors?.minlength">
                    {{ 'compose.form.errors.minlength.label' | transloco }}
                  </div>
                  <div class="c-label" *ngIf="currentRecipient.get('firstName')?.errors?.maxlength">
                    {{ 'compose.form.errors.maxlength.label' | transloco }}
                  </div>
                  <div class="c-label" *ngIf="currentRecipient.get('firstName')?.errors?.pattern">
                    {{ 'compose.form.errors.pattern.label' | transloco }}
                  </div>
                </ng-container>
              </div>

              <div class="input-line-holder c-error">
                <mat-label id="last-name-label" hidden>{{ 'Last name' | t }}</mat-label>
                <input id="last-name" name="last-name" type="text" formControlName="lastName"
                  aria-labelledby="last-name-label" tabindex="0"
                  placeholder="{{ 'compose.form.field.last.name.placeholder' | transloco }}"
                  [matAutocomplete]="autoLastName">

                <mat-autocomplete #autoLastName="matAutocomplete">
                  <mat-option *ngFor="let option of contactOptions" [value]="option.lastName"
                    (onSelectionChange)="selectContact(option)">
                    {{displayContactAutoComplete(option)}}
                  </mat-option>
                </mat-autocomplete>

                <ng-container *ngIf="currentRecipient.get('lastName')?.invalid &&
                  (currentRecipient.get('lastName')?.touched || currentRecipient.get('lastName')?.dirty)"
                  class="c-validation">
                  <div class="c-label" *ngIf="currentRecipient.get('lastName')?.errors?.required">
                    {{ 'compose.form.errors.required.label' | transloco }}
                  </div>
                  <div class="c-label" *ngIf="currentRecipient.get('lastName')?.errors?.minlength">
                    {{ 'compose.form.errors.minlength.label' | transloco }}
                  </div>
                  <div class="c-label" *ngIf="currentRecipient.get('lastName')?.errors?.maxlength">
                    {{ 'compose.form.errors.maxlength.label' | transloco }}
                  </div>
                  <div class="c-label" *ngIf="currentRecipient.get('lastName')?.errors?.pattern">
                    {{ 'compose.form.errors.pattern.label' | transloco }}
                  </div>
                </ng-container>
              </div>

              <div class="input-line-holder c-error">
                <mat-label id="company-name-label" hidden>{{ 'Company name' | t }}</mat-label>
                <input id="company-name" name="company-name" type="text" formControlName="company"
                  aria-labelledby="company-name-label" tabindex="0"
                  placeholder="{{ 'compose.form.field.company.name.placeholder' | transloco }}"
                  [matAutocomplete]="autoCompany">

                <mat-autocomplete #autoCompany="matAutocomplete">
                  <mat-option *ngFor="let option of contactOptions" [value]="option.company"
                    (onSelectionChange)="selectContact(option)">
                    {{displayContactAutoComplete(option)}}
                  </mat-option>
                </mat-autocomplete>

                <ng-container *ngIf="currentRecipient.get('company')?.invalid &&
                  (currentRecipient.get('company')?.touched || currentRecipient.get('company')?.dirty)"
                  class="c-validation">
                  <div class="c-label" *ngIf="currentRecipient.get('company')?.errors?.required">
                    {{ 'compose.form.errors.required.label' | transloco }}
                  </div>
                  <div class="c-label" *ngIf="currentRecipient.get('company')?.errors?.minlength">
                    {{ 'compose.form.errors.minlength.label' | transloco }}
                  </div>
                  <div class="c-label" *ngIf="currentRecipient.get('company')?.errors?.maxlength">
                    {{ 'compose.form.errors.maxlength.label' | transloco }}
                  </div>
                  <div class="c-label" *ngIf="currentRecipient.get('company')?.errors?.pattern">
                    {{ 'compose.form.errors.pattern.label' | transloco }}
                  </div>
                </ng-container>
              </div>

              <div class="input-line-holder c-error">
                <mat-label id="country-code-label" hidden>{{ 'compose.form.field.contact.country.code.placeholder' |
                  transloco }}</mat-label>
                <select id="countryCode" name="countryCode" class="classic" aria-labelledby="country-code-label"
                  tabindex="0" #countryCode (change)="changeCountryCode($event)">
                  <option value="" [selected]="selectedCountryCode === ''">
                    {{'compose.form.field.contact.country.code.placeholder' | transloco }}
                  </option>
                  <option *ngFor="let country of countries" [value]="country.code"
                    [selected]="country.code === selectedCountryCode">
                    {{country.name}}({{country.dial_code}}) - {{country.code}}
                  </option>
                </select>

                <ng-container *ngIf="this.currentRecipient.get('countryCode')?.invalid &&
                        (this.currentRecipient.get('countryCode')?.touched ||
                         this.currentRecipient.get('countryCode')?.dirty)" class="c-validation">
                  <div class="c-label" *ngIf="currentRecipient.get('countryCode')?.errors?.required">
                    {{ 'compose.form.errors.required.label' | transloco }}
                  </div>
                </ng-container>
              </div>

              <div class="input-line-holder with-info c-error">
                <mat-label id="fax-number-label" hidden>{{ 'Company name' | t }}</mat-label>
                <input id="fax-number" type="text" name="fax-number" formControlName="fax"
                  aria-labelledby="fax-number-label" tabindex="0" (keypress)="checkFaxNumbervalue($event)"
                  placeholder="{{ 'compose.form.field.fax.number.placeholder' | transloco }}">

                <ng-container *ngIf="currentRecipient.get('fax')?.invalid &&
                  (currentRecipient.get('fax')?.touched || currentRecipient.get('fax')?.dirty)" class="c-validation">
                  <div class="c-label" *ngIf="currentRecipient.get('fax')?.errors?.required">
                    {{ 'compose.form.errors.required.label' | transloco }}
                  </div>
                  <div class="c-label" *ngIf="currentRecipient.get('fax')?.errors?.minlength">
                    {{ 'error.minlength.8.label' | transloco }}
                  </div>
                  <div class="c-label" *ngIf="currentRecipient.get('fax')?.errors?.maxlength">
                    {{ 'compose.form.errors.maxlength.label' | transloco }}
                  </div>
                  <div class="c-label" *ngIf="currentRecipient.get('fax')?.errors?.pattern">
                    {{ 'compose.form.errors.pattern.label' | transloco }}
                  </div>
                </ng-container>
              </div>

            </div>

            <div class="box-controls">
              <div class="checkbox-holder">
                <input id="save-contact" type="checkbox" name="save-contact" aria-labelledby="save-contact-label"
                  tabindex="0" formControlName="saveContact">
                <label id="save-contact-label" for="save-contact">
                  {{ 'compose.checkbox.save.contact.text' | transloco }}
                </label>
              </div>
              <button id="btn-add-recipient" class="btn btn-md btn-outline" [disabled]="disableAddRecipient()"
                aria-labelledby="btn-add-recipient-label" tabindex="0" (click)="addRecipient(currentRecipient)">
                <span id="icon-plus" class="icon icon-plus"></span>
                {{ 'compose.button.add.recipient.text' | transloco }}
              </button>
            </div>
          </div>

          <recipients
            [(contactList)]="recipientsFormArray.controls"
            [maxAvailableSelection]="RECIPIENTS_MAX_NUMBER_ALLOWED">
          </recipients>

          <div id="additional-settings-form" formGroupName="additionalSettings" class="box">
            <a href="javascript:void(0)" tabindex="0" class="box-title box-opener" (click)="toggleAdditionalSettings()">
              <h5 id="aditional-settings-title">{{ 'compose.h5.additional.settings.title.text' | transloco }}</h5>
              <div class="icon icon-chevron-down"></div>
            </a>
            <div class="box-content" [hidden]="hideAdditionalSettings">
              <div class="input-line-holder c-error">

                <select class="classic" id="fax-quality" name="fax-quality" formControlName="faxQuality"
                  aria-labelledby="last-name-label" tabindex="0">
                  <option selected disabled value=""> {{ 'compose.form.field.fax.quality.placeholder' | transloco |
                    titlecase }} </option>
                  <option class="drop-opener" *ngFor="let quality of faxQuality" value="{{quality}}">
                    {{quality | titlecase}}
                  </option>

                  <ng-container *ngIf="additionalSettings.get('faxQuality')?.invalid &&
                  (additionalSettings.get('faxQuality')?.touched || additionalSettings.get('faxQuality')?.dirty)"
                    class="c-validation">
                    <div class="c-label" *ngIf="additionalSettings.get('faxQuality')?.errors?.required">
                      {{ 'compose.form.errors.required.label' | transloco }}
                    </div>
                  </ng-container>
                </select>
              </div>

              <div class="input-line-holder c-error">
                <select class="classic" id="send-email-address" name="send-email-address"
                  formControlName="sendEmailAddress">
                  <option selected disabled value=""> {{ 'compose.form.field.send.email.address.placeholder' | transloco
                    | titlecase }} </option>
                  <option class="drop-opener" *ngFor="let email of emailAddresses" value="{{email}}">
                    {{email}}
                  </option>

                  <ng-container
                    *ngIf="additionalSettings.get('sendEmailAddress')?.invalid &&
                (additionalSettings.get('sendEmailAddress')?.touched || additionalSettings.get('sendEmailAddress')?.dirty)"
                    class="c-validation">
                    <div class="c-label" *ngIf="additionalSettings.get('sendEmailAddress')?.errors?.required">
                      {{ 'compose.form.errors.required.label' | transloco }}
                    </div>
                  </ng-container>
                </select>
              </div>

              <div class="input-line-holder c-error">
                <input type="text" id="reference-id" name="reference-id" formControlName="referenceId"
                  placeholder="{{ 'compose.form.field.reference.id.placeholder' | transloco }}" />
                <ng-container *ngIf="additionalSettings.get('referenceId')?.invalid &&
                  (additionalSettings.get('referenceId')?.touched || additionalSettings.get('referenceId')?.dirty)"
                  class="c-validation">
                  <div class="c-label" *ngIf="additionalSettings.get('referenceId')?.errors?.required">
                    {{ 'compose.form.errors.required.label' | transloco }}
                  </div>
                  <div class="c-label" *ngIf="additionalSettings.get('referenceId')?.errors?.minlength">
                    {{ 'compose.form.errors.minlength.label' | transloco }}
                  </div>
                  <div class="c-label" *ngIf="additionalSettings.get('referenceId')?.errors?.maxlength">
                    {{ 'compose.form.errors.maxlength.label' | transloco }}
                  </div>
                  <div class="c-label" *ngIf="additionalSettings.get('referenceId')?.errors?.pattern">
                    {{ 'compose.form.errors.pattern.label' | transloco }}
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <div class="col col-md" [ngClass]="{'preview-width': getPreviewFile() }">
          <div class="box" formGroupName="coverPage">
            <div class="box-controls padding-sm">
              <div class="checkbox-holder">
                <input type="checkbox" name="include-cover-page" id="include-cover-page"
                  formControlName="includeCoverPage">
                <label id="include-cover-page-label" for="include-cover-page">{{
                  'compose.checkbox.label.include.cover.page.text' | transloco }}</label>
              </div>
            </div>
            <div class="box-content" [hidden]="!coverPage.get('includeCoverPage')?.value">
              <div class="input-line-holder c-error">
                <input type="text" (blur)="trimCoverPageControls('coverTitle')" id="cover-title" name="cover-title"
                  formControlName="coverTitle"
                  placeholder="{{ 'compose.form.field.cover.title.placeholder' | transloco }}" />
                <ng-container
                  *ngIf="coverPage.get('coverTitle')?.invalid && (coverPage.get('coverTitle')?.touched || coverPage.get('coverTitle')?.dirty)"
                  class="c-validation">
                  <div class="c-label" *ngIf="coverPage.get('coverTitle')?.errors?.required">
                    {{ 'compose.form.errors.required.label' | transloco }}
                  </div>
                  <div class="c-label" *ngIf="coverPage.get('coverTitle')?.errors?.minlength">
                    {{ 'compose.form.errors.minlength.label' | transloco }}
                  </div>
                  <div class="c-label" *ngIf="coverPage.get('coverTitle')?.errors?.pattern">
                    {{ 'compose.form.errors.pattern.label' | transloco }}
                  </div>
                  <div class="c-label" *ngIf="coverPage.get('coverTitle')?.hasError('forbiddenCharacters')">
                    {{'compose.form.errors.forbiddenCharacters.label' | transloco}}
                  </div>
                </ng-container>
              </div>
              <div class="input-line-holder padding-top-20">
                <textarea type="text" (blur)="trimCoverPageControls('coverText')" id="cover-text" name="cover-text"
                  formControlName="coverText" placeholder="{{ 'compose.form.cover.text.placeholder' | transloco }}">
                </textarea>
                <ng-container *ngIf="coverPage.get('coverText')?.invalid &&
                  (coverPage.get('coverText')?.touched || coverPage.get('coverText')?.dirty)" class="c-validation">
                  <div class="c-label" *ngIf="coverPage.get('coverText')?.errors?.pattern">
                    {{ 'compose.form.errors.pattern.label' | transloco }}
                  </div>
                  <div class="c-label" *ngIf="coverPage.get('coverText')?.errors?.required">
                    {{ 'compose.form.errors.required.label' | transloco }}
                  </div>
                  <div class="c-label" *ngIf="coverPage.get('coverText')?.errors?.minlength">
                    {{ 'compose.form.errors.minlength.label' | transloco }}
                  </div>
                  <div class="c-label" *ngIf="coverPage.get('coverText')?.errors?.maxlength">
                    {{ 'compose.form.errors.maxlength.label' | transloco }}
                  </div>

                  <div class="c-label" *ngIf="coverPage.get('coverText')?.hasError('forbiddenCharacters')">
                    {{'compose.form.errors.forbiddenCharacters.label' | transloco}}
                  </div>
                </ng-container>
              </div>
            </div>

            <div style="display:flex; justify-content: space-between; align-items: baseline;">
              <div class="box-title padding-lg">
                <h5 id="upload-area-title">{{ 'compose.h5.upload.area.title.text' | transloco }}</h5>
              </div>
            </div>
            <div [ngClass]="draggingFile === true ? 'upload-area-on' : 'upload-area'">
              <div appDragAndDrop (fileDropped)="onFileDropped($event)">
                <input type="file" accept="{{acceptedFileTypes}}" #fileUploadRef id="fileUploadRef" multiple
                  (change)="fileBrowserHandler($any($event.target).files)"
                  [title]="'compose.span.upload.area.text' | transloco " />
                <div class="upload-text">
                  <span class="icon icon-cloud-upload contrast"></span>
                  <label for="fileUploadRef" id="upload-area-span" class="contrast">
                    {{ 'compose.span.upload.area.text' | transloco }}
                  </label>
                </div>
              </div>
            </div>

            <mat-error class="max-file-size-error" *ngIf="maxFileSizeWarning"> {{ 'compose.maxFileSizeWarning'
              | transloco }}
            </mat-error>

            <mat-error class="max-file-size-error" *ngIf="maxFileNumberWarning"> {{ 'compose.maxFileNumberWarning'
              | transloco }}
            </mat-error>

            <div class="files-list">
              <div class="file-item" *ngFor=" let file of fileList; let i=index">
                <div title="{{file.name }}">
                  <span class="icon icon-fileshare"></span>
                  <a href="javascript:void(0)" (click)="setPreviewFile(file)">
                    <strong class="text-ellipsis"> {{removeExtension(file.name) | longFileName }}</strong>
                  </a>
                  <small>({{ onFormatBytes(file?.size) }})</small>
                  <button class="singular remove-file-button" (click)="removeFile(file, i)">X</button>
                </div>
              </div>
              <div class="file-item" *ngFor="let file of forwardedFaxes; let i=index">
                <div title="{{ file }}">
                  <span class="icon icon-fileshare"></span>
                  <a href="javascript:void(0)" (click)="setPreviewFile(file)">
                    <strong class="text-ellipsis"> {{file | longFileName }}</strong>
                  </a>
                </div>
              </div>
            </div>

            <div class="box-controls padding-lg">
              <button id="btn-send-fax" class="action-button btn btn-md btn-info" (click)="sendFax()"
                [disabled]="disableSendButton()">
                <span id="btn-send-fax-span" class="icon icon-send"></span>
                {{ 'compose.button.send.fax.text' | transloco }}
              </button>
            </div>

          </div>
        </div>

        <div class="col col-sm" *ngIf="getPreviewFile() !== undefined"
          [ngClass]="{'preview-width': getPreviewFile() !== undefined }">
          <div class="box">
            <div class="box-title padding-lg">
              <h5 class="w-100"> Preview Attachment <button class="singular remove-file-button"
                  (click)="clearPreview()">X</button> </h5>
            </div>
            <div class="box-content padding-lg" *ngIf="!pdfSrc && !disablePreviewImage">
              <ng-container *ngIf="!!(getPreviewFile()?.fileName) else image_preview">
                <p class="breakline"> {{removeExtension(getPreviewFile()?.fileName)}} <small>({{
                    onFormatBytes(getPreviewFile()?.size)
                    }})</small></p>
                <img [src]="getPreviewFile()?.url" class="preview-width-full" (error)="updateIcon($event)" />
              </ng-container>
              <ng-template #image_preview>
                <!-- <p class="breakline"> {{ removeExtension(getPreviewFile()?.fileName) }}</p> -->
                <div class="row-content">
                  <div class="column">
                    <ng-container *ngFor="let el of imagesByFaxId(getPreviewFile())">
                      <img [src]="el.url" class="preview-width-full" (error)="updateIcon($event)" />
                    </ng-container>
                  </div>
                </div>
              </ng-template>
            </div>

            <div class="row-content" *ngIf="pdfSrc !== undefined && !disablePreviewImage">
              <pdf-viewer [src]="pdfSrc" [show-all]="true" [render-text]="true" [original-size]="false"
                style="min-width: 500px; min-height: 500px; max-width: 90%; max-height: 90%; scroll-behavior: auto;">
              </pdf-viewer>
            </div>

            <span *ngIf="!pdfSrc && disablePreviewImage"
              class="fiv-viv font-100 fiv-icon-{{activeFileExtension}}"></span>
            <!-- In case attachment is a docx, xlsx or pdf -->
          </div>
        </div>



      </div>
    </form>

    <div class="error" *ngIf="showError">
      <span>{{sendFaxErrorMessage}}</span>
      <button class="dismiss-message-close-button" (click)="dismissErrorMessage()">X</button>
    </div>

    <div class="success" *ngIf="showSuccess">
      <span>{{ 'compose.label.msg.fax.send.success' | transloco }}</span>
      <button class="dismiss-message-close-button" (click)="dismissSuccessMessage()">X</button>
    </div>
  </main>

</div>
