<div class="content">
    <main class="main">
        <div class="table-actions">
            <label hidden id="single-message-go-back-label">{{ 'general.goBack' | transloco }}</label>
            <button mat-icon-button matTooltip="{{ 'general.goBack' | transloco }}" (click)="goBack()"
                aria-labelledby="single-message-go-back-label">
                <mat-icon aria-label="{{ 'general.goBack' | transloco }}">arrow_back
                </mat-icon>
            </button>

            <label hidden id="single-message-add-tag-label">{{ 'general.addTag' | transloco }}</label>
            <button mat-icon-button matTooltip="{{ 'general.addTag' | transloco }}" *ngIf="userSuspendedFlag === 0"
                [disabled]="!isToolbarEnabled || lastFolder.name === 'DOCUMENTS' || isSecure"
                (click)="showCreateTagDialog()" aria-labelledby="single-message-add-tag-label">
                <mat-icon aria-label="{{ 'general.addTag' | transloco }}">bookmark
                </mat-icon>
            </button>
            <label hidden id="single-message-remove-tag-label">{{ 'general.removeTag' | transloco }}</label>
            <button mat-icon-button matTooltip="{{ 'general.removeTag' | transloco }}" *ngIf="userSuspendedFlag === 0"
                [disabled]="!isToolbarEnabled || lastFolder.name === 'DOCUMENTS' || currentFax.tags.length === 0 || isSecure"
                (click)="showDeleteTagDialog()" aria-labelledby="single-message-remove-tag-label">
                <mat-icon aria-label="{{ 'general.removeTag' | transloco }}">bookmark_border
                </mat-icon>
            </button>
            <label hidden id="single-message-forward-label">{{ 'general.forward' | transloco }}</label>
            <button mat-icon-button matTooltip="{{ 'general.forward' | transloco }}" *ngIf="userSuspendedFlag === 0"
                [disabled]="!isToolbarEnabled || isSecure || serviceKey === undefined" [matMenuTriggerFor]="forwardMenu"
                aria-labelledby="single-message-forward-label">
                <mat-icon aria-label="{{ 'general.forward' | transloco }}">forward
                </mat-icon>
            </button>

            <mat-menu #forwardMenu="matMenu">
                <button mat-menu-item (click)="forwardMessage()">
                    <mat-icon>forward</mat-icon>
                    <span>{{ 'general.forwardByFax' | transloco }}</span>
                </button>
                <button mat-menu-item (click)="forwardByMail()">
                    <mat-icon>alternate_email</mat-icon>
                    <span>{{ 'general.forwardByMail' | transloco }}</span>
                </button>
            </mat-menu>

            <label hidden id="single-message-print-label">{{ 'general.print' | transloco }}</label>
            <a mat-icon-button matTooltip="{{ 'general.print' | transloco }}" [disabled]="!isToolbarEnabled"
                (click)="printMessages()" aria-labelledby="single-message-print-label">
                <mat-icon aria-label="{{ 'general.print' | transloco }}">print</mat-icon>
            </a>
            <label hidden id="single-message-download-label">{{ 'general.download' | transloco }}</label>
            <button mat-icon-button matTooltip="{{ 'general.download' | transloco }}" [disabled]="!isToolbarEnabled"
                (click)="download()" aria-labelledby="single-message-download-label">
                <mat-icon aria-label="{{ 'general.download' | transloco }}">cloud_download
                </mat-icon>
            </button>
            <label hidden id="single-message-mark-unread-label">{{ 'general.markUnread' | transloco }}</label>
            <button mat-icon-button matTooltip="{{ 'general.markUnread' | transloco }}" (click)="markReadUnread(false)"
                aria-labelledby="single-message-mark-unread-label" *ngIf="userSuspendedFlag === 0">
                <mat-icon aria-label="{{ 'general.markUnread' | transloco }}">markunread
                </mat-icon>
            </button>
            <label hidden id="single-message-delete-label">{{ 'general.delete' | transloco }}</label>
            <button mat-icon-button matTooltip="{{ 'general.delete' | transloco }}" [disabled]="!isToolbarEnabled"
                (click)="deleteFax()" aria-labelledby="single-message-delete-label" *ngIf="userSuspendedFlag === 0">
                <mat-icon aria-label="{{ 'general.delete' | transloco }}">delete
                </mat-icon>
            </button>
            <button
                [disabled]="lastFolder.name === 'DOCUMENTS' ||lastFolder.name === 'SENT' || lastFolder.name === 'SIGNED_DOCS'"
                attr.aria-label="{{ 'general.moveFax' | transloco }}" mat-icon-button (click)="moveFax()"
                matTooltip="{{ 'general.moveFax' | transloco }}" *ngIf="userSuspendedFlag === 0">
                <mat-icon>
                    drive_file_move_outline</mat-icon>
            </button>
            <button *ngIf="!hideSignOption && userSuspendedFlag === 0" mat-icon-button
                [disabled]="isSignatureDisabled() || isSecure" matTooltip="{{ 'general.signature' | transloco }}"
                (click)="signFax()" attr.aria-label="{{ 'general.signature' | transloco }}">
                <mat-icon>edit</mat-icon>
            </button>

            <div class="chip-list" *ngIf="currentFax.tags.length <= 4">
                <mat-chip-option selectable="false" *ngFor="let tag of currentFax.tags" aria-label="{{tag}}">
                    {{tag}}
                </mat-chip-option>
            </div>
            <div class="chip-list-more-4" *ngIf="currentFax.tags.length > 4">
                <mat-chip-option selectable="false" *ngFor="let tag of currentFax.tags" aria-label="{{tag}}">
                    {{tag}}
                </mat-chip-option>
            </div>
            <!-- Buttons for rotation and zoom -->
            <button mat-icon-button matTooltip="Rotate Left" (click)="rotateLeft()">
                <mat-icon>rotate_left</mat-icon>
            </button>
            <button mat-icon-button matTooltip="Rotate Right" (click)="rotateRight()">
                <mat-icon>rotate_right</mat-icon>
            </button>
            <button mat-icon-button matTooltip="Zoom In" (click)="zoomIn()" [disabled]="zoomInDisabled">
                <mat-icon>zoom_in</mat-icon>
            </button>
            <button mat-icon-button matTooltip="Zoom Out" (click)="zoomOut()" [disabled]="zoomOutDisabled">
                <mat-icon>zoom_out</mat-icon>
            </button>
        </div>
        <div class="fax-info">
            <span>Fax: <a (click)="download()"> {{ fileName }} </a></span>
            <!-- Page selector -->
            <div class="page-selector">
            <span>{{ 'messages.singleMessage.pages' | transloco  }}: </span>
            <select (change)="setPage($event)">
            <option *ngFor="let page of [].constructor(imageList?.length); let i = index" [value]="i"> {{ i + 1 }} </option>
            <option value="-1">{{ 'messages.singleMessage.all' | transloco  }}</option>
            </select>
            <span *ngIf="imageList">&nbsp; {{ imageList.length }} {{ 'messages.singleMessage.inTotal' | transloco  }} </span>
          </div>
          <span class="date">{{ 'general.date'| transloco }}:  {{ completedTimestamp | date: loadDateLocale(): utcOffset }}</span>
        </div>

        <myportal-printing-area #printingArea class="top-mar center" [imageList]="imageList">
        </myportal-printing-area>
    </main>
</div>