import { gql } from 'apollo-angular';
import { FaxFilterInput, FaxSortInput } from '../models/fax.model';

export const VIEW_MESSAGES_FOLDER_LIST_QUERY = gql`
query ($serviceKey: String) {
  folderList(serviceKey: $serviceKey) {
    __typename
    ... on FolderList {
      object_type
      folder {
        id
        name
        faxCountRead
        faxCountUnread
        faxTotalCount
      }
    }
    ... on ApiError {
      object_type
      code
      message
    }
  }
}`;

export const BULK_DOWNLOAD_QUERY = gql`query ($serviceKey: String, $faxIds: [String]) {
  multipleFaxDownload(
    serviceKey: $serviceKey
    faxIds : $faxIds
  ) {
    __typename
    ... on MultipleFaxDownload {
      object_type
      faxIdSuccess
      faxIdFail
      downloadUrl
    }
     ... on ApiError{
      code
      message
    }
  }
}`

export const VIEW_MESSAGES_FAX_LIST_QUERY = gql`
query ($serviceKey: String, $filter: FaxFilterInput, $limit: Int!, $offset: Int!, $sort: FaxSortInput) {
  faxList(
    serviceKey: $serviceKey
    filter: $filter
    limit: $limit
    offset: $offset
    sort: $sort
  ) {
    __typename
    ... on FaxList {
      faxes {
        object_type
        serviceKey
        faxId
        imageUrl
        originatingFaxMachine
        recipientName
        tags
        repositoryStatus
        transmissionStatus
        direction
        folderId
        folderName
        completedTimestamp
        originatingFaxNumber
        destinationFaxNumber
        pages
        isRead
        purgeDate
        notificationSentTimestamp
        notificationStatus
        notificationResult
        outboundData {
          faxId
          toName
          toCompany
          subject
          billingCode
          clientCode
          clientId
          clientMatter
          clientName
          clientReferenceId
        }
        thumbnails(startPage: 1, numPages: 4) {
          faxId
          object_type
          imageUrl
        }
        images(startPage: 1, numPages: 6) {
          faxId
          object_type
          imageUrl
        }
      }
    }
    ... on ApiError {
      object_type
      code
      message
    }
  }
}`;

export const VIEW_MESSAGES_CREATE_FOLDER_MUTATION = gql`
mutation ($serviceKey: String, $folderName: String){
  folderCreate (serviceKey:$serviceKey input: {
    name:$folderName
  }){
    __typename
    ... on FolderCreate{
      object_type
      folder{
      name
             id
    }
    }
     ... on ApiError{
      code
      message
    }
  }
}`;

export const VIEW_MESSAGES_RENAME_FOLDER_MUTATION = gql`
  mutation ($serviceKey: String, $folderId: String, $input: UpdateFolder!) {
    folderUpdate(
      serviceKey: $serviceKey
      folderId: $folderId
      input: $input
    ) {
      __typename
      ... on FolderUpdate {
          object_type
          folder {
              name
              id
          }
      }
      ... on ApiError {
        object_type
        code
        message
      }
    }
  }`;

export const FAX_PREPARE_SIGNEDDOC_MUTATION = gql`
mutation faxPrepareSignedDoc($serviceKey: String, $input: CreateSignedDocFax) {
  faxPrepareSignedDoc(serviceKey: $serviceKey, input: $input) {
    __typename
    ... on FaxSignedDocCreate {
      object_type
      fax {
        faxId
      }
      s3Url
    }
    ... on ApiError {
      code
      message
    }
  }
}
`;

export const VIEW_MESSAGES_DELETE_FOLDER_MUTATION = gql`
mutation ($serviceKey: String, $folderId: String){
  folderDelete (serviceKey:$serviceKey folderId:$folderId){
    __typename
    ... on FolderDelete{
      object_type
      folder{
        id
      }
    }
     ... on ApiError{
      code
      message
    }
  }
}`;

export const VIEW_MESSAGES_MOVE_FAXES_MUTATION = gql`
mutation faxMultipleMove($serviceKey: String!, $folderId: String!, $faxIds: [String]!, $allowCustomFolderToInbox: Boolean = false ){
  faxMultipleMove (serviceKey:$serviceKey folderId:$folderId faxIds: $faxIds, allowCustomFolderToInbox: $allowCustomFolderToInbox){
    __typename
    ... on FaxMultipleMove{
      faxIds
      object_type
			folderId
    }
     ... on ApiError{
      object_type
      code
      message
    }
  }
}`;

export const VIEW_MESSAGES_FAX_UPDATE = gql`
  mutation ($serviceKey: String, $faxId: String, $input: FaxUpdateParams){
    faxSingleUpdate(serviceKey: $serviceKey faxId: $faxId input: $input) {
      __typename
      ...on FaxUpdate{
        object_type
        fax{
          object_type
          serviceKey
          faxId
        }
      }
      ...on ApiError{
        object_type
        code
        message
      }
    }
}

  `;

export const VIEW_MESSAGES_FAX_SINGLE_DELETE = gql`
    mutation ($serviceKey: String $faxId: String $purgeImmediately: Boolean) {
      faxSingleDelete(serviceKey: $serviceKey faxId: $faxId purgeImmediately: $purgeImmediately){
      __typename
      ...on FaxDelete{
        object_type
        faxId
      }
      ...on ApiError{
        code
        message
      }
    }
  }
  `;

export const VIEW_MESSAGES_FAX_LIST_DELETE = gql`
  mutation($serviceKey: String $faxIds: [String] $purgeImmediately: Boolean){
    faxListDelete(serviceKey: $serviceKey faxIds: $faxIds purgeImmediately: $purgeImmediately){
      __typename
      ...on FaxListDelete{
        faxIds
      }
      ...on ApiError {
        code
        message
      }
    }
  }
`;

export const VIEW_MESSAGES_FAX_TAG_ADD = gql`
    mutation ($serviceKey: String, $faxId: String, $tag: String) {
      faxTagAdd(serviceKey: $serviceKey faxId: $faxId tag: $tag){
      __typename
      ...on FaxTagAdd{
        object_type
        tags
      }
      ...on ApiError{
        code
        message
      }
    }
}
  `;

export const VIEW_MESSAGES_FAX_TAG_DELETE = gql`
    mutation ($serviceKey: String, $faxId: String, $tag: String) {
      faxTagDelete(serviceKey: $serviceKey faxId: $faxId tag: $tag){
      __typename
      ...on FaxTagDelete{
        object_type
        tags
      }
      ...on ApiError{
        code
        message
      }
    }
}
  `;

export const VIEW_MESSAGES_FAX_SINGLE = gql`
    query ($serviceKey: String, $faxId: String!) {
      faxSingle(serviceKey: $serviceKey faxId: $faxId){
      __typename
      ...on FaxSingle{
        object_type
        fax{
          serviceKey
          faxId
          imageUrl
          originatingFaxMachine
          recipientName
          tags
          repositoryStatus
          transmissionStatus
          direction
          folderId
          folderName
          completedTimestamp
          originatingFaxNumber
          destinationFaxNumber
          pages
          isRead
          purgeDate
          notificationSentTimestamp
          notificationStatus
          notificationResult
          outboundData {
            faxId
            toName
            toCompany
            subject
            billingCode
            clientCode
            clientId
            clientMatter
            clientName
            clientReferenceId
          }
          images(startPage: 1, numPages: 25) {
            faxId
            object_type
            imageUrl
          }
        }
      }
      ...on ApiError{
        object_type
        code
        message
      }
    }
}
  `;


export const faxSingleImages = ($start: number, $end: number) => gql`
  query ($serviceKey: String, $faxId: String!) {
    faxSingle(serviceKey: $serviceKey faxId: $faxId){
    __typename
    ...on FaxSingle{
      object_type
      fax{
        images(startPage: ${$start}, numPages: ${$end}) {
          faxId
          object_type
          imageUrl
        }
      }
    }
    ...on ApiError{
      object_type
      code
      message
    }
  }
}
`;

export const faxSingleComplete = (pages: number) => gql`
query ($serviceKey: String, $faxId: String!) {
  faxSingle(serviceKey: $serviceKey faxId: $faxId){
  __typename
  ...on FaxSingle{
    object_type
    fax{
      serviceKey
      faxId
      imageUrl
      originatingFaxMachine
      recipientName
      tags
      repositoryStatus
      transmissionStatus
      direction
      folderId
      folderName
      completedTimestamp
      originatingFaxNumber
      destinationFaxNumber
      pages
      isRead
      purgeDate
      notificationSentTimestamp
      notificationStatus
      notificationResult
      outboundData {
        faxId
        toName
        toCompany
        subject
        billingCode
        clientCode
        clientId
        clientMatter
        clientName
        clientReferenceId
      }
      thumbnails(startPage: 1, numPages: ${pages}) {
        faxId
        object_type
        imageUrl
      }
    }
  }
  ...on ApiError{
    object_type
    code
    message
  }
}
}
`

export const VIEW_MESSAGES_SINGLE_REMOVE_FROM_CUSTOM_FOLDER = gql`
  mutation faxSingleRemoveFromCustomFolder($serviceKey: String!, $folderId: String!, $faxId: String!) {
    faxSingleRemoveFromCustomFolder(serviceKey: $serviceKey folderId: $folderId faxId: $faxId) {
      __typename
      ... on FaxSingleRemoveFromCustomFolder{
        object_type
        faxId
      }
      ... on ApiError{
        object_type
        code
        message
      }
    }
  }
`;

export const VIEW_MESSAGES__MULTIPLE_REMOVE_FROM_CUSTOM_FOLDER = gql`
  mutation faxMultipleRemoveFromCustomFolder($serviceKey: String!, $faxIds: [String]!, $folderId: String! ) {
    faxMultipleRemoveFromCustomFolder(serviceKey: $serviceKey faxIds: $faxIds folderId: $folderId) {
      __typename
      ... on FaxMultipleRemoveFromCustomFolder {
        object_type
        faxIds
      }
      ... on ApiError{
        object_type
        code
        message
      }
    }
  }
`;

export const VIEW_MESSAGES_FOLDER_SINGLE = gql`
  query ($serviceKey:String, $name:String!) {
    folderSingle(
      serviceKey:$serviceKey
      name:$name
    ) {
      __typename
      ... on FolderSingle {
        object_type
        folder {
          id
          name
          faxCountRead
          faxCountUnread
          faxTotalCount
        }
      }
      ... on ApiError{
        object_type
        code
        message
      }
    }
  }
`;

export const VIEW_MESSAGES_FORWARD_FAX_TO_EMAIL = gql`
  mutation ($faxIds:[String]!, $serviceKey:String!,
    $emails:[String]!, $subject:String, $message:String) {
    forwardFaxToEmail(faxIds:$faxIds, serviceKey:$serviceKey,
    emails:$emails, subject:$subject, message:$message) {
        ... on ForwardFaxToEmail {
          __typename
          object_type
          emailSuccess
          emailFail
        }
      ... on ApiError {
        __typename
        object_type
        code
        message
      }
    }
  }
`;

export const getFolderSingleQuery = (payload: {
  serviceKey: string, name: string,
  offset: number, limit: number, sort: FaxSortInput
}) => {
  return gql`
    query folderSingle($serviceKey:String, $name:String!) {
      folderSingle(serviceKey:$serviceKey, name:$name) {
        ... on FolderSingle {
          object_type
          folder {
            id
            name
            faxCountRead
            faxCountUnread
            faxTotalCount
            ${PARTIAL_FOLDER_SINGLE_FAXES_NO_FILTER(payload.limit, payload.offset,
    payload.sort?.sortBy !== undefined ? payload.sort?.sortBy?.toString() : 'DATE',
    payload.sort?.sortDirection !== undefined ? payload.sort?.sortDirection?.toString() : 'DESC')}
          }
        }
        ... on ApiError {
          __typename
          object_type
          code
          message
        }
      }
    }
    `;
}

const PARTIAL_FOLDER_SINGLE_FAXES_NO_FILTER = (limit: number,
  offset: number, sortBy: string, sortDirection: string) => {
  return `
    faxes (
      limit:${limit}, offset:${offset},
      sort:{
        sortBy:${sortBy},
        sortDirection:${sortDirection}
      }
    ) {
      object_type
      serviceKey
      faxId
      thumbnails(startPage:1, numPages:1) {
        imageUrl
      }
      originatingFaxMachine
      recipientName
      tags
      repositoryStatus
      transmissionStatus
      direction
      folderId
      folderName
      completedTimestamp
      originatingFaxNumber
      destinationFaxNumber
      pages
      isRead
      purgeDate
      notificationSentTimestamp
      notificationStatus
      notificationResult
      outboundData {
        subject
        toCompany
        clientName
        toName
        clientReferenceId
      }
      imageUrl
    }
    `;
}

const PARTIAL_FOLDER_SINGLE_FAXES_FILTER = (limit: number,
  offset: number, sortBy: string, sortDirection: string, searchText: string) => {
  return `
      faxes (
        filter:{
          searchText:"${searchText}",
          searchType:METADATA
        }
        limit:${limit}, offset:${offset},
        sort:{
          sortBy:${sortBy},
          sortDirection:${sortDirection}
        }
      ) {
        object_type
        serviceKey
        faxId
        thumbnails(startPage:1, numPages:1) {
          imageUrl
        }
        originatingFaxMachine
        recipientName
        tags
        repositoryStatus
        transmissionStatus
        direction
        folderId
        folderName
        completedTimestamp
        originatingFaxNumber
        destinationFaxNumber
        pages
        isRead
        purgeDate
        notificationSentTimestamp
        notificationStatus
        notificationResult
        outboundData {
          subject
          toCompany
          clientName
          toName
          clientReferenceId
        }
        imageUrl
      }
    `;
}
