import { gql } from 'apollo-angular';

export const CONTACTS_LIST_QUERY = gql`
query ($limit: Int!, $offset: Int!, $sortBy: contactSortBy, $sortDirection: SortDirection) {
  contactList(
    limit: $limit
    offset: $offset
    sortBy: $sortBy
    sortDirection: $sortDirection
  ) {
    __typename
    ... on ContactList {
      object_type
      nextPageStartIndex
      pageSize
      pageStartIndex
      sort
      sortDirection
      totalRecords
      contacts {
        contactId
        firstName
        lastName
        company
        fax
        email
        countryCode
      }
    }
    ... on ApiError {
      object_type
      code
      message
    }
  }
}`;

export const CONTACT_SINGLE_QUERY = gql`
query ($contactId: String!) {
  contactSingle(
    contactId: $contactId
  ) {
    __typename
    ... on ContactSingle {
      object_type
      contact {
        contactId
        firstName
        lastName
        company
        fax
        email
        countryCode
      }
    }
    ... on ApiError {
      object_type
      code
      message
    }
  }
}`;

export const CONTACTS_CREATE_CONTACT_MUTATION = gql`
mutation ($firstName: String, $lastName: String,  $company: String, $fax: String,
          $email: String, $countryCode: String){
  contactAdd ( input: {
    firstName:$firstName,
    lastName:$lastName,
    company:$company,
    fax:$fax,
    email:$email,
    countryCode:$countryCode   
  }){
    __typename
    ... on ContactAdd{
      object_type
      contact{
        firstName,
        lastName,
        company,
        fax,
        email,
        countryCode,
        contactId  
      }
    }
     ... on ApiError{
      code
      message
    }
  }
}`; 

export const CONTACTS_UPDATE_CONTACT_MUTATION = gql`
mutation ($contactId: String, $firstName: String, $lastName: String,  $company: String, $fax: String,
          $email: String, $countryCode: String){
  contactUpdate ( 
    contactId: $contactId,
    input: {
      firstName:$firstName,
      lastName:$lastName,
      company:$company,
      fax:$fax,
      email:$email,
      countryCode:$countryCode   
    }){
    __typename
    ... on ContactUpdate{
      object_type
      contact{
        firstName,
        lastName,
        company,
        fax,
        email,
        countryCode,
        contactId  
      }
    }
     ... on ApiError{
      code
      message
    }
  }
}`; 

export const CONTACTS_DELETE_CONTACT_MUTATION = gql`
mutation ( $contactId: String){
  contactDelete (contactId:$contactId){
    __typename
    ... on ContactDelete{
      object_type      
      contactId      
    }
     ... on ApiError{
      code
      message
    }
  }
}`;

