import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ContactsApi } from '../../api/contacts.api';
import {
  loadContactListBegin,
  loadContactListSuccess,
  loadContactListFailed,
  createNewContactBegin,
  createNewContactSuccess,
  createNewContactFailed,
  deleteContactBegin,
  deleteContactSuccess,
  deleteContactFailed,
  loadContactSingleBegin,
  loadContactSingleFailed,
  loadContactSingleSuccess,
  updateContactBegin,
  updateContactFailed,
  updateContactSuccess,
  deleteContactListBegin,
  addEditSingleContact
} from '../actions/contacts.actions';

@Injectable()
export class ContactsEffects {

  constructor(private actions$: Actions, private contactsApi: ContactsApi, private router: Router) { }

  loadContactListBegin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadContactListBegin),
      switchMap((action) => {
        return this.contactsApi.fetchContactList(action.payload)
          .pipe(
            map((response: any) => {
              if (response.contactList?.__typename === 'ContactList') {
                return loadContactListSuccess({ contactList: response.contactList })
              }
              return loadContactListFailed({ errorMessage: response })
            })
          );
      })
    )
  );

  createContactBegin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createNewContactBegin),
      switchMap((action) => {
        return this.contactsApi.createNewContact(action.payload)
          .pipe(
            map((response: any) => {
              if (response.data.contactAdd?.__typename === 'ContactAdd') {
                return createNewContactSuccess({ contact: response.data.contactAdd.contact })
              } else if (response.data.contactAdd?.__typename === 'ApiError') {
                return createNewContactFailed({ errorMessage: { ...response.data.contactAdd } })
              }

              return createNewContactFailed({ errorMessage: response })
            })
          );
      })
    )
  );

  createNewContactSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createNewContactSuccess),
      map(() => loadContactListBegin({ payload: this.contactsApi.createContactListParams() }))
    )
  );

  updateContactBegin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateContactBegin),
      switchMap((action) => {
        return this.contactsApi.updateContact(action.payload)
          .pipe(
            map((response: any) => {
              if (response.data.contactUpdate?.__typename === 'ContactUpdate') {

                return updateContactSuccess({ contact: response.data.contactUpdate.contact })

              } else if (response.data.contactUpdate?.__typename === 'ApiError') {
                return updateContactFailed({ errorMessage: { ...response.data.contactUpdate } })
              }
              return updateContactFailed({ errorMessage: response })
            })
          );
      })
    )
  );

  loadContactSingleBegin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadContactSingleBegin),
      switchMap((action) => {
        return this.contactsApi.fetchContactSingle(action.payload)
          .pipe(
            map((response: any) => {
              if (response.contactSingle?.__typename === 'ContactSingle') {
                return loadContactSingleSuccess({ contactSingle: response.contactSingle })
              }
              return loadContactSingleFailed({ errorMessage: response })
            })
          );
      })
    )
  );

  deleteContactBegin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteContactBegin),
      switchMap((action) => {
        return this.contactsApi.deleteContact(action.payload)
          .pipe(
            map((response: any) => {
              if (response.data.contactDelete?.__typename === 'ContactDelete') {
                return deleteContactSuccess({ contact: response.data.contactDelete.contact, list: action.payload.list })
              }
              return deleteContactFailed({ errorMessage: response })
            })
          );
      })
    )
  );

  deleteContactSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteContactSuccess),
      map((action) => loadContactListBegin({ payload: action.list }))
    )
  );

  deleteContactListBegin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteContactListBegin),
      switchMap(action => {
        return from(action.payload.contactsList)
          .pipe(
            mergeMap((arr) => this.contactsApi.deleteContact({ input: { id: arr } })),
            map((response: any) => {
              if (response?.data?.contactDelete?.__typename === 'ContactDelete') {
                return deleteContactSuccess({ contact: response?.data?.contactDelete?.contact, list: action.payload.paramsList })
              }
              return deleteContactFailed({ errorMessage: { message: 'No deleted contacts', code: 'ERR', object_type: 'ApiError' } })
            })
          )
      })
    )
  );

  addEditContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addEditSingleContact),
      tap((action) => {
        if (action.content === undefined) {
          this.router.navigate(['/contacts/new'], { skipLocationChange: true });
        } else {
          this.router.navigate(['/contacts/view'], { skipLocationChange: true });
        }
      })
    ), { dispatch: false }
  );
}
