import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { LangStateService } from '../lang/lang-state.service';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { BrandService } from '../services/brand.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  @Input() positionClass = "footer-right";

  availableLanguages: any | [];
  private showConsentMessage$: Subject<boolean> = new Subject<boolean>();
  private terminated$: Subject<boolean> = new Subject<boolean>();
  showConsent: boolean = false;
  currentBrand: String = '';
  private readonly COOKIE_CONSENT_NAME = '_vis_opt_cookie';
  privacyUrl: string = '';
  termsUrl: string = '';

  constructor(
    public langStateService: LangStateService,
    @Inject(DOCUMENT) private document: Document,
    private brandService: BrandService,
    private cookieService: CookieService
  ) {
    this.availableLanguages = langStateService.getAvailableLanguages();
    this.showConsentMessage$.pipe(takeUntil(this.terminated$)).subscribe(value => {
      this.showConsent = value
    });
    this.brandService.config$.pipe(filter(value => value !== undefined), takeUntil(this.terminated$)).subscribe((config: any) => {
      this.currentBrand = config.id;
    });
  }

  ngOnInit(): void {
    this.checkForConsent();
    this.brandService.config$.subscribe(config => {
      if (config) {
        const brandId = this.brandService.brandName;
        const links = this.brandService.getBrandLinks(brandId);
        this.privacyUrl = links.privacyUrl;
        this.termsUrl = links.termsUrl;
      }
    });
  }
  ngOnDestroy(): void {
    this.terminated$.next(true);
  }

  openUrl() {
    this.brandService.config$.pipe(filter(value => value !== undefined), take(1)).subscribe((config: any) => {
      const url = `https://www.${config.id}.com/legal?_ga=2.197844655.145728097.1677617910-1318093349.1638474162#privacy`
      this.document.location.href = url;

    });
  }

  changeLanguage(event: any): void {
    this.langStateService.setActiveLang(event.target.value);
  }

  isSelectedLanguage(languageId: string) {
    return languageId === this.langStateService.getSelectedLanguage()
  }

  private checkForConsent() {
    const cookieValue = this.cookieService.get(this.COOKIE_CONSENT_NAME);
    this.showConsentMessage$.next(cookieValue !== '1');
  }

  onUnderstandClick() {
    this.cookieService.set(this.COOKIE_CONSENT_NAME, "1", 360);
    this.checkForConsent();
  }

}
