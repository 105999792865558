import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'myportal-receive-fax-options',
  templateUrl: './receive-fax-options.component.html',
  styleUrls: ['./receive-fax-options.component.scss']
})
export class ReceiveFaxOptionsComponent {

  constructor(private dialogRef: DialogRef) {

  }

  close() {
    this.dialogRef.close();
  }

}
