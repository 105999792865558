import { Component, Inject, OnInit } from '@angular/core';
import { BillingDetails, BillingService, CardDetails, CardType } from '../../services/billing.service';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';

import { UsageBalanceComponent } from '../../components/usage-balance/usage-balance.component';
import { Router } from '@angular/router';
import { BillingSettingsComponent } from '../billing-settings/billing-settings/billing-settings.component';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'myportal-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {
  CardType = CardType;
  billingValues: any;
  showAddPayment: boolean = false;
  viewStatement: boolean = false;
  selectedPaymentType: CardType | null = null;
  public cardDetails: CardDetails | null = null;
  public billingDetails: BillingDetails;
  cardTypeSelected: string;
  newCard: boolean = false;
  window: any;

  constructor(private router: Router, private billingService: BillingService,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    @Inject(DOCUMENT) private document: Document) {
    this.window = document.defaultView;
  }

  ngOnInit(): void {
    this.getCardValues();
    this.getBillingDetails();
    if (!this.selectedPaymentType) {
      this.selectedPaymentType = CardType.CreditCard;
    }
  }

  getCardValues(): void {
    this.billingValues = this.billingService.getCards();
  }

  getBillingDetails(): void {
    this.billingDetails = this.billingService.getBillingDetails();
  }

  editPayment(type: CardType): void {
    this.cardDetails = this.billingService.getCardDetails(type);
    this.cardTypeSelected = type;
    this.showAddPayment = true;
  }

  addPayment(): void {
    this.showAddPayment = !this.showAddPayment;
    this.cardDetails = null;
  }

  updateSelection(type: CardType, event: Event): void {
    const input = event.target as HTMLInputElement;
    this.selectedPaymentType = input.checked ? type : null;
  }

  viewStatements(): void {
    this.viewStatement = !this.viewStatement;
  }

  handleNewCardAdded() {
    this.getCardValues();
    this.newCard = true;
    this.showAddPayment = false;
  }

  closePaymentForm() {
    this.showAddPayment = false;
  }

  async editUsageBalance() {
    this.dialog.open(UsageBalanceComponent, {
      width: '600px',
      panelClass: 'forgot-login-modal',
      hasBackdrop: true,
      data: {},
    });
  }

  async openBillingSummary() {
    console.log('openBillingSummary');
    this.router.navigate(['/billing/summary'], { skipLocationChange: true });
  }

  async editBalanceSettings() {
    const billingSettingsModal = this.dialog.open(BillingSettingsComponent,
      {
        width: '600px',
        panelClass: 'forgot-login-modal',
        hasBackdrop: true
      }
    )
    billingSettingsModal.afterClosed().pipe(take(1)).subscribe(() => {
      this.loadingService.setLoading(true);
      const tempTimeOut = this.window.setTimeout(() => {
        this.loadingService.setLoading(false);
        this.window.clearTimeout(tempTimeOut);
      }, 1000);
    })
  }
}
