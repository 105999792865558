import { Component, inject, Inject, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CREATE_TAG_PATTERN } from 'src/app/shared/constants/constants';

export interface TagHandlerData {
  isCreating: boolean;
  tags: string[];
  selectedTag: string;
}

@Component({
  selector: 'myportal-tag-handle-dialog',
  templateUrl: './tag-handle-dialog.component.html',
  styleUrls: ['./tag-handle-dialog.component.scss']
})
export class TagHandleDialogComponent implements OnInit {
  private fb: UntypedFormBuilder = inject(UntypedFormBuilder);
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<TagHandleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TagHandlerData) {

    this.form = this.fb.group({
      selectedTag: [this.data.selectedTag, [Validators.required, Validators.minLength(1), Validators.pattern(CREATE_TAG_PATTERN)]]
    });
  }

  ngOnInit(): void {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  selectingTag(tag: any) {
    this.form.value.patchValue({ selectedTag: tag });
  }
}
