import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'myportal-edit-display-message',
  templateUrl: './edit-display-message.component.html',
  styleUrls: ['./edit-display-message.component.scss']
})
export class EditDisplayMessageComponent {

  constructor(private dialogRef: DialogRef) {

  }

  close() {
    this.dialogRef.close();
  }
}
