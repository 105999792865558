<ng-container *ngIf="(brandConfig !== undefined) && (viewModel$ | async) as vm">

    <div class="split left">

        <div class="form-box" [formGroup]="form">

            <div class="logo">
                <img src="../../../assets/images/{{brandConfig.logo.name}}" alt="{{brandConfig.logo.alt}}">
            </div>

            <div class="form-field-container" *ngIf="!step2">

                <div class="title">
                    <h4 class="welcome"> {{'login.h4.welcome' | transloco }}</h4>
                    <small> {{ 'login.h4.sign.in.text' | transloco }} {{ 'login.h4.continue.subtitle.text' | transloco:
                        { name: brandConfig.name } }}</small>
                </div>

                <!-- Due to SSO, either an email, account number or username can be entered in this field, if an email is entered, SSO will be attempted -->
                <mat-form-field class="login-field" appearance="outline">
                    <mat-label id="username-label">{{ 'login.form.input.username.placeholder' | transloco }}</mat-label>
                    <input matInput id="username" name="username" class="input-text" type="text"
                        aria-labelledby="username-label" autocomplete="given-username" maxlength="100"
                        formControlName="username" required (keyup.enter)="onStepTwo()" />
                </mat-form-field>


                <ul *ngIf="ssoErrors">
                    <li *ngFor="let er of ssoErrors;">{{ er.user_message }}</li>
                </ul>

                <div class="create-account">
                    <a class="create-account-text" href="javascript:void(0)" (click)="onForgotLoginId()">{{
                        'login.form.link.forgot.login.id.text' |
                        transloco }}</a>
                </div>

                <mat-label id="button-next-label" hidden>{{ 'Next' | t }}</mat-label>
                <button id="button-next" name="button-next" class="action-button btn btn-md btn-info button-right"
                    type="button" aria-labelledby="button-next-label" required (click)="onStepTwo()"
                    [disabled]="!allowedToStepTwo">
                    {{ 'login.form.button.next.text' | transloco }}
                </button>
            </div>


            <div class="password-container" [hidden]="!step2">

                <div class="go-back">
                    <a href="javascript:void(0)" (click)="goToStepOne()">{{ 'login.sso.back' | transloco }}</a>
                </div>


                <mat-form-field class="login-field" appearance="outline">
                    <mat-label id="password-label">{{ 'login.form.input.password.placeholder' | transloco }}</mat-label>
                    <input matInput id="password" name="password" [type]="showPassword ? 'text' : 'password'"
                        class="input-text" formControlName="password" aria-labelledby="password-label"
                        autocomplete="given-password" maxlength="50" required (keyup.enter)="onSubmit()" #password />
                </mat-form-field>

                <div class="login-error" *ngIf="vm.isError && !cleanState">
                  <small>{{authErrorMessage}}</small>
                </div>

                <div class="checkbox" (change)="onCheck()">
                    <mat-label id="show-password-label" hidden>{{ 'Show password toggle' | t }}</mat-label>
                    <label for="showPassword">
                        <input [readonly]="this.loadingService.isLoading" id="showPassword" name="showPassword"
                            type="checkbox" aria-labelledby="show-password-label" autocomplete="show-password-toggle">
                        {{ 'login.form.checkbox.show.password.text' | transloco }}
                    </label>
                </div>

                <div class="forgot-password">
                    <a href="javascript:void(0)" (click)="onForgotPassword()">{{ 'login.form.link.forgot.password.text'
                        | transloco }}</a>
                </div>
                <mat-label id="btn-signin-label" hidden>{{ 'Sign In' | t }}</mat-label>
                <button id="btnSignIn" name="btn-sigin" type="button"
                    class="action-button btn btn-md btn-info button-right" aria-labelledby="btn-signin-label"
                    [disabled]="!form.valid || this.loadingService.isLoading" (click)="onSubmit()">
                    {{ 'login.h4.sign.in.text' | transloco }}
                </button>

            </div>

        </div>

        <div class="sign-up-container">
            <small> {{ 'login.signup' | transloco }} &nbsp;&nbsp; </small> <a class="btn btn-md sign-up-button"
                href="{{createAccountLink}}" target="_blank">{{ 'login.form.link.create.account.text' | transloco
                }}</a>
        </div>

        <div class="jsign-banner-mobile">
            <img src="../../../assets/images/jsign-logo.svg" alt="{{ 'login.img.background.right.alt' | transloco }}">
            <span class="jsign-description">
                {{ 'jsign.description' | transloco }}

                <a color="danger" target="_blank"
                    href="https://www.jsign.com/pricing?utm_source=website&utm_medium=banner&utm_campaign=xsell-efax-login"
                    class="btn btn-md try-free">
                    {{ 'jsign.button.text' | transloco }} </a>
            </span>

        </div>
    </div>

    <div class="split right">
        <div class="image-container">
            <img src="../../../assets/images/login-right.jpg" alt="{{ 'login.img.background.right.alt' | transloco }}">
        </div>
        <div class="centered-text">
            <div class="jsign-left">
                <img src="../../../assets/images/jsign-logo.svg"
                    alt="{{ 'login.img.background.right.alt' | transloco }}">
                <span class="jsign-description">
                    {{ 'jsign.description' | transloco }}
                </span>
                <a color="danger" target="_blank"
                    href="https://www.jsign.com/pricing?utm_source=website&utm_medium=banner&utm_campaign=xsell-efax-login"
                    class="btn btn-md try-free">
                    {{ 'jsign.button.text' | transloco }} </a>
            </div>
            <div class="jsign-right">
                <img src="../../../assets/images/jsign-comp.png"
                    alt="{{ 'login.img.background.right.alt' | transloco }}">
            </div>
        </div>
    </div>

    <app-footer [positionClass]="'footer-left'"></app-footer>
</ng-container>

<iframe id="loginIframe" #loginIframe [src]="loginLegacyURL" (load)="onLoad($event)" width="0" height="0"></iframe>