import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { iif, Observable, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { Service } from '../../auth/models/user.single.model';
import { AuthFacadeService } from '../../auth/services/auth-facade.service';
import { TranslocoService } from '@ngneat/transloco';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SUSPEND_FLAG_ALLOW_LIMIT } from 'src/app/shared/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class ComposeGuard implements CanActivate, CanLoad {

  constructor(private authFacade: AuthFacadeService,
    private router: Router,
    private translocoService: TranslocoService,
    private _snackBar: MatSnackBar
  ) {
    this.translocoService.setTranslationKey('access.not.allowed', "You don't have access to this resource, check your account");
  }

  canLoad() {
    return this.authFacade.authState$.pipe(
      filter(state => state !== undefined),
      take(1),
      map(state => { return { hasToken: !!state.access_token, flag: state.suspendedFlag } }),
      map(result => {
        if (result.flag > SUSPEND_FLAG_ALLOW_LIMIT) {
          return this.router.createUrlTree(['/error'], { queryParams: { message: "Access not allowed - account suspended" } });
        } else if (result.flag > 0) {
          return this.router.parseUrl('/view-messages')
        } else {
          if (result.hasToken)
            return true;
          else
            return this.router.parseUrl('/login');
        }
      })
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Need to check both conditions, send enabled and at least one email account
    return this.authFacade.userSingle$
      .pipe(
        map(result => result?.userSingle?.user?.services),
        map(svcs => svcs?.filter(svc => svc.serviceType === "OUTBOUND_FAXING")),
        map(svcList => svcList !== undefined ? svcList[0] : undefined),
        map((svc: Service | undefined) => svc?.features?.isSendEnabled && svc?.emailAddresses.filter(em => em.trim() !== "")?.length > 0),
        take(1),
        switchMap(isAllwd =>
          iif(() => isAllwd === undefined,
            of(isAllwd !== true ? this.redirect('/login') : true),
            iif(() => isAllwd !== true,
              of(isAllwd !== true ? this.redirect('/view-messages') : true),
              of(true)
            )
          )
        )
      );
  }

  redirect(path: string) {
    this._snackBar.open(this.translocoService.translate(["access.not.allowed"]), "X", { duration: 7000 });
    return this.router.createUrlTree([path]);
  }
}
