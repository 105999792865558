import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(value?: string | number | null): string {
    if (value === null) {
      return "";
    }
    let toConvert: string;
    if (typeof (value) === 'number') {
      toConvert = value.toString().trim();
    } else if (typeof (value) === 'string') {
      toConvert = value.trim();
    } else {
      return "";
    }
    let cleaned = toConvert.replace(/\D/g, '');
    let response = "";
    let intlCode = "";
    if (cleaned.length > 10) {
      // international number
      intlCode = cleaned.substr(0, cleaned.length - 10);
      toConvert = cleaned.substr(intlCode.length);
    } else {
      toConvert = cleaned;
    }
    let match = toConvert.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      response = intlCode.length > 0 ? intlCode + " " : "" +
        "(" + match[1] + ") " + match[2] + "-" + match[3]
    } else {
      response = toConvert;
    }
    return response;
  }

}
