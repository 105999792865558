import {
  Component,
  OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SearchbarFacade } from './searchbar.facade';
import { LoadingService } from '../services/loading.service';

const SEARCH_BAR_DEBOUNCE_TIME = 1200;

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss'],
})
export class SearchbarComponent implements OnInit {
  search: UntypedFormControl;
  subscriptions: Subscription[] = [];
  currentSection: string;

  constructor(public searchBarFacade: SearchbarFacade, private loadingService: LoadingService) {
    this.search = new UntypedFormControl('');
  }

  ngOnInit() {
    this.subscriptions.push(
      this.search.valueChanges.pipe(
        debounceTime(SEARCH_BAR_DEBOUNCE_TIME),
        distinctUntilChanged()
      ).subscribe(search => {
        this.loadingService.setLoading(true);
        this.searchBarFacade.onSearchChange(search);
      }));
    this.subscriptions.push(
      this.searchBarFacade.currentSearch$.subscribe(value => {
        this.search.setValue(value);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  isSearchEnabled() {
    return this.search.value !== '';
  }

  clearSearch() {
    this.search.reset();
    this.search.setValue('');
  }
}
