import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';

export interface DialogData {
  name: string;
  errorMessage: string;
}

@Component({
  selector: 'view-messages-new-folder-dialog',
  templateUrl: 'create-new-folder-dialog.component.html',
  styleUrls: ['./create-new-folder-dialog.component.scss']
})
export class DialogFolderCreate {
  constructor(
    public dialogRef: MatDialogRef<DialogFolderCreate>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onChange(): void {
    this.data.errorMessage = this.data.name ? this.data.errorMessage : '';
  }
}
