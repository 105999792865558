import { Component, Inject } from '@angular/core';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';


export interface DialogData {  
  folders: any;
  selectedFolder: any;
  type: any;
}

@Component({
  selector: 'view-messages-delete-folder-dialog',
  templateUrl: 'delete-folder-dialog.component.html',
  styleUrls: ['./delete-folder-dialog.component.scss']
})
export class DialogFolderDelete {  
  constructor(
    public dialogRef: MatDialogRef<DialogFolderDelete>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  getTitle(type: string): string {

    if(type === 'delete') {
      return 'general.deleteFolder'
    } else if(type === 'move') {
      return 'general.moveFax'
    }
    return ''
  }

  onNoClick(): void { 
    this.dialogRef.close();
  }

}