<ng-container *ngIf="loadingService.isLoading$ | async">
  <div class="loading-background">
    <i class="loading"></i>
  </div>
</ng-container>

<ng-container>
  <router-outlet></router-outlet>
</ng-container>
<ng-container>
</ng-container>
