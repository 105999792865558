import {
  EventEmitter,
  Injectable,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SearchbarFacade implements OnInit {

  @Output() searchChanged: EventEmitter<string> = new EventEmitter();
  currentSearch$: Subject<string> = new Subject<string>();

  constructor(private router: Router) { }

  ngOnInit() { }

  setNextSearch(current: string) {
    this.currentSearch$.next(current);
  }

  onSearchChange(search: string) {
    if (this.router.url.includes('/view-messages')) {
      this.searchChanged.emit(search);
    } else {
      this.router.navigate(['/view-messages'], { queryParams: { 'searchParam': search } })
    }
  }
}
