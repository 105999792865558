import { Injectable } from '@angular/core';
import { QueryRef } from 'apollo-angular';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DEFAULT_LIMIT_CONTACTS_QUERY, DEFAULT_OFFSET_CONTACTS_QUERY } from 'src/app/shared/constants/constants';
import { Contact, ContactDeleteInput, ContactSingleResult, ContactsListResult, ContactSortBy } from '../models/contact.model';
import { SortDirection } from '../models/shared.model';
import { CONTACTS_LIST_QUERY, CONTACTS_CREATE_CONTACT_MUTATION, CONTACTS_DELETE_CONTACT_MUTATION, CONTACT_SINGLE_QUERY, CONTACTS_UPDATE_CONTACT_MUTATION } from './contacts.query';
import {ApolloIntportal} from "../../shared/apollo-intportal";
import { LIMIT_TOTAL_COUNT_DEFAULT, OFFSET_DEFAULT } from '../models/table.model';

@Injectable()
export class ContactsApi {

  contactListQuery: QueryRef<ContactsListResult>;

  contactSingleQuery: QueryRef<ContactSingleResult, { contactId: String }>;

  constructor(private apollo: ApolloIntportal) {
    this.contactListQuery = this.apollo.watchQuery<ContactsListResult>({
      query: CONTACTS_LIST_QUERY,
      variables: { limit: DEFAULT_LIMIT_CONTACTS_QUERY, offset: Number(DEFAULT_OFFSET_CONTACTS_QUERY), sortBy: ContactSortBy.FIRST_NAME, sortDirection: SortDirection.ASC }
    })

    this.contactSingleQuery = this.apollo.watchQuery<ContactSingleResult, { contactId: String }>({
      query: CONTACT_SINGLE_QUERY
    })

  }

  fetchContactList(variables: { limit: number, offset: Number, sortBy: ContactSortBy, sortDirection: SortDirection }): Observable<ContactsListResult> {
    return from(this.refreshContactsList(variables))
      .pipe(map(result => result.data));
  }

  fetchContactSingle(variables: { contactId: string }): Observable<ContactSingleResult> {
    return from(this.refreshContactSingle(variables.contactId))
      .pipe(map(result => result.data));
  }

  createNewContact(variables: { input: Contact }) {
    return this.apollo.mutate({
      mutation: CONTACTS_CREATE_CONTACT_MUTATION,
      variables: {
        firstName: variables.input.firstName !== null ? variables.input.firstName : null,
        lastName: variables.input.lastName !== null ? variables.input.lastName : null,
        company: variables.input.company !== null ? variables.input.company : null,
        countryCode: variables.input.countryCode !== null ? variables.input.countryCode : null,
        fax: variables.input.fax !== null ? variables.input.fax : null,
        email: variables.input.email !== null ? variables.input.email : null
      },
    });
  }

  updateContact(variables: { contactId: string, input: Contact }) {
    return this.apollo.mutate({
      mutation: CONTACTS_UPDATE_CONTACT_MUTATION,
      variables: {
        contactId: variables.contactId,
        firstName: variables.input.firstName,
        lastName: variables.input.lastName,
        company: variables.input.company,
        countryCode: variables.input.countryCode,
        fax: variables.input.fax,
        email: variables.input.email
      },
    });
  }

  deleteContact(variables: { input: ContactDeleteInput }) {
    return this.apollo.mutate({
      mutation: CONTACTS_DELETE_CONTACT_MUTATION,
      variables: { contactId: variables.input.id },
    });
  }

  private refreshContactsList(variables: { limit: number, offset: Number, sortBy: ContactSortBy, sortDirection: SortDirection }) {
    return this.contactListQuery.refetch(variables);
  }

  private refreshContactSingle(contactId: String) {
    return this.contactSingleQuery.refetch({ contactId: contactId });
  }

  createContactListParams() {
    return { limit: LIMIT_TOTAL_COUNT_DEFAULT, offset: OFFSET_DEFAULT, sortBy: ContactSortBy.FIRST_NAME, sortDirection: SortDirection.ASC
    }
  }

}
