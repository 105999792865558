import { createAction, props } from '@ngrx/store';
import { ApiError, ComposeModel, CreateFax, FaxCreate, ForwardFaxMultiple, ForwardFaxMultipleFaxesResult, ForwardFaxSingle, ForwardFaxSingleFaxResult } from '../../models/compose.model';

export enum ComposeActionTypes {
    StartingFaxCreation = "[Compose] Starting fax creation",
    StartingSuccess = "[Compose] Starting fax succedding",
    StartingFail = "[Compose] Starting fax failed",
    SendResource = "[Compose] Send resource started",
    SendResourceSuccess = "[Compose] Send resource succedded",
    SendResourceFail = "[Compose] Send resource failed",
    CreateFaxStart = "[Compose] Creating fax start",
    CreateFaxSuccedded = "[Compose] Creating fax succedded",
    CreateFaxFail = "[Compose] Creating fax failed",
    SelectContactFromList = "[Compose] Selecting a contact from the list",
    UpdatingFaxCreation = "[Compose] Updating compose fax creation",
    ReplyComposeStart = "[Compose] Reply fax start",
    ForwardFaxSingleStart = "[Compose] Foward fax single start",
    ForwardFaxMultipleStart = "[Compose] Foward fax multiple start",
    ContactComposeStart = "[Compose] Contact compose fax start",
    ForwardFaxSingleSend = "[Compose] Sending forward fax single",
    ForwardFaxSingleReceive = "[Compose] Receiving response from forwarding fax single",
    ForwardFaxMultipleSend = "[Compose] Sending forward faxes multiple",
    ForwardFaxMultipleReceive = "[Compose] Receiving response from forwarding faxes multiple",
    SetDefaultState = "[Compose] Clearing state to initial state"
}

export const startingFaxCreation = createAction(ComposeActionTypes.StartingFaxCreation, props<{ model: ComposeModel }>());
export const startingSuccess = createAction(ComposeActionTypes.StartingSuccess, props<{ model: ComposeModel }>());
export const startingFail = createAction(ComposeActionTypes.StartingFail, props<{ errorMessage: string }>());
export const createFaxStart = createAction(ComposeActionTypes.CreateFaxStart, props<{ serviceKey: string, input: CreateFax }>())
export const createFaxSuccedded = createAction(ComposeActionTypes.CreateFaxSuccedded, props<{ faxPrepareSend: FaxCreate }>());
export const createFaxFail = createAction(ComposeActionTypes.CreateFaxFail, props<{ data: ApiError }>());
export const sendResource = createAction(ComposeActionTypes.SendResource, props<{ faxId: string, s3Url: string, files: File[] }>());
export const sendResourceSuccess = createAction(ComposeActionTypes.SendResourceSuccess, props<{ data: any }>());
export const sendResourceFail = createAction(ComposeActionTypes.SendResourceFail);
export const updatingFaxCreation = createAction(ComposeActionTypes.UpdatingFaxCreation, props<{ model: ComposeModel }>());
export const replyComposeStart = createAction(ComposeActionTypes.ReplyComposeStart, props<{ model: ComposeModel, faxId: string, serviceKey: string }>());
export const forwardFaxSingleStart = createAction(ComposeActionTypes.ForwardFaxSingleStart, props<{ model: ComposeModel, faxId: string, serviceKey: string }>());
export const forwardFaxMultipleStart = createAction(ComposeActionTypes.ForwardFaxMultipleStart, props<{ model: ComposeModel, input: ForwardFaxMultiple, faxIds: string[], serviceKey: string }>());
export const contactComposeStart = createAction(ComposeActionTypes.ForwardFaxSingleStart, props<{ model: ComposeModel }>());
export const forwardFaxSingleSend = createAction(ComposeActionTypes.ForwardFaxSingleSend, props<{ input: ForwardFaxSingle }>());
export const forwardFaxSingleReceive = createAction(ComposeActionTypes.ForwardFaxSingleReceive, props<{ model: ForwardFaxSingleFaxResult }>());
export const forwardFaxMultipleSend = createAction(ComposeActionTypes.ForwardFaxMultipleSend, props<{ input: ForwardFaxMultiple }>());
export const forwardFaxMultipleReceive = createAction(ComposeActionTypes.ForwardFaxMultipleReceive, props<{ model: ForwardFaxMultipleFaxesResult }>());
export const setDefaultState = createAction(ComposeActionTypes.SetDefaultState);
