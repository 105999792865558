<div class="section">
  <mat-icon matListItemIcon aria-label="{{ originalFolderName }}">
    {{ originalFolderName | iconMap }}
  </mat-icon>

  <div [class.hideFolderName]="isCollapse"
       mat-line
       class="folder-name"
       matTooltip="{{ originalFolderName }}">
    {{ originalFolderName| titlecase }}
    <small class="badge">{{ messagesCount | messageCount }} </small>
  </div>

  <span *ngIf="isEditable"
    class="material-icons edit" (click)="edit()">drive_file_rename_outline</span>
</div>
