import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take, takeUntil } from 'rxjs/operators';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { ReportsService, UsageLog } from '../services/reports.service';
import { Subject } from 'rxjs';
import { AuthFacadeService } from 'src/app/auth/services/auth-facade.service';

@Component({
  selector: 'myportal-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {

  usageData: UsageLog = { receivedFaxes: 0, sentFaxes: 0, storageCode: 'LifeTime' };

  private terminated$: Subject<boolean> = new Subject<boolean>();

  constructor(private router: Router,
    private loadingService: LoadingService,
    private authService: AuthFacadeService,
    private reportService: ReportsService) {

  }

  ngOnDestroy(): void {
    this.terminated$.next(true);
  }

  ngOnInit(): void {
    this.authService.currentService$.pipe(takeUntil(this.terminated$)).subscribe(svc => {
      if (svc !== undefined) {
        this.callUsageReport(svc.phoneNumber);
      }
    });
  }

  goToInbound() {
    this.router.navigate(['/reports/inbound'], { skipLocationChange: true });
  }

  goToOutbound() {
    this.router.navigate(['/reports/inbound'], { skipLocationChange: true, queryParams: { outbound: true } });
  }

  private callUsageReport(currentDID: string) {
    this.loadingService.setLoading(true);
    this.reportService.getUsageReport(currentDID).pipe(take(1)).subscribe(value => {
      if (value !== undefined) {
        this.usageData = { ...value };
      }
    },
      (err) => {
        console.log("Error on getting usage report: ", err);
      },
      () => {
        if (this.loadingService.isLoading)
          this.loadingService.setLoading(false);
      });
  }

}
