
export enum SortBy {
    DATE = "DATE",
    FROM = "FROM",
    TO = "TO",
    SUBJECT ="SUBJECT"
}

export enum SortDirection {
    ASC = "ASC",
    DESC = "DESC"
}

export interface ApiError {
    object_type: string
    code: string
    message: string
}

export enum FileFormat {
    PNG,
    PDF,
    TIFF
}

export enum RepositoryStatuses {
    NEW,
    STORED,
    ARCHIVED,
    TRASH,
    DELETED,
    DELETING,
    MIGRATING,
    SUBMITTED
}

export enum TransmissionStatuses {
    NEW,
    INPROGRESS,
    COMPLETE,
    ERROR,
    SUBMITTED
}

export enum FaxDirections {
    INBOUND,
    OUTBOUND,
    DOCUMENT
}
export enum SearchType {
    METADATA,
    FULL_TEXT
}