import {AfterViewInit, Directive, ElementRef, Input} from "@angular/core";
import {BrandService} from "../services/brand.service";

@Directive({
  selector: '[access]'
})
export abstract class PermissionDirective implements AfterViewInit {

  @Input() brands: string = "";
  private _brandsArray: Array<String> = [];
  private _brandName: string = "";

  constructor(protected elementRef: ElementRef,
              protected brandService: BrandService) {}

  ngAfterViewInit(): void {
    this._brandName = this.brandService.brandName
    this._brandsArray = this.brands
      .split("|")
      .map(function (x) {
        return x.toUpperCase();
      })
  }

  protected get brandsArray(): Array<String> {
    return this._brandsArray;
  }

  protected get brandName(): String {
    return this._brandName;
  }

}

