import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryRef } from 'apollo-angular';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WebAutoLoginHash } from '../models/authenticate.model';
import { UserSingle } from '../models/user.single.model';
import { USER_SINGLE_QUERY, AUTH_REFRESH_TOKEN_QUERY, AUTH_WEB_AUTH_LOGIN_HASH, EMAIL_123_QUERY } from './auth.query';
import { environment } from "../../../environments/environment";
import {ApolloIntportal} from "../../shared/apollo-intportal";

@Injectable()
export class AuthApi {

  private userSingleQuery: QueryRef<UserSingle>;
  private webLoginHashQuery: QueryRef<WebAutoLoginHash, { serviceKey: string, isIntPortal: boolean, current: boolean }>;

  constructor(private apollo: ApolloIntportal, private http: HttpClient) {
    this.userSingleQuery = this.watchUserSingle();
    this.webLoginHashQuery = this.apollo.watchQuery<WebAutoLoginHash, { serviceKey: string, isIntPortal: boolean, current: boolean }>({
      query: AUTH_WEB_AUTH_LOGIN_HASH
    });
  }

  private watchUserSingle(): QueryRef<UserSingle> {
    return this.apollo.watchQuery<UserSingle>({
      query: USER_SINGLE_QUERY,
      variables: {
        isRefresh: false
      }
    });
  }

  refreshToken() {
    return this.apollo.mutate({
      mutation: AUTH_REFRESH_TOKEN_QUERY,
    })
  }

  getUserSingle(): Observable<UserSingle> {
    return from(this.userSingleQuery.refetch({ isRefresh: true }))
      .pipe(map(result => result.data));
  }

  getSessionId(serviceKey: string, brand: string) {
    return this.apollo.query<any>({
      query: EMAIL_123_QUERY,
      variables: {
        serviceKey: serviceKey,
        brand: brand
      }
    }).pipe(map(result => result.data))
  }

  public redirect(url: string, target = '_blank') {
    window.open(url, target);
  }

  getWebLoginHash(variables: { serviceKey: string, isIntPortal: boolean, current: boolean }): Observable<WebAutoLoginHash> {
    return from(this.webLoginHashQuery.refetch(variables))
      .pipe(map(result => result.data));
  }

  getLinkedAppSimple(info: string): Observable<string> {
    return this.http.get(`${environment.coreApiUrl}/internal/users/linked-app/simple/${info}`, { responseType: 'text' })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  /// deprecated: replaced by iframe
  getMyAccountTokenRefresh() {
    const portalSessionID = localStorage.getItem("portalSessionID") || "";
    const requestStickySession = "JK_JSESSIONID=" + (portalSessionID || "");
    const url = `${environment.accountDetailsBaseUrl}refresh_portal_session?isIntPortal=true&${requestStickySession}`

    const formData: FormData = new FormData();
    formData.append('portalSessionID', portalSessionID);
    return this.http.post(url, formData)
      .pipe(map(response => JSON.parse(JSON.stringify(response))));
  }

  /// deprecated: replaced by iframe
  getMyAccountLogOut(portalSessionID: string) {
    const url = `${environment.accountDetailsBaseUrl}removePortalContext?isIntPortal=true&JK_JSESSIONID=${portalSessionID}`;
    const formData: FormData = new FormData();
    formData.append('portalSessionID', portalSessionID);
    return this.http.post(url, formData);
  }
}
