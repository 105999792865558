import { Component } from '@angular/core';

@Component({
  selector: 'myportal-recaptcha-load-fail-dialog',
  templateUrl: './recaptcha-load-fail-dialog.component.html',
  styleUrls: ['./recaptcha-load-fail-dialog.component.scss']
})
export class RecaptchaLoadFailDialogComponent {

}
