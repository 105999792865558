import {AfterViewInit, Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {from, of} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {SortDirection} from '../models/shared.model';
import {ContactListParams, ContactSortBy,} from '../models/contact.model';
import {CONTACT_SELECT_COLUMNS, ContactsTableData, OFFSET_DEFAULT, TABLE_DATA} from '../models/table.model';
import {ContactsFacadeService} from 'src/app/contacts/services/contacts-facade.service';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {DEFAULT_LIMIT_CONTACTS_QUERY} from 'src/app/shared/constants/constants';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {joinWithSeparatorIfExist} from "../../shared/functions/stringFormater";
import {MatSort} from "@angular/material/sort";
import {combineAll, map} from "rxjs/operators";


export interface DialogData {
  maxAvailableSelection: number;
  skipFaxInfo: [{
    faxNumber: string,
    email: string,
    firstName: string,
    lastName: string
  }]
}

@Component({
  selector: 'app-select-contacts',
  templateUrl: './select-contacts.component.html',
  styleUrls: ['./select-contacts.component.scss']
})
export class SelectContactsComponent implements OnInit, AfterViewInit {

  protected dataSource = new MatTableDataSource(TABLE_DATA);
  protected rowPerPage = DEFAULT_LIMIT_CONTACTS_QUERY;

  selection = new SelectionModel<ContactsTableData>(true, []);
  contactsHeader: string[] = CONTACT_SELECT_COLUMNS;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  @Input() maxAvailableSelection: number;

  private joinedFaxInfo: string[]

  constructor(public dialogRef: MatDialogRef<SelectContactsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
              readonly contactsFacadeService: ContactsFacadeService) {
  }

  ngOnInit(): void {
    this.maxAvailableSelection = this.data?.maxAvailableSelection
    if (this.data?.skipFaxInfo) {
      this.joinedFaxInfo = this.data.skipFaxInfo
        .map(el =>
          this.joinFaxNumber_Email([
            el.faxNumber,
            el.firstName,
            el.lastName
          ]))
    }

    this.initContactList();
    const refreshContactOption = this.defaultRefreshContactOption();
    this.contactsFacadeService.dispatchLoadContactsList({ ...refreshContactOption });
  }

  ngAfterViewInit(): void {
    this.dataSource = new MatTableDataSource(TABLE_DATA.filter(contact => contact.fax));
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  private defaultRefreshContactOption(): ContactListParams {
    return {
      limit: 0,
      offset: OFFSET_DEFAULT,
      sortBy: ContactSortBy.FIRST_NAME,
      sortDirection: SortDirection.ASC,
    }
  }

  canSelectRow(row: ContactsTableData) {
    return this.selection.selected.includes(row) || this.maxAvailableSelection != this.selection.selected.length
  }

  isAllSelected() {
    return this.currentPageContacts().every( r => this.selection.isSelected(r));
  }

  checkboxLabel(row?: ContactsTableData): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
  }

  currentPageContacts() {
      const start = this.paginator.pageIndex * this.paginator.pageSize;
      const remaining = this.dataSource.data.slice(start, this.dataSource.data.length);
      const end = start + Math.min(this.paginator.pageSize, remaining.length);
      return this.dataSource.data.slice(start, end);
  }

  masterToggle(event: MatCheckboxChange) {
    if (!event.checked) {
      this.selection.deselect(...this.currentPageContacts());
      return;
    }

    if (!this.canSelectFullPage()) {
      event.source.checked = false;
      return;
    }

    const currentPageContacts = this.currentPageContacts();
    const end = Math.min(currentPageContacts.length, this.getRemainingAllowedSelection());
    const newSelectedContacts = currentPageContacts.slice(0, Math.min(this.paginator.pageSize,  end));
    const alreadySelectedContacts = this.selection.selected;
    this.selection.setSelection(...alreadySelectedContacts, ...newSelectedContacts);
  }

  getRemainingAllowedSelection() {
    return this.maxAvailableSelection - this.selection.selected.length;
  }

  canSelectFullPage() {
    return this.getRemainingAllowedSelection() >= this.paginator.pageSize
  }

  private joinFaxNumber_Email(faxInfo: Array<String>) {
    return  joinWithSeparatorIfExist(faxInfo, '_')
  }


  private initContactList() {
    this.contactsFacadeService.contactsList$
      .subscribe(contactsObject => {
        if (contactsObject != undefined) {
          from(contactsObject.contacts).pipe(
            map(el =>
              of({
                contactId: el.contactId,
                name: joinWithSeparatorIfExist([el.firstName, el.lastName], ' '),
                company: el.company,
                fax: el.fax,
                email: el.email,
                firstName: el.firstName,
                lastName: el.lastName,
                countryCode: el.countryCode
              })
            ),
            combineAll()
          ).subscribe(el => {
            this.dataSource.data = el.filter(contact => contact.fax);
            this.selection.setSelection(...el.filter(el => this.joinedFaxInfo?.includes(
              this.joinFaxNumber_Email([
                el.fax,
                el.firstName,
                el.lastName,
              ])
            )))
          })
        }
      })

    this.refreshMaxAvailableSelection()

  }

  private refreshMaxAvailableSelection(){
    if (this.joinedFaxInfo) {
      this.maxAvailableSelection -= this.joinedFaxInfo.length - this.dataSource.data
        .filter(el => this.joinedFaxInfo?.includes(this.joinFaxNumber_Email([
          el.fax,
          el.firstName,
          el.lastName,
        ])))
        .length
    }
  }

  get disableSubmitButton(): boolean {
    return !this.selection.hasValue() || this.selection.selected.length === 0
  }

  get recordsLength() {
    return Number(this.dataSource.data?.length)
  }
}
