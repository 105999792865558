<div class="corp-set">
    <section>
        <h2>{{ 'corporate.title' | transloco }}</h2>

        <h3>{{ 'corporate.validating.message' | transloco }}</h3>
        <h3>{{ 'corporate.validating.message.2' | transloco }}</h3>

        <p>{{ 'corporate.orientation.message' | transloco }}</p>
    </section>
</div>
