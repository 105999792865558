import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormArray, FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { formatBytes } from '../../shared/functions/formatBytes';
import {
  ALLOWED_FILE_TYPES,
  FILE_MAX_LENGTH_ALLOWED,
  FILE_MAX_SIZE_ALLOWED,
  RECIPIENTS_MAX_NUMBER_ALLOWED,
  SEARCH_BAR_DEBOUNCE_TIME
} from 'src/app/shared/constants/constants';
import { ComposeFacadeService } from '../services/compose-facade.service';
import { AttachmentModel, ComposeAction, ComposeActionTypes, ComposeModel } from '../models/compose.model';
import { countries } from '../models/countries.data-store';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';
import { AuthFacadeService } from 'src/app/auth/services/auth-facade.service';
import { ContactsTableData } from 'src/app/contacts/models/table.model';
import { ContactsFacadeService } from 'src/app/contacts/services/contacts-facade.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SelectContactsComponent } from 'src/app/contacts/components/select-contacts.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from 'src/environments/environment';
import { Contact } from 'src/app/contacts/models/contact.model';
import { LoadingService } from "../../shared/services/loading.service";
import { joinWithSeparatorIfExist } from "../../shared/functions/stringFormater";

function forbiddenCharactersValidator() {
  return (control: FormControl) => {
    const forbiddenCharacters = /[<>]/; // Regular expression to check for > and < characters

    if (forbiddenCharacters.test(control.value)) {
      return { forbiddenCharacters: true };
    }

    return null;
  };
}

@Component({
  selector: 'app-compose',
  templateUrl: './compose.component.html',
  styleUrls: ['./compose.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ComposeComponent implements OnInit, OnDestroy, AfterViewInit {


  @ViewChild('fileUploadRef')
  fileUploadRef!: ElementRef;
  draggingFile = false
  serviceKey = ""; // is OUTBOUND_ServiceKey actually
  hideAdditionalSettings = false;
  acceptedFileTypes = "image/*".concat(",").concat(ALLOWED_FILE_TYPES.join(","))

  fileList: File[] = [];

  private previewFile: any = undefined;
  createFaxForm: UntypedFormGroup = this.fb.group({});
  composeModel!: ComposeModel;
  sendSuscription!: Subscription;
  showSuccess = false;
  showError = false;
  modalContact = false;
  isUploadingFile = false;
  currentFilesSize = 0;
  emailAddresses: string[] = [];
  faxQuality = ['STANDARD', 'FINE'];
  private localSubscriptions: Subscription[] = [];
  fileTypesPopoverVisible = false;
  pdfSrc: any;
  activeFileExtension: string;
  disablePreviewImage = false;
  maxFileSizeWarning = false;
  maxFileNumberWarning = false;
  public countries: any = countries;
  defaultEmailAddress: string | undefined = undefined;
  defaultCountryCode: string | undefined = undefined;
  selectedCountryCode: string | undefined = undefined;
  @ViewChild('countryCode', { static: true })
  countryCodeRef: ElementRef;

  readonly terminated$: Subject<boolean> = new Subject<boolean>();
  protected readonly RECIPIENTS_MAX_NUMBER_ALLOWED = RECIPIENTS_MAX_NUMBER_ALLOWED;

  private currentFormAction: ComposeAction | undefined = undefined;
  forwarding: any;
  userId: string;
  sendFaxToken: string = '';
  faxCSID: string;
  sendFaxErrorMessage: string = '';

  contactsSource: any[] = [];
  contactOptions: any[] = [];

  forwardedFaxes: string[] = [];

  constructor(public dialog: MatDialog, private fb: UntypedFormBuilder,
    private composeFacadeService: ComposeFacadeService,
    private authFacadeService: AuthFacadeService,
    private sanitizer: DomSanitizer,
    private _snackBar: MatSnackBar,
    private translocoService: TranslocoService,
    private loadingService: LoadingService,
    readonly contactsFacadeService: ContactsFacadeService,
    private router: ActivatedRoute) {

    this.router.params.subscribe((params) => {
      this.forwarding = params.forward;
    });
    this.setDefaultFormActionType();
  }

  ngOnDestroy(): void {

    this.localSubscriptions.forEach(s => s.unsubscribe());
    this.composeFacadeService.dispatchSetInitialState();
    this.clearPageComponents();
    this.unsubscribeSendFax();
    this.clearRecipient();
    this.terminated$.next(true);
  }

  ngAfterViewInit(): void {
    if (this.currentRecipient.get('fax')?.value?.length < 1) {
      this.prePopulateFaxNumber(this.countryCodeRef.nativeElement.value);
    }
  }

  removeExtension(fileName: string) {
    let file = fileName.split('.');
    file.pop();
    return file.join('.');
  }

  ngOnInit(): void {

    this.resetForm();
    this.subscribeToComposeAction();
    this.subscribeToCurrentService();
    this.subscribeToCurrentUserMapToDefaultCountryCode();
    this.subscribeToFaxError();
    this.subscribeToAddCoverPageChanges();
    this.subscribeToFaxForwardSingle();
    this.subscribeToFaxForwardMultiple();
    this.subscribeToAuthState();
    this.getfaxCSID();
    this.subscribeToCurrentRecipientChanges();
    this.subscribeToContactList();
  }

  subscribeToCurrentRecipientChanges() {
    this.currentRecipient.get('firstName')?.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.terminated$), debounceTime(SEARCH_BAR_DEBOUNCE_TIME))
      .subscribe((value: string | null) => {
        this.filterContacts(value);
      });
    this.currentRecipient.get('lastName')?.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.terminated$), debounceTime(SEARCH_BAR_DEBOUNCE_TIME))
      .subscribe((value: string | null) => {
        this.filterContacts(value);
      });
    this.currentRecipient.get('company')?.valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.terminated$), debounceTime(SEARCH_BAR_DEBOUNCE_TIME))
      .subscribe((value: string | null) => {
        this.filterContacts(value);
      });
  }

  private filterContacts(value: string | null) {
    if (value === null || value.length < 3) {
      this.contactOptions.splice(0);
      return
    }
    this.contactOptions = this.contactsSource
      .filter((c: Contact) => c.firstName?.toLowerCase().includes(value?.toLowerCase() || '') ||
        c.lastName?.toLowerCase().includes(value?.toLowerCase() || '') ||
        c.company?.toLowerCase().includes(value?.toLowerCase() || ''));
  }

  displayContactAutoComplete(c: Contact) {
    return `${c.firstName} ${c.lastName ? c.lastName : ''} ${c.company ? '- ' + c.company : ''}`
  }

  selectContact(contact: Contact) {
    this.changeCountryCode({ target: { value: contact.countryCode } });
    this.currentRecipient.patchValue({
      contactId: contact.contactId,
      firstName: contact.firstName,
      lastName: contact.lastName,
      company: contact.company,
      fax: contact.fax,
      saveContact: false
    }, { emitEvent: false });
  }

  private subscribeToContactList() {
    this.contactsFacadeService.contactsList$
      .pipe(distinctUntilChanged(), takeUntil(this.terminated$))
      .subscribe(contactsObject => {
        if (contactsObject != undefined) {
          this.contactsSource = contactsObject.contacts;
        }
      })
  }

  changeCountryCode(event: any) {
    this.selectedCountryCode = event.target.value;
    this.setCountryCode(this.selectedCountryCode);
    this.prePopulateFaxNumber(event.target.value);
  }

  private prePopulateFaxNumber(code: string) {
    const country = this.findCountryCode(code);
    if (country) {
      this.setFaxNumber(this.clearDialCode(country?.dial_code));
    }
  }

  private clearDialCode(dial_code: string) {
    return dial_code.replace(/^\D+/g, '');
  }

  private findCountryCode(code: string) {
    return countries.find(ct => ct.code === code);
  }

  get recipientFaxNumber() {
    return this.currentRecipient?.get('fax') as UntypedFormControl
  }

  setFaxNumber(value: string | undefined) {
    this.recipientFaxNumber.setValue(value);
  }

  setCountryCode(value: string | undefined) {
    this.currentRecipient.value.countryCode = value;
  }

  private subscribeToAddCoverPageChanges() {
    this.coverPage?.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.terminated$))
      .subscribe(value => {
        if (value.includeCoverPage === false) {
          this.clearCoverPage();
        }
      });
  }

  private subscribeToFaxError() {
    this.localSubscriptions.push(
      this.composeFacadeService.isFaxError$
        .pipe(
          filter(value => !!value))
        .subscribe(_ => this.showFaxErrorMessage())
    );
  }

  // private subscribeToIsBusy() {
  //   this.localSubscriptions.push(
  //     this.composeFacadeService.isBusy$.subscribe(value => this.loadingService.setLoading(value))
  //   );
  // }

  private subscribeToAuthState() {
    this.authFacadeService.authState$.subscribe((value) => {
      this.userId = value.user_name;
    })
  }

  private subscribeToCurrentService() {
    this.authFacadeService.userSingle$
      .pipe(
        takeUntil(this.terminated$),
        map(result => result?.userSingle?.user?.services),
        map(svcs => svcs?.filter(svc => svc.serviceType === "OUTBOUND_FAXING")))
      .subscribe(value => {
        if (value !== undefined) {
          this.serviceKey = value[0].serviceKey;
          this.emailAddresses = value[0].emailAddresses.filter(email => email.trim() !== "");
          if (this.emailAddresses.length > 0) {
            this.defaultEmailAddress = this.emailAddresses[0];
            this.setDefaultEmail(this.defaultEmailAddress);
          }
        }
      })
  }

  private setDefaultEmail(email: string | undefined) {
    const sendEmailAddressControl = this.createFaxForm?.get('additionalSettings')?.get('sendEmailAddress');
    if (sendEmailAddressControl && email) {
      sendEmailAddressControl.setValue(email);
    }
  }

  private getfaxCSID() {
    this.localSubscriptions.push(
      this.authFacadeService.userSingle$
        .pipe(map(result => result?.userSingle?.user?.services), map(svcs => svcs?.filter(svc => svc.serviceType === "INBOUND_FAXING")))
        .subscribe(value => {
          if (value !== undefined) {
            this.faxCSID = value[0].features.faxCSID;
          }
        })
    );
  }
  private subscribeToCurrentUserMapToDefaultCountryCode() {
    this.authFacadeService.userSingle$
      .pipe(map(result => result?.userSingle?.user?.userInfo?.country), takeUntil(this.terminated$))
      .subscribe(value => {
        this.defaultCountryCode = value !== undefined ? value : "";
        this.setDefaultSelectedCountryCode(value);
      });
  }

  private setDefaultSelectedCountryCode(value: string | undefined) {
    this.selectedCountryCode = value !== undefined ? value : "";
    if ([undefined, null].includes(this.recipientFaxNumber.value)) {
      this.prePopulateFaxNumber(this.selectedCountryCode);
    }
  }

  private subscribeToComposeAction() {
    this.localSubscriptions.push(
      this.composeFacadeService.formAction$
        .subscribe(action => {
          if (action !== undefined) {
            this.currentFormAction = action;
            this.handleNewComposeAction(action);
          } else {
            this.setDefaultFormActionType();
          }
        })
    );
  }

  private setDefaultFormActionType() {
    this.currentFormAction = { faxId: undefined, serviceKey: this.serviceKey, form: undefined, type: ComposeActionTypes.NEW };
  }

  private handleNewComposeAction(action: ComposeAction | undefined) {
    if (this.shouldResetForm(action?.type)) {
      this.resetForm();
    }
    this.composeModel = action?.form as ComposeModel;
    if (this.composeModel && this.composeModel.userId) {
      this.composeModel.userId = this.faxCSID ? this.faxCSID : this.userId;
    }
    this.refreshFormData();
    if (action?.faxId !== undefined) {
      // handling attachments
      this.forwardedFaxes = typeof (action.faxId) === "string" ? [action.faxId] : [...action.faxId];
    } else {
      this.forwardedFaxes = [];
    }
  }

  imagesByFaxId(faxId: string) {
    return this.composeModel?.originalAttachments?.filter(v => v.faxId === faxId) || [];
  }

  get currentRecipient(): UntypedFormGroup {
    return this.createFaxForm.get('currentRecipient') as UntypedFormGroup;
  }

  get coverPage(): UntypedFormGroup {
    return this.createFaxForm.get('coverPage') as UntypedFormGroup;
  }

  get additionalSettings(): UntypedFormGroup {
    return this.createFaxForm.get('additionalSettings') as UntypedFormGroup;
  }

  checkFaxNumbervalue(evt: any) {
    if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57) {
      evt.preventDefault();
    }
  }

  get recipientsFormArray(): FormArray {
    return this.createFaxForm.get('recipients') as FormArray;
  }

  addRecipient(rec: UntypedFormGroup) {
    if (this.allowAddRecipient()) {
      return
    }
    rec.value.countryCode = this.selectedCountryCode;

    if (this.doesObjectExistInFormArray(rec.value)) {
      return
    }

    const newRecipient = this.emptyRecipient;
    newRecipient.patchValue(rec.value);

    this.recipientsFormArray.insert(0, newRecipient)

    this.clearCurrentRecipient();
    this.setDefaultSelectedCountryCode(this.defaultCountryCode);

    let country = countries.find(ct => ct.code === this.defaultCountryCode)

    this.currentRecipient.get('fax')?.setValue(country?.dial_code.replace('+', ''));
  }

  private doesObjectExistInFormArray(objToFind: any): boolean {
    const isExist = this.recipientsFormArray.getRawValue().some(contact => {
      return contact.firstName?.toUpperCase() === objToFind.firstName?.toUpperCase() &&
        contact.lastName?.toUpperCase() === objToFind.lastName?.toUpperCase() &&
        contact.company?.toUpperCase() === objToFind.company?.toUpperCase() &&
        contact.fax === objToFind.fax &&
        contact.countryCode === objToFind.countryCode
    })

    if (isExist) {
      this._snackBar.open(this.translocoService.translate(["compose.duplicated.recipient"]), "X", { duration: 10000 })
      return true;
    }
    return false;
  }

  clearRecipient() {
    this.recipientsFormArray.reset()
    this.updateStore();
  }

  updateStore() {
    this.composeFacadeService.updateFormData(this.createFaxForm.getRawValue() as ComposeModel);
  }

  toggleAdditionalSettings() {
    this.hideAdditionalSettings = !this.hideAdditionalSettings;
  }

  get emptyRecipient(): UntypedFormGroup {
    return this.fb.group({
      contactId: [null],
      firstName: [null, [Validators.required, Validators.maxLength(100),
      Validators.minLength(2)]],
      lastName: ['', [Validators.maxLength(100),
      Validators.minLength(2)]],
      company: ['', [Validators.maxLength(100)]],
      fax: [null, [Validators.required, Validators.maxLength(20), Validators.minLength(8)]],
      countryCode: [null],
      saveContact: [false]
    });
  }

  get emptyFaxCover(): UntypedFormGroup {
    return this.fb.group({
      includeCoverPage: [false],
      coverTitle: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(255), forbiddenCharactersValidator()]],
      coverText: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(1000), forbiddenCharactersValidator()]]
    });
  }

  get emptyAdditionalSettings(): UntypedFormGroup {
    return this.fb.group({
      faxQuality: [this.faxQuality[1], [Validators.required]],
      sendEmailAddress: ["", [Validators.email]],
      referenceId: ["", [Validators.maxLength(60)]]
    });
  }

  refreshFormData() {
    this.createFaxForm.patchValue(this.composeModel);
    if (this.composeModel?.recipients) {
      this.composeModel.recipients.forEach(value => {
        const newRecipient = this.emptyRecipient;
        newRecipient.patchValue(value);
        this.recipientsFormArray.push(newRecipient);
      });
    }
  }

  private handleSaveContact() {
    if (null === this.composeModel || null === this.composeModel.recipients) {
      return
    }

    this.composeModel.recipients.forEach(contact => {
      if (contact.saveContact) {
        const countryCode = contact?.countryCode?.trim()
        const countryCodeObject = this.findCountryCode(countryCode);
        const faxNumber = contact?.fax?.trim();
        this.contactsFacadeService.dispatchCreateNewContact({
          input: {
            contactId: '',
            fax: faxNumber,
            firstName: contact?.firstName?.trim() || "",
            lastName: contact?.lastName?.trim() || "",
            company: contact?.company?.trim() || "",
            email: '',
            countryCode: countryCode
          }
        });
      }
    });
  }


  sendFax() {
    let formSend = new FormData();
    const mailbox = `${this.userId}@${environment.signDomain}`;
    switch (this.currentFormAction?.type) {
      case ComposeActionTypes.FOWARD_FAX_SINGLE:
        formSend.append("mailbox", mailbox as string);
        formSend.append("mids", this.currentFormAction?.faxId as string);
        this.sendGeneralFax(formSend);
        break;
      case ComposeActionTypes.FOWARD_FAX_MULTIPLE:
        const faxIds: string = undefined != this.currentFormAction?.faxId && null != this.currentFormAction?.faxId ? this.currentFormAction?.faxId.toString().replace(/,/g, "|") : "";
        formSend.append("mailbox", mailbox as string);
        formSend.append("mids", faxIds);
        this.sendGeneralFax(formSend);
        break;
      case ComposeActionTypes.REPLY:
        formSend.append("mailbox", mailbox as string);
        formSend.append("mids", this.currentFormAction?.faxId as string);
        this.sendGeneralFax(formSend);
        break;
      case ComposeActionTypes.NEW:
      default:
        this.composeModel = this.createFaxForm.getRawValue();
        //this.sendRegularFax(); // sending a fax using  faxclient API.
        this.sendGeneralFax(formSend);
        break;
    }
    this.setDefaultFormActionType();
  }

  private sendGeneralFax(formSend: FormData) {
    const form = this.createFaxForm.getRawValue() as ComposeModel;
    const portalSessionID = localStorage.getItem("portalSessionID");
    const portalSessionHashID = localStorage.getItem("portalSessionIdHash");
    if (portalSessionHashID !== null) {
      this.composeFacadeService.generateSendToken(portalSessionHashID.toString()).pipe(take(1)).subscribe(response => {
        let jsonResp = JSON.parse(JSON.stringify(response));
        if (jsonResp.data.generateSendToken.sendTokenHash) {

          this.sendFaxToken = jsonResp.data.generateSendToken.sendTokenHash;
          this.unsubscribeSendFax();
          let sendAFaxContactList: string = "";
          let corelationId = self.crypto.randomUUID();
          console.log("SendFax corelationId : " + corelationId);
          form.recipients.forEach(data => {
            sendAFaxContactList += data.fax + "," + data.firstName + "," + (data.lastName || '') + "," + (data.company || '') + "|$$|";
          });

          //Set FormData
          if (portalSessionHashID !== null && portalSessionID !== null) {
            formSend.append("token", this.sendFaxToken);
            formSend.append("renderMode", "json");
            formSend.append("attachmentCount", this.fileList.length.toString());
            formSend.append("recipientFirstName", form.currentRecipient.firstName);
            formSend.append("recipientLastName", form.currentRecipient.lastName || "");
            formSend.append("recipientCompany", form.currentRecipient.company || "");
            formSend.append("toCountry", form.currentRecipient.countryCode);
            formSend.append("faxNumber", form.currentRecipient.fax);
            formSend.append("_chk_addContact", "");
            formSend.append("chk_addContact", form.currentRecipient.saveContact == true ? "on" : "");
            formSend.append("_includeCoverPage", "");
            formSend.append("includeCoverPage", this.isCoverPageFilled(form) ? "on" : "");
            formSend.append("faxSubject", form.coverPage.coverTitle || '');
            formSend.append("faxBody", form.coverPage.coverText || '');
            formSend.append("referenceId", form.additionalSettings.referenceId || '');
            formSend.append("sendReceipt", form.additionalSettings.sendEmailAddress);
            formSend.append("faxMode", form.additionalSettings.faxQuality.toLowerCase());
            this.fileList.forEach(attachment => {
              formSend.append("attachment", attachment);
            });
            formSend.append("useRecipientList", form.recipients.length > 0 ? "true" : "false");
            formSend.append("recipientListSize", form.recipients.length.toString());
            formSend.append("SendAFaxContactList", sendAFaxContactList);
            // formSend.append('portalSessionID', portalSessionID.toString());
            formSend.append('isIntPortal', "true");
            formSend.append('corelationId', corelationId);

            //sendFax
            this.composeFacadeService.sendFax(formSend).subscribe(result => {
              let jsonResp = JSON.parse(JSON.stringify(result));
              if (jsonResp.messageCode == "ma_success") {
                this.handleSaveContact();
                this.showFaxSuccessMessage();
              } else {
                if (null != jsonResp.errorMessage && undefined != jsonResp.errorMessage)
                  this.sendFaxErrorMessage = this.translocoService.translate("compose.label.msg.fax.send.error") + " : " + jsonResp.errorMessage;
                else
                  this.sendFaxErrorMessage = this.translocoService.translate("compose.label.msg.fax.send.error");
                this.showFaxErrorMessage();
              }
            }, error => {
              console.error(`something went wrong uploading the file`, error);
              this.sendFaxErrorMessage = this.translocoService.translate("compose.label.msg.fax.send.error");
              this.showFaxErrorMessage();
            }, () => {
              console.info(`received the complete tick.`);
            });
          } else {
            this.sendFaxErrorMessage = this.translocoService.translate("compose.label.msg.fax.send.error");
            this.showFaxErrorMessage();
          }

        } else {
          console.error("send Token is not generated");
        }
      });
    }

  }

  private isCoverPageFilled(form: ComposeModel) {
    const coverTitle = form.coverPage.coverTitle;
    const coverText = form.coverPage.coverText;
    return form.coverPage.includeCoverPage && ((coverTitle && coverTitle.length > 0) || (coverText && coverText.length > 0));
  }

  trimCoverPageControls(control: string) {
    const value = this.coverPage.get(control)?.value;
    this.coverPage.get(control)?.setValue(value?.trim());
  }

  private subscribeToFaxForwardSingle() {
    this.composeFacadeService.faxForwardSingle$
      .pipe(
        filter(value => value !== undefined),
        takeUntil(this.terminated$)
      ).subscribe(result => {
        if (result !== undefined) {
          if (result.object_type === 'ApiError') {
            this.showFaxErrorMessage();
          } else {
            this.showFaxSuccessMessage();
          }
        }
      })
  }

  private subscribeToFaxForwardMultiple() {
    this.composeFacadeService.faxForwardMultiple$
      .pipe(
        filter(value => value !== undefined),
        takeUntil(this.terminated$)
      ).subscribe(result => {
        if (result !== undefined) {
          if (result.object_type === 'ApiError') {
            this.showFaxErrorMessage();
          } else {
            this.showFaxSuccessMessage();
          }
        }
      });
  }



  fileBrowserHandler(files: FileList) {
    this.addFiles(files);
  }

  onFileDropped(files: FileList) {
    this.addFiles(files);
  }

  onFormatBytes(size: number | undefined) {
    return size ? formatBytes(size) : 0;
  }

  private addFiles(files: FileList) {

    const filesArray = Object.values(files)

    for (const file of filesArray) {
      if (this.isInFileList(file)) {
        console.log(`file: '${file.name}' is already added`);
        continue;
      }

      const nextFilesSize = this.currentFilesSize + file?.size

      if (this.exceededMaxSize(nextFilesSize)) {
        this.maxFileSizeWarning = true;
        console.warn(`max file size: '${FILE_MAX_SIZE_ALLOWED}' is exceeded`);
        break;
      } else {
        this.maxFileSizeWarning = false;
      }

      const nextFileCount = this.fileList.length + 1
      if (this.exceededMaxNumberAllowed(nextFileCount)) {
        this.maxFileNumberWarning = true;
        console.warn(`max number of files: '${FILE_MAX_LENGTH_ALLOWED}' is exceeded`);
        break;
      } else {
        this.maxFileNumberWarning = false;
      }

      const fileExtension = file.name.toLowerCase().split('.').pop()
      if (!this.isExtensionAllowed(fileExtension)) {
        console.warn(`this '${fileExtension}' is not allowed`);
        this._snackBar.open(this.translocoService.translate(["compose.file.type.not.supported"]), "X", { duration: 10000 })
        continue;
      }

      this.fileList.push(file);
      this.updateFileSize();
    }

    this.fileUploadRef.nativeElement.value = ''
  }

  private isExtensionAllowed(extension: string | undefined) {
    return !!extension && this.acceptedFileTypes.includes(extension);
  }

  private exceededMaxSize(currentSize: number) {
    return currentSize >= FILE_MAX_SIZE_ALLOWED;
  }

  private isInFileList(file: File) {
    return this.fileList.filter(item => item.name == file.name).length !== 0;
  }

  private exceededMaxNumberAllowed(nextFileCount: number) {
    return nextFileCount > FILE_MAX_LENGTH_ALLOWED
  }

  removeFile(file: File | AttachmentModel, index: number) {
    if (file instanceof File) {
      this.fileList = this.fileList.filter((_, arrayIndex) => index !== arrayIndex);
    } else {
      this.composeModel.originalAttachments = this.composeModel.originalAttachments?.filter((_, arrayIndex) => index !== arrayIndex);
    }
    this.updateFileSize();
    this.clearPreview();
  }

  updateFileSize() {
    this.currentFilesSize = 0;
    this.fileList.forEach((file) => {
      this.currentFilesSize += file.size
    });
    if (this.exceededMaxSize(this.currentFilesSize)) {
      this.maxFileSizeWarning = true;
      console.warn(`max file size: '${FILE_MAX_SIZE_ALLOWED}' is exceeded`);
    } else {
      this.maxFileSizeWarning = false;
    }

    if (this.exceededMaxNumberAllowed(this.fileList.length)) {
      this.maxFileNumberWarning = true;
      console.warn(`max number of files: '${FILE_MAX_LENGTH_ALLOWED}' is exceeded`);
    } else {
      this.maxFileNumberWarning = false;
    }

  }

  disableAddRecipient() {
    return this.currentRecipient.invalid || this.allowAddRecipient()
  }



  disableSendButton() {
    const isServiceKeyValid = !!this.serviceKey
    const isThereFiles = this.fileList.length > 0 || this.currentFormAction?.form?.originalAttachments?.length
    const isMaxFilesAllowed = this.fileList.length < 11
    const isincludingCoverPage = this.coverPage.get('includeCoverPage')?.value;
    const isCoverPageValid = (isincludingCoverPage === true) ? this.coverPage.valid : false;
    const isThereCoverPageOrFilesAdded = (isincludingCoverPage !== true) ? isThereFiles : isCoverPageValid;
    const isRecipientsValid = this.recipientsFormArray.getRawValue().length > 0
    const enableButton = isServiceKeyValid && isRecipientsValid && isThereCoverPageOrFilesAdded && !this.loadingService.isLoading && !this.isUploadingFile && isMaxFilesAllowed;
    return !enableButton
  }

  @HostListener('dragover', ['$event'])
  handleDragOver() {
    this.draggingFile = true
  }

  @HostListener('dragleave', ['$event'])
  handleDragLeave() {
    this.draggingFile = false
  }

  private clearFeedbackMessage() {
    setTimeout(() => {
      this.dismissSuccessMessage()
      this.dismissErrorMessage()
    }, 3000)
  }

  private unsubscribeSendFax() {
    this.sendSuscription?.unsubscribe()
  }

  private showFaxErrorMessage() {
    this.showError = true;
    this.showSuccess = false;
    this.isUploadingFile = false;
    this.clearFeedbackMessage()
  }

  private showFaxSuccessMessage() {
    this.showError = false;
    this.showSuccess = true;
    this.isUploadingFile = false;
    this.clearPageComponents();
    this.composeFacadeService.dispatchSetInitialState();
    this.clearFeedbackMessage();
  }

  private clearPageComponents() {
    this.clearFileList();
    this.clearCoverPage();
    this.clearRecipientsArray();
    this.clearCurrentRecipient();
    this.clearAdditionalSettings();
    this.clearPreview();
  }

  private clearFileList() {
    this.fileList = [];
    if (this.composeModel?.originalAttachments?.length) {
      this.updateStore();
    }
  }

  private clearRecipientsArray() {
    this.resetForm();
  }

  private resetForm() {
    this.createFaxForm = this.fb.group({
      currentRecipient: this.emptyRecipient,
      recipients: this.fb.array([]),
      coverPage: this.emptyFaxCover,
      additionalSettings: this.emptyAdditionalSettings
    });
    this.setDefaultEmail(this.defaultEmailAddress);
    this.setDefaultSelectedCountryCode(this.defaultCountryCode);
  }

  private clearCurrentRecipient() {
    this.currentRecipient.markAsPristine();
    this.currentRecipient.reset();
    this.prePopulateFaxNumber(this.countryCodeRef.nativeElement.value);
  }

  private clearAdditionalSettings() {
    this.additionalSettings.markAsPristine();
    this.additionalSettings.get('referenceId')?.reset();
  }

  private clearCoverPage() {
    this.coverPage.markAsUntouched();
    this.coverPage.markAsPristine();
    this.coverPage.clearValidators();
    this.coverPage.reset();
  }

  dismissSuccessMessage() {
    this.showSuccess = false
  }

  dismissErrorMessage() {
    this.showError = false
  }

  updateIcon($event: any) {
    if ($event.type === "error") {
      this.disablePreviewImage = true;
    }
  }

  setPreviewFile(file: File | string) {
    this.clearPreview();
    if (file instanceof File) {
      let that = this;

      that.pdfSrc = undefined;

      if (file.type === 'application/pdf') {
        let read = new FileReader();

        read.readAsBinaryString(file);

        read.onloadend = function () {
          that.pdfSrc = { data: read.result };
          that.previewFile = file;
        }
        this.disablePreviewImage = false;
      } else if (!file.type.includes('image')) {
        that.pdfSrc = undefined;
        that.activeFileExtension = file.name.split('.')[file.name.split('.').length - 1];
        this.disablePreviewImage = true;
      } else if (file.type.includes('image')) {
        that.disablePreviewImage = false;
        const newFile: AttachmentModel = {
          fileName: file.name,
          url: URL.createObjectURL(file),
          faxId: "",
          size: file.size
        }
        this.previewFile = newFile as AttachmentModel;
      }
    } else {
      this.disablePreviewImage = false;
      this.previewFile = file as string;
    }
  }

  getThumbnail(file: File) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file));
  }

  getPreviewFile() {
    return this.previewFile;
  }

  clearPreview() {
    if (this.previewFile !== undefined) {
      if (!!this.previewFile?.fileName) {
        URL.revokeObjectURL(this.previewFile.url);
      }
      this.previewFile = undefined;
    }
  }

  selectContactsDialogConfig: MatDialogConfig = {
    panelClass: ['select-contacts-dialog', 'modal-dialog'],
    backdropClass: 'cdk-overlay-transparent-backdrop',
    hasBackdrop: true,
    position: {
      top: "6%"
    }
  }

  onSelectContactsId() {
    this.selectContactsDialogConfig.data = {
      maxAvailableSelection: RECIPIENTS_MAX_NUMBER_ALLOWED,
      skipFaxInfo: this.recipientsFormArray.getRawValue().map(el => {
        return {
          faxNumber: el.fax,
          email: el.email,
          firstName: el.firstName,
          lastName: el.lastName
        }
      })
    }
    const selectContactDialog = this.dialog.open(SelectContactsComponent, {
      ...this.selectContactsDialogConfig
    }
    );
    selectContactDialog.afterClosed().pipe(
      filter(r => (r.cancel === false || r.cancel === undefined)),
      take(1),
      map(s => {
        return { ...s, selected: s.selected as ContactsTableData[] }
      })
    ).subscribe(result => {
      this.insertedContacts(result.selected, result.all);
    });
  }

  insertedContacts(selectedContacts: ContactsTableData[], all: []) {
    if (this.allowAddRecipient()) {
      return
    }

    let timeOut = 0;
    this.recipientsFormArray.getRawValue().reduce(function (ind: any, el: any, i: any) {
      if (!selectedContacts.some(el1 => el1.contactId === el.contactId)
        && all.some(el2 => el2["contactId"] === el.contactId)) {
        ind.push(i);
        timeOut = 700;
      }
      return ind;
    }, [])
      .reverse()
      .forEach((el: number) => {
        this.recipientsFormArray.removeAt(el)
      })

    setTimeout(a => {
      selectedContacts
        .filter(item1 =>
          !this.recipientsFormArray.getRawValue().find(item2 => item1.contactId === item2.contactId)
          && !this.recipientsFormArray.getRawValue().find(item2 =>
            joinWithSeparatorIfExist([item1.fax, item1.firstName, item1.lastName], '_') ==
            joinWithSeparatorIfExist([item2.fax, item2.firstName, item2.lastName], '_')
          ))
        .forEach(el => {
          const newRecipient = this.emptyRecipient;
          newRecipient.patchValue(el);
          this.recipientsFormArray.insert(0, newRecipient)
        });
    }, timeOut)
  }

  showPopover() {
    this.fileTypesPopoverVisible = true;
  }

  hidePopover() {
    this.fileTypesPopoverVisible = false;
  }

  private shouldResetForm(type: ComposeActionTypes | undefined) {
    return type !== ComposeActionTypes.REPLY &&
      type !== ComposeActionTypes.FOWARD_FAX_SINGLE &&
      type !== ComposeActionTypes.FOWARD_FAX_MULTIPLE &&
      type !== ComposeActionTypes.UPDATE
  }

  private allowAddRecipient(): boolean {
    return this.recipientsFormArray.getRawValue().length >= RECIPIENTS_MAX_NUMBER_ALLOWED
  }
}
