<main class="main">
  <div class="content pb-30">
    <div class="toolbar">
      <label hidden id="single-message-go-back-label">{{
        "general.goBack" | transloco
        }}</label>
      <button mat-icon-button matTooltip="{{ 'general.goBack' | transloco }}" (click)="goBack()"
        aria-labelledby="single-message-go-back-label">
        <mat-icon aria-labelledby="single-message-go-back-label">arrow_back</mat-icon>
      </button>
      <button [disabled]="isPrintDisabled()" mat-icon-button attr.aria-label="{{ 'general.print' | transloco }}"
        matTooltip="{{ 'general.print' | transloco }}" (click)="print()">
        <mat-icon>print</mat-icon>
      </button>
      <button [disabled]="isDownloadDisabled()" mat-icon-button attr.aria-label="{{ 'general.download' | transloco }}"
        matTooltip="{{ 'general.download' | transloco }}" (click)="download()">
        <mat-icon>cloud_download </mat-icon>
      </button>
    </div>

    <div class="wrapper">
      <mat-tab-group [(selectedIndex)]="segmentIndex" mat-stretch-tabs="false" mat-align-tabs="start" color="primary">
        <mat-tab label="{{'reports.received.faxes' | transloco}}" class="tab-container">
          <div class="segment">
            <div class="description">
              {{'reports.choose.date' | transloco }}
            </div>
            <!-- <div class="horizontal"> -->
            <myportal-date-range-picker [(start)]="receivedLogsFilter.start"
              (buttonClick)="filterReceivedLogs({ start: receivedLogsFilter.start, end: receivedLogsFilter.end })"
              [startLabel]="('reports.table.header.from' | transloco) + ':'" [(end)]="receivedLogsFilter.end"
              [endLabel]="('reports.table.header.to' | transloco) + ':'" [minForStart]="receivedLogsFilter.minForStart"
              [maxForStart]="receivedLogsFilter.maxForStart" [minForEnd]="receivedLogsFilter.minForEnd"
              [maxForEnd]="receivedLogsFilter.maxForEnd" (startChange)="changeStartDateForReceived($event)"
              (endChange)="changeEndDateForReceived($event)">
            </myportal-date-range-picker>
            <!-- </div> -->
          </div>
          <myportal-received-logs-table #receivedLogsTable class="printable-area">
          </myportal-received-logs-table>
        </mat-tab>

        <mat-tab label="{{'reports.sent.faxes' | transloco }}" class="tab-container">
          <div class="segment">
            <div class="description">
              {{
              'reports.choose.date' | transloco
              }}
            </div>
            <!-- <div class="input-line-holder">
              <mat-label for="fromDate">{{ 'reports.table.header.from' | transloco }}</mat-label>
              <input type="date" id="fromDate" name="fromDate">
            </div> -->
            <!-- <div class="horizontal"> -->
            <myportal-date-range-picker [(start)]="sentLogsFilter.start"
              (buttonClick)="filterSentLogs({ start: sentLogsFilter.start, end: sentLogsFilter.end })"
              [startLabel]="('reports.table.header.from' | transloco) + ':'" [(end)]="sentLogsFilter.end"
              [endLabel]="('reports.table.header.to' | transloco) + ':'" [minForStart]="sentLogsFilter.minForStart"
              [maxForStart]="sentLogsFilter.maxForStart" [minForEnd]="sentLogsFilter.minForEnd"
              [maxForEnd]="sentLogsFilter.maxForEnd" (startChange)="changeStartDateForSent($event)"
              (endChange)="changeEndDateForSent($event)">
            </myportal-date-range-picker>
            <!-- </div> -->
          </div>
          <myportal-sent-logs-table #sentLogsTable class="printable-area">
          </myportal-sent-logs-table>
        </mat-tab>

        <!--
          <mat-tab label="Usage Reports">Usage Reports</mat-tab>
          <mat-tab label="Batch Reports">Batch Reports</mat-tab> -->
      </mat-tab-group>
    </div>
  </div>
</main>