import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'myportal-delete-fax-dialog',
  templateUrl: './delete-fax-dialog.component.html',
  styleUrls: ['./delete-fax-dialog.component.scss']
})
export class DeleteFaxDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DeleteFaxDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  cancel(): void {
    this.dialogRef.close();
  }
}
