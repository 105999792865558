import {CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';

import { ComposeRoutingModule } from './compose-routing.module';
import { ComposeComponent } from './components/compose.component';
import { SelectContactsComponent } from '../contacts/components/select-contacts.component';
import { TranslocoRootModule } from '../../transloco-root.module';
import { SharedModule } from '../shared/shared.module';
import * as fromStore from './store/index';
import { EffectsModule } from '@ngrx/effects';
import { ComposeEffects } from './store/effects/compose.effects';
import { ComposeApi } from './api/compose.api';
import { ComposeFacadeService } from './services/compose-facade.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { PipesModule } from '../shared/pipes/pipes.module';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatCheckboxModule } from '@angular/material/checkbox';

import {MatAutocompleteModule} from '@angular/material/autocomplete'
import {RecipientsComponent} from "./components/recipients/recipients.component";

@NgModule({
  declarations: [
    ComposeComponent,
    RecipientsComponent,
    SelectContactsComponent
  ],
  providers: [ComposeApi, ComposeFacadeService],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ComposeRoutingModule,
    TranslocoRootModule,
    SharedModule,
    StoreModule.forFeature('compose', fromStore.reducers),
    EffectsModule.forFeature([ComposeEffects]),
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    PdfViewerModule,
    PipesModule,
    MatAutocompleteModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    SelectContactsComponent
  ]
})
export class ComposeModule { }
