<div class="modal">
  <div class="toolbar space-between">
    <h3 class="title">
      {{ "edit.display.messages.title" | transloco }}
    </h3>
    <button mat-icon-button aria-label="close dialog" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="hr"></div>

  <div class="explanation">
    <p>
      <label
        >{{ "edit.display.messages.explanation" | transloco }}
      </label>
    </p>
  </div>

  <div class="checkbox-container">
    <div><input name="checkbox" type="checkbox"></div>
    <div><label>{{'edit.display.messages.positive' | transloco}}</label></div>
  </div>

  <div class="checkbox-container">
    <div><input name="checkbox" type="checkbox"></div>
    <div><label>{{'edit.display.messages.negative' | transloco}}</label></div>
  </div>

  <div class="hr"></div>

  <div class="update-button-container">
    <button
      id="update-button"
      name="update-button"
      aria-labelledby="update-button-label"
      class="btn btn-md btn-info update-button">
      <label>
        {{ "billing.usage.update" | transloco }}
      </label>
      <mat-icon>play_circle_filled</mat-icon>
    </button>
  </div>
</div>

