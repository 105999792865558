<div *ngIf="!isError">
  <h5 class="title-dialog" mat-dialog-title>{{ 'recaptcha.message' | transloco }}</h5>
  <div mat-dialog-content class="content">
    <div #divRecaptcha id="recaptcha" class="dialog-recaptcha"></div>
  </div>
</div>


<div *ngIf="isError">
  <h5 class="title-dialog" mat-dialog-title>{{ 'recaptcha.failed.title' | transloco }}</h5>
  <div mat-dialog-content class="content">
    <label>{{ 'recaptcha.failed.message' | transloco }}</label>
  </div>
</div>
