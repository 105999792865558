import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CorporateComponent } from './components/corporate.component';
import { ErrorLoginComponent } from './components/error-login.component';
import { LoginComponent } from './components/login.component';
import { LoginGuard } from './guards/login.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [LoginGuard],
    component: LoginComponent,

  },
  {
    path: 'corporate',
    component: CorporateComponent
  },
  {
    path: 'error',
    component: ErrorLoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }

