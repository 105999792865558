import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthApi } from 'src/app/auth/api/auth.api';
import { AuthFacadeService } from 'src/app/auth/services/auth-facade.service';
import { environment } from '../../../environments/environment';
import { LoadingService } from "../services/loading.service";
import {LoginFacade} from "../../login/facade/login.facade";

@Component({
  selector: 'myportal-holder',
  templateUrl: './holder.component.html',
  styleUrls: ['./holder.component.scss']
})
export class HolderComponent implements OnDestroy {
  @ViewChild('myIframe') myIframe: ElementRef;
  serviceKeyText: string = '';
  targetUrl: string = '';
  iFrameResp: number = 1;
  private terminated$: Subject<boolean> = new Subject<boolean>();
  private eventSet: boolean = false;

  constructor(private authFacadeService: AuthFacadeService,
    private authApi: AuthApi,
    private loginFacadeService: LoginFacade,
    private loadingService: LoadingService) {
    if (window.addEventListener && !this.eventSet) {
      window.addEventListener("message", this.receiveMessage.bind(this), false);
      this.eventSet = true;
    } else if (!this.eventSet) {
      (<any>window).attachEvent("onmessage", this.receiveMessage.bind(this));
      this.eventSet = true;
    }
    this.authFacadeService.currentService$.subscribe(service => {
      if (service !== undefined) {
        this.serviceKeyText = service.phoneNumber;
        this.updateIframeUrl();
      }
    });
    this.loadingService.setLoading(true);
  }

  ngAfterViewInit() {
    const iframeElement = this.myIframe.nativeElement;
    iframeElement.addEventListener('load', this.loaderOff.bind(this));
    if (iframeElement.readyState === 'complete') {
      // If the iframe has already loaded, handle it immediately
      this.loaderOff();
    }
  }

  private updateIframeUrl() {
    const portalSessionID = localStorage.getItem('portalSessionID');

    if (portalSessionID !== null && !!this.serviceKeyText) {
      this.authFacadeService.authState$.pipe(map(s => s.suspendedFlag), take(1)).subscribe(value => {
        const adminUrl = environment.accountDetailsBaseUrl + 'overview?isIntPortal=true' + (value > 0 ? '&suspendFlags=2' : '');
        this.targetUrl = adminUrl + '&accountDID=' + this.serviceKeyText; //&portalSessionID=' + portalSessionID + '&JK_JSESSIONID=' + portalSessionID;
      })
    } else {
      console.error('Missing required values to create the URL');
    }
  }

  receiveMessage(event: any) {
    if (typeof (event.data) === 'string') {
      if ((event !== null && event !== undefined) && event.data === 'errorMassage') {
        this.loadingService.setLoading(false);
      }
      else if ((event !== null && event !== undefined) && event.data === 'updateClick') {
        // if (!this.loadingService.isLoading) {
        //   this.loadingService.setLoading(true);
        //   this.authFacadeService.updateUserSingle(this.serviceKeyText);
        // }
        if (!this.authFacadeService.forceUpdateUserFlag) {
          this.authFacadeService.forceUpdateUserFlag = true;
          this.authFacadeService.forceUpdateServiceNumber = this.serviceKeyText;
        }
      }
      else if (event !== null && event !== undefined && event.data?.includes('portalSessionId_')) {
        // localStorage.setItem("portalSessionID", event.data.split('_')[1]);

        //update local storage while chang in date-time
        if (event.data.includes('portalLegacySettingsUpdated')) {
          //on timezone changed, request to FaxClient to get refresh data for userprofile and update object
          this.authFacadeService.updateUserSingle(this.serviceKeyText);
        }
      }
      else if ((event !== null && event !== undefined) && event.data === 'portalLogout') {
        console.log("Received portalLogout");
        this.loginFacadeService.logOut();
      }
    }
  }

  loaderOff() {
    if (this.iFrameResp == 2) {
      this.loadingService.setLoading(false);
    } else {
      this.iFrameResp = this.iFrameResp + 1;
    }

  }

  ngOnDestroy(): void {
    this.terminated$.next(true);
    // next line updates userSingle after leaving account details always
    // this.authFacadeService.updateUserSingle(this.serviceKeyText);
  }

}
