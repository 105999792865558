import { DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

export interface PrintableTableData {
  columns: string[];
  columnsMap: any;
  dataSource: any[];
}

@Component({
  selector: 'myportal-printable-table',
  templateUrl: './printable-table.component.html',
  styleUrls: ['./printable-table.component.scss']
})
export class PrintableTableComponent implements OnInit {

  columns: string[];
  columnsMap: any;
  dataSource = new MatTableDataSource<any>();

  constructor(private dialogRef: DialogRef,
              @Inject(MAT_DIALOG_DATA) public data: PrintableTableData,
  ) {
     this.columnsMap = this.data.columnsMap;
     this.columns = this.data.columns;
     this.dataSource.data = this.data.dataSource;
  }

  ngOnInit(): void {
    window.addEventListener('afterprint', () => {
      this.close();
    });

    setTimeout(() => {
      window.print();
    }, 1000);
  }

  close() {
    this.dialogRef.close();
  }

  getValue(column: string,_row: any) {
    const key = this.columnsMap[column];
    return _row[key];
  }
}
