import { createAction, props } from '@ngrx/store';

import { Service, UserSingle } from '../../models/user.single.model';
import { AuthPayload } from '../models/auth.payload';


export enum AuthActionTypes {
  LoadAuth = '[Auth] Load Auth from store',
  LoadAuthComplete = '[Auth] Load Auth Complete',
  LoadAuthFailure = '[Auth] Load Auth Failure',
  SetAuth = '[Auth] Set Auth from Log in',
  SetAuthComplete = '[Auth] Set Auth from Log in complete',
  RemoveAuth = '[Auth] Remove Auth from Log off',
  RemoveAuthComplete = '[Auth] Remove Auth from Log off complete',
  LoadUserSingle = '[Auth] Load user single',
  UpdateUserSingle = '[Auth] Update user single',
  UpdateUserSingleRecurrent = '[Auth] Updating user single recurrently',
  SetCurrentService = '[Auth] Set current service',
  RefreshAuth = '[Auth] Refresh Auth in store started',
  RefreshAuthComplete = '[Auth] Refresh auth completed',
  RefreshAuthFailure = '[Auth] Refresh Auth in store failed',
  RefreshTime = '[Auth] Refresh only the timing log',
  ExpiringStarted = '[Auth] Expiring session process started',
  ExpiringFinished = '[Auth] Expiring session process finished',
  SetUserName = '[Auth] Setting username logged in',
  AuthenticationError = "[Auth] Authentication process failed, error action",
  AuthenticationSuccedded = "[Auth] Authentication process completed and succedded",

  FireLegacyStart = "[Auth] Start iframe loading",
  FireLegacyEnd = "[Auth] End iframe call"
}

export const loadAuth = createAction(AuthActionTypes.LoadAuth);
export const loadAuthComplete = createAction(AuthActionTypes.LoadAuthComplete, props<{ auth: AuthPayload }>());
export const loadAuthFailure = createAction(AuthActionTypes.LoadAuthFailure);
export const loadUserSingle = createAction(AuthActionTypes.LoadUserSingle, props<{
  user: UserSingle | undefined,
  error: any | undefined, userName: string | undefined, flag: number, fromLogin: boolean
}>());
export const updateUserSingle = createAction(AuthActionTypes.UpdateUserSingle, props<{ currentDID: string }>());
export const updateUserSingleRecurrent = createAction(AuthActionTypes.UpdateUserSingleRecurrent, props<{ user: UserSingle, current: Service }>())
export const setAuth = createAction(AuthActionTypes.SetAuth, props<{ auth: AuthPayload }>());
export const setAuthComplete = createAction(AuthActionTypes.SetAuthComplete, props<{ auth: AuthPayload, fromLogin: boolean }>());
export const removeAuth = createAction(AuthActionTypes.RemoveAuth);
export const removeAuthComplete = createAction(AuthActionTypes.RemoveAuthComplete);
export const setCurrentService = createAction(AuthActionTypes.SetCurrentService, props<{ service: Service }>());
export const refreshAuth = createAction(AuthActionTypes.RefreshAuth);
export const refreshAuthComplete = createAction(AuthActionTypes.RefreshAuthComplete, props<{ newToken: string, setIn: number, expires: number }>());
export const refreshAuthFailure = createAction(AuthActionTypes.RefreshAuthFailure, props<{ error: string }>());
export const refreshTiming = createAction(AuthActionTypes.RefreshTime, props<{ setIn: number, expires: number }>());
export const expiringStarted = createAction(AuthActionTypes.ExpiringStarted);
export const expiringFinished = createAction(AuthActionTypes.ExpiringFinished);
export const settingUserName = createAction(AuthActionTypes.SetUserName, props<{ username: string }>());
export const authenticationError = createAction(AuthActionTypes.AuthenticationError, props<{ error: any }>());
export const authenticationSuccedded = createAction(AuthActionTypes.AuthenticationSuccedded, props<{ initialService: Service, flag: number, fromLogin: boolean }>());

export const fireLegacyUrlCall = createAction(AuthActionTypes.FireLegacyStart, props<{ typeNumber: number, locationUrl: string }>());
export const setLegacyUrlNone = createAction(AuthActionTypes.FireLegacyEnd);
