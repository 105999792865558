import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'myportal-usage-balance',
  templateUrl: './usage-balance.component.html',
  styleUrls: ['./usage-balance.component.scss']
})
export class UsageBalanceComponent {
  balance: number = 100.00;
  newReorderAmount: number = 10.00;
  lastReorderAmount: number = 0;
  amounts: number[] = [
    10.00,
    25.00,
    50.00,
    75.00,
    100.00,
    200.00,
    150.00,
    200.00,
    250.00
  ];

  constructor(private dialogRef: DialogRef) {

  }
  close() {
    this.dialogRef.close();
  }

}
