import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoRootModule } from '../../transloco-root.module';

import { GraphQLModule } from './modules/graphql.module';

import { ThemeService } from './services/theme.service';

import { TopbarComponent } from './topbar/topbar.component';
import { SearchbarComponent } from './searchbar/searchbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { LayoutComponent } from './layout/layout.component';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { ThumbPreviewDirective } from './directives/thumb-preview.directive';
import { IdleComponent } from './idle/idle.component';

import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { PipesModule } from './pipes/pipes.module';
import { SnackSuccess } from './feedback/snack.success'
import { SnackError } from './feedback/snack.error';
import { HolderComponent } from './layout/holder.component';
import { FileshareComponent } from './layout/fileshare.component';
import { ConsentPopupComponent } from './footer/consent-popup.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HidePermissionDirective } from "./permissions/hide-permission.directive";
import { DisablePermissionDirective } from "./permissions/disable-permission.directive";
import { ShowPermissionDirective } from "./permissions/show-permission.directive";
import { MatDividerModule } from "@angular/material/divider";
import { ApolloIntportal } from "./apollo-intportal";
import { LangStateService } from './lang/lang-state.service';
import { LoadingService } from './services/loading.service';
import { CsvService } from './services/csv.service';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { environment } from "../../environments/environment";
import { GoogleTagOnclickDirective } from "../googletagmanager/directives/google-tag-onclick.directive";
import { GoogleTagManagerModule } from "../googletagmanager/google-tag-manager.module";
import { ReceiveEmailAddressComponent } from './components/receive-email-address/receive-email-address.component';
import { ReceiveFaxOptionsComponent } from './components/receive-fax-options/receive-fax-options.component';
import { SendFaxOptionsComponent } from './components/send-fax-options/send-fax-options.component';
import { ProfileComponent } from './components/profile/profile.component';
import { EditDisplayMessageComponent } from './components/edit-display-message/edit-display-message.component';
import { CompanyNameComponent } from './components/company-name/company-name.component';
import { CompanySizeComponent } from './components/company-size/company-size.component';
import { HomeNumberComponent } from './components/home-number/home-number.component';
import { WorkNumberComponent } from './components/work-number/work-number.component';
import { MobileNumberComponent } from './components/mobile-number/mobile-number.component';
import { PrintableTableComponent } from './components/printable-table/printable-table.component';

@NgModule({
  declarations: [
    TopbarComponent,
    SidebarComponent,
    FooterComponent,
    LayoutComponent,
    DragAndDropDirective,
    ThumbPreviewDirective,
    HidePermissionDirective,
    ShowPermissionDirective,
    DisablePermissionDirective,
    IdleComponent,
    SearchbarComponent,
    SnackSuccess,
    SnackError,
    HolderComponent,
    FileshareComponent,
    ConsentPopupComponent,
    DateRangePickerComponent,
    GoogleTagOnclickDirective,
    ReceiveEmailAddressComponent,
    ReceiveFaxOptionsComponent,
    SendFaxOptionsComponent,
    ProfileComponent,
    EditDisplayMessageComponent,
    CompanyNameComponent,
    CompanySizeComponent,
    HomeNumberComponent,
    WorkNumberComponent,
    MobileNumberComponent,
    PrintableTableComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GraphQLModule,
    RouterModule,
    TranslocoRootModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatTabsModule,
    PipesModule,
    MatDialogModule,
    MatMenuModule,
    MatSnackBarModule,
    MatDividerModule,
    MatChipsModule,
    MatCheckboxModule,
    MatListModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSortModule,
    MatTableModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatTabsModule,
    MatAutocompleteModule,
    GoogleTagManagerModule.forRoot({
      id: environment.gtm_id
    })
  ],
  exports: [
    TranslocoRootModule,
    TopbarComponent,
    SidebarComponent,
    FooterComponent,
    LayoutComponent,
    DragAndDropDirective,
    ThumbPreviewDirective,
    GoogleTagOnclickDirective,
    HidePermissionDirective,
    ShowPermissionDirective,
    DisablePermissionDirective,
    MatButtonModule,
    MatInputModule,
    IdleComponent,
    MatIconModule,
    MatTooltipModule,
    SearchbarComponent,
    SnackSuccess,
    SnackError,
    MatMenuModule,
    MatDialogModule,
    MatSnackBarModule,
    MatDividerModule,
    MatDialogModule,
    MatChipsModule,
    PipesModule,
    MatCheckboxModule,
    MatListModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSortModule,
    MatTableModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatTabsModule,
    MatAutocompleteModule,
    DateRangePickerComponent,
    ProfileComponent,
    ReceiveEmailAddressComponent,
    ReceiveFaxOptionsComponent,
    SendFaxOptionsComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    ApolloIntportal,
    ThemeService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'accent' } },
    LangStateService,
    LoadingService,
    CsvService
  ]
})
export class SharedModule { }
