import { gql } from 'apollo-angular';

export const LOGIN_MUTATION = gql`
  mutation Login($username: String, $password: String, $brand: String) {
    login(username: $username, password: $password, brand: $brand) {
      __typename

      ... on Authenticate {
        object_type
        token
        passwordExpireIn
        suspendedFlag
      }

      ... on ApiError {
        object_type
        code
        message
      }
    }
  }
`;



export const RECAPTCHA_MUTATION = gql`
  mutation ReCaptchaVerification ($reCaptchaClient: String!) {
    reCaptchaVerification (reCaptchaClient: $reCaptchaClient) {
      __typename

      ... on ReCaptchaVerification {
        object_type
        score
      }

      ... on ApiError {
        object_type
        code
        message
      }
    }
  }
`;

export const LOGIN_SSO_MUTATION = gql`
  mutation Login($code: String, $correlation_id: String) {
    login(code: $code, correlationId: $correlation_id) {
      __typename

      ... on Authenticate {
        token
      }

      ... on ApiError {
        object_type
        code
        message
      }
    }
  }
`;

export const LOGIN_FORGOT_ID = gql`
  mutation recoverLoginId($email: String!, $brand: String) {
  recoverLoginId(email: $email, brand: $brand) {
    __typename

    ... on RecoverLoginId {
      status
    }

    ... on ApiError {
      object_type
      code
      message
    }
  }
}
`;

export const LOGIN_FORGOT_PASSWORD_MUTATION = gql`
  mutation passwordForgot($faxNumber: String!, $brand: String) {
    passwordForgot(faxNumber: $faxNumber, brand: $brand) {
      __typename
      
      ...on PasswordForgot {
        object_type
        emailSent
      }
      
      ... on ApiError {
          object_type
          code
          message
      }
    }
  }
`;

export const LOGIN_PASSWORD_VERIFY_MUTATION = gql`
  mutation ($faxNumber: String!,
    $securityCode: String!,
    $password: String!,
    $confirmPassword: String!,
    $brand: String
  ) {
    passwordVerify(
      faxNumber: $faxNumber,
      securityCode: $securityCode,
      password: $password,
      confirmPassword: $confirmPassword,
      brand: $brand
    ) {
      __typename
      ...on PasswordVerify {
        object_type
        messageCode
        status
      }
      ... on ApiError {
        object_type
        code
        message
      }
    }
  }
`;