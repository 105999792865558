import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApplicationRef, Injectable } from '@angular/core';
import {
  LoginAction,
  LoginCompleteAction, LoginErrorAction,
  LogOutAction,
  LogOutCompleteAction,
  LoginSSOStart,
  LoginSSOSuccess,
  LoginSSOFailure,
  SSOResponseValidated,
  ForgotLoginIdStart,
  ForgotLoginIdFailure,
  ForgotLoginIdSuccess
} from '../actions/login.actions';
import { LoginApi, LoginAPIResponseTypes } from '../../api/login.api';
import { ssoResponseError } from '../../models/login.model';
import { forkJoin, of } from 'rxjs';
import { Router } from '@angular/router';
import { AuthFacadeService } from 'src/app/auth/services/auth-facade.service';
import { ApiError } from 'src/app/auth/models/api.error.model';

@Injectable()
export class LoginEffects {

  constructor(private actions$: Actions,
    private loginApi: LoginApi,
    private ref: ApplicationRef,
    private router: Router,
    private authFacade: AuthFacadeService) { }

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginAction),
      switchMap((action) => {
        this.ref.tick()
        return forkJoin(
          [
            this.loginApi.login(action.payload, action.brand),
            this.loginApi.loginFF(action.payload, action.brand)
          ]
        )
          .pipe(
            map(response => {
              return {
                ...response[0].data,
                username: action.payload.username,
                faxFusion: response[1].token
              }
            }),
            tap(() => this.authFacade.setUserName(action.payload.username)),
            map(data => {
              return this.handleLoginResponse(data);
            }),
            catchError(() => of(this.loginErrorDefault))
          );
      })
    );
  });

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LogOutAction),
      tap(() => {
        this.authFacade.removeAuth();
      }),
      map(() => LogOutCompleteAction())
    );
  });

  logOutComplete$ = createEffect(() => this.actions$.pipe(
    ofType(LogOutCompleteAction),
    tap(() =>
      this.router.navigate(['/login']).then(() => {
        // FMA-15595 Whatfix variables reload - requires a hard refresh for the plugin to notice the changes
        window.location.reload();
      })
    )),
    { dispatch: false }
  );

  checkSSO$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginSSOStart),
      switchMap(action => this.loginApi.ssoLookup(action.user_email)),
      tap(value => { console.log(value) }),
      map(value => {
        return LoginSSOSuccess({ response: { ...value } })
      }),
      catchError((err) => {
        if (err?.error?.errors?.length > 0) {
          const severalErrors: ssoResponseError[] = [];
          severalErrors.push(...err.error.errors);
          return of(LoginSSOFailure({ errors: severalErrors }));
        } else {
          const oneError: ssoResponseError = { error_code: err?.status, developer_message: err?.message, user_message: err?.statusText }
          console.log(oneError?.developer_message);
          return of(LoginSSOFailure({ errors: [oneError] }));
        }
      })
    )
  });

  loginCompleteAction$ = createEffect(() => this.actions$.pipe(
    ofType(LoginCompleteAction),
    tap((value) => {
      this.authFacade.setAuth(value.payload.auth.token,
        value.payload.userName, value.payload.auth.suspendedFlag,
        value.faxFusion);
    })),
    { dispatch: false }
  );

  /* loginErrorAction$ = createEffect(() => this.actions$.pipe(
    ofType(LoginErrorAction),
    tap(() => {
      this.ref.tick()
    })),
    { dispatch: false }
  ); */

  loginBySSO$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SSOResponseValidated),
      switchMap(action => this.loginApi.loginSSO(action.response.code, action.response.correlation_id)),
      tap(value => { JSON.stringify(value) }),
      map(result => result.data),
      map((value: any) => { return { ...value, username: '' } }),
      map(data => this.handleLoginResponse(data)),
      catchError(() => {
        return of(this.loginErrorDefault)
      })
    )
  });

  forgotLoginId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ForgotLoginIdStart),
      switchMap(action => this.loginApi.forgotLoginIdRequest(action.payload.user_email, action.payload.brand)),
      map(result => result.data),
      map((data: any) => {
        if (data?.recoverLoginId?.__typename === LoginAPIResponseTypes.API_ERROR) {
          return this.handleApiError(data.recoverLoginId as ApiError);
        }
        return ForgotLoginIdSuccess({ status: data.recoverLoginId.status });
      }),
      catchError((err) => {
        console.log('Error catch en forgot login id:', err);
        this.router.navigate([this.buildErrorUrl(err)], { skipLocationChange: true });
        return of(this.handleApiError(undefined));
      })
    )
  });

  private handleApiError(apiError: ApiError | undefined) {
    const localError: ApiError = {
      message: apiError !== undefined ? apiError.message : "",
      code: apiError !== undefined ? apiError.code : "",
      object_type: LoginAPIResponseTypes.API_ERROR
    }
    return ForgotLoginIdFailure({ error: localError })
  }

  private handleLoginResponse(data: any) {
    switch (data.login.__typename) {
      case LoginAPIResponseTypes.API_ERROR:
        return this.handleLoginError(data);
      case LoginAPIResponseTypes.AUTHENTICATE:
        return this.handleLoginSuccess(data);
      default:
        return this.loginErrorDefault;
    }
  }

  private handleLoginSuccess(data: any) {
    return LoginCompleteAction({
      payload: {
        userName: data.username,
        auth: {
          token: data.login.token,
          object_type: data.login.object_type,
          suspendedFlag: data.login.suspendedFlag ?? 0
        },
        loginSucceded: true,
        unhandled: false,
        apiError: { object_type: "", message: "", code: "" }
      }, faxFusion: data.faxFusion
    });
  }

  private handleLoginError(data: any) {
    return LoginErrorAction({
      payload: {
        userName: data.username,
        auth: {
          token: "",
          object_type: "",
          suspendedFlag: 0
        },
        loginSucceded: false,
        unhandled: true,
        apiError: {
          object_type: data.login.object_type,
          message: data.login.message,
          code: data.login.code
        }
      }
    });
  }

  private get loginErrorDefault() {
    return LoginErrorAction({
      payload: {
        userName: '',
        auth: {
          token: "",
          object_type: "",
          suspendedFlag: 0
        },
        loginSucceded: false,
        unhandled: true,
        apiError: {
          object_type: 'Error',
          message: 'Connection has not been stablished.',
          code: '5xx'
        }
      }
    });
  }

  buildErrorUrl(error: any) {
    let baseUrlError = '/error'
    if (error?.code !== undefined || error?.message !== undefined) {
      baseUrlError += '?'
    }
    if (error?.code !== undefined) {
      baseUrlError += 'error_code=' + error?.code
    }
    if (error?.message !== undefined) {
      baseUrlError += error?.code !== undefined ? '&message=' : 'message=' + error?.message
    }
    console.log('baseerrorurl->', baseUrlError)
    return baseUrlError
  }

}
