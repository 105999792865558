<div class="modal">
  <div class="toolbar space-between">
    <h3 class="title">
      {{ "home.number.title" | transloco }}
    </h3>
    <button mat-icon-button aria-label="close dialog" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>


  <div class="hr"></div>

  <div class="fields">

    <div class="fieldset">
      <label for="home-number">{{'home.number.label' | transloco}}:</label>
      <input name="home-number" matInput formControlName="companyName" />
    </div>

  </div>

  <div class="hr"></div>

  <div class="update-button-container">
    <button
      id="update-button"
      name="update-button"
      aria-labelledby="update-button-label"
      class="btn btn-md btn-info update-button">
      <label>
        {{ "billing.usage.update" | transloco }}
      </label>
      <mat-icon>play_circle_filled</mat-icon>
    </button>
  </div>
</div>

