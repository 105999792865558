<h1 class="title-dialog" mat-dialog-title align="center">
  {{ data.isCreating ? ("general.addTag" | transloco) : ("general.removeTag" | transloco) }}
</h1>
<div mat-dialog-content>
  <label id="dialog-title-label">{{
    data.isCreating
    ? ("general.tag.createMessage" | transloco)
    : ("general.tag.deleteMessage" | transloco)
    }}:</label>
  <form [formGroup]="form">
    <ng-container *ngIf="!data.isCreating">
      <mat-radio-group aria-labelledby="dialog-title-label" class="radio-group" formControlName="selectedTag">
        <mat-radio-button class="radio-button" *ngFor="let item of data.tags" [value]="item" aria-label="{{ item }}">
          {{ item }}
        </mat-radio-button>
      </mat-radio-group>
    </ng-container>
    <ng-container *ngIf="data.isCreating">
      <mat-chip-listbox role="list" class="chipbox">
        <mat-chip-option selectable="false" role="listitem" *ngFor="let item of data.tags" aria-label="{{ item }}">{{
          item
          }}</mat-chip-option>
      </mat-chip-listbox>

      <mat-form-field appearance="fill" [style.width.%]="100">
        <input matInput formControlName="selectedTag" maxlength="30" [placeholder]="'general.tagNameCustom' | transloco"
          id="tag-dialog-name" aria-labelledby="dialog-title-label" autocomplete="tag-name" />
      </mat-form-field>
    </ng-container>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-button class="btn btn-md" (click)="onNoClick()">
    {{ "general.cancel" | transloco }}
  </button>
  <button mat-button class="btn btn-md btn-info" [mat-dialog-close]="form.value.selectedTag" cdkFocusInitial
    [disabled]="form.invalid">
    {{ "general.submit" | transloco }}
  </button>
</div>