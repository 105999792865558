import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ComposeFeatureState } from '../index';

export const selectComposeState = createFeatureSelector<ComposeFeatureState>("compose");

export const selectFaxCreate = createSelector(selectComposeState, state => state.composeForm.faxCreate);
export const selectFaxCreatedError = createSelector(selectComposeState, state => state.composeForm.isError);
export const selectFaxCreatedSucceed = createSelector(selectComposeState, state => state.composeForm.loadedFax);
export const selectFaxCreateBeingSent = createSelector(selectComposeState, state => state.composeForm.loadingFax);
export const selectFormAction = createSelector(selectComposeState, state => state.composeForm.formAction);
export const selectFaxForwardSingle = createSelector(selectComposeState, state => state.composeForm.forwardFaxSingle);
export const selectFaxForwardMultiple = createSelector(selectComposeState, state => state.composeForm.forwardFaxMultiple);

