import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, tap } from 'rxjs/operators';
import { ComposeApi } from '../../api/compose.api';
import { FaxCreate, ForwardFaxMultipleFaxesResult, ForwardFaxSingleFaxResult } from '../../models/compose.model';
import { contactComposeStart, createFaxFail, createFaxStart, createFaxSuccedded, forwardFaxMultipleReceive, forwardFaxMultipleSend, forwardFaxMultipleStart, forwardFaxSingleReceive, forwardFaxSingleSend, forwardFaxSingleStart, replyComposeStart } from '../actions/compose.actions';
import * as fromStore from '../../../auth/store/reducers';
import { refreshAuth } from '../../../auth/store/actions/auth.actions';
import { Router } from '@angular/router';
@Injectable()
export class ComposeEffects {

  constructor(
    private router: Router,
    private actions$: Actions,
    private composeApi: ComposeApi,
    private store: Store<fromStore.AppState>) { }

  prepareSendFax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createFaxStart),
      switchMap((action) => {
        return this.composeApi.faxPrepareSend(action.serviceKey, action.input)
          .pipe(
            tap(value => {
              let res = JSON.parse(JSON.stringify(Object.assign({}, value)));
              if (res.data?.faxPrepareSend.code === "401" &&
                res.data?.faxPrepareSend.message === "ACCESS_TOKEN_EXPIRED" &&
                res.data?.faxPrepareSend.__typename === "ApiError") {
                this.store.dispatch(refreshAuth());
              }
            }),
            map((response: any) => {
              if (response.data?.faxPrepareSend?.__typename === 'FaxCreate') {
                const created: FaxCreate = { ...response.data.faxPrepareSend }
                console.info('about to change fax create: ', created);
                return createFaxSuccedded({ faxPrepareSend: created })
              }
              return createFaxFail({ data: response })
            })
          );
      })
    )
  );

  replyComposeStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(replyComposeStart),
      tap(_ => this.router.navigate(['/compose']))),
    { dispatch: false }
  );

  fowardComposeStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(forwardFaxSingleStart),
      tap(_ => this.router.navigate(['/compose', { forward: true }]))),
    { dispatch: false }
  );

  forwardFaxMultipleStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(forwardFaxMultipleStart),
      tap(_ => this.router.navigate(['/compose', { forward: true }]))),
    { dispatch: false }
  );

  contactComposeStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactComposeStart),
      tap(_ => this.router.navigate(['/compose']))),
    { dispatch: false }
  );

  forwardFaxSingleSend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(forwardFaxSingleSend),
      switchMap((action) => this.composeApi.forwardFaxSingle(action.input)),
      map(result => forwardFaxSingleReceive({ model: <ForwardFaxSingleFaxResult>result.data }))
    )
  );


  forwardFaxMultipleSend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(forwardFaxMultipleSend),
      switchMap((action) => this.composeApi.forwardFaxMultiple(action.input)),
      map(result => forwardFaxMultipleReceive({ model: <ForwardFaxMultipleFaxesResult>result.data }))
    )
  );
}
