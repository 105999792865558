import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HealthcheckRoutingModule } from './healthcheck-routing.module';
import { HealthcheckComponent } from './components/healthcheck.component';
import { TranslocoRootModule } from 'src/transloco-root.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    HealthcheckComponent
  ],
  providers: [],
  imports: [
    CommonModule,
    HealthcheckRoutingModule,
    TranslocoRootModule,
    SharedModule
  ]
})
export class HealthcheckModule { }
