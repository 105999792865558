<div [ngClass]="positionClass">
  <ul>
    <li>
      <select class="drop" id="languages" (change)="changeLanguage($event)" aria-label="Language Selection">
        <option class="drop-opener" *ngFor="let lang of (availableLanguages | async );" value="{{lang.id}}"
          [selected]="isSelectedLanguage(lang.id)">
          {{lang.label}}
        </option>
      </select>
    </li>
    <li hidden>
      <a id="help" href="javascript:void(0)">{{ 'footer.link.help.text' | transloco }}</a>
    </li>
    <li>
      <a id="privacy" [href]="privacyUrl" target="_blank">{{ 'footer.link.privacy.text' | transloco }}</a>
    </li>
    <li>
      <a id="terms" [href]="termsUrl" target="_blank">{{ 'footer.link.terms.text' | transloco }}</a>
    </li>
  </ul>
</div>
<ng-Container *ngIf="showConsent">
  <myportal-consent-popup (consent)="onUnderstandClick()"></myportal-consent-popup>
</ng-Container>
