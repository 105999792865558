import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ReportsApiService } from './reports-api.service';
import { Subject } from 'rxjs';

export interface SortElement {
  direction: string;
  nullHandling: string;
  ascending: boolean;
  property: string;
  ignoreCase: boolean;
}

export interface PageElement {
  offset: number;
  sort: [SortElement] | undefined;
  pageSize: number;
  pageNumber: number;
  paged: boolean;
  unpaged: boolean;
}

export interface UsageLog {
  receivedFaxes: number;
  sentFaxes: number;
  storageCode: string;
}

export interface ReceivedFax {
  messageTime: string;
  formattedMessageTime: string;
  destination: string;
  pages: number;
  csid: string;
  type: string;
  durationInSec: number;
}

export interface SentFax {
  messageTime: string;
  formattedMessageTime: string;
  destination: string;
  pages: number;
  durationInSeconds: number | string;
  formattedChargeAmount: string;
  subject: string;
  status: string;
  csid: string;
  msgType: string;
}

export interface DetailUsageLog {
  totalElements: number;
  totalPages: number;
  size: number;
  content: [SentFax];
  number: number,
  sort: [SortElement],
  pageable: PageElement,
  first: boolean,
  last: boolean,
  numberOfElements: number,
  empty: boolean
}

export interface dateFilter {
  start: string;
  end: string;
}

export interface inboudFilter {
  start: string;
  end: string;
  currentDID: string;
}

@Injectable()
export class ReportsService {

  receiveFilter$: Subject<inboudFilter> = new Subject<inboudFilter>();
  sendFilter$: Subject<dateFilter> = new Subject<dateFilter>();

  constructor(private http: HttpClient, private reportsApi: ReportsApiService) { }

  /// Received logs from local file, (before deployment of fax fusion)
  getReceivedLogs() {
    return this.http.get('../assets/data/received_logs_new.json');
  }

  ///Sent logs from local file, (before deployment of fax fusion)
  getSentLogs() {
    return this.http.get('../assets/data/sent_logs_new.json');
  }

  /// Totals from local file, before deployment of fax fusion
  getInboundTotal() {
    return this.getReceivedLogs().pipe(map((r: any) => r.reduce((prev: number, cur: any) => prev + 1, 0)));
  }

  /// Also totals from local file, before deployment of fax fusion
  getOutboundTotal() {
    return this.getSentLogs().pipe(map((r: any) => r.reduce((prev: any, cur: any) => prev + 1, 0)));
  }

  /// Calls Fax Fusion API to get usage with integrated authentication
  getUsageReport(currentDID: string) {
    return this.reportsApi.getUsage(currentDID);
  }

  getUsageSentLogs(fromDate: string, toDate: string, pageIndex: number, pageSize: number) {
    return this.reportsApi.getSendLogs(fromDate, toDate, pageIndex, pageSize);
  }

  getUsageReceivedLogs(fromDate: string, toDate: string, currentDID: string, pageIndex: number, pageSize: number) {
    return this.reportsApi.getReceivedLogs(fromDate, toDate, currentDID, pageIndex, pageSize);
  }

  callReceiveReport(startDate: string, endDate: string, currentDID: string) {
    this.receiveFilter$.next({ start: startDate, end: endDate, currentDID: currentDID });
  }

  callSendReport(startDate: string, endDate: string) {
    this.sendFilter$.next({ start: startDate, end: endDate });
  }
}
