import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HealthcheckComponent } from './components/healthcheck.component';

const routes: Routes = [
  {
    path: '',
    component: HealthcheckComponent    
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HealthcheckRoutingModule { }
