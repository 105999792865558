
export enum SortBy {
    DATE = "DATE",
    FROM = "FROM",
    TO = "TO",
    SUBJECT ="SUBJECT"
}

export enum SortDirection {
    ASC = "ASC",
    DESC = "DESC"
}

export interface ApiError {
    object_type: string
    code: string
    message: string
}

export enum SearchType {
    METADATA,
    FULL_TEXT
}

export enum ContactOperationStatus {
    CREATED = 'CREATED',
    CREATE_ERROR = 'CREATE_ERROR',
    UPDATED = 'UPDATED',
    UPDATE_ERROR = 'UPDATE_ERROR',
    DUPLICATE_CONTACT = 'DUPLICATE_CONTACT',
    DELETED = 'DELETED',
    DELETE_ERROR = 'DELETE_ERROR'
}