import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DEFAULT_THUMB_PREVIEW} from 'src/app/shared/constants/constants';

@Component({
  selector: 'myportal-printing-item',
  templateUrl: './printing-item.component.html',
  styleUrls: ['./printing-item.component.scss']
})
export class PrintingItemComponent {

  defaultPreview = DEFAULT_THUMB_PREVIEW;

  @Output() onLoadAll = new EventEmitter<void>();

  protected _imageList: string[];
  private onLoadCount: number = 0;

  constructor() {
  }

  @Input() set imageList(imageList: string[]) {
    this._imageList = imageList;
    this.onLoadCount = 0;
  }

  get isLoaded(): boolean {
    return (this.onLoadCount == null && this._imageList == null) ||
            (this.onLoadCount != null && this._imageList != null && this.onLoadCount == this._imageList.length)
  }

  protected onLoad(index: number) {
    this.onLoadCount++;
    if (this.isLoaded && this.onLoadAll != null) {
      this.onLoadAll.emit();
    }
  }

}
