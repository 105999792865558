import { gql } from 'apollo-angular';

export const FAX_PREPARE_SEND_MUTATION = gql`
mutation faxPrepareSend($serviceKey: String, $input: CreateFax) {
  faxPrepareSend(serviceKey: $serviceKey, input: $input) {
    __typename
    ... on FaxCreate {
      object_type
      fax {
        faxId
      }
      s3Url
    }
    ... on ApiError {
      code
      message
    }
  }
}
`;

export const FORWARD_FAX_SINGLE_FAX = gql`
mutation (
  $faxId: String!
  $recipients: [Recipient]!
  $serviceKey: String!
  $outboundServiceKey: String!
  $fromEmail: String!
  $note: String
) {
  forwardFaxSingleFax(
    faxId: $faxId
    recipients: $recipients
    serviceKey: $serviceKey
    outboundServiceKey: $outboundServiceKey
    fromEmail: $fromEmail
    note: $note
  ) {
    __typename
    ... on ForwardFaxSingleFax {
        object_type
        status
      }
    ... on ApiError {
      object_type
      code
      message
    }
  }
}
`;

export const FORWARD_FAX_MULTIPLE_FAX = gql`
mutation forwardFaxMultipleFax(
  $faxIds: [String]!
  $recipients: [Recipient]!
  $serviceKey: String!
  $outboundServiceKey: String!
  $fromEmail: String!
  $note: String
  $subject: String
) {
  forwardFaxMultipleFax(
    faxIds: $faxIds
    recipients: $recipients
    serviceKey: $serviceKey
    outboundServiceKey: $outboundServiceKey
    fromEmail: $fromEmail
    note: $note
    subject: $subject
  ) {
    __typename

    ... on ApiError {
      object_type
      code
      message
    }
  }
}
`;

export const GENERATE_SENDFAX_TOKEN = gql`
query ($hashToken: String!) {
  generateSendToken(hashToken: $hashToken){
    ... on GenerateSendToken{
      object_type
      sendTokenHash
    }
    ... on ApiError{
      object_type
      code
      message
    }
  }
}
`;

